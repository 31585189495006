import React from 'react'
import { Header } from 'semantic-ui-react'
import Link from './link'

export default function Copyright({ inverted }) {
	const year = new Date().getFullYear();
	return <Header inverted={inverted} size="small">
		<Header.Subheader>
			{year <= 2020 ? '2020 © ' : ('2020 - ' + year + ' © ')}
			<Link href="https://konsept-it.no" target="_blank" className={'link-secondary link-underline' + (inverted ? ' inverted' : '')}>Konsept-IT AS</Link>
		</Header.Subheader>
	</Header>
}