import { Response, PaginatedResponse } from '..'
import { buildQuery } from '../../helpers'

// GET /api/autotask/ticket-notes
// POST /api/autotask/ticket-notes/search
export async function getTicketNotes(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/autotask/ticket-notes' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/autotask/ticket-notes/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/autotask/ticket-notes
export async function createTicketNote(data, requestOptions = {}) {
	return Response.request('/api/autotask/ticket-notes', data, requestOptions)
}

// GET /api/autotask/ticket-notes/:id
export async function getTicketNote(id, requestOptions = {}) {
	return Response.request('/api/autotask/ticket-notes/' + encodeURIComponent(id), null, requestOptions)
}

// PUT /api/autotask/ticket-notes/:id
export async function updateTicketNote(id, data, requestOptions = {}) {
	return Response.request('/api/autotask/ticket-notes/' + encodeURIComponent(id), data, Object.assign({ method: 'PUT' }, requestOptions))
}

export const NOTE_TYPE_TASK_SUMMARY = 1; // Task Summary
export const NOTE_TYPE_TASK_DETAIL = 2; // Task Detail
export const NOTE_TYPE_TASK_NOTES = 3; // Task Notes
export const NOTE_TYPE_WORKFLOW_RULE_NOTE_TASK = 13; // Workflow Rule Note - Task
export const NOTE_TYPE_DUPLICATE_TICKET_NOTE = 15; // Duplicate Ticket Note
export const NOTE_TYPE_OUTSOURCE_WORKFLOW_NOTE = 16; // Outsource Workflow Note
export const NOTE_TYPE_SURVEYS = 17; // Surveys
export const NOTE_TYPE_CLIENT_PORTAL_NOTE = 18; // Client Portal Note
export const NOTE_TYPE_TASKFIRE_NOTE = 19; // Taskfire Note
export const NOTE_TYPE_WORKFLOW_RULE_ACTION_NOTE = 91; // Workflow Rule Action Note
export const NOTE_TYPE_FORWARD_MODIFY_NOTE = 92; // Forward/Modify Note
export const NOTE_TYPE_MERGED_INTO_TICKET = 93; // Merged Into Ticket
export const NOTE_TYPE_ABSORBED_ANOTHER_TICKET = 94; // Absorbed Another Ticket
export const NOTE_TYPE_COPIED_TO_PROJECT = 95; // Copied to Project
export const NOTE_TYPE_RMM_NOTE = 99; // RMM Note
export const NOTE_TYPE_BDR_NOTE = 100; // BDR Note

export const PUBLISH_ALL_AUTOTASK_USERS = 1; // All Autotask Users
export const PUBLISH_INTERNAL = 2; // Internal Project Team
export const PUBLISH_INTERNAL_AND_CO_MANAGED = 4; // Internal & Co-Managed