import React from 'react'
import { Header, Icon, List, Message, Placeholder } from 'semantic-ui-react'
import ResourceCard from './resource_card'
import { isObject } from '../helpers'
import Link from './link'

export default function DomainHostingServiceCard({ loading, value }) {
	loading = loading ?? false
	value = value ?? null

	let content = <ResourceCard.Content>Skriv inn et domenenavn...</ResourceCard.Content>
	if (loading) {
		content = <ResourceCard.Content>
			<Placeholder>
				<Placeholder.Line />
			</Placeholder>
		</ResourceCard.Content>
	} else if (isObject(value)) {
		content = <ResourceCard.Content>
			<Header size="small">{value.name}</Header>
			{value.type === 'kit_redir' && value.redirect ? <p>Videresending til <Link href={value.redirect.url} rel="noopener nofollow noreferrer" target="_blank"><Icon name="external" />&ensp;{value.redirect.domain ?? value.redirect.url}</Link></p> : null}
			<List bulleted>{value.hosts.map((host, i) => <List.Item key={'hosting_host_' + i}><code>{host.hostname}</code></List.Item>).concat(value.ips.map((ip, i) => <List.Item key={'hosting_ip_' + i}><code>{ip}</code></List.Item>))}</List>
		</ResourceCard.Content>
	} else {
		content = <ResourceCard.Content>
			<Message error content="Fant ingen hosting-tjeneste på domenet." />
		</ResourceCard.Content>
	}

	return <ResourceCard
		icon="server"
		title="Hosting-tjeneste"
	>
		{content}
	</ResourceCard>
}