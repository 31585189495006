import React, { useEffect, useState } from 'react'
import { parsePhoneNumber, formatIncompletePhoneNumber } from 'libphonenumber-js/min'
import { Segment, Container, Header, Card, Popup, Icon, Label, List, Message, Loader, Placeholder, Button } from 'semantic-ui-react'
import { genUUID, buildQuery, append, preventDefault, navigate, handleRedirect } from '../../helpers'
import { getTickets } from '../../api/autotask/tickets'
import { getCompany } from '../../api/autotask/companies'
import Link from '../../components/link'
import { Await, defer, useLoaderData, useNavigate } from 'react-router-dom'
import { getContacts } from '../../api/autotask/contacts'
import { useMessages } from '../root'

const internalPhoneBook = {
	'40': 'sentralbord',
	'41': 'Marius sitt nummer',
	'42': 'Robin sitt nummer',
	'43': 'Alexander sitt nummer',
	'44': 'Henrik sitt nummer',
	'45': 'ukjent nummer',
	'46': 'ukjent nummer',
	'47': 'ukjent nummer',
	'48': 'ukjent nummer',
	'49': 'vakttelefon',
}

const parseNumber = text => {
	if (typeof text !== 'string') return null
	const options = {
		defaultCountry: 'NO',
		defaultCallingCode: '47'
	}
	try {
		const phoneNumber = parsePhoneNumber(text, options)
		return {
			int: phoneNumber.number,
			nat: formatIncompletePhoneNumber(phoneNumber.number, options),
			tel: 'tel:' + phoneNumber.number,
		}
	} catch (e) {
		return null
	}
}

export async function loader({ request }) {
	const phone = new URL(request.url).searchParams.get('phone')
	if (!phone) {
		throw new Error('Mangler query parameter "phone".')
	}

	let callingNumberRaw = phone
	let calledNumberRaw = ''
	let calledNumberDescription = 'personlig nummer'
	if (phone.length > 8) {
		for (const prefix in internalPhoneBook) {
			if (callingNumberRaw.startsWith(prefix)) {
				calledNumberRaw = '+47313090' + prefix
				calledNumberDescription = internalPhoneBook[prefix]
				callingNumberRaw = callingNumberRaw.substring(prefix.length)
				break
			}
		}
	}

	const callingNumber = parseNumber(callingNumberRaw)
	const calledNumber = append(parseNumber(calledNumberRaw) ?? {}, {
		desc: calledNumberDescription
	})

	const contacts = getContacts([
		{op: 'or', items: [
			{op: 'eq', field: 'phone', value: callingNumber.int},
			{op: 'eq', field: 'mobilePhone', value: callingNumber.int},
			{op: 'eq', field: 'alternatePhone', value: callingNumber.int},
		]}
	], { limit: 10 }).then(({data}) => data.map(contact => append(contact, {
		alternatePhone: parseNumber(contact.alternatePhone),
		mobilePhone: parseNumber(contact.mobilePhone),
		phone: parseNumber(contact.phone),
	})))

	return defer({ calledNumber, callingNumber, contacts })
}

const initialContactTicketCount = {}
const initialContactCompanyTicketCount = {}
const initialContactCompanies = {}

export default function AutotaskCallToInteractionToolPage() {
	const [, setMessages] = useMessages()

	const { calledNumber, callingNumber, contacts } = useLoaderData()
	const [contactTicketCount, setContactTicketCount] = useState(initialContactTicketCount)
	const [contactCompanyTicketCount, setContactCompanyTicketCount] = useState(initialContactCompanyTicketCount)
	const [contactCompanies, setContactCompanies] = useState(initialContactCompanies)
	const [phoneNumbersInt, setPhoneNumbersInt] = useState([])
	const [contactsLoaded, setContactsLoaded] = useState(false)

	const routerNavigateFn = useNavigate()

	const renderContactName = contact => {
		if (!contact) return null
		return [contact.namePrefix, contact.firstName, contact.middleInitial, contact.lastName, contact.nameSuffix].filter(v => typeof v === 'string' && v.trim().length > 0).join(' ');
	}

	useEffect(() => {
		contacts.then(contacts => {
			setPhoneNumbersInt(contacts.flatMap(contact => [
				contact.mobilePhone !== null ? contact.mobilePhone.int : null,
				contact.phone !== null ? contact.phone.int : null,
				contact.alternatePhone !== null ? contact.alternatePhone.int : null,
			].filter(int => int !== null)))
			return Promise.allSettled(contacts.map(contact => Promise.allSettled([
				getTickets([{op: 'eq', field: 'contactID', value: contact.id}], {limit: 0})
					.then(res => {
						handleRedirect(res, routerNavigateFn)
						const {data_info: { count: ticketCount }} = res
						setContactTicketCount(contactTicketCount => append(contactTicketCount, {
							[contact.id]: ticketCount,
						}))
					}),
				getCompany(contact.companyID, { phone_format: 'int,nat,tel' })
					.then(res => {
						handleRedirect(res, routerNavigateFn)
						const {data: company} = res

						setPhoneNumbersInt(phoneNumbersInt => phoneNumbersInt.concat([
							company.phone !== null ? company.phone.int : null,
							company.alternatePhone1 !== null ? company.alternatePhone1.int : null,
							company.alternatePhone2 !== null ? company.alternatePhone2.int : null,
						]))

						setContactCompanies(contactCompanies => append(contactCompanies, {
							[contact.id]: company,
						}))

						return company.id
					})
					.then(company_id => getTickets([{op: 'eq', field: 'companyID', value: company_id}], {limit: 0}))
					.then(res => {
						handleRedirect(res, routerNavigateFn)
						const {data_info: { count: companyTicketCount }} = res
						setContactCompanyTicketCount(contactCompanyTicketCount => append(contactCompanyTicketCount, {
							[contact.id]: companyTicketCount,
						}))
					}),
			])))
		}).then(results => {
			let success = true
			for (const result of results) {
				if (result.status !== 'fulfilled') {
					console.error('Error looking up number: ', result.reason)
					success = false
				}
				for (const result2 of result.value) {
					if (result2.status !== 'fulfilled') {
						console.error('Error looking up number: ', result2.reason)
						success = false
					}
				}
			}
			if (success) {
				setContactsLoaded(true)
			} else {
				setMessages(messages => messages.concat({
					key: 'lookup_number_error_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'error',
					icon: 'exclamation circle',
					title: 'Oppslag feilet',
					content: 'En feil oppstod ved oppslag av nummeret ' + callingNumber.int
				}))
				setContactsLoaded(true)
			}
		})
	}, [contacts, callingNumber, routerNavigateFn, setMessages])

	const newSmsLink = '/sms/messages/new' + buildQuery({
		recipient: callingNumber.int
	})

	const newDirectionsSmsLink = '/sms/messages/new' + buildQuery({
		recipient: callingNumber.int,
		contents: "Veivisning til Konsept-IT, Eternitveien 30:\n\nFra Slemmestadveien, kjør inn på Eternitveien mot Slemmestad Handelspark. Kjør forbi handelsparken. Kjør forbi Eternitveien 10. Ta til venstre inn på Bjerkås Næringspark. Ta til venstre ved Plastmo-bygget, her kan du parkere. Du finner oss i 2. etg i Plastmo-bygget.\n\nhttps://maps.apple.com/?address=Eternitveien%2030%2C%203470%20Slemmestad%2C%20Norge"
	})

	const numberLookup180Link = 'https://www.180.no/varsel/nummer/' + encodeURIComponent(callingNumber.int)
	const numberLookupGulesiderLink = 'https://www.gulesider.no/' + encodeURIComponent(callingNumber.int) + '/hvem+har+ringt'
	const numberLookup1881Link = 'https://www.1881.no/' + buildQuery({ query: callingNumber.int })

	return <>
		<Segment vertical padded="very">
			<Container>
				<Header size="huge">
					Telefonsamtale
					<Header.Subheader>Fra <Link href={callingNumber.tel}>{callingNumber.nat}</Link></Header.Subheader>
				</Header>

				<p>
					<Button
						as="a"
						href={newDirectionsSmsLink}
						onClick={preventDefault(e => navigate(newDirectionsSmsLink, '_blank', {}, routerNavigateFn))}
						target="_blank"
					>
						<Icon name="send" /> Send veibeskrivelse-SMS
					</Button>
					<Button
						basic
						as="a"
						href={newSmsLink}
						onClick={preventDefault(e => navigate(newSmsLink, '_blank', {}, routerNavigateFn))}
						target="_blank"
					>
						<Icon name="send" /> Send SMS
					</Button>
				</p>

				<p>
					<Button
						as="a"
						href={numberLookup180Link}
						onClick={preventDefault(e => navigate(numberLookup180Link, '_blank', {}, routerNavigateFn))}
						target="_blank"
						rel="noopener nofollow noreferrer"
					>
						<Icon name="external" /> 180.no
					</Button>
					<Button
						as="a"
						href={numberLookupGulesiderLink}
						onClick={preventDefault(e => navigate(numberLookupGulesiderLink, '_blank', {}, routerNavigateFn))}
						target="_blank"
						rel="noopener nofollow noreferrer"
					>
						<Icon name="external" /> 1880.no / Gule Sider
					</Button>
					<Button
						as="a"
						href={numberLookup1881Link}
						onClick={preventDefault(e => navigate(numberLookup1881Link, '_blank', {}, routerNavigateFn))}
						target="_blank"
						rel="noopener nofollow noreferrer"
					>
						<Icon name="external" /> 1881.no
					</Button>
				</p>

				{phoneNumbersInt.includes(callingNumber.int) ? null : <Card>
					<Card.Content>
						<Label attached="top" image>
							<div className="content">
								<Icon name="user" />&ensp;Innringer
							</div>
						</Label>
						<Card.Header>
							<React.Suspense fallback="Henter informasjon...">
								<Await resolve={contacts}>
									{contacts => contacts.length > 0 ? renderContactName(contacts[0]) : 'Ukjent nummer'}
								</Await>
							</React.Suspense>
						</Card.Header>
						<Card.Description>
							<List>
								<List.Item>
									<List.Icon name="phone" />
									<List.Content><Link href={callingNumber.tel} className="text-bold">{callingNumber.nat}</Link></List.Content>
								</List.Item>
							</List>
						</Card.Description>
					</Card.Content>
					<React.Suspense>
						<Await resolve={contacts}>
							{contacts => contacts.length > 0 ? null : <Card.Content extra>
								<Link
									href={'https://ww19.autotask.net/Autotask/AutotaskExtend/ExecuteCommand.aspx?Code=NewTicket&Phone=' + encodeURIComponent(callingNumber.int)}
									target="tmp"
									popupOptions={{width: 1, height: 1}}
								>
									<Icon name="plus" /> Opprett sak
								</Link>
							</Card.Content>}
						</Await>
					</React.Suspense>
				</Card>}

				<p className="text-secondary">Anrop mottatt via {calledNumber.desc}</p>
			</Container>
		</Segment>

		<React.Suspense>
			<Await resolve={contacts}>
				{contacts => contacts.length > 0 ? contacts.map((contact, i) => {
					const contactNewTicketID = genUUID()
					return <Segment vertical padded="very" secondary key={'contact_' + contact.id}>
						<Container>
							{contactsLoaded || contactCompanies.hasOwnProperty(contact.id) ? (contactCompanies.hasOwnProperty(contact.id) ? <Header size="large">{contactCompanies[contact.id].companyName}</Header> : null) : <Header size="large">
								<Placeholder>
									<Placeholder.Header>
										<Placeholder.Line length="very long" />
									</Placeholder.Header>
								</Placeholder>
							</Header>}

							<Card.Group stackable>
								<Card>
									<Card.Content>
										<Label attached="top" image>
											<div className="content">
												<Icon name="user" />&ensp;Kontakt
											</div>
											<Label.Detail>
												<Popup
													inverted
													content="Åpne kontakt i Autotask"
													trigger={<Link
														href={'https://ww19.autotask.net/Autotask/AutotaskExtend/ExecuteCommand.aspx?Code=OpenContact&ContactID=' + encodeURIComponent(contact.id)}
														target="tmp"
														popupOptions={{width: 1, height: 1}}
													><Icon name="external" />&ensp;Åpne</Link>}
												/>
											</Label.Detail>
										</Label>
										<Card.Header>{renderContactName(contact)} <Popup inverted content={contact.isActive ? (contactCompanies.hasOwnProperty(contact.id) && !contactCompanies[contact.id].isActive ? 'Kontakt aktiv, firma inaktiv' : 'Aktiv') : 'Inaktiv'} trigger={<Icon name="dot circle outline" color={contact.isActive ? (contactCompanies.hasOwnProperty(contact.id) && !contactCompanies[contact.id].isActive ? 'yellow' : 'green') : 'red'} />} /></Card.Header>
										{contact.title || contact.userDefinedFields['External Company'] || contactCompanies.hasOwnProperty(contact.id) ? <Card.Meta>{contact.title ? contact.title + (contactCompanies.hasOwnProperty(contact.id) ? ', ' : '') : null}{contact.userDefinedFields['External Company'] ? contact.userDefinedFields['External Company'] : (contactCompanies.hasOwnProperty(contact.id) ? contactCompanies[contact.id].companyName : null)}</Card.Meta> : null}
										{contact.userDefinedFields['External Company'] && contactCompanies.hasOwnProperty(contact.id) ? <Card.Meta>på vegne av {contactCompanies[contact.id].companyName}</Card.Meta> : null}
										<Card.Description>
											<List>
												{contact.mobilePhone && contact.mobilePhone.tel ? <List.Item>
													<List.Icon name="mobile" />
													<List.Content><Link href={contact.mobilePhone.tel} className={callingNumber.int === contact.mobilePhone.int ? 'text-bold' : ''}>{contact.mobilePhone.nat}</Link></List.Content>
												</List.Item> : null}
												{contact.phone && contact.phone.tel ? <List.Item>
													<List.Icon name="phone" />
													<List.Content><Link href={contact.phone.tel} className={callingNumber.int === contact.phone.int ? 'text-bold' : ''}>{contact.phone.nat}</Link></List.Content>
												</List.Item> : null}
												{contact.alternatePhone && contact.alternatePhone.tel ? <List.Item>
													<List.Icon name="phone" />
													<List.Content><Link href={contact.alternatePhone.tel} className={callingNumber.int === contact.alternatePhone.int ? 'text-bold' : ''}>{contact.alternatePhone.nat}</Link></List.Content>
												</List.Item> : null}
												{contact.emailAddress ? <List.Item>
													<List.Icon name="mail" />
													<List.Content><Link href={'mailto:' + encodeURIComponent(contact.emailAddress)}>{contact.emailAddress}</Link></List.Content>
												</List.Item> : null}
												{contact.emailAddress2 ? <List.Item>
													<List.Icon name="mail" />
													<List.Content><Link href={'mailto:' + encodeURIComponent(contact.emailAddress2)}>{contact.emailAddress2}</Link></List.Content>
												</List.Item> : null}
												{contact.emailAddress3 ? <List.Item>
													<List.Icon name="mail" />
													<List.Content><Link href={'mailto:' + encodeURIComponent(contact.emailAddress3)}>{contact.emailAddress3}</Link></List.Content>
												</List.Item> : null}
											</List>
										</Card.Description>
									</Card.Content>
									<Card.Content extra>
										<Link
											href={'https://ww19.autotask.net/Mvc/ServiceDesk/TicketNew.mvc/Create?categoryId=103&entityType=5&entityId=' + encodeURIComponent(contact.id)}
											target={'new_ticket' + contactNewTicketID}
											popupOptions={{width: 1230, height: 750}}
										>
											<Icon name="plus" /> Opprett sak
										</Link>
										<span className="right floated">
											<Link
												href={'https://ww19.autotask.net/Mvc/ServiceDesk/TicketGridInsightDrilldown.mvc?drilldownType=ByAccountContact&filterType=All&recurringType=All&entityId=' + encodeURIComponent(contact.id)}
												target="view_tickets_by_contact"
												popupOptions={{width: 1230, height: 750}}
											>
												Vis saker {contactTicketCount.hasOwnProperty(contact.id) ? ' (' + contactTicketCount[contact.id] + ')' : null}&ensp;<Icon name="inbox" className="right" />
											</Link>
										</span>
									</Card.Content>
									{contact.note ? <Message attached info content={contact.note} className="small-icon" /> : null}
								</Card>

								{contactCompanies.hasOwnProperty(contact.id) ? <Card>
									<Card.Content>
										<Label attached="top" image>
											<div className="content">
												<Icon name="building" />&ensp;Firma
											</div>
											<Label.Detail>
												<Popup
													inverted
													content="Åpne firma i Autotask"
													trigger={<Link
														href={'https://ww19.autotask.net/Autotask/AutotaskExtend/ExecuteCommand.aspx?Code=OpenAccount&AccountID=' + encodeURIComponent(contactCompanies[contact.id].id)}
														target="tmp"
														popupOptions={{width: 1, height: 1}}
													><Icon name="external" />&ensp;Åpne</Link>}
												/>
											</Label.Detail>
										</Label>
										<Card.Header>{contactCompanies[contact.id].companyName} <Popup inverted content={contactCompanies[contact.id].isActive ? 'Aktiv' : 'Inaktiv'} trigger={<Icon name="dot circle outline" color={contactCompanies[contact.id].isActive ? 'green' : 'red'} />} /></Card.Header>
										<Card.Description>
											<List>
												{contactCompanies[contact.id].phone && contactCompanies[contact.id].phone.tel ? <List.Item>
													<List.Icon name="phone" />
													<List.Content><Link href={contactCompanies[contact.id].phone.tel} className={callingNumber.int === contactCompanies[contact.id].phone.int ? 'text-bold' : ''}>{contactCompanies[contact.id].phone.nat}</Link></List.Content>
												</List.Item> : null}
												{contactCompanies[contact.id].alternatePhone1 && contactCompanies[contact.id].alternatePhone1.tel ? <List.Item>
													<List.Icon name="phone" />
													<List.Content><Link href={contactCompanies[contact.id].alternatePhone1.tel} className={callingNumber.int === contactCompanies[contact.id].alternatePhone1.int ? 'text-bold' : ''}>{contactCompanies[contact.id].alternatePhone1.nat}</Link></List.Content>
												</List.Item> : null}
												{contactCompanies[contact.id].alternatePhone2 && contactCompanies[contact.id].alternatePhone2.tel ? <List.Item>
													<List.Icon name="phone" />
													<List.Content><Link href={contactCompanies[contact.id].alternatePhone2.tel} className={callingNumber.int === contactCompanies[contact.id].alternatePhone2.int ? 'text-bold' : ''}>{contactCompanies[contact.id].alternatePhone2.nat}</Link></List.Content>
												</List.Item> : null}
												{contactCompanies[contact.id].userDefinedFields.hasOwnProperty('Email') && contactCompanies[contact.id].userDefinedFields['Email'] ? <List.Item>
													<List.Icon name="mail" />
													<List.Content><Link href={'mailto:' + encodeURIComponent(contactCompanies[contact.id].userDefinedFields['Email'])}>{contactCompanies[contact.id].userDefinedFields['Email']}</Link></List.Content>
												</List.Item> : null}
											</List>
										</Card.Description>
									</Card.Content>
									<Card.Content extra>
										<Link
											href={'https://ww19.autotask.net/Autotask/AutotaskExtend/ExecuteCommand.aspx?Code=NewTicket&AccountID=' + encodeURIComponent(contactCompanies[contact.id].id)}
											target="tmp"
											popupOptions={{width: 1, height: 1}}
										>
											<Icon name="plus" /> Opprett sak
										</Link>
										<span className="right floated">
											<Link
												href={'https://ww19.autotask.net/Mvc/ServiceDesk/TicketGridInsightDrilldown.mvc?drilldownType=ByAccount&filterType=All&recurringType=All&entityId=' + encodeURIComponent(contactCompanies[contact.id].id)}
												target="view_tickets_by_company"
												popupOptions={{width: 1230, height: 750}}
											>
												Vis saker {contactCompanyTicketCount.hasOwnProperty(contact.id) ? ' (' + contactCompanyTicketCount[contact.id] + ')' : null}&ensp;<Icon name="inbox" className="right" />
											</Link>
										</span>
									</Card.Content>
								</Card> : null}
							</Card.Group>
						</Container>
					</Segment>
				}) : null}
			</Await>
		</React.Suspense>

		{contactsLoaded ? null : <Segment vertical padded="very" secondary>
			<Segment basic padded textAlign="center">
				<Loader active content="Henter informasjon..." />
			</Segment>
		</Segment>}
	</>
}