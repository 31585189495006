import React, { useState } from 'react'
import { Segment, Container, Header, Button, Form } from 'semantic-ui-react'
import { handleRedirect, preventDefault } from '../../helpers'
import { Response } from '../../api'
import { useNavigate } from 'react-router-dom'
import { useMessages } from '../root'
import TaskExecutionLink from '../../components/task_execution_link'

export default function InvoicingToolPage() {
	const [, setMessages] = useMessages()
	const [isE2, setIsE2] = useState(false)
	const [creatingInvoiceTaskExecution, setCreatingInvoiceTaskExecution] = useState(false)
	const [creatingUpdateInvoiceNumbersTaskExecution, setCreatingUpdateInvoiceNumbersTaskExecution] = useState(false)
	const [invoiceTaskExecutionId, setInvoiceTaskExecutionId] = useState(null)
	const [updateInvoiceNumbersTaskExecutionId, setUpdateInvoiceNumbersTaskExecutionId] = useState(null)
	const routerNavigateFn = useNavigate()

	const runInvoicing = () => {
		setCreatingInvoiceTaskExecution(true)

		Response.request('/tools/invoicing', { e2: isE2 })
			.then(res => {
				handleRedirect(res, routerNavigateFn)
				const { execution_id } = res.data;
				setCreatingInvoiceTaskExecution(false)
				setInvoiceTaskExecutionId(execution_id)
			})
			.catch(e => {
				handleRedirect(e.res, routerNavigateFn)
				console.error('Unable to start invoicing:', e)
				setMessages(messages => messages.concat({
					key: 'invoicing_error_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'error',
					icon: 'exclamation circle',
					title: 'En feil oppstod',
					content: e.message
				}))
				setCreatingInvoiceTaskExecution(false)
			})
	}

	const updateInvoiceNumbers = () => {
		setCreatingUpdateInvoiceNumbersTaskExecution(true)

		Response.request('/tools/invoicing/update-invoice-numbers', { e2: isE2 })
			.then(res => {
				handleRedirect(res, routerNavigateFn)
				const { execution_id } = res.data;
				setCreatingUpdateInvoiceNumbersTaskExecution(false)
				setInvoiceTaskExecutionId(execution_id)
			})
			.catch(e => {
				console.error('Unable to start invoicing:', e)
				setMessages(messages => messages.concat({
					key: 'invoicing_error_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'error',
					icon: 'exclamation circle',
					title: 'En feil oppstod',
					content: e.message
				}))
				setCreatingUpdateInvoiceNumbersTaskExecution(false)
				handleRedirect(e.res, routerNavigateFn)
			})
	}

	return <>
		<Segment vertical padded="very">
			<Container>
				<Header size="huge">Fakturering</Header>
				<p>Trykk på knappen for å sette i gang eksport av faktura fra Autotask til Tripletex ordre.</p>

				{invoiceTaskExecutionId === null ? null : <TaskExecutionLink
					id={invoiceTaskExecutionId}
					onDismiss={() => setInvoiceTaskExecutionId(null)}
					header="Fakturering startet"
				/>}

				{updateInvoiceNumbersTaskExecutionId === null ? null : <TaskExecutionLink
					id={updateInvoiceNumbersTaskExecutionId}
					onDismiss={() => setUpdateInvoiceNumbersTaskExecutionId(null)}
					header="Oppdatering av fakturanummer startet"
				/>}

				{invoiceTaskExecutionId !== null || updateInvoiceNumbersTaskExecutionId !== null ? null : <Form onSubmit={preventDefault()}>
					<Form.Radio
						label="Konsept-IT AS"
						checked={!isE2}
						disabled={creatingInvoiceTaskExecution}
						onChange={(_, data) => setIsE2(!data.checked)}
					/>
					<Form.Radio
						label="E2 Solutions AS"
						checked={isE2}
						disabled={creatingInvoiceTaskExecution}
						onChange={(_, data) => setIsE2(data.checked)}
					/>

					<Form.Field>
						<Button
							color="orange"
							loading={creatingInvoiceTaskExecution}
							disabled={creatingInvoiceTaskExecution || creatingUpdateInvoiceNumbersTaskExecution}
							onClick={preventDefault(() => runInvoicing())}
						>
							Sett i gang eksport
						</Button>

						<Button
							loading={creatingUpdateInvoiceNumbersTaskExecution}
							disabled={creatingInvoiceTaskExecution || creatingUpdateInvoiceNumbersTaskExecution}
							onClick={preventDefault(() => updateInvoiceNumbers())}
						>
							Sett i gang oppdatering av fakturanummer
						</Button>
					</Form.Field>
				</Form>}
			</Container>
		</Segment>
	</>
}