// General Allocation Codes - Active
export const ID_GENERAL_MANAGEMENT = 29682808; // Administrasjon
export const ID_GENERAL_USERADMIN = 29683518; // Brukeradministrasjon
export const ID_GENERAL_CUSTOMER_GUIDANCE = 29683542; // Brukerveiledning
export const ID_GENERAL_CHANGE = 29683547; // Endring
export const ID_GENERAL_CUSTOMER_SUPPORT = 29682801; // Brukerstøtte
export const ID_GENERAL_CUSTOMER_SUPPORT_REMOTE_OFFICE_WINDOWS = 29683520; // Fjernhjelp (Office/Windows)
export const ID_GENERAL_CUSTOMER_SUPPORT_REMOTE = 29683543; // Fjernhjelp
export const ID_GENERAL_CUSTOMER_SUPPORT_ONSITE = 29682800; // Onsite
export const ID_GENERAL_SETUP = 29683492; // Oppsett
export const ID_GENERAL_PLANNED_OVERTIME = 29683499; // Planlagt overtidsarbeid
export const ID_GENERAL_REPAIR_WARRANTY = 29683546; // Reparasjon (garanti)
export const ID_GENERAL_REPAIR_OR_UPGRADE = 29683545; // Reparasjon / oppgradering
export const ID_GENERAL_SALES = 29682860; // Salg
export const ID_GENERAL_WITHOUT_AGREEMENT = 29683529; // Uten serviceavtale
export const ID_GENERAL_ON_CALL = 29682802; // Vakt / Hasteoppdrag
export const ID_GENERAL_MAINTENANCE = 29682804; // Vedlikehold
export const ID_GENERAL_WEB_CONTENT = 29683544; // Web-innhold

// General Allocation Codes - Inactive
export const ID_GENERAL_CUSTOMER_SUPPORT_NON_BILLABLE = 29682861; // Brukerstøtte (ikke fakturerbar)
export const ID_GENERAL_CUSTOMER_SUPPORT_MS365 = 29683519; // Brukerstøtte (MS365)
export const ID_GENERAL_SETUP_NON_BILLABLE = 29683516; // Oppsett (ikke fakturerbar)
export const ID_GENERAL_PROJECT_PLANNING = 29683494; // Prosjektering
export const ID_GENERAL_SYSTEMS_DEVELOPMENT = 29683537; // Systemutvikling
export const ID_GENERAL_MAINTENANCE_NON_BILLABLE = 29683530; // Vedlikehold (ikke fakturerbar)
export const ID_GENERAL_WEB_DEVELOPMENT = 29683497; // Webutvikling

// Expense Codes
export const ID_EXPENSE_AIRLINE_TICKETS = 29683358; // Airline Tickets
export const ID_EXPENSE_BREAKFAST = 29683481; // Breakfast
export const ID_EXPENSE_CELL_PHONE = 29683486; // Cell Phone
export const ID_EXPENSE_DINNER = 29683483; // Dinner
export const ID_EXPENSE_ENTERTAINMENT_EXPENSE = 3; // Entertainment Expense
export const ID_EXPENSE_GAS = 29683479; // Gas
export const ID_EXPENSE_HOTEL = 29683357; // Hotel
export const ID_EXPENSE_INTERNET_ACCESS = 29683487; // Internet Access
export const ID_EXPENSE_LUNCH = 29683482; // Lunch
export const ID_EXPENSE_MILEAGE = 2; // Mileage
export const ID_EXPENSE_PARKING = 29683485; // Parking
export const ID_EXPENSE_RENTAL_CAR = 29683480; // Rental Car
export const ID_EXPENSE_TAXI_BUS_GROUND_TRANSPORTATION = 29683484; // Taxi/Bus/Ground Transportation
export const ID_EXPENSE_TOLLS = 29683488; // Tolls

// Internal Allocation Codes
export const ID_INTERNAL_BEREAVEMENT = 29683490; // Bereavement
export const ID_INTERNAL_HOLIDAY = 91208; // Holiday
export const ID_INTERNAL_HR_RECRUITING = 29682781; // HR/Recruiting
export const ID_INTERNAL_INTERNAL_MEETING = 29682779; // Internal Meeting
export const ID_INTERNAL_JURY_DUTY = 29683489; // Jury Duty
export const ID_INTERNAL_OFFICE_MANAGEMENT = 29682780; // Office Management
export const ID_INTERNAL_RESEARCH = 29683312; // Research
export const ID_INTERNAL_SICK_TIME = 91207; // Sick Time
export const ID_INTERNAL_TRAINING = 29683311; // Training
export const ID_INTERNAL_TRAVEL_TIME = 27192; // Travel Time

// Internal Allocation Codes - Inactive
export const ID_INTERNAL_COMP_TIME = 4; // Comp Time
export const ID_INTERNAL_PERSONAL_TIME = 91209; // Personal Time
export const ID_INTERNAL_VACATION = 91206; // Vacation

// Material Cost Codes
export const ID_MATERIAL_CHANGE_ORDERS = 5; // Change Orders
export const ID_MATERIAL_DISCOUNT_NON_TAXABLE = -2; // Discount Non-Taxable
export const ID_MATERIAL_DISCOUNT = -1; // Discount
export const ID_MATERIAL_DOMAIN = 29683517; // Domene
export const ID_MATERIAL_SHIPPING = 29683374; // Frakt
export const ID_MATERIAL_HARDWARE = 29682871; // Hardware
export const ID_MATERIAL_IVO_WEBSITE = 29683522; // iVo Nettside
export const ID_MATERIAL_KIT_HOSTING = 29683526; // KIT Hosting
export const ID_MATERIAL_KIT_MANAGED_INTERNET = 29683524; // KIT Managed Internet
export const ID_MATERIAL_KIT_MANAGED_NETWORK = 29683525; // KIT Managed Network
export const ID_MATERIAL_KIT_MANAGED_WORDPRESS = 29683523; // KIT Managed Wordpress
export const ID_MATERIAL_MIGRATION = 29683536; // Migrering
export const ID_MATERIAL_MISCELLANEOUS = 29682783; // Miscellaneous
export const ID_MATERIAL_WEBSITES_ADDON_OTHER = 29683531; // Nettsider tillegg/annet
export const ID_MATERIAL_NON_TAXABLE_PRODUCTS = 29682839; // Non-Taxable Products
export const ID_MATERIAL_SETUP = 29683495; // Oppsett
export const ID_MATERIAL_SOFTWARE = 29682872; // Programvare
export const ID_MATERIAL_RMA = 29683491; // RMA
export const ID_MATERIAL_SSL_CERTIFICATE = 29683521; // SSL-sertifikat
export const ID_MATERIAL_TAXABLE_PRODUCTS = 29682840; // Taxable Products
export const ID_MATERIAL_SERVICES = 29683540; // Tjenester

// Recurring Contract Service Codes
export const ID_SERVICE_BITDEFENDER = 29683515; // Bitdefender
export const ID_SERVICE_DATTORMM = 29683514; // DattoRMM
export const ID_SERVICE_DOMAIN_OWNER_CHANGE = 29683501; // Domene eierskifte
export const ID_SERVICE_DOMAIN = 29683500; // Domene
export const ID_SERVICE_HOSTING = 29683504; // Hosting
export const ID_SERVICE_DIGITAL_SIGNAGE = 29683538; // Infoskjerm
export const ID_SERVICE_IVO = 29683503; // Ivo
export const ID_SERVICE_KIT_MANAGED_BACKUP_365 = 29683508; // KIT Managed Backup 365
export const ID_SERVICE_KIT_MANAGED_BACKUP = 29683507; // KIT Managed Backup
export const ID_SERVICE_KIT_MANAGED_CLIENT = 29683511; // KIT Managed Client
export const ID_SERVICE_KIT_MANAGED_INTERNET = 29683509; // KIT Managed Internet
export const ID_SERVICE_KIT_MANAGED_NETWORK = 29683513; // KIT Managed Network
export const ID_SERVICE_KIT_MANAGED_SERVER = 29683512; // KIT Managed Server
export const ID_SERVICE_KITCLOUD_SYNC = 29683532; // KitCloud Sync
export const ID_SERVICE_MANAGED_WORDPRESS = 29683505; // Managed Wordpress
export const ID_SERVICE_MARKETING = 29683527; // Markedsføring
export const ID_SERVICE_MICROSOFT_365 = 29683510; // Microsoft 365
export const ID_SERVICE_WEBSITES_ADDON_OTHER = 29683506; // Nettsider tillegg/annet
export const ID_SERVICE_SETUP = 29683541; // Oppsett
export const ID_SERVICE_SOFTWARE = 29683534; // Programvare
export const ID_SERVICE_AGREEMENT = 29683533; // Serviceavtale
export const ID_SERVICE_SSL_CERTIFICATE = 29683502; // SSL-sertifikat
export const ID_SERVICE_SERVICES = 29683539; // Tjenester

// Milestone Codes
export const ID_MILESTONE_FIXED_PRICE_PROJECT = 29683340; // Fixed Price Project

// Quickbooks Codes
export const ID_QUICKBOOKS_LABOR = 29682918; // Labor
export const ID_QUICKBOOKS_MILESTONE = 29682913; // Milestone
export const ID_QUICKBOOKS_PROJECTS = 29682915; // Projects
export const ID_QUICKBOOKS_RECURRING_SERVICES = 29682912; // Recurring Services
export const ID_QUICKBOOKS_SUBSCRIPTION = 29682919; // Subscription
export const ID_QUICKBOOKS_TASKS = 29682916; // Tasks
export const ID_QUICKBOOKS_TAXES_FROM_AUTOTASK = 29682914; // Taxes from Autotask
export const ID_QUICKBOOKS_TICKETS = 29682917; // Tickets

// Block/Retainer/Ticket Purchase Codes
export const ID_BLOCK_PURCHASE = 29683360; // Block Purchase
export const ID_RETAINER_PURCHASE = 29683361; // Retainer Purchase
export const ID_TICKET_PURCHASE = 29683362; // Ticket Purchase

export const TYPE_NORMAL = 0;
export const TYPE_SYSTEM = 1;
export const TYPE_NON_BILLABLE = 2;

export const USE_TYPE_GENERAL_ALLOCATION_CODE = 1; // General Allocation Code
export const USE_TYPE_EXPENSE_CATEGORY = 2; // Expense Category
export const USE_TYPE_INTERNAL_ALLOCATION_CODE = 3; // Internal Allocation Code
export const USE_TYPE_MATERIAL_COST_CODE = 4; // Material Cost Code
export const USE_TYPE_RECURRING_CONTRACT_SERVICE_CODE = 5; // Recurring Contract Service Code
export const USE_TYPE_MILESTONE_CODE = 6; // Milestone Code
export const USE_TYPE_QUICKBOOKS = 9; // Quickbooks
export const USE_TYPE_BLOCK_PURCHASE = 10; // Block Purchase
export const USE_TYPE_RETAINER_PURCHASE = 11; // Retainer Purchase
export const USE_TYPE_TICKET_PURCHASE = 12; // Ticket Purchase