import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Dropdown, Form, Grid, Header, Icon, Input, Label, Loader, Popup, Segment, Table } from 'semantic-ui-react'
import { formatNumber, getSearchQuery, append, dateLocales, filterTypes, isObject, preventDefault, omit, debounce } from '../helpers'
import { Response } from '../api'
import WebsocketClient from '../websocket_client'
import DataSelect from './data_select'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { useAuth } from '../pages/root'

const initialOrder = {}
const initialFilterInput = {}
const initialUpdateDataReason = null
const initialSearchInput = ''
const initialPageInput = 1

const defaultEmptyText = 'Ingen resultater.'
const searchEmptyText = 'Skriv inn et søkeord.'
const loadingText = 'Laster inn...'

export default function DataTable({
	disabled,
	className,
	searchOnly,
	columns,
	striped,
	compact,
	emptyText,
	loading,

	websocketDisablePerAccountEvents,
	websocketRelatedTarget,
	websocketRelatedId,

	format,
	query: baseQuery,
	limit: forcedLimit,
	defaultOrder,
	apiSearch,

	dirty,
	onCancelEdit,

	isRowActive,
	isRowBeingEdited,
	renderEditRow,
	onClickRow,
	renderRow,

	extraPanes,
	onChangeActivePane,
	activePane,

	refresh: externalRefreshRequested,
	onRefresh,
}) {
	//
	// property fallbacks
	//

	// attributes
	searchOnly = searchOnly ?? false
	columns = columns ?? []
	striped = striped ?? true
	compact = compact ?? false
	disabled = disabled ?? false
	className = className ?? ''
	emptyText = emptyText ?? defaultEmptyText
	loading = loading ?? false

	// websocket configuration
	websocketDisablePerAccountEvents = websocketDisablePerAccountEvents ?? false
	websocketRelatedTarget = websocketRelatedTarget ?? null
	websocketRelatedId = websocketRelatedId ?? null

	// api search method
	format = format ?? "internal"
	baseQuery = baseQuery ?? null
	forcedLimit = forcedLimit ?? null
	if (typeof forcedLimit === 'string') forcedLimit = parseInt(forcedLimit)
	defaultOrder = defaultOrder ?? initialOrder
	apiSearch = apiSearch ?? (async (query, options, requestOptions) => {throw new Error('No apiSearch method provided')})

	// row edit functionality
	dirty = dirty ?? null
	onCancelEdit = onCancelEdit ?? (() => {})
	isRowActive = isRowActive ?? ((data, columns, index) => false)
	isRowBeingEdited = isRowBeingEdited ?? ((data, columns) => false)
	renderEditRow = renderEditRow ?? ((data, columns, index) => null)

	// row click handler
	onClickRow = onClickRow ?? null

	// row render method
	renderRow = renderRow ?? ((data, columns, index) => columns.map(({ key }) => {
		if (typeof key !== 'string') return null
		let val = data
		for (const keyPart of key.split('.')) {
			if (val.hasOwnProperty(keyPart)) {
				val = val[keyPart]
			}
		}
		if (typeof val === 'object') val = null
		return val
	}))

	// add extra buttons/panes
	extraPanes = extraPanes ?? [/* { key, title, icon, render, buttonContent = title, buttonHoverText = null, buttonIcon = icon, buttonWidth = 3, buttonColor = null }, ... */]

	const [activePaneInternal, setActivePaneInternal] = useState(null)
	onChangeActivePane = onChangeActivePane ?? (key => setActivePaneInternal(key))
	activePane = activePane ?? activePaneInternal

	// external refresh functionality
	externalRefreshRequested = externalRefreshRequested ?? false
	onRefresh = onRefresh ?? (() => {})

	const { user } = useAuth()

	//
	// user input state handling
	//

	// set on user action, reset in updateData
	const [updateDataReason, setUpdateDataReason] = useState(initialUpdateDataReason)
	const [updatingDataReason, setUpdatingDataReason] = useState(initialUpdateDataReason)

	const cachedExternalRefreshRequested = useRef(externalRefreshRequested)
	useEffect(() => {
		if (cachedExternalRefreshRequested.current !== externalRefreshRequested && externalRefreshRequested) {
			setUpdateDataReason('externalRefresh')
		}
		cachedExternalRefreshRequested.current = externalRefreshRequested
	}, [externalRefreshRequested])

	// debounced user input: search field
	const [searchInput, setSearchInput] = useState(initialSearchInput)
	const [debouncedSearchInput, setDebouncedSearchInput] = useState(initialSearchInput)
	const debouncedSetDebouncedSearchInput = useMemo(() => debounce(setDebouncedSearchInput, 200), [])
	useEffect(() => debouncedSetDebouncedSearchInput(searchInput), [searchInput, debouncedSetDebouncedSearchInput])
	const cachedSearchInput = useRef(initialSearchInput)
	useEffect(() => {
		if (cachedSearchInput.current !== debouncedSearchInput) {
			if (!searchOnly || debouncedSearchInput !== initialSearchInput) {
				setUpdateDataReason('search')
				setPageInput(1)
				setDebouncedPageInput(1)
			} else if (searchOnly && debouncedSearchInput === initialSearchInput) {
				responsePageNumber.current = 1
				setSearchError(null)
				setResponse(null)
			}
			cachedSearchInput.current = debouncedSearchInput
		}
	}, [searchOnly, debouncedSearchInput])

	// debounced user input: page number field
	const [pageInput, setPageInput] = useState(initialPageInput)
	const [debouncedPageInput, setDebouncedPageInput] = useState(initialPageInput)
	const debouncedSetPageInputDebounced = useMemo(() => debounce(setDebouncedPageInput, 200), [])
	useEffect(() => debouncedSetPageInputDebounced(pageInput), [pageInput, debouncedSetPageInputDebounced])
	const pageInputUpdateReason = useRef(null)
	const cachedPageInput = useRef(initialPageInput)
	useEffect(() => {
		if (cachedPageInput.current !== debouncedPageInput) {
			setUpdateDataReason(pageInputUpdateReason.current)
		}
		cachedPageInput.current = debouncedPageInput
		pageInputUpdateReason.current = null
	}, [debouncedPageInput])

	// debounced user input: filter input (can contain multiple fields)
	const [filterInput, setFilterInput] = useState(initialFilterInput)
	const [debouncedFilterInput, setDebouncedFilterInput] = useState(initialFilterInput)
	const debouncedSetFilterInputDebounced = useMemo(() => debounce(setDebouncedFilterInput, 200), [])
	useEffect(() => debouncedSetFilterInputDebounced(filterInput), [filterInput, debouncedSetFilterInputDebounced])
	const cachedFilterInput = useRef(initialFilterInput)
	useEffect(() => {
		if (JSON.stringify(cachedFilterInput.current) !== JSON.stringify(debouncedFilterInput)) {
			setUpdateDataReason('changeFilter')
			setPageInput(1)
			setDebouncedPageInput(1)
		}
		cachedFilterInput.current = debouncedFilterInput
	}, [debouncedFilterInput])

	// non-debounced user input (input type: click)
	const [orderInput, setOrderInput] = useState(defaultOrder)
	const cachedOrderInput = useRef(defaultOrder)
	useEffect(() => {
		if (JSON.stringify(cachedOrderInput.current) !== JSON.stringify(orderInput)) {
			responsePageNumber.current = 1
			setPageInput(1)
			setDebouncedPageInput(1)
			setResponse(null)
			setSearchError(null)
			setUpdateDataReason('changeOrder')
		}
		cachedOrderInput.current = orderInput
	}, [orderInput])

	// defaultOrder property update effect
	useEffect(() => {
		if (defaultOrder !== null) {
			setOrderInput(defaultOrder)
		}
	}, [defaultOrder])

	// non-debounced user input (input type: dropdown/select)
	const initialLimitInput = forcedLimit ?? 25
	const [limitInput, setLimitInput] = useState(initialLimitInput)
	const cachedLimitInput = useRef(initialLimitInput)
	useEffect(() => {
		if (cachedLimitInput.current !== limitInput) {
			setUpdateDataReason('changeLimit')
		}
		cachedLimitInput.current = limitInput
	}, [limitInput])

	// limit property update effect
	useEffect(() => {
		const newLimit = parseInt(localStorage.getItem('kit-datatable-items-per-page') ?? '25')
		if (typeof newLimit === 'number' && !isNaN(newLimit)) {
			setLimitInput(forcedLimit ?? newLimit)
		} else {
			setLimitInput(forcedLimit)
		}
	}, [forcedLimit])

	// set by updateData
	const [searchError, setSearchError] = useState(null)
	const [paginationError, setPaginationError] = useState(null)
	const responsePageNumber = useRef(1)
	const [response, setResponse] = useState(null)
	const subscribedWebSocketEvents = useRef([])

	// calculated from query/search/filter input
	const searchInputIsEmpty = debouncedSearchInput === initialSearchInput && JSON.stringify(debouncedFilterInput) === JSON.stringify(initialFilterInput)

	// disabled/searchOnly property update effect
	const cachedDisabled = useRef(null)
	const cachedSearchOnly = useRef(searchOnly)
	useEffect(() => {
		if (
			(disabled !== cachedDisabled.current && disabled) ||
			(searchOnly !== cachedSearchOnly.current && searchOnly && searchInputIsEmpty)
		) {
			responsePageNumber.current = 1
			setPageInput(1)
			setDebouncedPageInput(1)
			setSearchError(null)
			setResponse(null)
		} else if (disabled !== cachedDisabled.current && !disabled && !searchOnly) {
			setUpdateDataReason('initialDataUpdate')
			setPageInput(1)
			setDebouncedPageInput(1)
		} else if (disabled !== cachedDisabled.current && !disabled && searchOnly && !searchInputIsEmpty) {
			setUpdateDataReason('search')
			setPageInput(1)
			setDebouncedPageInput(1)
		}
		cachedDisabled.current = disabled
		cachedSearchOnly.current = searchOnly
	}, [disabled, searchOnly, searchInputIsEmpty])

	// baseQuery property update effect
	const cachedBaseQuery = useRef(baseQuery)
	useEffect(() => {
		if (JSON.stringify(cachedBaseQuery.current) !== JSON.stringify(baseQuery)) {
			responsePageNumber.current = 1
			setPageInput(1)
			setDebouncedPageInput(1)
			setSearchError(null)
			setResponse(null)
			setUpdateDataReason('changeBaseQuery')
		}
		cachedBaseQuery.current = baseQuery
	}, [baseQuery])

	// calculated from response
	const dataInfo = response && response.data_info ? response.data_info : null
	const pages = dataInfo ? dataInfo.pages() : 1
	const hasPrevPage = dataInfo && typeof dataInfo.prev === 'string'
	const hasNextPage = dataInfo && typeof dataInfo.next === 'string'

	//
	// data updater
	//

	// data update callback
	const preloadAbortController = useRef(null)
	const updateDataAbortController = useRef(null)
	const updateData = useCallback(async (reason) => {
		const currentUpdateDataAbortController = updateDataAbortController.current
		let currentPreloadAbortController = preloadAbortController.current
		const query = getSearchQuery(baseQuery, columns, cachedSearchInput.current, cachedFilterInput.current, format)
		const queryIsBaseQuery = JSON.stringify(query) === JSON.stringify(baseQuery)

		const orderString = Object.keys(cachedOrderInput.current)
			.map(key => {
				let dir = cachedOrderInput.current[key]
				dir = dir === 'ASC' ? '-' : (dir === 'DESC' ? '+' : null)
				if (dir === null) return null
				return dir + key
			})
			.filter(sortCol => sortCol !== null)
			.join(',')

		const newPage = cachedPageInput.current
		let newResponse = null
		let error = null
		try {
			if (response !== null && response.prev_res !== null && newPage === responsePageNumber.current - 1) {
				newResponse = await response.prev({ signal: currentUpdateDataAbortController.signal })
			} else if (response !== null && response.next_res !== null && newPage === responsePageNumber.current + 1) {
				newResponse = await response.next({ signal: currentUpdateDataAbortController.signal })
			} else {
				if (currentPreloadAbortController !== null) {
					currentPreloadAbortController.abort(new Error('Aborting preload due to data update (' + reason + ')'))
				}
				currentPreloadAbortController = preloadAbortController.current = new AbortController()

				const queryOptions = {
					limit: cachedLimitInput.current,
					offset: (newPage - 1) * cachedLimitInput.current,
					order: orderString,
				}
				newResponse = await apiSearch(query, queryOptions, { signal: currentUpdateDataAbortController.signal })
			}
		} catch (e) {
			if (currentUpdateDataAbortController.signal.aborted || currentPreloadAbortController.signal.aborted) {
				// do nothing further, as the request was aborted
				//
				// (this happens if we are navigating away or
				// starting a new updateData call)
				return
			}

			// get human-readable error string
			if (e.res && e.res.error) {
				if (e.res.error.code === 'not_found' && queryIsBaseQuery) {
					error = null
				} else if (e.res.error.message) {
					error = e.res.error.message
					console.error('DataTable.updateData:', e, e.res)
				} else {
					error = 'En feil oppstod - prøv igjen'
					console.error('DataTable.updateData:', e, e.res)
				}
			} else {
				error = 'En feil oppstod - prøv igjen'
				console.error('DataTable.updateData:', e, e.res)
			}

			newResponse = new Response(e.res)
		}

		if (error) {
			if (['search', 'changeFilter'].includes(reason)) {
				setSearchError(error)
			} else if (['changePage', 'prevPage', 'nextPage'].includes(reason)) {
				setPaginationError(error)
			}
		} else {
			setSearchError(null)
			setPaginationError(null)
		}

		if (newResponse.success) {
			// fetch and cache previous and next responses (preloading)
			if (newPage === responsePageNumber.current - 1) {
				// we are navigating to the previous page
				// newResponse = prev, response = next
				if (response !== null) {
					response.next_res = null
					response.prev_res = newResponse
				}
				newResponse.next_res = response
			} else if (newPage === responsePageNumber.current + 1) {
				// we are navigating to the next page
				// response = prev, newResponse = next
				if (response !== null) {
					response.prev_res = null
					response.next_res = newResponse
				}
				newResponse.prev_res = response
			}
			newResponse.preload({ signal: preloadAbortController.current.signal })

			// subscribe to websocket events and attach event listeners
			if (isObject(user) && isObject(user.auth) && typeof user.auth.websocketUrl === 'string' && newResponse.data_info && typeof newResponse.data_info.source === 'string') {
				const ws = WebsocketClient.getInstance(user.auth.websocketUrl)
				const subscribeToWebSocketEvents = session => {
					const handleWebSocketEvent = (topic, event) => setResponse(response => {
						if (!topic.startsWith('no.kitcloud.sap.change.') && !topic.startsWith('no.kitcloud.sap.change_related.')) return
						if (!response || typeof response.data !== 'object' || !Array.isArray(response.data) || !response.data_info || response.data_info.source !== event.source) return
						switch (event.type) {
						case 'create':
							// only refresh if on the first or last page
							// we do not care about live reloading while on a middle page right now
							if (!response.data_info.prev || !response.data_info.next) {
								setUpdateDataReason('refresh')
							}
							return response
						case 'update':
							return Object.assign(Object.create(Object.getPrototypeOf(response)), append(response, {
								data: response.data.map(row => {
									if (!row.hasOwnProperty(event.id.field) || row[event.id.field] !== event.id.value) return row
									return append(row, event.new_data)
								})
							}))
						case 'delete':
							return Object.assign(Object.create(Object.getPrototypeOf(response)), append(response, {
								data: response.data.filter(row => !row.hasOwnProperty(event.id.field) || row[event.id.field] !== event.id.value)
							}))
						default:
							return response
						}
					})

					const wampSource = newResponse.data_info.source.toLowerCase().replace(/[^a-z0-9_]/g, '_')
					if (!websocketDisablePerAccountEvents && typeof user.auth.accountIds === 'object' && Array.isArray(user.auth.accountIds)) {
						for (const accountId of user.auth.accountIds) {
							const topic = websocketRelatedTarget !== null && websocketRelatedId !== null
								? 'no.kitcloud.sap.change_related.' + accountId + '.' + websocketRelatedTarget + '.' + websocketRelatedId + '.any.' + wampSource
								: 'no.kitcloud.sap.change.' + accountId + '.any.' + wampSource
							if (!subscribedWebSocketEvents.current.includes(topic)) {
								subscribedWebSocketEvents.current.push(topic)
								session.subscribe(topic, (topic, event) => handleWebSocketEvent(topic, event))
							}
						}
					} else if (websocketDisablePerAccountEvents) {
						const topic = websocketRelatedTarget !== null && websocketRelatedId !== null
							? 'no.kitcloud.sap.change_related.' + websocketRelatedTarget + '.' + websocketRelatedId + '.any.' + wampSource
							: 'no.kitcloud.sap.change.any.' + wampSource
						session.subscribe(topic, (topic, event) => handleWebSocketEvent(topic, event))
					}
				}
				if (ws.isConnected) {
					subscribeToWebSocketEvents(ws.session)
				} else {
					const listener = session => {
						subscribeToWebSocketEvents(session)
						ws.removeEventListener(listener)
					}
					ws.addEventListener('open', listener)
				}
			}
		}

		// update response and page number
		responsePageNumber.current = newPage
		setResponse(newResponse)

		// call onRefresh if refresh was requested externally
		if (reason === 'externalRefresh' && typeof onRefresh === 'function') {
			onRefresh()
		}

		// reset updating data reason
		setUpdatingDataReason(null)
	}, [
		apiSearch,
		baseQuery,
		columns,
		format,
		response,
		websocketDisablePerAccountEvents,
		websocketRelatedId,
		websocketRelatedTarget,
		user,
		onRefresh,
	])

	// data update effect
	useEffect(() => {
		if (updateDataReason !== null) {
			// reset update data reason
			setUpdatingDataReason(updateDataReason)
			setUpdateDataReason(null)

			// abort any in-progress data update
			if (updateDataAbortController.current !== null && !updateDataAbortController.current.signal.aborted) {
				updateDataAbortController.current.abort(new Error('Data update (' + updateDataReason + ')'))
			}

			// trigger data update
			updateDataAbortController.current = new AbortController()
			updateData(updateDataReason)
		}
	}, [updateData, updateDataReason])

	//
	// event handlers
	//

	const goToPage = newPageNumber => {
		if (typeof newPageNumber !== 'number' || isNaN(newPageNumber) || newPageNumber <= 1 || newPageNumber >= pages) return
		setPageInput(newPageNumber)
		pageInputUpdateReason.current = 'changePage'
	}

	const prevPage = () => {
		if (pageInput <= 1) return
		const newPageInput = pageInput - 1
		setPageInput(newPageInput)
		setDebouncedPageInput(newPageInput)
		pageInputUpdateReason.current = 'prevPage'
	}

	const nextPage = () => {
		if (pages !== null && pageInput >= pages) return
		const newPageInput = pageInput + 1
		setPageInput(newPageInput)
		setDebouncedPageInput(newPageInput)
		pageInputUpdateReason.current = 'nextPage'
	}

	const getOrderDir = key => {
		// get column
		const column = columns.find(col => col.key === key)
		if (!column || !column.sortable) return null

		let orderDir = null
		if (orderInput.hasOwnProperty(key)) {
			// get current column order
			orderDir = orderInput[key]
			if (typeof orderDir === 'string') {
				orderDir = orderDir.toUpperCase()
			}
			if (orderDir !== 'ASC' && orderDir !== 'DESC') {
				orderDir = null
			}
		} else if (defaultOrder.hasOwnProperty(key)) {
			// get default column order
			orderDir = defaultOrder[key]
			if (typeof orderDir === 'string') {
				orderDir = orderDir.toUpperCase()
			}
			if (orderDir !== 'ASC' && orderDir !== 'DESC') {
				orderDir = null
			}
		}

		return orderDir
	}

	const wrapHandlerWithConfirmation = handlerFn => {
		return (...args) => {
			if (dirty) {
				const confirmation = window.confirm("Du har endringer som ikke er lagret.\nDenne handlingen vil forårsake oppdatering av data-tabellen.\nEr du sikker på at du vil fortsette?")
				if (!confirmation) return
			}
			if (typeof onCancelEdit === 'function') onCancelEdit()
			handlerFn(...args)
		}
	}

	//
	// rendering
	//

	const showLoadingState = loading || updatingDataReason !== null || (!disabled && !(searchOnly && searchInputIsEmpty) && response === null)

	const renderSearchError = () => {
		if (!searchError) return null
		return <Label pointing="above" prompt>
			<Icon name="exclamation triangle" />&ensp;{searchError}
		</Label>
	}

	const defaultPanes = () => {
		const valueColumns = columns.filter(({ key }) => typeof key === 'string' && key.length > 0)
		const filterable = valueColumns.findIndex(({ filterType }) => Object.keys(filterTypes).includes(filterType)) !== -1
		let panes = []
		if (filterable) panes.push({
			key: 'filter',
			title: 'Filtrer' + (Object.keys(filterInput).length > 0 ? ' *' : ''),
			icon: 'filter',
			buttonWidth: 2,
			render: () => renderFilter(),
		})
		return panes
	}

	const renderSearch = () => {
		const valueColumns = columns.filter(({ key }) => typeof key === 'string' && key.length > 0)
		const searchable = valueColumns.findIndex(({ searchable }) => searchable) !== -1
		if (!searchable && extraPanes.length < 1) return null

		let remainingWidth = 16
		const extraPaneButtons = defaultPanes().concat(extraPanes).map(pane => {
			const width = pane.buttonWidth ?? 3
			remainingWidth -= width
			const icon = pane.buttonIcon ?? pane.icon
			const color = pane.buttonColor
			const content = pane.buttonContent ?? pane.title
			const button = <Button
				fluid
				color={color}
				disabled={disabled}
				basic={activePane === pane.key}
				onClick={preventDefault(() => onChangeActivePane(activePane === pane.key ? null : pane.key))}
			>
				{typeof icon === 'string' ? <Icon name={icon} /> : null} {content}
			</Button>
			return <Form.Field key={pane.key} width={width}>
				{typeof pane.buttonHoverText === 'string' ? <Popup
					inverted
					content={pane.buttonHoverText}
					trigger={button}
				/> : button}
			</Form.Field>
		})

		const refreshButton = <Form.Field width={1}>
			<Button
				basic
				fluid
				disabled={disabled || (searchOnly && searchInputIsEmpty) || showLoadingState}
				icon="refresh"
				loading={['refresh', 'externalRefresh', 'changeOrder', 'changeLimit', 'changeBaseQuery'].includes(updatingDataReason)}
				onClick={preventDefault(wrapHandlerWithConfirmation(() => setUpdateDataReason('refresh')))}
			/>
		</Form.Field>
		remainingWidth -= 1

		const searchField = !searchable ? null : <Form.Field error={!!searchError} width={remainingWidth}>
			<Input
				fluid
				disabled={disabled}
				loading={updatingDataReason === 'search'}
				icon="search"
				placeholder="Søk..."
				value={searchInput}
				onChange={wrapHandlerWithConfirmation((e, data) => setSearchInput(data.value))}
			/>
			{renderSearchError()}
		</Form.Field>

		return <Form className="kit-datatable-controls">
			<Form.Group>
				{searchField}
				{refreshButton}
				{extraPaneButtons}
			</Form.Group>
		</Form>
	}

	const renderFilter = () => {
		const valueColumns = columns.filter(({ key }) => typeof key === 'string' && key.length > 0)
		const filterableColumns = valueColumns.filter(({ filterType }) => Object.keys(filterTypes).includes(filterType))
		if (filterableColumns.length === 0) return null

		let fieldGroups = []
		let fieldsPerRow = 1
		if (filterableColumns.length === 2) {
			fieldsPerRow = 2
		} else if (filterableColumns.length === 3) {
			fieldsPerRow = 3
		} else if (filterableColumns.length > 3) {
			fieldsPerRow = 4
		}

		for (let i = 0; i < filterableColumns.length; i += fieldsPerRow) {
			let fields = []
			for (let j = i; j < Math.min(i + fieldsPerRow, filterableColumns.length); j++) {
				const column = filterableColumns[j]

				const value = filterInput.hasOwnProperty(column.key) ? filterInput[column.key] : ''
				const onChange = wrapHandlerWithConfirmation(newValue => {
					setFilterInput(
						(newValue === '' || newValue === null)
							? omit(column.key, filterInput)
							: append(filterInput, {
								[column.key]: newValue
							})
					)
				})

				let filterInputElem = null
				if (column.filterType === filterTypes.search) {
					filterInputElem = <Form.Field key={column.key}>
						<label>{column.text}</label>
						<Input
							fluid
							icon={value === '' ? <Icon name="search" /> : <Icon name="delete" link onClick={preventDefault(() => onChange(''))} />}
							placeholder="Søk..."
							value={value}
							onChange={(e, data) => onChange(data.value)}
						/>
					</Form.Field>
				} else if (column.filterType === filterTypes.boolean || column.filterType === filterTypes.booleanQuery) {
					filterInputElem = <Form.Field key={column.key}>
						<label>{column.filterText ?? column.text}</label>
						<Dropdown
							selection
							clearable
							placeholder="Velg en verdi..."
							options={[
								{value: '1', text: 'Ja'},
								{value: '0', text: 'Nei'},
							]}
							value={value}
							onChange={(e, data) => onChange(data.value)}
						/>
					</Form.Field>
				} else if (column.filterType === filterTypes.date) {
					filterInputElem = <SemanticDatepicker
						clearable
						type="range"
						locale="nb-NO"
						format={dateLocales.no.dateFormat}
						key={column.key}
						label={column.text}
						value={value}
						onChange={(e, data) => onChange(data.value)}
					/>
				} else if (column.filterType === filterTypes.model) {
					filterInputElem = <Form.Field key={column.key}>
						<label>{column.text}</label>
						<DataSelect
							searchOnly
							clearable
							query={column.filterQuery}
							order={column.filterOrder}
							searchColumns={column.filterSearchColumns}
							apiSearch={column.filterApiSearch}
							placeholder={column.filterPlaceholder}
							valueKey={column.filterKey}
							textKey={column.filterTextKey || column.filterKey}
							renderItem={typeof column.filterRenderItem === 'function' ? column.filterRenderItem : (item, textKey) => item[textKey]}
							value={value}
							onChange={value => onChange(value)}
						/>
					</Form.Field>
				}
				fields.push(filterInputElem)
			}
			fieldGroups.push(<Form.Group key={i} widths={fieldsPerRow === 4 ? 4 : 'equal'}>{fields}</Form.Group>)
		}

		return <Form>
			{fieldGroups}
			<Form.Group className="kit-va-bottom">
				<Form.Button
					size="small"
					disabled={showLoadingState}
					loading={updatingDataReason === 'changeFilter'}
					content="Tilbakestill"
					onClick={preventDefault(wrapHandlerWithConfirmation(() => setFilterInput(initialFilterInput)))}
				/>
			</Form.Group>
		</Form>
	}

	const renderPane = () => {
		const pane = defaultPanes().concat(extraPanes).find(pane => activePane === pane.key)
		if (!isObject(pane) || typeof pane.render !== 'function') return null

		return <Segment secondary>
			<Label attached="top">
				{typeof pane.icon === 'string' ? <><Icon name={pane.icon} />&ensp;</> : null}
				{pane.title}
			</Label>
			{pane.render()}
		</Segment>
	}

	const renderEmptyState = () => {
		return <Segment placeholder>
			{showLoadingState ? <Loader active>{loadingText}</Loader> : <Header textAlign="center">{searchOnly && searchInputIsEmpty ? searchEmptyText : emptyText}</Header>}
		</Segment>
	}

	const getDataWithBlankEditRows = () => {
		if (!response || !response.data || (typeof response.data === 'object' && Array.isArray(response.data) && response.data.length === 0)) return null

		let data = [...response.data]
		let editRowIndexes = []
		for (let i = 0; i < data.length; i++) {
			const row = data[i]
			if (isRowBeingEdited(row, columns)) {
				editRowIndexes.unshift(i)
			}
		}
		if (editRowIndexes.length > data.length) return data
		for (const editRowIndex of editRowIndexes) {
			data.splice(editRowIndex + 1, 0, '__kit_datatable_edit_row')
		}
		return data
	}

	const renderTable = () => {
		if (disabled) return renderEmptyState()

		const data = getDataWithBlankEditRows()
		if (!data) return renderEmptyState()

		const visibleColumns = columns.filter(({ visible }) => visible)

		const headerRow = visibleColumns.map(column => {
			const orderDir = getOrderDir(column.key)
			const orderIcon = orderDir !== null ? <> <Icon name={orderDir === 'ASC' ? 'sort up' : 'sort down'} /></> : null

			return <Table.HeaderCell key={column.key}>
				{column.sortable ? <button onClick={preventDefault(e => {
					const isMultisort = format !== 'cloudflare' && e.ctrlKey
					const orderDir = getOrderDir(column.key)
					let newOrder = {}
					if (isMultisort) {
						newOrder = {...orderInput}
						for (const { key, sortable } of columns) {
							if (!sortable) continue
							if (newOrder[key] === null) delete newOrder[key]
						}
					}
					newOrder[column.key] = orderDir === null
						? 'ASC'
						: ( orderDir === 'ASC' ? 'DESC' : null )
					for (const { key, sortable } of columns) {
						if (!sortable || key === column.key) continue
						if (!isMultisort || !newOrder.hasOwnProperty(key)) {
							newOrder[key] = null
						}
					}
					setOrderInput(newOrder)
				})}>{column.text}{orderIcon}</button> : column.text}
			</Table.HeaderCell>
		})

		return <Table
			unstackable
			compact={compact}
			className={((typeof onClickRow === 'function' ? 'clickable' : '') + ' ' + className).trim()}
			selectable={typeof onClickRow === 'function'}
			striped={striped}
			tableData={data}
			headerRow={headerRow}
			renderBodyRow={(rowDataOrEditMarker, index) => {
				const isEditRow = rowDataOrEditMarker === '__kit_datatable_edit_row'
				const dataIndex = isEditRow ? index - 1 : index
				const rowData = data[dataIndex]
				const row = isEditRow ? <Table.Cell colSpan={visibleColumns.length}>{renderEditRow(rowData, columns, dataIndex)}</Table.Cell> : renderRow(rowData, columns, dataIndex).map((col, i) => {
					if (React.isValidElement(col) && col.type === Table.Cell) {
						return col
					}
					return <Table.Cell key={visibleColumns[i].key} className={visibleColumns[i].singleLineNoOverflow ? 'kit-datatable-column-single-line-no-overflow' : null} width={visibleColumns[i].width ? visibleColumns[i].width : null}>{col}</Table.Cell>
				})
				const onClick = typeof onClickRow === 'function' && !isEditRow ? e => {
					const { ctrlKey, shiftKey } = e
					if (['TD', 'TR', 'SPAN', 'P'].includes(e.target.tagName)) {
						onClickRow(rowData, dataIndex, { ctrlKey, shiftKey })
					}
				} : null
				if (React.isValidElement(row) && row.type === Table.Row) {
					if (!row.onClick) row.onClick = onClick
					return row
				}
				return <Table.Row key={dataIndex + (isEditRow ? '_edit' : '_data')} onClick={onClick} active={isRowActive(rowData, columns, dataIndex)}>{row}</Table.Row>
			}}
		/>
	}

	const renderPaginationError = () => {
		if (!paginationError) return null
		return <Label pointing="above" basic color="red">
			<Icon name="exclamation triangle" />&ensp;{paginationError}
		</Label>
	}

	const renderPagination = () => {
		const count = dataInfo ? dataInfo.count : 0
		const resultsOnPage = response && response.data ? response.data.length : 0
		const firstRowNumberZeroIndexed = dataInfo ? (responsePageNumber.current - 1) * limitInput + Math.min(1, resultsOnPage) : 0
		const lastRowNumberZeroIndexed = firstRowNumberZeroIndexed + resultsOnPage - Math.min(1, resultsOnPage)

		const resultsText = showLoadingState ? loadingText : 'Viser ' + (
			count <= 1
				? formatNumber(count) + ' resultat' + (count === 1 ? '' : 'er')
				: formatNumber(firstRowNumberZeroIndexed) + ' til ' + formatNumber(lastRowNumberZeroIndexed) + (
					format === 'tripletex'
						? ''
						: ' av ' + formatNumber(count)
					) + ' resultater'
			)

		return <Grid columns={forcedLimit ? 2 : 3}>
			<Grid.Row>
				<Grid.Column>
					<Button
						loading={updatingDataReason === 'prevPage'}
						disabled={disabled || showLoadingState || !hasPrevPage}
						onClick={preventDefault(() => prevPage())}
					>
						<Icon name="left chevron" /> Forrige side
					</Button>
					<Button
						loading={updatingDataReason === 'nextPage'}
						disabled={disabled || showLoadingState || !hasNextPage}
						onClick={preventDefault(() => nextPage())}
					>
						Neste side <Icon name="right chevron" />
					</Button>
					{renderPaginationError()}
				</Grid.Column>
				<Grid.Column textAlign={forcedLimit ? 'right' : 'center'}>
					{format === 'autotask' ? <p className="kit-page-info-text text-secondary">Side {responsePageNumber.current} av {formatNumber(pages)}{forcedLimit === null ? null : <>&ensp;&mdash;&ensp;{resultsText}</>}</p> : <>
						<p className="kit-page-info-text text-secondary">Side </p>
						<Input
							style={{minWidth: 'auto'}}
							type="number"
							disabled={disabled}
							step={1}
							min={1}
							max={pages}
							value={pageInput.toString()}
							onChange={(e, data) => goToPage(parseInt(data.value))}
						/>
						<p className="kit-page-info-text text-secondary">{format === 'tripletex' ? null : ' av ' + formatNumber(pages)}{forcedLimit === null ? null : <>&ensp;&mdash;&ensp;{resultsText}</>}</p>
					</>}
					{forcedLimit !== null ? null : <p className="text-secondary">{resultsText}</p>}
				</Grid.Column>
				{forcedLimit !== null ? null : <Grid.Column textAlign="right">
					<p className="kit-page-info-text text-secondary">Vis </p>
					<Dropdown
						style={{minWidth: 'auto'}}
						disabled={disabled}
						selection
						options={[
							{ key: '5',  value: '5',  text: '5' },
							{ key: '10',  value: '10',  text: '10' },
							{ key: '25',  value: '25',  text: '25' },
							{ key: '50',  value: '50',  text: '50' },
							{ key: '100', value: '100', text: '100' },
							{ key: '250', value: '250', text: '250' },
							{ key: '500', value: '500', text: '500' },
						]}
						value={limitInput.toString()}
						onChange={(e, data) => {
							const newLimit = parseInt(data.value)
							localStorage.setItem('kit-datatable-items-per-page', JSON.stringify(newLimit))
							setLimitInput(newLimit)
						}}
					/>
					<p className="kit-page-info-text text-secondary"> resultater</p>
				</Grid.Column>}
			</Grid.Row>
		</Grid>
	}

	return <>
		{renderSearch()}
		{renderPane()}
		{renderTable()}
		{renderPagination()}
	</>
}
