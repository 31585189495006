import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { getSearchQuery, debounce, isObject } from '../helpers'
import { Response } from '../api'

const initialOrder = {}
const initialUpdateDataReason = null
const initialSearchInput = ''

const defaultEmptyText = 'Ingen resultater.'
const searchEmptyText = 'Skriv inn et søkeord.'
const loadingText = 'Laster inn...'

export default function DataSelect({
	disabled,
	className,
	searchOnly,
	searchColumns,
	emptyText,
	multiple,
	placeholder,
	clearable,
	loading,
	staticOptions,

	format,
	query: baseQuery,
	limit,
	group,
	defaultOrder,
	apiSearch,

	refresh: externalRefreshRequested,
	onRefresh,

	value,
	onChange,
	renderItem,
	textKey,
	valueKey,
}) {
	//
	// property fallbacks
	//

	// attributes
	searchOnly = searchOnly ?? false
	searchColumns = searchColumns ?? []
	disabled = disabled ?? false
	className = className ?? ''
	emptyText = emptyText ?? defaultEmptyText
	multiple = multiple ?? false
	placeholder = placeholder ?? null
	clearable = clearable ?? false
	loading = loading ?? false
	staticOptions = staticOptions ?? []

	// api search method
	format = format ?? "internal"
	baseQuery = baseQuery ?? null
	limit = limit ?? null
	group = group ?? null
	if (typeof limit === 'string') limit = parseInt(limit)
	defaultOrder = defaultOrder ?? initialOrder
	apiSearch = apiSearch ?? (async (query, options, requestOptions) => {throw new Error('No apiSearch method provided')})

	// external refresh functionality
	externalRefreshRequested = externalRefreshRequested ?? false
	onRefresh = onRefresh ?? (() => {})

	// DataSelect attributes
	value = value ?? []
	onChange = onChange ?? (() => {})
	renderItem = renderItem ?? ((item, textKey) => item[textKey])
	textKey = textKey ?? null
	valueKey = valueKey ?? null

	//
	// user input state handling
	//

	// set on user action, reset in updateData
	const [updateDataReason, setUpdateDataReason] = useState(initialUpdateDataReason)
	const [updatingDataReason, setUpdatingDataReason] = useState(initialUpdateDataReason)

	const cachedExternalRefreshRequested = useRef(externalRefreshRequested)
	useEffect(() => {
		if (cachedExternalRefreshRequested.current !== externalRefreshRequested && externalRefreshRequested) {
			setUpdateDataReason('externalRefresh')
		}
		cachedExternalRefreshRequested.current = externalRefreshRequested
	}, [externalRefreshRequested])

	// debounced user input: search field
	const [searchInput, setSearchInput] = useState(initialSearchInput)
	const [debouncedSearchInput, setDebouncedSearchInput] = useState(initialSearchInput)
	const debouncedSetDebouncedSearchInput = useMemo(() => debounce(setDebouncedSearchInput, 200), [])
	useEffect(() => debouncedSetDebouncedSearchInput(searchInput), [searchInput, debouncedSetDebouncedSearchInput])
	const cachedSearchInput = useRef(initialSearchInput)
	useEffect(() => {
		if (cachedSearchInput.current !== debouncedSearchInput) {
			if (!searchOnly || debouncedSearchInput !== initialSearchInput) {
				setUpdateDataReason('search')
			} else if (searchOnly && debouncedSearchInput === initialSearchInput) {
				setSearchError(null)
				setResponse(null)
			}
			cachedSearchInput.current = debouncedSearchInput
		}
	}, [searchOnly, debouncedSearchInput])

	// non-debounced user input (input type: click)
	const [orderInput, setOrderInput] = useState(defaultOrder)
	const cachedOrderInput = useRef(defaultOrder)
	useEffect(() => {
		if (JSON.stringify(cachedOrderInput.current) !== JSON.stringify(orderInput)) {
			setSearchError(null)
			setResponse(null)
			setUpdateDataReason('changeOrder')
		}
		cachedOrderInput.current = orderInput
	}, [orderInput])

	// defaultOrder property update effect
	useEffect(() => {
		if (defaultOrder !== null) {
			setOrderInput(defaultOrder)
		}
	}, [defaultOrder])

	// set by updateData
	const [searchError, setSearchError] = useState(null)
	const [response, setResponse] = useState(null)

	// calculated from query/search/filter input
	const searchInputIsEmpty = debouncedSearchInput === initialSearchInput

	// disabled/searchOnly property update effect
	const cachedDisabled = useRef(null)
	const cachedSearchOnly = useRef(searchOnly)
	useEffect(() => {
		if (
			(disabled !== cachedDisabled.current && disabled) ||
			(searchOnly !== cachedSearchOnly.current && searchOnly && searchInputIsEmpty)
		) {
			setSearchError(null)
			setResponse(null)
		} else if (disabled !== cachedDisabled.current && !disabled && !searchOnly) {
			setUpdateDataReason('initialDataUpdate')
		} else if (disabled !== cachedDisabled.current && !disabled && searchOnly && !searchInputIsEmpty) {
			setUpdateDataReason('search')
		}
		cachedDisabled.current = disabled
		cachedSearchOnly.current = searchOnly
	}, [disabled, searchOnly, searchInputIsEmpty])

	// baseQuery property update effect
	const cachedBaseQuery = useRef(baseQuery)
	useEffect(() => {
		if (JSON.stringify(cachedBaseQuery.current) !== JSON.stringify(baseQuery)) {
			setSearchError(null)
			setResponse(null)
			if (!disabled) {
				setUpdateDataReason('changeBaseQuery')
			}
		}
		cachedBaseQuery.current = baseQuery
	}, [disabled, baseQuery])

	//
	// data updater
	//

	// data update callback
	const updateDataAbortController = useRef(null)
	const updateData = useCallback(async (reason) => {
		const currentUpdateDataAbortController = updateDataAbortController.current
		const columns = searchColumns.map(name => ({key: name, visible: false, searchable: true}))
		const query = getSearchQuery(baseQuery, columns, cachedSearchInput.current, {}, format)
		const queryIsBaseQuery = JSON.stringify(query) === JSON.stringify(baseQuery)

		const orderString = Object.keys(cachedOrderInput.current)
			.map(key => {
				let dir = cachedOrderInput.current[key]
				dir = dir === 'ASC' ? '-' : (dir === 'DESC' ? '+' : null)
				if (dir === null) return null
				return dir + key
			})
			.filter(sortCol => sortCol !== null)
			.join(',')

		let newResponse = null
		let error = null
		do {
			try {
				if (newResponse !== null && typeof newResponse.data_info.next === 'string') {
					const res = await newResponse.next({ signal: currentUpdateDataAbortController.signal })
					if (!res.success) break
					newResponse.data = newResponse.data.concat(res.data)
					newResponse.data_info = res.data_info
				} else {
					let queryOptions = {
						limit,
						order: orderString,
					}
					if (group !== null) {
						queryOptions.group = group
						queryOptions.first_of_group = true
					}
					newResponse = await apiSearch(query, queryOptions, { signal: currentUpdateDataAbortController.signal })
				}
			} catch (e) {
				if (currentUpdateDataAbortController.signal.aborted) {
					// do nothing further, as the request was aborted
					//
					// (this happens if we are navigating away or
					// starting a new updateData call)
					return
				}

				// get human-readable error string
				if (e.res && e.res.error) {
					if (e.res.error.code === 'not_found' && queryIsBaseQuery) {
						error = null
					} else if (e.res.error.message) {
						error = e.res.error.message
						console.error('DataTable.updateData:', e, e.res)
					} else {
						error = 'En feil oppstod - prøv igjen'
						console.error('DataTable.updateData:', e, e.res)
					}
				} else {
					error = 'En feil oppstod - prøv igjen'
					console.error('DataTable.updateData:', e, e.res)
				}

				newResponse = new Response(e.res)
			}

			// update response (while we are still loading more data)
			setResponse(newResponse)
		} while (!currentUpdateDataAbortController.signal.aborted && error === null && newResponse !== null && newResponse.data_info.next !== null)

		if (error) {
			if (['search', 'changeFilter'].includes(reason)) {
				setSearchError(error)
			}
		} else {
			setSearchError(null)
		}

		// call onRefresh if refresh was requested externally
		if (reason === 'externalRefresh' && typeof onRefresh === 'function') {
			onRefresh()
		}

		// reset updating data reason
		setUpdatingDataReason(null)
	}, [
		limit,
		group,
		apiSearch,
		baseQuery,
		searchColumns,
		format,
		onRefresh,
	])

	// data update effect
	useEffect(() => {
		if (updateDataReason !== null) {
			// reset update data reason
			setUpdatingDataReason(updateDataReason)
			setUpdateDataReason(null)

			// abort any in-progress data update
			if (updateDataAbortController.current !== null && !updateDataAbortController.current.signal.aborted) {
				updateDataAbortController.current.abort(new Error('Data update (' + updateDataReason + ')'))
			}

			// trigger data update
			updateDataAbortController.current = new AbortController()
			updateData(updateDataReason)
		}
	}, [updateData, updateDataReason])

	//
	// rendering
	//

	const getValue = item => {
		if (typeof valueKey === 'string') {
			return item[valueKey]
		} else if (typeof valueKey === 'function') {
			return valueKey(item)
		}
		return null
	}

	const getValueItems = v => {
		if (!v) return null
		if (value) {
			const valueItem = multiple ? value.find(item => getValue(item) === v) : (getValue(value) === v ? value : null)
			if (valueItem) return valueItem
		}
		if (staticOptions.length > 0) {
			const staticOption = staticOptions.find(item => getValue(item) === v)
			if (staticOption) return staticOption
		}
		if (response && response.data) {
			const responseItem = response.data.find(item => getValue(item) === v)
			if (responseItem) return responseItem
		}
		return null
	}

	const showLoadingState = loading || updatingDataReason !== null || (!disabled && !(searchOnly && searchInputIsEmpty) && response === null)

	const selection = multiple
		? value.map(item => ({
			value: getValue(item),
			text: renderItem(item, textKey)
		}))
		: (value ? {
			value: getValue(value),
			text: renderItem(value, textKey)
		} : null)

	let options = !response || typeof response.data !== 'object' || !Array.isArray(response.data)
		? []
		: staticOptions.concat(response.data).map(item => ({
			value: getValue(item),
			text: renderItem(item, textKey)
		}))

	// add selection if missing from options list
	if (typeof selection === 'object' && Array.isArray(selection)) {
		options.unshift(...selection.filter(s => options.findIndex(option => option.value === s.value) === -1))
	} else if (isObject(selection) && options.findIndex(option => option.value === selection.value) === -1) {
		options.unshift(selection)
	}

	// get unique values only
	options = options.filter((item, i, ar) => ar.findIndex(item2 => item2.value === item.value) === i)

	return <Dropdown
		selection
		search={searchColumns.length > 0}
		clearable={clearable}
		error={!!searchError}
		multiple={multiple}
		disabled={disabled}
		placeholder={placeholder}
		noResultsMessage={searchError ? searchError : (showLoadingState ? loadingText : (searchOnly && searchInputIsEmpty ? searchEmptyText : emptyText))}
		loading={showLoadingState}
		options={options}
		value={selection ? (multiple ? selection.map(v => v.value) : selection.value) : null}
		onChange={(e, data) => {
			if (multiple) {
				onChange(data.value.map(value => getValueItems(value)).filter(item => item !== null))
			} else {
				onChange(getValueItems(data.value))
			}
			setSearchInput('')
		}}
		onSearchChange={(e, data) => setSearchInput(data.searchQuery)}
	/>
}
