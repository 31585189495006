import React, { useCallback, useEffect, useState } from 'react'
import { Segment, Header, Container, Grid, Form, Button, Icon, FormTextArea, FormField, ButtonGroup, Placeholder } from 'semantic-ui-react'
import DataTable from '../../components/data_table'
import { append, dateToString, handleRedirect, isObject, preventDefault } from '../../helpers'
import { getTaskExecution, getTaskExecutionLogEntries, retryTaskExecution, updateTaskExecution } from '../../api/tasks'
import WebsocketClient from '../../websocket_client'
import { Await, defer, useLoaderData, useNavigate } from 'react-router-dom'
import { preloadedEntity, useAuth, useMessages } from '../root'
import { Response } from '../../api'

export async function loader({ params: { executionId } }) {
	if (isObject(preloadedEntity.current) && typeof preloadedEntity.current.id === 'number' && preloadedEntity.current.id.toString() === executionId) {
		const execution = new Response({data: preloadedEntity.current})
		preloadedEntity.current = null
		return { executionId, execution }
	}
	const execution = getTaskExecution(executionId).catch(e => {
		console.error('Unable to load task execution:', e)
		return null
	})
	return defer({ executionId, execution })
}

const initialUpdatedExecutionData = {}

export default function TaskExecutionPage() {
	const { user } = useAuth()
	const { executionId, execution: initialExecution } = useLoaderData()
	const [updatedExecutionData, setUpdatedExecutionData] = useState(initialUpdatedExecutionData)
	const [, setMessages] = useMessages()
	const [inputChange, setInputChange] = useState(null)
	const [outputChange, setOutputChange] = useState(null)
	const [updatingInput, setUpdatingInput] = useState(false)
	const [updatingOutput, setUpdatingOutput] = useState(false)
	const routerNavigateFn = useNavigate()

	const handleWebSocketEvent = useCallback((topic, event) => initialExecution.then(({data: execution}) => setUpdatedExecutionData(updatedExecutionData => {
		if (
			topic.indexOf('no.kitcloud.sap.change.') === 0 &&
			event.type === 'update' &&
			execution.hasOwnProperty(event.id.field) &&
			execution[event.id.field] === event.id.value
		) {
			return append(updatedExecutionData, event.new_data)
		}
		return updatedExecutionData
	})), [initialExecution])

	const subscribeToWebSocketEvents = useCallback(session => {
		const topic = 'no.kitcloud.sap.change.update.task_executions.' + executionId
		session.subscribe(topic, (topic, event) => handleWebSocketEvent(topic, event))
	}, [executionId, handleWebSocketEvent])

	useEffect(() => {
		const { websocketUrl } = user.auth
		const ws = WebsocketClient.getInstance(websocketUrl)
		if (ws.isConnected) {
			subscribeToWebSocketEvents(ws.session)
		} else {
			const listener = session => {
				subscribeToWebSocketEvents(session)
				ws.removeEventListener(listener)
			}
			ws.addEventListener('open', listener)
		}
	}, [subscribeToWebSocketEvents, user])

	const triggerRetry = () => retryTaskExecution(executionId).then(res => {
		setMessages(messages => messages.concat({
			key: 'task_retry_success_' + Math.round(new Date() / 1000).toString(),
			dismissable: true,
			type: 'success',
			icon: 'check',
			content: 'Oppgaven er lagt i kø for å prøve igjen',
		}))
		handleRedirect(res, routerNavigateFn)
	}).catch(e => {
		setMessages(messages => messages.concat({
			key: 'task_retry_error_' + Math.round(new Date() / 1000).toString(),
			dismissable: true,
			type: 'error',
			icon: 'exclamation circle',
			title: 'En feil oppstod ved forsøk på å prøve oppgaven igjen',
			content: e.message
		}))
		handleRedirect(e.res, routerNavigateFn)
	})

	const apiSearch = useCallback(async (query, options, requestOptions) => {
		const res = await getTaskExecutionLogEntries(executionId, query, options, requestOptions)
		handleRedirect(res, routerNavigateFn)
		return res
	}, [executionId, routerNavigateFn])

	return <>
		<Segment vertical padded="very">
			<Container>
				<Header size="huge">
					Kjøring #{executionId} av <React.Suspense fallback={<Placeholder><Placeholder.Line /></Placeholder>}>
						<Await resolve={initialExecution}>
							{initialExecution => {
								const execution = append(isObject(initialExecution) && isObject(initialExecution.data) ? initialExecution.data : {}, updatedExecutionData)
								return execution.task_display_name
							}}
						</Await>
					</React.Suspense>
					<Header.Subheader>Viser status, jobb-status, input og output i JSON-format, samt. logger for kjøring av bakgrunnsjobben.</Header.Subheader>
				</Header>

				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Header>Status</Header>
							<React.Suspense fallback={<Placeholder><Placeholder.Line /></Placeholder>}>
								<Await resolve={initialExecution}>
									{initialExecution => {
										const execution = append(isObject(initialExecution) && isObject(initialExecution.data) ? initialExecution.data : {}, updatedExecutionData)
										return <p>{execution.state_display_name} ({execution.state_updated_at !== null ? dateToString(execution.state_updated_at, true, true) : 'Ikke startet enda'})</p>
									}}
								</Await>
							</React.Suspense>
							<React.Suspense>
								<Await resolve={initialExecution}>
									{initialExecution => {
										const execution = append(isObject(initialExecution) && isObject(initialExecution.data) ? initialExecution.data : {}, updatedExecutionData)
										return execution.can_retry ? <Button onClick={preventDefault(() => triggerRetry())}><Icon name="refresh" /> Prøv igjen</Button> : null
									}}
								</Await>
							</React.Suspense>
						</Grid.Column>
						<Grid.Column>
							<Header>Jobb-status</Header>
							<React.Suspense fallback={<Placeholder><Placeholder.Line /></Placeholder>}>
								<Await resolve={initialExecution}>
									{initialExecution => {
										const execution = append(isObject(initialExecution) && isObject(initialExecution.data) ? initialExecution.data : {}, updatedExecutionData)
										return <p>{execution.task_state_display_name}</p>
									}}
								</Await>
							</React.Suspense>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<p></p>

				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Header>Input</Header>
							<Form>
								<React.Suspense fallback={<FormTextArea disabled />}>
									<Await resolve={initialExecution}>
										{initialExecution => {
											const execution = append(isObject(initialExecution) && isObject(initialExecution.data) ? initialExecution.data : {}, updatedExecutionData)
											return <FormTextArea
												value={inputChange ?? JSON.stringify(execution.input)}
												onChange={(e, data) => setInputChange(data.value === execution.input ? null : data.value)}
											/>
										}}
									</Await>
								</React.Suspense>
								<FormField>
									<ButtonGroup>
										{inputChange === null ? null : <Button
											color="orange"
											loading={updatingInput}
											disabled={updatingInput}
											onClick={preventDefault(() => {
												setUpdatingInput(true)
												updateTaskExecution(executionId, { input: inputChange })
													.then(res => {
														setInputChange(null)
														setUpdatingInput(false)
														setUpdatedExecutionData(execution => append(execution, { input: res.data.input }))
														handleRedirect(res, routerNavigateFn)
													})
													.catch(e => {
														setUpdatingInput(false)
														setMessages(messages => messages.concat({
															key: 'task_execution_update_error_' + Math.round(new Date() / 1000).toString(),
															dismissable: true,
															type: 'error',
															icon: 'exclamation circle',
															title: 'En feil oppstod',
															content: 'Kunne ikke oppdatere bakgrunnsjobben: ' + e.message
														}))
														handleRedirect(e.res, routerNavigateFn)
													})
											})}
										>Lagre endringer</Button>}
										{inputChange === null ? null : <Button
											loading={updatingInput}
											disabled={updatingInput}
											onClick={preventDefault(() => setInputChange(null))}
										>Tilbakestill endringer</Button>}
									</ButtonGroup>
								</FormField>
							</Form>
						</Grid.Column>
						<Grid.Column>
							<Header>Output</Header>
							<Form>
								<React.Suspense fallback={<FormTextArea disabled />}>
									<Await resolve={initialExecution}>
										{initialExecution => {
											const execution = append(isObject(initialExecution) && isObject(initialExecution.data) ? initialExecution.data : {}, updatedExecutionData)
											return <FormTextArea
												value={outputChange ?? JSON.stringify(execution.output)}
												onChange={(e, data) => setOutputChange(data.value === execution.output ? null : data.value)}
											/>
										}}
									</Await>
								</React.Suspense>
								<FormField>
									<ButtonGroup>
										{outputChange === null ? null : <Button
											color="orange"
											loading={updatingOutput}
											disabled={updatingOutput}
											onClick={preventDefault(() => {
												setUpdatingOutput(true)
												updateTaskExecution(executionId, { output: outputChange })
													.then(res => {
														setOutputChange(null)
														setUpdatingOutput(false)
														setUpdatedExecutionData(execution => append(execution, { output: res.data.output }))
													})
													.catch(e => {
														setUpdatingOutput(false)
														setMessages(messages => messages.concat({
															key: 'task_execution_update_error_' + Math.round(new Date() / 1000).toString(),
															dismissable: true,
															type: 'error',
															icon: 'exclamation circle',
															title: 'En feil oppstod',
															content: 'Kunne ikke oppdatere bakgrunnsjobben: ' + e.message
														}))
													})
											})}
										>Lagre endringer</Button>}
										{outputChange === null ? null : <Button
											loading={updatingOutput}
											disabled={updatingOutput}
											onClick={preventDefault(() => setOutputChange(null))}
										>Tilbakestill endringer</Button>}
									</ButtonGroup>
								</FormField>
							</Form>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<p></p>

				<Header>Logg</Header>
				<DataTable
					className="kit-log-table"
					columns={[
						{ key: 'created_at', text: 'Tidspunkt', visible: true, sortable: true },
						{ key: 'severity', text: 'Type', visible: true, sortable: true },
						{ key: 'entry', text: 'Logg', visible: true, sortable: true, searchable: true },
					]}
					defaultOrder={{'created_at': 'DESC'}}
					websocketDisablePerAccountEvents
					websocketRelatedTarget="task_executions"
					websocketRelatedId={executionId}
					apiSearch={apiSearch}
					renderRow={data => [
						dateToString(data.created_at, true, true),
						data.display_severity,
						<pre>{data.entry}</pre>,
					]}
				/>
			</Container>
		</Segment>
	</>
}