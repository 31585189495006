import React, { useCallback, useState } from 'react'
import { Segment, Header, Container, Button, Popup, Icon, Grid, Message } from 'semantic-ui-react'
import DataTable from '../../../components/data_table'
import { getForwardings } from '../../../api/mail1/forwardings'
import { formatNumber, handleRedirect, navigate, preventDefault } from '../../../helpers'
import { useNavigate } from 'react-router-dom'
import { usePreloadedEntityDispatcher } from '../../root'

export default function KitmailForwardingsPage() {
	const [dataInfo, setDataInfo] = useState(null)
	const routerNavigateFn = useNavigate()
	const preloadEntity = usePreloadedEntityDispatcher()

	const apiSearch = useCallback(async (query, options, requestOptions) => {
		const res = await getForwardings(query, options, requestOptions)
		handleRedirect(res, routerNavigateFn)
		if (query === null) {
			setDataInfo(res.data_info)
		}
		return res
	}, [routerNavigateFn])

	return <>
		<Segment vertical padded="very">
			<Container>
				<Grid stackable>
					<Grid.Row>
						<Grid.Column width={10}>
							<Header size="huge">
								KitMail-videresendinger
								<Header.Subheader>Liste over KitMail-videresendinger du kan administrere</Header.Subheader>
							</Header>
						</Grid.Column>
						<Grid.Column width={6} textAlign="right">
							<Button
								as="a"
								href="/kitmail/forwardings/new"
								onClick={preventDefault(e => navigate('/kitmail/forwardings/new', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
								color="orange"
								label={dataInfo ? formatNumber(dataInfo.count) : '0'}
								labelPosition="right"
								content={<><Icon name="add" /> Opprett ny videresending</>}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<p></p>
				<Message info><Icon name="info" /> Adresser som starter med <code>@</code> er "wildcard"-videresendinger (videresend all e-post fra domenet)</Message>

				<DataTable
					columns={[
						{ key: 'source.address', text: 'Fra', width: 7, visible: true, sortable: true, searchable: true },
						{ key: 'destination.address', text: 'Til', width: 7, visible: true, sortable: true, searchable: true },
						{ key: 2, text: '', width: 2, visible: true },
					]}
					defaultOrder={{'source.address': 'ASC'}}
					apiSearch={apiSearch}
					renderRow={data => [
						data.source.address,
						data.destination.address,
						<div className="text-ha-right">
							<Button.Group size="mini" compact>
								<Popup
									inverted
									content="Slett videresending"
									trigger={<Button
										as="a"
										negative
										icon="delete"
										href={'/kitmail/forwardings/' + data.source.address + '/delete'}
										onClick={preventDefault(e => {
											// preload forwarding data
											preloadEntity(data)
											// navigate to forwarding delete page
											navigate('/kitmail/forwardings/' + data.source.address + '/delete', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn)
										})}
									/>}
								/>
							</Button.Group>
						</div>,
					]}
				/>
			</Container>
		</Segment>
	</>
}