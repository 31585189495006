import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Segment, Container, Header, Form, Input, Label, Icon, Grid, Placeholder, Message, List, Button } from 'semantic-ui-react'
import { emailRegex, urlRegex, countryOptions, objValOrDefault, preventDefault, extractHostFromUrlOrEmailAddress, rtrim, isProbablyFullyQualifiedDomainName, debounce, isObject, handleRedirect } from '../../helpers'
import ResourceCard from '../../components/resource_card'
import { getDomainRegistrarServiceByName, getDomainNameserverServiceByName, getDomainEmailServiceByName, getDomainHostingServiceByName, requestTransferToken } from '../../api/domains'
import DomainContact from '../../components/domain_contact'
import { isValidPhoneNumber } from 'react-phone-number-input'
import Link from '../../components/link'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { useMessages } from '../root'
import DomainRegistrarServiceCard from '../../components/domain_registrar_service_card'
import DomainNameserverServiceCard from '../../components/domain_nameserver_service_card'
import DomainEmailServiceCard from '../../components/domain_email_service_card'
import DomainHostingServiceCard from '../../components/domain_hosting_service_card'

export async function loader({ params }) {
	const domainName = isObject(params) && typeof params.domain === 'string' ? params.domain : null
	return { domainName }
}

export default function LookupDomainToolPage() {
	const [, setMessages] = useMessages()

	const { domainName: initialDomainName } = useLoaderData()

	const routerNavigateFn = useNavigate()

	// debounced user input: search field
	const initialSearchInput = ''
	const [searchInput, setSearchInput] = useState(initialSearchInput)
	const [debouncedTransformedSearchInput, setDebouncedTransformedSearchInput] = useState(initialSearchInput)
	const debouncedSetDebouncedTransformedSearchInput = useMemo(() => debounce(value => {
		const transformedValue = rtrim(extractHostFromUrlOrEmailAddress(value), '.')
		setDebouncedTransformedSearchInput(transformedValue)
	}, 500), [])
	useEffect(() => debouncedSetDebouncedTransformedSearchInput(searchInput), [searchInput, debouncedSetDebouncedTransformedSearchInput])
	const cachedTransformedSearchInput = useRef(initialSearchInput)
	useEffect(() => {
		if (cachedTransformedSearchInput.current !== debouncedTransformedSearchInput) {
			cachedTransformedSearchInput.current = debouncedTransformedSearchInput

			// validate search query
			if (!isProbablyFullyQualifiedDomainName(cachedTransformedSearchInput.current)) {
				setSearchError('Dette ser ikke ut som et gyldig domenenavn, prøv igjen')
				reset(false)
				return
			}

			setSearchError(null)
			reset(true)
			Promise.all([
				getDomainRegistrarServiceByName(cachedTransformedSearchInput.current)
					.then(registrarService => {
						handleRedirect(registrarService, routerNavigateFn)
						if (isObject(registrarService) && registrarService.success && isObject(registrarService.data)) {
							setRegistrarService(registrarService.data)
						} else {
							setRegistrarService(null)
						}
					})
					.finally(() => {
						setLoadingRegistrarService(false)
					}),
				getDomainNameserverServiceByName(cachedTransformedSearchInput.current)
					.then(nameserverService => {
						handleRedirect(nameserverService, routerNavigateFn)
						if (isObject(nameserverService) && nameserverService.success && isObject(nameserverService.data)) {
							setNameserverService(nameserverService.data)
						} else {
							setNameserverService(null)
						}
					})
					.finally(() => {
						setLoadingNameserverService(false)
					}),
				getDomainEmailServiceByName(cachedTransformedSearchInput.current)
					.then(emailService => {
						handleRedirect(emailService, routerNavigateFn)
						if (isObject(emailService) && emailService.success && isObject(emailService.data)) {
							setEmailService(emailService.data)
						} else {
							setEmailService(null)
						}
					})
					.finally(() => {
						setLoadingEmailService(false)
					}),
				getDomainHostingServiceByName(cachedTransformedSearchInput.current)
					.then(hostingService => {
						handleRedirect(hostingService, routerNavigateFn)
						if (isObject(hostingService) && hostingService.success && isObject(hostingService.data)) {
							setHostingService(hostingService.data)
						} else {
							setHostingService(null)
						}
					})
					.finally(() => {
						setLoadingHostingService(false)
					}),
			]).catch(e => {
				// Add failure message (unknown error)
				console.error('Error looking up domain ' + cachedTransformedSearchInput.current + ':', e)
				setSearchError(e.message)
			})
		}
	}, [debouncedTransformedSearchInput, routerNavigateFn])

	const [searchError, setSearchError] = useState(null)

	const [loadingEmailService, setLoadingEmailService] = useState(false)
	const [emailService, setEmailService] = useState(null)

	const [loadingHostingService, setLoadingHostingService] = useState(false)
	const [hostingService, setHostingService] = useState(null)

	const [loadingNameserverService, setLoadingNameserverService] = useState(false)
	const [nameserverService, setNameserverService] = useState(null)

	const [loadingRegistrarService, setLoadingRegistrarService] = useState(false)
	const [registrarService, setRegistrarService] = useState(null)

	const [sendingTransferToken, setSendingTransferToken] = useState(false)

	const loading = loadingEmailService && loadingHostingService && loadingNameserverService && loadingRegistrarService

	const reset = newLoading => {
		setLoadingEmailService(newLoading)
		setEmailService(null)

		setLoadingHostingService(newLoading)
		setHostingService(null)

		setLoadingNameserverService(newLoading)
		setNameserverService(null)

		setLoadingRegistrarService(newLoading)
		setRegistrarService(null)
	}

	const sendTransferToken = () => {
		if (!isObject(registrarService) || !isObject(registrarService.whois) || !registrarService.whois.registered || registrarService.type === 'kit' || !isObject(registrarService.domain_name_parts) || registrarService.domain_name_parts.tld !== 'no' || sendingTransferToken) return
		let email = null
		if (registrarService.whois.contacts && typeof registrarService.whois.contacts.owner === 'object' && Array.isArray(registrarService.whois.contacts.owner) && registrarService.whois.contacts.owner.length > 0) {
			if (typeof registrarService.whois.contacts.owner[0].email === 'string') {
				email = registrarService.whois.contacts.owner[0].email
			} else if (typeof registrarService.whois.contacts.owner[0].email === 'object' && Array.isArray(registrarService.whois.contacts.owner[0].email) && registrarService.whois.contacts.owner[0].email.length > 0) {
				email = registrarService.whois.contacts.owner[0].email[0]
			}
		}
		const hasEmail = typeof email === 'string'
		const emailIsValid = hasEmail && emailRegex.test(email)
		if (!hasEmail || !emailIsValid) return
		setSendingTransferToken(true)
		requestTransferToken(registrarService.whois.idnName)
			.then(() => {
				console.log('Transfer token sent for domain ' + registrarService.whois.idnName)
				setMessages(messages => messages.concat({
					key: 'send_transfer_token_success_' + registrarService.whois.idnName + '_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'success',
					icon: 'check',
					title: 'Engangskode sendt',
					content: 'Engangskoden for domenet ' + registrarService.whois.idnName + ' ble sendt til ' + email
				}))
			})
			.catch(e => {
				// Add failure message (unknown error)
				console.error('Error sending transfer token for domain ' + registrarService.whois.idnName + ':', e)
				setMessages(messages => messages.concat({
					key: 'send_transfer_token_error_' + registrarService.whois.idnName + '_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'warning',
					icon: 'exclamation triangle',
					title: 'Feil ved utsending av engangskode',
					content: 'En feil oppstod ved sending av engangskode for domenet ' + registrarService.whois.idnName + ': ' + e.message
				}))
			})
			.finally(() => setSendingTransferToken(false))
	}

	const renderSearchError = () => {
		if (!searchError) return null

		return <Label pointing="above" prompt>
			<Icon name="exclamation triangle" />&ensp;{searchError}
		</Label>
	}

	const renderSearch = () => {
		return <Form>
			<Form.Field error={!!searchError}>
				<Input
					fluid
					icon="search"
					disabled={sendingTransferToken}
					loading={loading}
					placeholder="Skriv inn et domenenavn, en link eller en e-postadresse..."
					value={searchInput}
					onChange={(e, data) => setSearchInput(data.value)}
				/>
				{renderSearchError()}
			</Form.Field>
		</Form>
	}

	useEffect(() => {
		if (initialDomainName !== null) {
			setSearchInput(initialDomainName)
		}
	}, [initialDomainName])

	let ownerContactContent = null
	let actions = []
	if (isObject(registrarService) && isObject(registrarService.whois) && isObject(registrarService.whois.contacts) && typeof registrarService.whois.contacts.owner === 'object' && Array.isArray(registrarService.whois.contacts.owner) && registrarService.whois.contacts.owner.length > 0) {
		let email = registrarService.whois.contacts.owner[0].email
		const hasEmail = typeof email === 'string' || (typeof email === 'object' && Array.isArray(email) && email.length > 0 && email.findIndex(em => typeof em !== 'string') === -1)
		const emailIsURL = hasEmail && (typeof email === 'object' && Array.isArray(email) ? email.findIndex(em => !urlRegex.test(em)) === -1 : urlRegex.test(email))
		if (emailIsURL && typeof email === 'string') {
			email = email.replace('mailto:', '')
			const emailQuerystringPos = email.indexOf('?')
			if (emailQuerystringPos !== -1) {
				email = email.substring(emailQuerystringPos)
			}
			email = decodeURIComponent(email)
		} else if (emailIsURL && typeof email === 'object' && Array.isArray(email)) {
			email = email.map(em => {
				em = em.replace('mailto:', '')
				const emailQuerystringPos = em.indexOf('?')
				if (emailQuerystringPos !== -1) {
					em = em.substring(emailQuerystringPos)
				}
				return decodeURIComponent(em)
			})
		}
		const emailIsValid = hasEmail && (typeof email === 'object' && Array.isArray(email) ? email.findIndex(em => !emailRegex.test(em)) === -1 : emailRegex.test(email))

		const phone = registrarService.whois.contacts.owner[0].phone
		const hasPhone = typeof phone === 'string'
		const phoneIsValid = hasPhone && isValidPhoneNumber(phone)

		const fax = registrarService.whois.contacts.owner[0].fax
		const hasFax = typeof fax === 'string'
		const faxIsValid = hasFax && isValidPhoneNumber(fax)

		const countryName = objValOrDefault(countryOptions.no.find(option => option.value === registrarService.whois.contacts.owner[0].country), 'text', null)
		ownerContactContent = <DomainContact
			type="owner"
			domainNameParts={registrarService.domain_name_parts}
			data={{
				id: null,
				account_id: null,
				country: {
					iso2: registrarService.whois.contacts.owner[0].country,
					name: countryName,
				},
				name: registrarService.whois.contacts.owner[0].name,
				phone: {
					number: hasPhone && phoneIsValid ? phone : null,
					uri: hasPhone && phoneIsValid ? 'tel:' + phone.replace('.', '') : null,
				},
				fax: {
					number: hasFax && faxIsValid ? fax : null,
					uri: hasFax && faxIsValid ? 'tel:' + fax.replace('.', '') : null,
				},
				email_address: hasEmail && emailIsValid ? email : null,
				city: registrarService.whois.contacts.owner[0].city,
				state: registrarService.whois.contacts.owner[0].state,
				street_address: registrarService.whois.contacts.owner[0].address,
				postal_code: registrarService.whois.contacts.owner[0].zipcode,
				norid_identity_type: null,
				norid_identity: null,
				contact_type: registrarService.whois.contacts.owner[0].type,
				organization_name: registrarService.whois.contacts.owner[0].organization,
				organization_number: null,
				id_card_number: null,
				created_at: null,
				updated_at: null,
			}}
		/>

		if (registrarService.whois.registered && registrarService.type !== 'kit' && isObject(registrarService.domain_name_parts) && registrarService.domain_name_parts.tld === 'no' && hasEmail && emailIsValid) {
			actions.push(<Button
				key="action_norid_request_transfer_token"
				color="orange"
				disabled={sendingTransferToken}
				loading={sendingTransferToken}
				onClick={preventDefault(() => sendTransferToken())}
			>
				Send engangskode <Icon name="send" className="right" />
			</Button>)
		}
	}

	return <>
		<Segment vertical padded="very">
			<Container>
				<Header size="huge">Domeneoppslag</Header>
				{renderSearch()}
			</Container>
		</Segment>
		{debouncedTransformedSearchInput.length > 0 && !searchError && !loading ? <>
			<Segment vertical padded="very" secondary>
				<Container>
					<Header size="large">Resultater for <code>{debouncedTransformedSearchInput}</code></Header>
					<p className="text-secondary">Verktøyet viser kun informasjon om hoveddomenet, ikke subdomene.</p>

					<Grid>
						<Grid.Row columns={3}>
							<Grid.Column>
								<DomainRegistrarServiceCard loading={loadingRegistrarService} value={registrarService} />
							</Grid.Column>
							<Grid.Column>
								{registrarService && registrarService && registrarService.whois && !registrarService.whois.registered ? null : <DomainNameserverServiceCard loading={loadingNameserverService} value={nameserverService} />}
								{registrarService && registrarService && registrarService.whois && (!registrarService.whois.contacts || !registrarService.whois.contacts.owner) ? null : ownerContactContent}
							</Grid.Column>
							<Grid.Column>
								{registrarService && registrarService && registrarService.whois && !registrarService.whois.registered ? null : <DomainHostingServiceCard loading={loadingHostingService} value={hostingService} />}
								{registrarService && registrarService && registrarService.whois && !registrarService.whois.registered ? null : <DomainEmailServiceCard loading={loadingEmailService} value={emailService} />}
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
			</Segment>
			{actions.length > 0 ? <Segment vertical padded="very">
				<Container>
					<Header size="large">Handlinger</Header>
					<Button.Group>{actions}</Button.Group>
					<p></p>
					<p className="text-secondary">Engangskode for flytting av domenet sendes til juridisk eier via e-post. Koden kan kun brukes av oss og utløper etter ett bruk eller 30 dager. Funksjonen er kun tilgjengelig for <code>.no</code>-domener</p>
				</Container>
			</Segment> : null}
		</> : null}
	</>
}