import React from 'react'
import { Form, Input } from 'semantic-ui-react'
import { formatBytes } from '../helpers'
import Slider from './slider'

const OneGiB = ((2 ** 10) ** 3)

// 0, 1, 2 GiB
let quotaSteps = [0, OneGiB, 2 * OneGiB]
// 5 - 25 GiB in 5 GiB increments
for (let i = 5; i <= 25; i += 5) { quotaSteps.push(i * OneGiB); }
// 30 - 100 GiB in 10 GiB increments
for (let i = 30; i <= 100; i += 10) { quotaSteps.push(i * OneGiB); }

export default function EmailQuotaInput({
	label,
	value,
	onChange,
}) {
	label = label ?? 'Lagringskvote'
	value = value ?? (5 * OneGiB)
	onChange = onChange ?? (value => {})

	return <Form.Group>
		<Form.Field width={14}>
			<label>{label}</label>
			<Slider
				value={value}
				min={quotaSteps[0]}
				max={quotaSteps[quotaSteps.length - 1]}
				steps={quotaSteps}
				onlySteps
				precision={0}
				label={formatBytes(value)}
				onChange={value => onChange(value)}
			/>
		</Form.Field>
		<Form.Field>
			<label>&nbsp;</label>
			<Input
				type="number"
				step={1}
				min={0}
				label="GiB"
				labelPosition="right"
				value={parseFloat((value / OneGiB).toFixed(2))}
				onChange={(e, data) => onChange(parseInt(data.value) * OneGiB)}
			/>
		</Form.Field>
	</Form.Group>
}