import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Segment, Header, Container, Select, Form, Button, Icon, Label, Input, Popup, Loader } from 'semantic-ui-react'
import AddressInput, { defaultAddress, formatAddress } from '../../components/address_input'
import { append, genCorrelationId, formatNumber, request, mode, preventDefault, handleRedirect, debounce, isObject } from '../../helpers'
import { createConsignment, estimateConsignment, getPickupPoints, getPostalCode, getPostalCodeSupportedCountryCodes } from '../../api/mybring'
import { createTicketNote, PUBLISH_INTERNAL as TICKET_NOTE_PUBLISH_INTERNAL, NOTE_TYPE_TASK_NOTES as TICKET_NOTE_TYPE_TASK_NOTES } from '../../api/autotask/ticket_notes'
import { createTicketCharge, updateTicketCharge, STATUS_READY_TO_DELIVER_OR_SHIP as TICKET_CHARGE_STATUS_READY_TO_DELIVER_OR_SHIP, STATUS_DELIVERED_OR_SHIPPED_FULL as TICKET_CHARGE_STATUS_DELIVERED_OR_SHIPPED_FULL, CHARGE_TYPE_OPERATIONAL as TICKET_CHARGE_TYPE_OPERATIONAL } from '../../api/autotask/ticket_charges'
import { createTicketNoteAttachment, PUBLISH_INTERNAL as TICKET_NOTE_ATTACHMENT_PUBLISH_INTERNAL } from '../../api/autotask/ticket_note_attachments'
import { ID_MATERIAL_SHIPPING as BILLING_CODE_ID_MATERIAL_SHIPPING } from '../../api/autotask/billing_codes'
import Link from '../../components/link'
import { Await, defer, useLoaderData, useNavigate } from 'react-router-dom'
import { getTickets } from '../../api/autotask/tickets'
import { getCompany } from '../../api/autotask/companies'
import { getContact } from '../../api/autotask/contacts'
import { getCountries } from '../../api/autotask/countries'
import { getCompanyLocation } from '../../api/autotask/company_locations'
import { useMessages } from '../root'

const defaultPackage = {
	weightInKg: '0.5',
	volumeInDm3: null,
	dimensions: {
		heightInCm: '0',
		widthInCm: '0',
		lengthInCm: '0',
	},
	correlationId: '',
}

const serviceNames = {
	'en': {
		'POSTOPPKRAV': 'Cash on delivery',
		'EVARSLING': 'eAdvising',
		'LETTER_NOTIFICATION': 'Notification by letter',
		'LORDAGSUTKJORING': 'Saturday delivery',
		'SIMPLIFIED_DELIVERY': 'Simplified delivery',
		'1133': 'ID verification',
		'VALGFRITT_POSTKONTOR': 'Optional pickup point',
		'1134': 'Individual Verification',
		'1081': 'Bag on door',
		'1083': 'Temperature controlled transport',
		'SOSIAL_KONTROLL': 'Social control',
		'2012': 'Same day delivery',
	},
	'no': {
		'POSTOPPKRAV': 'Postoppkrav',
		'EVARSLING': 'eVarsling',
		'LETTER_NOTIFICATION': 'Varsling per brev',
		'LORDAGSUTKJORING': 'Utlevering lørdag',
		'SIMPLIFIED_DELIVERY': 'Forenklet utlevering',
		'1133': 'Utlevering mot ID',
		'VALGFRITT_POSTKONTOR': 'Valgfritt hentested',
		'1134': 'Personlig utlevering',
		'1081': 'Pose på døren',
		'1083': 'Temperaturkontrollert transport',
		'SOSIAL_KONTROLL': 'Sosial kontroll',
		'2012': 'Utlevering samme dag',
	}
}

const deliveryOptionNames = {
	'en': {
		'ONE_DELIVERY_THEN_PIB': 'One delivery attempt, then pickup point',
		'TWO_DELIVERIES_THEN_PIB': 'Two delivery attempts, then pickup point',
		'TWO_DELIVERIES_THEN_RETURN': 'Two delivery attempts, then return',
	},
	'no': {
		'ONE_DELIVERY_THEN_PIB': 'Ett utleveringsforsøk, så hentested',
		'TWO_DELIVERIES_THEN_PIB': 'To utleveringsforsøk, så hentested',
		'TWO_DELIVERIES_THEN_RETURN': 'To utleveringsforsøk, så retur',
	}
}

const productInfo = {
	'5000': { // Pakke til bedrift (i Norge)
		returnProductId: '9000',
		maxWeight: 35,
		services: ['SIMPLIFIED_DELIVERY'],
		defaultServices: [],
		deliveryOptions: ['ONE_DELIVERY_THEN_PIB', 'TWO_DELIVERIES_THEN_PIB', 'TWO_DELIVERIES_THEN_RETURN'],
		defaultDeliveryOptionId: 'ONE_DELIVERY_THEN_PIB'
	},
	'4850': { // Ekspress neste dag (til bedrift i Norge)
		returnProductId: '9600',
		maxWeight: 35,
		services: ['LORDAGSUTKJORING', '1133', 'VALGFRITT_POSTKONTOR', '1083'],
		defaultServices: [],
		deliveryOptions: [],
		defaultDeliveryOptionId: null,
	},
	'3584': { // Pakke i postkassen (til privatperson i Norge)
		returnProductId: '9650',
		maxWeight: 5,
		services: ['EVARSLING', '1081'],
		defaultServices: ['EVARSLING'],
		deliveryOptions: [],
		defaultDeliveryOptionId: null,
	},
	'0345': { // Pakke i postkassen (til privatperson i Danmark/Finland/Sverige)
		returnProductId: null,
		maxWeight: 5,
		services: [],
		defaultServices: [],
		deliveryOptions: [],
		defaultDeliveryOptionId: null,
	},
	'5800': { // Pakke til hentested (til privatperson i Norge)
		returnProductId: '9300',
		maxWeight: 35,
		services: ['EVARSLING', 'LETTER_NOTIFICATION', '1133', 'VALGFRITT_POSTKONTOR', '1134', 'SOSIAL_KONTROLL' /* XXX: 'POSTOPPKRAV' is disabled because it requires extra parameters */],
		defaultServices: ['EVARSLING', 'VALGFRITT_POSTKONTOR'],
		deliveryOptions: [],
		defaultDeliveryOptionId: null,
	},
	'5600': { // Pakke levert hjem (til privatperson i Norge)
		returnProductId: '9300',
		maxWeight: 35,
		services: ['SIMPLIFIED_DELIVERY', '1133', '1134', '2012'],
		defaultServices: [],
		deliveryOptions: ['ONE_DELIVERY_THEN_PIB', 'TWO_DELIVERIES_THEN_PIB', 'TWO_DELIVERIES_THEN_RETURN'],
		defaultDeliveryOptionId: 'ONE_DELIVERY_THEN_PIB'
	},
}

const addressTypeText = {
	company: 'Kunde/leverandør',
	contact: 'Kontaktperson',
	location: 'Lokasjon',
	manual: 'Egendefinert adresse',
}

const initialProductCode = '5000'
const initialPackages = [{...defaultPackage}]
const initialEnabledServices = productInfo[initialProductCode].defaultServices
const initialDeliveryOptionId = productInfo[initialProductCode].defaultDeliveryOptionId
const initialLoadingPickupPoints = initialEnabledServices.includes('VALGFRITT_POSTKONTOR')

export async function loader({ params: { ticketNumber } }) {
	const kitCompany = getCompany(0).then(({data}) => data)
	const ticket = getTickets([{op: 'eq', field: 'ticketNumber', value: ticketNumber}], { limit: 1 })
		.then(ticketsResponse => typeof ticketsResponse.data === 'object' &&
			ticketsResponse.data.length > 0
				? ticketsResponse.data[0]
				: null
		)
	const ticketCompany = ticket.then(ticket => isObject(ticket) &&
		(typeof ticket.companyID === 'string' || typeof ticket.companyID === 'number')
			? getCompany(ticket.companyID).then(({data}) => data).catch(e => null)
			: Promise.resolve(null)
		)
	const ticketContact = ticket.then(ticket => isObject(ticket) &&
		(typeof ticket.contactID === 'string' || typeof ticket.contactID === 'number')
			? getContact(ticket.contactID).then(({data}) => data).catch(e => null)
			: Promise.resolve(null)
		)
	const ticketCompanyLocation = ticket.then(ticket => isObject(ticket) &&
		(typeof ticket.companyLocationID === 'string' || typeof ticket.companyLocationID === 'number')
			? getCompanyLocation(ticket.companyLocationID).then(({data}) => data).catch(e => null)
			: Promise.resolve(null)
		)

	const ticketId = ticket.then(ticket => isObject(ticket) ? ticket.id : null)
	const addresses = Promise.allSettled([
		kitCompany,
		ticketCompany,
		ticketContact,
		ticketCompanyLocation
	])
		.then(([kitCompanyRes, ticketCompanyRes, ticketContactRes, ticketCompanyLocationRes]) => {
			const kitCompany = kitCompanyRes.status === 'fulfilled' ? kitCompanyRes.value : null
			const ticketCompany = ticketCompanyRes.status === 'fulfilled' ? ticketCompanyRes.value : null
			const ticketContact = ticketContactRes.status === 'fulfilled' ? ticketContactRes.value : null
			const ticketCompanyLocation = ticketCompanyLocationRes.status === 'fulfilled' ? ticketCompanyLocationRes.value : null

			const countryIDs = [
				kitCompany.countryID,
				isObject(ticketCompany) ? ticketCompany.countryID : null,
				isObject(ticketContact) ? ticketContact.countryID : null,
				isObject(ticketCompanyLocation) ? ticketCompanyLocation.countryID : null,
			].filter(id => typeof id === 'number')

			const uniqueCountryIDs = countryIDs.filter((countryID, i, arr) => arr.indexOf(countryID) === i)
			const countries = getCountries([{op: 'in', field: 'id', value: uniqueCountryIDs}]).then(({data}) => data)
			return Promise.all([
				countries,
				Promise.resolve(kitCompany),
				Promise.resolve(ticketCompany),
				Promise.resolve(ticketContact),
				Promise.resolve(ticketCompanyLocation)
			])
		})
		.then(([countries, kitCompany, ticketCompany, ticketContact, ticketCompanyLocation]) => {
			const kitCompanyCountry = (countries.find(country => country.id === kitCompany.countryID) ?? null)
			const ticketCompanyCountry = isObject(ticketCompany) ? (countries.find(country => country.id === ticketCompany.countryID) ?? null) : null
			const ticketContactCountry = isObject(ticketContact) ? (countries.find(country => country.id === ticketContact.countryID) ?? null) : null
			const ticketCompanyLocationCountry = isObject(ticketCompanyLocation) ? (countries.find(country => country.id === ticketCompanyLocation.countryID) ?? null) : null

			const postalCodes = [
				kitCompanyCountry.countryCode.toUpperCase() + '-' + kitCompany.postalCode,
				isObject(ticketCompany) && isObject(ticketCompanyCountry) && getPostalCodeSupportedCountryCodes.includes(ticketCompanyCountry.countryCode.toUpperCase()) ? ticketCompanyCountry.countryCode.toUpperCase() + '-' + ticketCompany.postalCode : null,
				isObject(ticketContact) && isObject(ticketContactCountry) && getPostalCodeSupportedCountryCodes.includes(ticketContactCountry.countryCode.toUpperCase()) ? ticketContactCountry.countryCode.toUpperCase() + '-' + ticketContact.zipCode : null,
				isObject(ticketCompanyLocation) && isObject(ticketCompanyLocationCountry) && getPostalCodeSupportedCountryCodes.includes(ticketCompanyLocationCountry.countryCode.toUpperCase()) ? ticketCompanyLocationCountry.countryCode.toUpperCase() + '-' + ticketCompanyLocation.postalCode : null,
			].filter(postalCode => typeof postalCode === 'string' && postalCode.trim().length > 0).map(postalCode => postalCode.trim())
			const uniquePostalCodes = postalCodes.filter((postalCode, i, arr) => arr.indexOf(postalCode) === i)
			const postalCodeInfo = Promise.all(uniquePostalCodes.map(postalCodeAndCountryCode => {
				const [countryCode, postalCode] = postalCodeAndCountryCode.split('-', 2)
				return getPostalCode(postalCode, countryCode).then(({data}) => data)
			}))

			return Promise.all([
				postalCodeInfo,
				Promise.resolve(kitCompanyCountry),
				Promise.resolve(ticketCompanyCountry),
				Promise.resolve(ticketContactCountry),
				Promise.resolve(ticketCompanyLocationCountry),
				Promise.resolve(kitCompany),
				Promise.resolve(ticketCompany),
				Promise.resolve(ticketContact),
				Promise.resolve(ticketCompanyLocation),
			])
		})
		.then(([
			postalCodeInfo,
			kitCompanyCountry,
			ticketCompanyCountry,
			ticketContactCountry,
			ticketCompanyLocationCountry,
			kitCompany,
			ticketCompany,
			ticketContact,
			ticketCompanyLocation,
		]) => {
			const kitCompanyPostalCodeInfo = (postalCodeInfo.find(info => info.postalcode === kitCompany.postalCode.trim()) ?? null)
			const ticketCompanyPostalCodeInfo = isObject(ticketCompany) && typeof ticketCompany.postalCode === 'string' && ticketCompany.postalCode.trim().length > 0 && isObject(ticketCompanyCountry) && getPostalCodeSupportedCountryCodes.includes(ticketCompanyCountry.countryCode.toUpperCase()) ? (postalCodeInfo.find(info => info.postalcode === ticketCompany.postalCode.trim()) ?? null) : null
			const ticketContactPostalCodeInfo = isObject(ticketContact) && typeof ticketContact.zipCode === 'string' && ticketContact.zipCode.trim().length > 0 && isObject(ticketContactCountry) && getPostalCodeSupportedCountryCodes.includes(ticketContactCountry.countryCode.toUpperCase()) ? (postalCodeInfo.find(info => info.postalcode === ticketContact.zipCode.trim()) ?? null) : null
			const ticketCompanyLocationPostalCodeInfo = isObject(ticketCompanyLocation) && typeof ticketCompanyLocation.postalCode === 'string' && ticketCompanyLocation.postalCode.trim().length > 0 && isObject(ticketCompanyLocationCountry) && getPostalCodeSupportedCountryCodes.includes(ticketCompanyLocationCountry.countryCode.toUpperCase()) ? (postalCodeInfo.find(info => info.postalcode === ticketCompanyLocation.postalCode.trim()) ?? null) : null

			const initialFromAddress = {
				name: kitCompany.companyName,
				contactName: '',
				address1: kitCompany.address1 ?? '',
				address2: kitCompany.address2 ?? '',
				city: (isObject(kitCompanyPostalCodeInfo) && typeof kitCompanyPostalCodeInfo.city === 'string' ? kitCompanyPostalCodeInfo.city : kitCompany.city) ?? '',
				postalCode: kitCompany.postalCode ?? '',
				state: kitCompany.state ?? '',
				note: kitCompany.additionalAddressInformation ?? '',
				reference: ticketNumber,
				countryCode: isObject(kitCompanyCountry) && typeof kitCompanyCountry.countryCode === 'string' ? kitCompanyCountry.countryCode : '',
				countryName: isObject(kitCompanyCountry) && typeof kitCompanyCountry.name === 'string' ? kitCompanyCountry.name : '',
				email: isObject(kitCompany.userDefinedFields) && kitCompany.userDefinedFields.hasOwnProperty('Email') && typeof kitCompany.userDefinedFields['Email'] === 'string' ? kitCompany.userDefinedFields['Email'] : '',
				mobile: kitCompany.phone ?? '',
			}

			const companyAddress = isObject(ticketCompany) ? {
				name: ticketCompany.companyName ?? '',
				contactName: isObject(ticketContact) ? [[ticketContact.firstName ?? '', ticketContact.middleInitial ?? ''].join(' ').trim(), ticketContact.lastName ?? ''].join(' ').trim() : '',
				address1: ticketCompany.address1 ?? '',
				address2: ticketCompany.address2 ?? '',
				city: (isObject(ticketCompanyPostalCodeInfo) && typeof ticketCompanyPostalCodeInfo.city === 'string' ? ticketCompanyPostalCodeInfo.city : ticketCompany.city) ?? '',
				postalCode: ticketCompany.postalCode ?? '',
				state: ticketCompany.state ?? '',
				note: ticketCompany.additionalAddressInformation ?? '',
				reference: ticketNumber,
				countryCode: isObject(ticketCompanyCountry) ? ticketCompanyCountry.countryCode : '',
				countryName: isObject(ticketCompanyCountry) ? ticketCompanyCountry.name : '',
				email: isObject(ticketContact) && typeof ticketContact.emailAddress === 'string' ? ticketContact.emailAddress : (isObject(ticketCompany) && isObject(ticketCompany.userDefinedFields) && ticketCompany.userDefinedFields.hasOwnProperty('Email') && typeof ticketCompany.userDefinedFields['Email'] === 'string' ? ticketCompany.userDefinedFields['Email'] : ''),
				mobile: isObject(ticketContact) && typeof ticketContact.mobilePhone === 'string' ? ticketContact.mobilePhone : '',
			} : null

			const contactAddress = isObject(ticketContact) ? {
				name: isObject(ticketCompany) && typeof ticketCompany.companyName === 'string' ? ticketCompany.companyName : '',
				contactName: [[ticketContact.firstName ?? '', ticketContact.middleInitial ?? ''].join(' ').trim(), ticketContact.lastName ?? ''].join(' ').trim(),
				address1: ticketContact.addressLine ?? '',
				address2: ticketContact.addressLine1 ?? '',
				city: (isObject(ticketContactPostalCodeInfo) && typeof ticketContactPostalCodeInfo.city === 'string' ? ticketContactPostalCodeInfo.city : ticketContact.city) ?? '',
				postalCode: ticketContact.zipCode ?? '',
				state: ticketContact.state ?? '',
				note: ticketContact.additionalAddressInformation ?? '',
				reference: ticketNumber,
				countryCode: isObject(ticketContactCountry) && typeof ticketContactCountry.countryCode === 'string' ? ticketContactCountry.countryCode : '',
				countryName: isObject(ticketContactCountry) && typeof ticketContactCountry.name === 'string' ? ticketContactCountry.name : '',
				email: ticketContact.emailAddress ?? '',
				mobile: ticketContact.mobilePhone ?? '',
			} : null

			const locationAddress = isObject(ticketCompany) ? {
				name: ticketCompany.companyName ?? '',
				contactName: isObject(ticketContact) ? [[ticketContact.firstName, ticketContact.middleInitial].join(' ').trim(), ticketContact.lastName].join(' ').trim() : '',
				address1: ticketCompanyLocation.address1 ?? '',
				address2: ticketCompanyLocation.address2 ?? '',
				city: (isObject(ticketCompanyLocationPostalCodeInfo) && typeof ticketCompanyLocationPostalCodeInfo.city === 'string' ? ticketCompanyLocationPostalCodeInfo.city : ticketCompanyLocation.city) ?? '',
				postalCode: ticketCompanyLocation.postalCode ?? '',
				state: ticketCompanyLocation.state ?? '',
				note: ticketCompanyLocation.additionalAddressInformation ?? '',
				reference: ticketNumber,
				countryCode: isObject(ticketCompanyLocationCountry) && typeof ticketCompanyLocationCountry.countryCode === 'string' ? ticketCompanyLocationCountry.countryCode : '',
				countryName: isObject(ticketCompanyLocationCountry) && typeof ticketCompanyLocationCountry.name === 'string' ? ticketCompanyLocationCountry.name : '',
				email: isObject(ticketContact) && typeof ticketContact.emailAddress === 'string' ? ticketContact.emailAddress : (isObject(ticketCompany.userDefinedFields) && ticketCompany.userDefinedFields.hasOwnProperty('Email') && typeof ticketCompany.userDefinedFields['Email'] === 'string' ? ticketCompany.userDefinedFields['Email'] : ''),
				mobile: isObject(ticketContact) && typeof ticketContact.mobilePhone === 'string' ? ticketContact.mobilePhone : '',
			} : null

			return { initialFromAddress, companyAddress, contactAddress, locationAddress }
		})

	const ticketData = Promise.all([ticketId, addresses])

	return defer({ ticketData })
}

export function TicketShippingLabelPageContent({ ticketData }) {
	const [, setMessages] = useMessages()

	const [ticketId, { initialFromAddress, companyAddress, contactAddress, locationAddress }] = ticketData
	const [fromAddress, setFromAddress] = useState(initialFromAddress)
	const [manualAddress, setManualAddress] = useState(defaultAddress)

	const addresses = {
		company: companyAddress,
		contact: contactAddress,
		location: locationAddress,
		manual: manualAddress,
	}
	const addressOptions = Object.keys(addresses).filter(key => isObject(addresses[key])).map(key => {
		const formatted = formatAddress(addresses[key])
		return {
			key,
			value: key,
			text: addressTypeText[key] + (formatted ? ': ' + formatted : '')
		}
	})

	const [addressType, setAddressType] = useState('contact')
	const toAddress = addresses.hasOwnProperty(addressType) && isObject(addresses[addressType]) ? addresses[addressType] : defaultAddress

	const toAddressIncomplete = typeof toAddress.name !== 'string' || toAddress.name.trim().length === 0 ||
		typeof toAddress.address1 !== 'string' || toAddress.address1.trim().length === 0 ||
		typeof toAddress.postalCode !== 'string' || toAddress.postalCode.trim().length === 0 ||
		typeof toAddress.city !== 'string' || toAddress.city.trim().length === 0 || (
			(typeof toAddress.mobile !== 'string' || toAddress.mobile.trim().length === 0) &&
			(typeof toAddress.email !== 'string' || toAddress.email.trim().length === 0)
		)
	const fromAddressIncomplete = typeof fromAddress.name !== 'string' || fromAddress.name.trim().length === 0 ||
		typeof fromAddress.address1 !== 'string' || fromAddress.address1.trim().length === 0 ||
		typeof fromAddress.postalCode !== 'string' || fromAddress.postalCode.trim().length === 0 ||
		typeof fromAddress.city !== 'string' || fromAddress.city.trim().length === 0 || (
			(typeof fromAddress.mobile !== 'string' || fromAddress.mobile.trim().length === 0) &&
			(typeof fromAddress.email !== 'string' || fromAddress.email.trim().length === 0)
		)

	const [showProductChangedWarning, setShowProductChangedWarning] = useState(false)

	const [packages, setPackages] = useState(initialPackages)

	const [pickupPointId, setPickupPointId] = useState(null)
	const [productCode, setProductCode] = useState(initialProductCode)
	const [enabledServices, setEnabledServices] = useState(initialEnabledServices)
	const [deliveryOptionId, setDeliveryOptionId] = useState(initialDeliveryOptionId)

	const [estimatedCost, setEstimatedCost] = useState(null)
	const [price, setPrice] = useState('0')

	const [gettingEstimate, setGettingEstimate] = useState(false)
	const [creatingLabel, setCreatingLabel] = useState(false)

	const [labelUrl, setLabelUrl] = useState(null)
	const [trackingUrl, setTrackingUrl] = useState(null)

	const [cityLoadingFrom, setCityLoadingFrom] = useState(false)
	const [cityLoadingTo, setCityLoadingTo] = useState(false)

	const [loadingPickupPoints, setLoadingPickupPoints] = useState(initialLoadingPickupPoints)
	const [pickupPoints, setPickupPoints] = useState([])

	const routerNavigateFn = useNavigate()

	// Ensure a unique correlation ID is set for the default package on page load
	useEffect(() => {
		setPackages(packages => packages.map(pkg => pkg.correlationId === '' ? append(pkg, {
			correlationId: genCorrelationId('PACKAGE')
		}) : pkg))
	}, [])

	// Watch for changes in "from" address
	const [debouncedFromAddress, setDebouncedFromAddress] = useState(initialFromAddress)
	const debouncedSetFromAddressDebounced = useMemo(() => debounce(setDebouncedFromAddress, 500), [])
	useEffect(() => debouncedSetFromAddressDebounced(fromAddress), [fromAddress, debouncedSetFromAddressDebounced])
	const cachedFromAddress = useRef(initialFromAddress)
	useEffect(() => {
		if (
			getPostalCodeSupportedCountryCodes.includes(debouncedFromAddress.countryCode) &&
			(
				debouncedFromAddress.postalCode !== cachedFromAddress.current.postalCode ||
				debouncedFromAddress.countryCode !== cachedFromAddress.current.countryCode
			)
		) {
			// Load new city when the "from" address postal code changes
			setCityLoadingFrom(true)
			getPostalCode(debouncedFromAddress.postalCode, debouncedFromAddress.countryCode)
				.then(res => {
					handleRedirect(res, routerNavigateFn)
					setFromAddress(fromAddress => append(fromAddress, { city: res.data.city }))
					setCityLoadingFrom(false)
				})
				.catch(e => {
					handleRedirect(e.res, routerNavigateFn)
					setCityLoadingFrom(false)
				})
		}
		cachedFromAddress.current = {...debouncedFromAddress}
	}, [debouncedFromAddress, routerNavigateFn])

	// Watch for changes in "to" address
	const initialToAddress = contactAddress ?? defaultAddress
	const [debouncedToAddress, setDebouncedToAddress] = useState(initialToAddress)
	const debouncedSetToAddressDebounced = useMemo(() => debounce(setDebouncedToAddress, 500), [])
	useEffect(() => debouncedSetToAddressDebounced(toAddress), [toAddress, debouncedSetToAddressDebounced])
	const cachedToAddress = useRef(initialToAddress)
	const cachedEnabledServices = useRef(initialEnabledServices)
	useEffect(() => {
		if (
			getPostalCodeSupportedCountryCodes.includes(debouncedToAddress.countryCode) &&
			(
				debouncedToAddress.postalCode !== cachedToAddress.current.postalCode ||
				debouncedToAddress.countryCode !== cachedToAddress.current.countryCode
			)
		) {
			// Load new city when the "to" address postal code changes
			setCityLoadingTo(true)
			getPostalCode(debouncedToAddress.postalCode, debouncedToAddress.countryCode)
				.then(res => {
					handleRedirect(res, routerNavigateFn)
					if (isObject(res.data) && typeof res.data.city === 'string' && res.data.city.toLowerCase().trim() !== (debouncedToAddress.city ?? '').toLowerCase().trim()) {
						setAddressType('manual')
						setManualAddress(append(debouncedToAddress, {
							city: res.data.city
						}))
					}
					setCityLoadingTo(false)
				})
				.catch(e => {
					handleRedirect(e.res, routerNavigateFn)
					setCityLoadingTo(false)
				})
		}
		if (
			enabledServices.includes('VALGFRITT_POSTKONTOR') && (
				debouncedToAddress.postalCode !== cachedToAddress.current.postalCode ||
				debouncedToAddress.countryCode !== cachedToAddress.current.countryCode ||
				debouncedToAddress.address1 !== cachedToAddress.current.address1 ||
				enabledServices.includes('VALGFRITT_POSTKONTOR') !== cachedEnabledServices.current.includes('VALGFRITT_POSTKONTOR')
			)
		) {
			// Load new pick-up points when the "to" address changes or "VALGFRITT_POSTKONTOR" service is enabled
			setLoadingPickupPoints(true)
		}
		cachedToAddress.current = {...debouncedToAddress}
	}, [debouncedToAddress, enabledServices, routerNavigateFn])

	const loadPickupPoints = useCallback(() => {
		getPickupPoints(toAddress.postalCode, toAddress.countryCode, { street: toAddress.address1 })
			.then(res => {
				handleRedirect(res, routerNavigateFn)
				let newPickupPointId = null
				if (typeof res.data === 'object' && Array.isArray(res.data)) {
					setPickupPoints(res.data)
					if (res.data.length > 0) {
						newPickupPointId = res.data[0].id
					}
				} else {
					setPickupPoints([])
				}
				setPickupPointId(newPickupPointId)
				setLoadingPickupPoints(false)
			})
			.catch(e => {
				handleRedirect(e.res, routerNavigateFn)
				setPickupPoints([])
				setPickupPointId(null)
				setLoadingPickupPoints(false)
			})
	}, [toAddress, routerNavigateFn])

	const cachedLoadingPickupPoints = useRef(false)
	useEffect(() => {
		if (loadingPickupPoints && loadingPickupPoints !== cachedLoadingPickupPoints.current) {
			loadPickupPoints()
		}
		cachedLoadingPickupPoints.current = loadingPickupPoints
	}, [loadingPickupPoints, loadPickupPoints])

	const reset = () => {
		setEstimatedCost(null)
		setPrice('0')

		setGettingEstimate(false)
		setCreatingLabel(false)

		setLabelUrl(null)
		setTrackingUrl(null)
	}

	const debouncedHideProductChanged = useMemo(() => debounce(() => setShowProductChangedWarning(false), 5000), [])
	const showProductChanged = () => {
		setShowProductChangedWarning(true)
		debouncedHideProductChanged()
	}

	const handleAddPackage = (correlationId, afterCorrelationId) => {
		setPackages(packages => {
			let pkgIndex = correlationId ? packages.findIndex(pkg => pkg.correlationId === correlationId) : -1
			let afterPkgIndex = afterCorrelationId ? packages.findIndex(pkg => pkg.correlationId === afterCorrelationId) : -1
			let pkg = null
			if (pkgIndex !== -1) {
				pkg = {...packages[pkgIndex]}
			}
			if (pkg === null) {
				pkg = {...defaultPackage}
			}
			pkg.correlationId = genCorrelationId('PACKAGE')
			if (afterPkgIndex !== -1) {
				return packages.slice(0, afterPkgIndex + 1).concat(pkg, packages.slice(afterPkgIndex + 1))
			} else {
				return packages.concat(pkg)
			}
		})
	}

	const handleRemovePackage = (correlationId) => {
		setPackages(packages => packages.filter(pkg => pkg.correlationId !== correlationId))
	}

	const getConsignment = () => {
		let consignment = {
			shippingDateTime: new Date().toISOString(),
			correlationId: genCorrelationId('CONSIGNMENT'),
			product: {
				id: productCode,
				additionalServices: enabledServices.map(serviceId => {
					if (serviceId === 'EVARSLING') {
						if (toAddress.countryCode === 'NO' && (toAddress.mobile || toAddress.email)) {
							return {
								id: serviceId,
								email: toAddress.email,
								mobile: toAddress.mobile,
							}
						}
						return null
					} else if (serviceId === 'VALGFRITT_POSTKONTOR') {
						return null
					}
					return { id: serviceId }
				}).filter(v => v !== null),
			},
			parties: {
				sender: {
					name: fromAddress.name,
					addressLine: fromAddress.address1,
					addressLine2: fromAddress.address2,
					city: fromAddress.city,
					postalCode: fromAddress.postalCode,
					countryCode: fromAddress.countryCode,
					contact: {
						name: fromAddress.contactName,
						email: fromAddress.email,
						phoneNumber: fromAddress.mobile,
					},
					additionalAddressInfo: fromAddress.note,
					reference: fromAddress.reference,
				},
				recipient: {
					name: toAddress.name,
					addressLine: toAddress.address1,
					addressLine2: toAddress.address2,
					city: toAddress.city,
					postalCode: toAddress.postalCode,
					countryCode: toAddress.countryCode,
					contact: {
						name: toAddress.contactName,
						email: toAddress.email,
						phoneNumber: toAddress.mobile,
					},
					additionalAddressInfo: toAddress.note,
					reference: toAddress.reference,
				},
			},
			packages
		}
		if (enabledServices.includes('VALGFRITT_POSTKONTOR') && pickupPointId !== null) {
			consignment.parties.pickupPoint = {
				id: pickupPointId,
				countryCode: toAddress.countryCode,
			}
		}
		// if (productInfo[productCode].returnProductId !== null) {
		// 	consignment.returnProduct = { id: productInfo[productCode].returnProductId }
		// }
		if (deliveryOptionId !== null) {
			consignment.product.services = {
				deliveryOption: deliveryOptionId,
			}
		}
		return consignment
	}

	const handleGetEstimate = () => {
		const consignment = getConsignment()
		setGettingEstimate(true)
		estimateConsignment({consignment})
			.then(res => {
				handleRedirect(res, routerNavigateFn)
				if (res.data && typeof res.data.products === 'object' && Array.isArray(res.data.products) && res.data.products.length > 0 && res.data.products[0].price !== null) {
					const newEstimatedCost = res.data.products[0].price.netPrice.priceWithAdditionalServices.amountWithoutVAT
					// Got an estimated shipping cost
					setEstimatedCost(newEstimatedCost)
					setPrice(newEstimatedCost.toString())
					setGettingEstimate(false)
				} else {
					// Unable to get an estimate, most likely package volume is not defined
					setEstimatedCost(false)
					setPrice('0')
					setGettingEstimate(false)
				}
			})
			.catch(e => {
				handleRedirect(e.res, routerNavigateFn)
				console.error('Error getting consignment estimate:', e)
				setEstimatedCost(null)
				setPrice('0')
				setGettingEstimate(false)
				setMessages(messages => messages.concat({
					key: 'consignment_estimate_error_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'error',
					icon: 'exclamation circle',
					title: 'En feil oppstod',
					content: 'Kunne ikke estimere pris for bestilling: ' + e.message
				}))
			})
	}

	const handleCreateConsignment = () => {
		const consignment = getConsignment()
		setCreatingLabel(true)
		createConsignment({consignment})
			.then(res => {
				handleRedirect(res, routerNavigateFn)
				if (res.data && res.data.confirmation) {
					// Found consignment confirmation
					const {
						consignmentNumber,
						links: { labels: labelUrl, tracking: trackingUrl }
					} = res.data.confirmation

					const proxyLabelUrl = labelUrl.replace('https://api.bring.com', window.location.origin + '/api/mybring');

					// Update state with label PDF URL and tracking URL
					setLabelUrl(labelUrl)
					setTrackingUrl(trackingUrl)

					// Add ticket note containing tracking URL
					const ticketNoteCreatePromise = createTicketNote({
						ticketID: ticketId,
						title: 'Fraktlapp og sporings-link',
						description: 'Sporing: ' + trackingUrl,
						noteType: TICKET_NOTE_TYPE_TASK_NOTES,
						publish: TICKET_NOTE_PUBLISH_INTERNAL,
					})
						.then(res => {
							handleRedirect(res, routerNavigateFn)
							return res.data
						})
						.catch(e => {
							console.error('Error creating TicketNote:', e)
							setMessages(messages => messages.concat({
								key: 'consignment_create_ticketnote_error_' + Math.round(new Date() / 1000).toString(),
								dismissable: true,
								type: 'error',
								icon: 'exclamation circle',
								title: 'En feil oppstod',
								content: 'Kunne ikke opprette saksnotat: ' + e.message
							}))
							handleRedirect(e.res, routerNavigateFn)
						})

					// Download label PDF as base64
					const labelPDFRequestPromise = request(proxyLabelUrl, null, { headers: { 'Accept': 'application/pdf' } })
						.then(res => Promise.all([Promise.resolve(res), res.blob()]))
						.then(([res, blob]) => {
							handleRedirect(res, routerNavigateFn)
							if (res.headers.get('Content-Type').split(';', 2)[0].trim().toLowerCase() !== 'application/pdf') {
								return Promise.all([Promise.resolve(res), Promise.resolve(null)]);
							}
							const base64DataPromise = new Promise((resolve, _) => {
								const reader = new FileReader()
								reader.onloadend = () => resolve(reader.result.substring(reader.result.indexOf(',') + 1))
								reader.readAsDataURL(blob)
							});
							return Promise.all([Promise.resolve(res), base64DataPromise])
						})
						.catch(e => {
							handleRedirect(e.res, routerNavigateFn)
							console.error('Error downloading label PDF:', e)
							setMessages(messages => messages.concat({
								key: 'consignment_create_downloadpdf_error_' + Math.round(new Date() / 1000).toString(),
								dismissable: true,
								type: 'error',
								icon: 'exclamation circle',
								title: 'En feil oppstod',
								content: 'Kunne ikke laste ned fraktlapp-PDF: ' + e.message
							}))
						})

					let priceFloat = parseFloat(price)
					if (isNaN(priceFloat)) priceFloat = 0.0
					priceFloat = Math.max(0.0, priceFloat)

					let ticketChargeCreatePromise = Promise.resolve(null)
					if ((estimatedCost !== null && estimatedCost !== false) || priceFloat > 0) {
						let estimatedCostFloat = parseFloat(estimatedCost)
						if (isNaN(estimatedCostFloat)) estimatedCostFloat = 0.0
						estimatedCostFloat = Math.max(0.0, estimatedCostFloat)

						// Add charge to ticket if we have an estimated cost or a price
						ticketChargeCreatePromise = createTicketCharge({
							ticketID: ticketId,
							billingCodeID: BILLING_CODE_ID_MATERIAL_SHIPPING, // Frakt
							name: 'Frakt',
							datePurchased: new Date().toISOString(),
							isBillableToCompany: true,
							chargeType: TICKET_CHARGE_TYPE_OPERATIONAL,
							status: TICKET_CHARGE_STATUS_READY_TO_DELIVER_OR_SHIP,
							unitQuantity: 1,
							unitCost: estimatedCostFloat,
							unitPrice: Math.max(0.0, priceFloat),
						})
							.then(res => {
								handleRedirect(res, routerNavigateFn)
								return res.data
							})
							.catch(e => {
								console.error('Error creating TicketCharge:', e)
								setMessages(messages => messages.concat({
									key: 'consignment_create_ticketcharge_error_' + Math.round(new Date() / 1000).toString(),
									dismissable: true,
									type: 'error',
									icon: 'exclamation circle',
									title: 'En feil oppstod',
									content: 'Kunne ikke opprette sakskostnad: ' + e.message
								}))
								handleRedirect(e.res, routerNavigateFn)
							})
					}

					Promise.allSettled([ticketNoteCreatePromise, labelPDFRequestPromise, ticketChargeCreatePromise])
						.then(([ticketNoteCreatePromise, labelPDFRequestPromise, ticketChargeCreatePromise]) => {
							let ticketNoteAttachmentPromise = Promise.resolve(null)
							if (ticketNoteCreatePromise.status === 'fulfilled' && labelPDFRequestPromise.status === 'fulfilled') {
								// Re-upload label PDF as attachment to the created ticket note
								const [ /* labelResponse */, labelPDFBase64 ] = labelPDFRequestPromise.value
								if (labelPDFBase64 !== null) {
									ticketNoteAttachmentPromise = createTicketNoteAttachment({
										ticketID: ticketId,
										ticketNoteID: ticketNoteCreatePromise.value.itemId,
										title: 'Fraktlapp',
										fullPath: 'Fraktlapp-' + consignmentNumber + '.pdf',
										data: labelPDFBase64,
										attachmentType: 'FILE_ATTACHMENT',
										publish: TICKET_NOTE_ATTACHMENT_PUBLISH_INTERNAL,
									})
										.then(res => {
											handleRedirect(res, routerNavigateFn)
											return res.data
										})
										.catch(e => {
											console.error('Error creating TicketAttachment:', e)
											setMessages(messages => messages.concat({
												key: 'consignment_create_ticketattachment_error_' + Math.round(new Date() / 1000).toString(),
												dismissable: true,
												type: 'error',
												icon: 'exclamation circle',
												title: 'En feil oppstod',
												content: 'Kunne ikke opprette saksvedlegg: ' + e.message
											}))
											handleRedirect(e.res, routerNavigateFn)
										})
								}
							}

							let ticketChargeUpdatePromise = Promise.resolve(null)
							if (ticketChargeCreatePromise.status === 'fulfilled') {
								ticketChargeUpdatePromise = updateTicketCharge(ticketChargeCreatePromise.value.itemId, {
									status: TICKET_CHARGE_STATUS_DELIVERED_OR_SHIPPED_FULL,
								})
							}

							return Promise.all([ticketNoteAttachmentPromise, ticketChargeUpdatePromise])
						})
						.finally(() => setCreatingLabel(false))
				} else if (res.data && typeof res.data.errors === 'object' && Array.isArray(res.data.errors) && res.data.errors.length > 0) {
					// Got an error message
					const { errors } = res.data
					const lastError = errors[errors.length - 1]
					const commonLang = mode(lastError.messages.map(msg => msg.lang), 'no')
					const commonLangMessages = lastError.messages.filter(msg => msg.lang === commonLang)
					const firstMessage = commonLangMessages.length > 0 ? commonLangMessages[0].message : 'Ukjent feil'

					console.error('Could not create consignment:', firstMessage)
					setCreatingLabel(false)
					setMessages(messages => messages.concat({
						key: 'consignment_create_error_' + Math.round(new Date() / 1000).toString(),
						dismissable: true,
						type: 'error',
						icon: 'exclamation circle',
						title: 'En feil oppstod',
						content: 'Kunne ikke opprette sending: ' + firstMessage
					}))
				} else {
					// No consignment confirmations found
					console.error('Could not find any consignment confirmations:', res.data)
					setCreatingLabel(false)
					setMessages(messages => messages.concat({
						key: 'consignment_create_error_' + Math.round(new Date() / 1000).toString(),
						dismissable: true,
						type: 'error',
						icon: 'exclamation circle',
						title: 'En feil oppstod',
						content: 'Kunne ikke opprette sending: Fant ingen sendinger i svar fra MyBring'
					}))
				}
			})
			.catch(e => {
				console.error('Error creating consignment:', e)
				setEstimatedCost(null)
				setCreatingLabel(false)
				setMessages(messages => messages.concat({
					key: 'consignment_creation_error_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'error',
					icon: 'exclamation circle',
					title: 'En feil oppstod',
					content: 'Kunne ikke opprette forsendelse: ' + e.message
				}))
				handleRedirect(e.res, routerNavigateFn)
			})
	}

	// const postalCodeInfoKey = toAddress.countryCode.toUpperCase() + '-' + toAddress.postalCode

	if (creatingLabel) {
		return <Segment vertical padded="very">
			<Container>
				<Segment placeholder basic>
					<Loader active size="big">Oppretter fraktlapp...</Loader>
				</Segment>
			</Container>
		</Segment>
	} else if (labelUrl || trackingUrl) {
		return <Segment vertical padded="very">
			<Container>
				<Header>Fraktlappen er opprettet</Header>
				<p>Det skal ha blitt lagt inn et notat i saken med link til sporing og fraktlapp som PDF-vedlegg.</p>

				<p>
					<b>Fraktlapp:</b> <Link href={labelUrl} target="_blank" rel="noopener nofollow noreferrer">{labelUrl}</Link><br/>
					<b>Sporings-link:</b> <Link href={trackingUrl} target="_blank" rel="noopener nofollow noreferrer">{trackingUrl}</Link>
				</p>
				<p>&nbsp;</p>

				<Button onClick={preventDefault(() => reset())}>
					Lag en ny fraktlapp <Icon name="undo" className="right" />
				</Button>
			</Container>
		</Segment>
	}
	return <Form onSubmit={preventDefault(() => handleCreateConsignment())} className="kit-dash-segment-container">
		<Segment vertical padded="very">
			<Container>
				<Header>Avsender</Header>
				<AddressInput
					compact
					address={fromAddress}
					onChange={(newFromAddress, _changedField) => setFromAddress(newFromAddress)}
					cityLoading={cityLoadingFrom}
				/>

				<Header>Mottaker</Header>
				<Form.Field>
					<label>Mal</label>
					<Select
						options={addressOptions}
						value={addressType}
						onChange={(_, data) => {
							const newProductCode = data.value === 'contact' ? '5600' : '5000'
							const productCodeChanged = productCode !== newProductCode
							setProductCode(newProductCode)
							if (productCodeChanged) showProductChanged()
							setEnabledServices(enabledServices => productCodeChanged ? productInfo[productCode].defaultServices : enabledServices)
							setAddressType(data.value)
							setManualAddress({...defaultAddress})
						}}
					/>
				</Form.Field>
				<p></p>
				<AddressInput
					compact={addressType !== 'manual'}
					address={toAddress}
					onChange={(newManualAddress, _changedField) => {
						setAddressType('manual')
						setManualAddress(newManualAddress)
					}}
					cityLoading={cityLoadingTo}
				/>
			</Container>
		</Segment>

		<Segment vertical padded="very">
			<Container>
				<Header>Vekt og volum</Header>
				{packages.map((pkg, i) => <React.Fragment key={pkg.correlationId}>
					<div><Form.Field label={'Kolli ' + (i+1)} /></div>
					<Form.Group>
						<Form.Field width={3}>
							<label><small>Vekt</small></label>
							<Input
								type="number"
								step="0.1"
								min="0.1"
								max={productInfo[productCode].maxWeight}
								labelPosition="right"
								label="kg"
								value={pkg.weightInKg}
								onChange={e => {
									const { value } = e.target
									const weightInKg = value.length === 0 ? '0.1' : value.replace(',', '.')
									setPackages(packages => packages.map(v => v.correlationId === pkg.correlationId ? append(v, { weightInKg }) : v))
								}}
							/>
						</Form.Field>
						<Form.Field width={1}></Form.Field>
						<Form.Field width={3}>
							<label><small>Lengde</small></label>
							<Input
								type="number"
								step="0.1"
								min="0"
								labelPosition="right"
								label="cm"
								value={pkg.dimensions.lengthInCm}
								onChange={e => {
									const { value } = e.target
									const lengthInCm = value.length === 0 ? '0' : value.replace(',', '.')
									setPackages(packages => packages.map(v => v.correlationId === pkg.correlationId ? append(v, {
										dimensions: append(v.dimensions, { lengthInCm })
									}) : v))
								}}
							/>
						</Form.Field>
						<Form.Field width={3}>
							<label><small>Bredde</small></label>
							<Input
								type="number"
								step="0.1"
								min="0"
								labelPosition="right"
								label="cm"
								value={pkg.dimensions.widthInCm}
								onChange={e => {
									const { value } = e.target
									const widthInCm = value.length === 0 ? '0' : value.replace(',', '.')
									setPackages(packages => packages.map(v => v.correlationId === pkg.correlationId ? append(v, {
										dimensions: append(v.dimensions, { widthInCm })
									}) : v))
								}}
							/>
						</Form.Field>
						<Form.Field width={3}>
							<label><small>Høyde</small></label>
							<Input
								type="number"
								step="0.1"
								min="0"
								labelPosition="right"
								label="cm"
								value={pkg.dimensions.heightInCm}
								onChange={e => {
									const { value } = e.target
									const heightInCm = value.length === 0 ? '0' : value.replace(',', '.')
									setPackages(packages => packages.map(v => v.correlationId === pkg.correlationId ? append(v, {
										dimensions: append(v.dimensions, { heightInCm })
									}) : v))
								}}
							/>
						</Form.Field>
						<Form.Field width={3} className="text-ha-right">
							<label>&nbsp;</label>
							{packages.length > 1 ? <Popup
								inverted
								content="Fjern kolli"
								trigger={<Button negative icon="trash" onClick={preventDefault(() => handleRemovePackage(pkg.correlationId))} />}
							/> : null}
							<Popup
								inverted
								content="Legg til kolli"
								trigger={<Button icon="plus" onClick={preventDefault(() => handleAddPackage(null, pkg.correlationId))} />}
							/>
							<Popup
								inverted
								content="Dupliser kolli"
								trigger={<Button icon="copy" onClick={preventDefault(() => handleAddPackage(pkg.correlationId, pkg.correlationId))} />}
							/>
						</Form.Field>
					</Form.Group>
				</React.Fragment>)}
			</Container>
		</Segment>

		<Segment vertical padded="very">
			<Container>
				<Header>Bestilling</Header>
				<Form.Field>
					<label>Produkt</label>
					<Select
						options={[
							{key: '5000', value: '5000', text: <>Pakke til bedrift <span className="text-secondary">(i Norge)</span></>},
							{key: '4850', value: '4850', text: <>Ekspress neste dag <span className="text-secondary">(til bedrift i Norge)</span></>},
							{key: '3584', value: '3584', text: <>Pakke i postkassen <span className="text-secondary">(til privatperson i Norge)</span></>},
							{key: '0345', value: '0345', text: <>Pakke i postkassen <span className="text-secondary">(til privatperson i Danmark/Finland/Sverige)</span></>},
							{key: '5800', value: '5800', text: <>Pakke til hentested <span className="text-secondary">(til privatperson i Norge)</span></>},
							{key: '5600', value: '5600', text: <>Pakke levert hjem <span className="text-secondary">(til privatperson i Norge)</span></>},
						]}
						value={productCode}
						onChange={(_, data) => {
							setProductCode(data.value)
							setEnabledServices(productInfo[data.value].defaultServices)
						}}
					/>
					{showProductChangedWarning ? <Label pointing="above" color="orange">
						<Icon name="exclamation triangle" />&ensp;Produkt ble endret automatisk!
					</Label> : null}
				</Form.Field>

				{productInfo[productCode].deliveryOptions.length > 0 ? <div><Form.Field label="Utleveringsforsøk" /></div> : null}
				{productInfo[productCode].deliveryOptions.map(optionId => <Form.Checkbox
					radio
					name="deliveryOptionId"
					key={optionId}
					value={optionId}
					label={deliveryOptionNames.no[optionId]}
					checked={deliveryOptionId === optionId}
					onChange={(_, data) => setDeliveryOptionId(data.value)}
				/>)}
				{productInfo[productCode].services.length > 0 ? <div><Form.Field label="Tilleggstjenester" /></div> : null}
				{productInfo[productCode].services.map(serviceId => <Form.Checkbox
					key={serviceId}
					label={serviceNames.no[serviceId]}
					checked={enabledServices.includes(serviceId)}
					onChange={(_, data) => {
						const { checked } = data
						setEnabledServices(enabledServices => enabledServices.filter(v => v !== serviceId).concat(checked ? serviceId : []))
					}}
				/>)}
				{enabledServices.includes('VALGFRITT_POSTKONTOR') ? <Form.Field>
					<label>Hentested</label>
					<Select
						options={pickupPoints.map(pickupPoint => ({
							key: pickupPoint.id,
							value: pickupPoint.id,
							text: pickupPoint.name,
						}))}
						disabled={loadingPickupPoints || pickupPoints.length === 0}
						loading={loadingPickupPoints}
						value={pickupPointId}
						onChange={(_, data) => setPickupPointId(data.value)}
					/>
				</Form.Field> : null}
				<Form.Field>
					<label>Pris</label>
					<Input
						type="number"
						step="0.01"
						min="0"
						labelPosition="left"
						label="NOK"
						value={price}
						onChange={(e, data) => setPrice(data.value.replace(',', '.'))}
					/>
					{estimatedCost !== null ? <p><b>Estimert pris:</b> {(estimatedCost !== false ? formatNumber(parseFloat(estimatedCost), { precision: 2 }) + ' kr' : 'Ukjent (sjekk at du har skrevet inn både vekt og lengde/bredde/høyde)')}</p> : null}
					<p className="text-secondary">Prisen over blir oppdatert automatisk når du trykker "Få estimert pris". Det blir lagt inn en Charge på saken med estimert pris (vår kostnad) og eventuelt overstyrt/endret pris (fakturerbar kostnad til kunden).</p>
				</Form.Field>

				<Form.Group>
					<Form.Button
						type="button"
						loading={gettingEstimate}
						disabled={fromAddressIncomplete || toAddressIncomplete || gettingEstimate}
						onClick={preventDefault(() => handleGetEstimate())}
					>
						Estimer fraktpris <Icon name="calculator" className="right" />
					</Form.Button>
					<Form.Button
						type="submit"
						loading={creatingLabel || gettingEstimate}
						disabled={fromAddressIncomplete || toAddressIncomplete || creatingLabel || gettingEstimate}
					>
						Opprett fraktlapp <Icon name="shipping fast" className="right" />
					</Form.Button>
				</Form.Group>
			</Container>
		</Segment>
	</Form>
}

export default function TicketShippingLabelPage() {
	const { ticketData } = useLoaderData()
	return <>
		<Segment vertical padded="very">
			<Container>
				<Header size="huge">
					Opprett fraktlapp
					<Header.Subheader>Opprett en Bring-fraktlapp, med forhåndsutfylt adresse fra saken</Header.Subheader>
				</Header>
			</Container>
		</Segment>

		<React.Suspense fallback={
			<>
				<Segment vertical padded="very" secondary>
					<Segment basic padded textAlign="center">
						<Loader active indeterminate content="Henter adresser fra saken..." />
					</Segment>
				</Segment>
			</>
		}>
			<Await resolve={ticketData}>
				{ticketData => <TicketShippingLabelPageContent ticketData={ticketData} />}
			</Await>
		</React.Suspense>
	</>
}