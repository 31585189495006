import React from 'react'
import { Card, Icon, Label, Popup } from 'semantic-ui-react'
import { isObject, omit } from '../helpers'
import Link from './link'

export default function ResourceCard(props) {
	const { icon, title, titleLink: link, children } = props

	return <Card {...omit(['icon', 'title', 'titleLink', 'children'], props)}>
		<Card.Content className="kit-resource-card-label-content">
			<Label attached="top" image>
				<div className="content">
					{icon ? <><Icon name={icon} />&ensp;</> : null}{title}
				</div>
				{isObject(link) ? <Label.Detail>
					{link.title ? <Popup
						inverted
						content={link.title}
						trigger={<Link href={link.href} target={link.external ? '_blank' : '_self'} rel={link.external ? 'noopener nofollow noreferrer' : ''}>{link.name}</Link>}
					/> : <Link href={link.href} target={link.external ? '_blank' : '_self'} rel={link.external ? 'noopener nofollow noreferrer' : ''}>{link.external ? <><Icon name="external" />&ensp;</> : null}{link.name}</Link>}
				</Label.Detail> : null}
			</Label>
		</Card.Content>
		{children}
	</Card>
}

ResourceCard.Content = Card.Content
ResourceCard.Description = Card.Description
ResourceCard.Group = Card.Group
ResourceCard.Header = Card.Header
ResourceCard.Meta = Card.Meta