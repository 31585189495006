import { Response, PaginatedResponse } from '..'
import { buildQuery } from '../../helpers'

// GET /api/autotask/services
// POST /api/autotask/services/search
export async function getServices(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/autotask/services' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/autotask/services/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/autotask/services
export async function createService(data, requestOptions = {}) {
	return Response.request('/api/autotask/services', data, requestOptions)
}

// GET /api/autotask/services/:id
export async function getService(id, requestOptions = {}) {
	return Response.request('/api/autotask/services/' + encodeURIComponent(id), null, requestOptions)
}

// PUT /api/autotask/services/:id
export async function updateService(id, data, requestOptions = {}) {
	return Response.request('/api/autotask/services/' + encodeURIComponent(id), data, Object.assign({ method: 'PUT' }, requestOptions))
}

// DELETE /api/autotask/services/:id
export async function deleteService(id, requestOptions = {}) {
	return Response.request('/api/autotask/services/' + encodeURIComponent(id), null, Object.assign({ method: 'DELETE' }, requestOptions))
}

export const PERIOD_TYPE_MONTHLY = 2; // Monthly
export const PERIOD_TYPE_QUARTERLY = 3; // Quarterly
export const PERIOD_TYPE_SEMIANNUAL = 4; // Semi-Annual
export const PERIOD_TYPE_YEARLY = 5; // Yearly

export const ID_KIT_MANAGED_CLIENT_BASIC = 14; // KIT Managed Client Basic
export const ID_KIT_MANAGED_CLIENT_STANDARD = 15; // KIT Managed Client Standard
export const ID_KIT_MANAGED_CLIENT_PREMIUM = 16; // KIT Managed Client Premium
export const ID_MS365_BUSINESS_STANDARD = 17; // Microsoft 365 Business Standard Managed
export const ID_MS365_BUSINESS_PREMIUM = 18; // Microsoft 365 Business Premium Managed
export const ID_MS365_BUSINESS_BASIC = 19; // Microsoft 365 Business Basic Managed
export const ID_MS365_E3_OFFICE = 20; // Microsoft Office 365 E3 Managed
export const ID_MS365_EXCHANGE_ONLINE_KIOSK = 21; // Microsoft 365 Exchange Online Kiosk Managed
export const ID_MS365_EXCHANGE_ONLINE_PLAN1 = 22; // Microsoft 365 Exchange Online (Plan 1) Managed
export const ID_MS365_PROJECT_PLAN1 = 23; // Microsoft 365 Project Plan 1 Managed
export const ID_MS365_PROJECT_PLAN3 = 24; // Microsoft 365 Project Plan 3 Managed
export const ID_MS365_VISIO_PLAN1 = 25; // Microsoft 365 Visio Plan 1 Managed
export const ID_MS365_VISIO_PLAN2 = 26; // Microsoft 365 Visio Plan 2 Managed
export const ID_KIT_MANAGED_NETWORK = 27; // KIT Managed Network
export const ID_KIT_HOSTING_1GB = 28; // KIT Hosting 1GB
export const ID_KIT_HOSTING_2GB = 29; // KIT Hosting 2GB
export const ID_KIT_HOSTING_5GB = 30; // KIT Hosting 5GB
export const ID_KIT_HOSTING_10GB = 31; // KIT Hosting 10GB
export const ID_BITDEFENDER_EDR = 32; // Bitdefender EDR
export const ID_KIT_MANAGED_BACKUP_365_MONTHLY_YEARLYBINDING = 33; // KIT Managed Backup 365 (månedlig, årlig binding)
export const ID_KIT_MANAGED_BACKUP_365_UNLIMITED_MONTHLY_YEARLYBINDING = 34; // KIT Managed Backup 365 Unlimited (månedlig, årlig binding)
export const ID_MS365_POWER_BI_PRO = 35; // Microsoft 365 Power BI Pro Managed
export const ID_KIT_MANAGED_CLIENT_EXTRA_DEVICE = 36; // KIT Managed Client Extra device
export const ID_KIT_MAIL_BASIC = 37; // KIT Mail Basic
export const ID_KIT_MANAGED_SERVER = 38; // KIT Managed Server
export const ID_GOOGLE_ADS = 39; // Google Annonsering
export const ID_KIT_MANAGED_BACKUP_1TB = 41; // KIT Managed Backup 1TB
export const ID_KIT_MANAGED_BACKUP_5TB = 42; // KIT Managed Backup 5TB
export const ID_KIT_MANAGED_BACKUP_2TB = 43; // KIT Managed Backup 2TB
export const ID_KIT_MANAGED_ANTIVIRUS = 44; // KIT Managed Antivirus
export const ID_MS365_E3 = 45; // Microsoft 365 E3 Managed
export const ID_KIT_MANAGED_INTERNET_20MDOWN_20MUP = 46; // KIT Managed Internet 20/20 Mbps
export const ID_MS365_APPS_FOR_BUSINESS = 47; // Microsoft 365 Apps for Business Managed
export const ID_MS365_APPS_FOR_ENTERPRISE = 48; // Microsoft 365 Apps for Enterprise Managed
export const ID_KITCLOUD_SYNC = 49; // KitCloud Sync
export const ID_KIT_MANAGED_BACKUP_50GB = 50; // KIT Managed Backup 50GB
export const ID_MS365_INTUNE_DEVICE = 51; // Microsoft Intune Device
export const ID_SLA = 52; // Serviceavtale
export const ID_KIT_MANAGED_BACKUP_25TB = 53; // KIT Managed Backup 25TB
export const ID_ADOBE_ACROBAT_STANDARD_DC_FOR_TEAMS_COMMERCIAL_MULTILANGUAGE = 54; // Adobe Acrobat Standard DC for teams Commercial Multilanguage
export const ID_MS365_BUSINESS_STANDARD_MONTHLY_YEARLYBINDING = 55; // Microsoft 365 Business Standard Managed (månedlig, årlig binding)
export const ID_MS365_BUSINESS_STANDARD_YEARLY_YEARLYBINDING = 56; // Microsoft 365 Business Standard Managed (årlig, årlig binding)
export const ID_MS365_BUSINESS_STANDARD_MONTHLY_NOBINDING = 57; // Microsoft 365 Business Standard Managed (månedlig, ingen binding)
export const ID_MS365_EXCHANGE_ONLINE_KIOSK_MONTHLY_YEARLYBINDING = 58; // Microsoft 365 Exchange Online Kiosk Managed (månedlig, årlig binding)
export const ID_MS365_EXCHANGE_ONLINE_KIOSK_YEARLY_YEARLYBINDING = 59; // Microsoft 365 Exchange Online Kiosk Managed (årlig, årlig binding)
export const ID_MS365_EXCHANGE_ONLINE_KIOSK_MONTHLY_NOBINDING = 60; // Microsoft 365 Exchange Online Kiosk Managed (månedlig, ingen binding)
export const ID_MS365_EXCHANGE_ONLINE_PLAN1_MONTHLY_YEARLYBINDING = 61; // Microsoft 365 Exchange Online (Plan 1) Managed (månedlig, årlig binding)
export const ID_MS365_EXCHANGE_ONLINE_PLAN1_YEARLY_YEARLYBINDING = 62; // Microsoft 365 Exchange Online (Plan 1) Managed (årlig, årlig binding)
export const ID_MS365_EXCHANGE_ONLINE_PLAN1_MONTHLY_NOBINDING = 63; // Microsoft 365 Exchange Online (Plan 1) Managed (månedlig, ingen binding)
export const ID_MS365_APPS_FOR_BUSINESS_MONTHLY_YEARLYBINDING = 64; // Microsoft 365 Apps for Business Managed (månedlig, årlig binding)
export const ID_MS365_APPS_FOR_BUSINESS_YEARLY_YEARLYBINDING = 65; // Microsoft 365 Apps for Business Managed (årlig, årlig binding)
export const ID_MS365_APPS_FOR_BUSINESS_MONTHLY_NOBINDING = 66; // Microsoft 365 Apps for Business Managed (månedlig, ingen binding)
export const ID_MS365_BUSINESS_BASIC_MONTHLY_YEARLYBINDING = 67; // Microsoft 365 Business Basic Managed (månedlig, årlig binding)
export const ID_MS365_BUSINESS_BASIC_YEARLY_YEARLYBINDING = 68; // Microsoft 365 Business Basic Managed (årlig, årlig binding)
export const ID_MS365_BUSINESS_BASIC_MONTHLY_NOBINDING = 69; // Microsoft 365 Business Basic Managed (månedlig, ingen binding)
export const ID_MS365_BUSINESS_PREMIUM_MONTHLY_YEARLYBINDING = 70; // Microsoft 365 Business Premium Managed (månedlig, årlig binding)
export const ID_MS365_BUSINESS_PREMIUM_YEARLY_YEARLYBINDING = 71; // Microsoft 365 Business Premium Managed (årlig, årlig binding)
export const ID_MS365_BUSINESS_PREMIUM_MONTHLY_NOBINDING = 72; // Microsoft 365 Business Premium Managed (månedlig, ingen binding)
export const ID_MS365_E3_MONTHLY_YEARLYBINDING = 73; // Microsoft 365 E3 Managed (månedlig, årlig binding)
export const ID_MS365_E3_YEARLY_YEARLYBINDING = 74; // Microsoft 365 E3 Managed (årlig, årlig binding)
export const ID_MS365_E3_MONTHLY_NOBINDING = 75; // Microsoft 365 E3 Managed (månedlig, ingen binding)
export const ID_MS365_VISIO_PLAN1_MONTHLY_YEARLYBINDING = 76; // Microsoft 365 Visio Plan 1 Managed (månedlig, årlig binding)
export const ID_MS365_VISIO_PLAN1_YEARLY_YEARLYBINDING = 77; // Microsoft 365 Visio Plan 1 Managed (årlig, årlig binding)
export const ID_MS365_VISIO_PLAN1_MONTHLY_NOBINDING = 78; // Microsoft 365 Visio Plan 1 Managed (månedlig, ingen binding)
export const ID_MS365_VISIO_PLAN2_MONTHLY_YEARLYBINDING = 79; // Microsoft 365 Visio Plan 2 Managed (månedlig, årlig binding)
export const ID_MS365_VISIO_PLAN2_YEARLY_YEARLYBINDING = 80; // Microsoft 365 Visio Plan 2 Managed (årlig, årlig binding)
export const ID_MS365_VISIO_PLAN2_MONTHLY_NOBINDING = 81; // Microsoft 365 Visio Plan 2 Managed (månedlig, ingen binding)
export const ID_MS365_PROJECT_PLAN1_MONTHLY_YEARLYBINDING = 82; // Microsoft 365 Project Plan 1 Managed (månedlig, årlig binding)
export const ID_MS365_PROJECT_PLAN1_YEARLY_YEARLYBINDING = 83; // Microsoft 365 Project Plan 1 Managed (årlig, årlig binding)
export const ID_MS365_PROJECT_PLAN1_MONTHLY_NOBINDING = 84; // Microsoft 365 Project Plan 1 Managed (månedlig, ingen binding)
export const ID_MS365_PROJECT_PLAN3_MONTHLY_YEARLYBINDING = 85; // Microsoft 365 Project Plan 3 Managed (månedlig, årlig binding)
export const ID_MS365_PROJECT_PLAN3_YEARLY_YEARLYBINDING = 86; // Microsoft 365 Project Plan 3 Managed (årlig, årlig binding)
export const ID_MS365_PROJECT_PLAN3_MONTHLY_NOBINDING = 87; // Microsoft 365 Project Plan 3 Managed (månedlig, ingen binding)
export const ID_MS365_POWER_BI_PRO_MONTHLY_YEARLYBINDING = 88; // Microsoft 365 Power BI Pro Managed (månedlig, årlig binding)
export const ID_MS365_POWER_BI_PRO_YEARLY_YEARLYBINDING = 89; // Microsoft 365 Power BI Pro Managed (årlig, årlig binding)
export const ID_MS365_POWER_BI_PRO_MONTHLY_NOBINDING = 90; // Microsoft 365 Power BI Pro Managed (månedlig, ingen binding)
export const ID_MS365_INTUNE_DEVICE_MONTHLY_YEARLYBINDING = 91; // Microsoft 365 Intune Device (månedlig, årlig binding)
export const ID_MS365_INTUNE_DEVICE_YEARLY_YEARLYBINDING = 92; // Microsoft 365 Intune Device (årlig, årlig binding)
export const ID_MS365_INTUNE_DEVICE_MONTHLY_NOBINDING = 93; // Microsoft 365 Intune Device (månedlig, ingen binding)
export const ID_MS365_APPS_FOR_ENTERPRISE_MONTHLY_YEARLYBINDING = 94; // Microsoft 365 Apps for Enterprise Managed (månedlig, årlig binding)
export const ID_MS365_APPS_FOR_ENTERPRISE_YEARLY_YEARLYBINDING = 95; // Microsoft 365 Apps for Enterprise Managed (årlig, årlig binding)
export const ID_MS365_APPS_FOR_ENTERPRISE_MONTHLY_NOBINDING = 96; // Microsoft 365 Apps for Enterprise Managed (månedlig, ingen binding)
export const ID_MS365_E3_OFFICE_MONTHLY_YEARLYBINDING = 97; // Microsoft Office 365 E3 Managed (månedlig, årlig binding)
export const ID_MS365_E3_OFFICE_YEARLY_YEARLYBINDING = 98; // Microsoft Office 365 E3 Managed (årlig, årlig binding)
export const ID_MS365_E3_OFFICE_MONTHLY_NOBINDING = 99; // Microsoft Office 365 E3 Managed (månedlig, ingen binding)
export const ID_MS365_AZURE_AD_PREMIUM_P1_MONTHLY_YEARLYBINDING = 100; // Microsoft 365 Azure Active Directory Premium P1 (månedlig, årlig binding)
export const ID_MS365_AZURE_AD_PREMIUM_P1_YEARLY_YEARLYBINDING = 101; // Microsoft 365 Azure Active Directory Premium P1 (årlig, årlig binding)
export const ID_MS365_AZURE_AD_PREMIUM_P1_MONTHLY_NOBINDING = 102; // Microsoft 365 Azure Active Directory Premium P1 (månedlig, ingen binding)
export const ID_MS365_INTUNE_MONTHLY_YEARLYBINDING = 103; // Microsoft 365 Intune (månedlig, årlig binding)
export const ID_MS365_INTUNE_YEARLY_YEARLYBINDING = 104; // Microsoft 365 Intune (årlig, årlig binding)
export const ID_MS365_INTUNE_MONTHLY_NOBINDING = 105; // Microsoft 365 Intune (månedlig, ingen binding)
export const ID_ADOBE_ILLUSTRATOR_FOR_TEAMS_COMMERCIAL_MULTILANGUAGE = 106; // Adobe Illustrator for teams Commercial Multilanguage
export const ID_DIGITAL_SIGNAGE = 107; // KIT Infoskjerm
export const ID_KIT_MANAGED_BACKUP_365_MONTHLY_NOBINDING = 109; // KIT Managed Backup 365 (månedlig, ingen binding)
export const ID_KIT_MANAGED_BACKUP_365_YEARLY_YEARLYBINDING = 110; // KIT Managed Backup 365 (årlig, årlig binding)
export const ID_KIT_MANAGED_BACKUP_365_UNLIMITED_MONTHLY_NOBINDING = 111; // KIT Managed Backup 365 Unlimited (månedlig, ingen binding)
export const ID_KIT_MANAGED_BACKUP_365_UNLIMITED_YEARLY_YEARLYBINDING = 112; // KIT Managed Backup 365 Unlimited (årlig, årlig binding)
export const ID_ADOBE_ACROBAT_PRO_DC_FOR_TEAMS_COMMERCIAL_MULTILANGUAGE = 113; // Adobe Acrobat Pro DC for teams Commercial Multilanguage
export const ID_MS_AZURE = 114; // Microsoft Azure
export const ID_KIT_MANAGED_BIMCLOUD = 115; // KIT Managed BIMcloud
export const ID_KITCLOUD_SYNC_1GB = 116; // KitCloud Sync 1GB
export const ID_KITCLOUD_SYNC_5GB = 117; // KitCloud Sync 5GB
export const ID_KITCLOUD_SYNC_10GB = 118; // KitCloud Sync 10GB
export const ID_KITCLOUD_SYNC_25GB = 119; // KitCloud Sync 25GB
export const ID_KITCLOUD_SYNC_50GB = 120; // KitCloud Sync 50GB
export const ID_KITCLOUD_SYNC_100GB = 121; // KitCloud Sync 100GB
export const ID_KITCLOUD_SYNC_50GB_5_USERS = 122; // KitCloud Sync 50GB (inntil 5 brukere)
export const ID_KITCLOUD_SYNC_100GB_5_USERS = 123; // KitCloud Sync 100GB (inntil 5 brukere)
export const ID_KIT_MANAGED_INTERNET_8IP = 124; // KIT Managed Internet - 8 IPv4 adresser (5 tilgjengelig)
export const ID_KIT_MANAGED_INTERNET_16IP = 125; // KIT Managed Internet - 16 IPv4 adresser (13 tilgjengelig)
export const ID_KIT_TRANSACTIONAL_EMAIL = 126; // KIT Transactional Email
export const ID_KIT_MANAGED_BACKUP_PC_YEARLYBINDING = 127; // KIT Managed Backup for PCs (årlig binding)
export const ID_KIT_MANAGED_BACKUP_PC_NOBINDING = 128; // KIT Managed Backup for PCs (ingen binding)