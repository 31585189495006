import { Response, PaginatedResponse } from './'
import { buildQuery } from '../helpers'

// GET /api/accounts
// POST /api/accounts/search
export async function getAccounts(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/accounts' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/accounts/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/accounts
export async function createAccount(account, requestOptions = {}) {
	return Response.request('/api/accounts', account, requestOptions)
}

// GET /api/accounts/:account_id
export async function getAccount(accountId, requestOptions = {}) {
	return Response.request('/api/accounts/' + encodeURIComponent(accountId), null, requestOptions)
}

// PUT /api/accounts/:account_id
export async function updateAccount(accountId, account, requestOptions = {}) {
	return Response.request('/api/accounts/' + encodeURIComponent(accountId), account, Object.assign({ method: 'PUT' }, requestOptions))
}

// DELETE /api/accounts/:account_id
export async function deleteAccount(accountId, requestOptions = {}) {
	return Response.request('/api/accounts/' + encodeURIComponent(accountId), null, Object.assign({ method: 'DELETE' }, requestOptions))
}

// GET /api/accounts/:account_id/users
// POST /api/accounts/:account_id/users/search
export async function getAccountUsers(accountId, query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/accounts/' + encodeURIComponent(accountId) + '/users' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/accounts/' + encodeURIComponent(accountId) + '/users/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/accounts/:account_id/users
export async function createAccountUser(accountId, user, requestOptions = {}) {
	return Response.request('/api/accounts/' + encodeURIComponent(accountId), user, requestOptions)
}

// GET /api/accounts/:account_id/users/:user_id
export async function getAccountUser(accountId, userId, requestOptions = {}) {
	return Response.request('/api/accounts/' + encodeURIComponent(accountId) + '/users/' + encodeURIComponent(userId), requestOptions)
}

// PUT /api/accounts/:account_id/users/:user_id
export async function updateAccountUser(accountId, userId, user, requestOptions = {}) {
	return Response.request('/api/accounts/' + encodeURIComponent(accountId) + '/users/' + encodeURIComponent(userId), user, Object.assign({ method: 'PUT' }, requestOptions))
}

// DELETE /api/accounts/:account_id/users/:user_id
export async function deleteAccountUser(accountId, userId, requestOptions = {}) {
	return Response.request('/api/accounts/' + encodeURIComponent(accountId) + '/users/' + encodeURIComponent(userId), null, Object.assign({ method: 'DELETE' }, requestOptions))
}

// GET /api/accounts/:account_id/domains
// POST /api/accounts/:account_id/domains/search
export async function getAccountDomains(accountId, query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/accounts/' + encodeURIComponent(accountId) + '/domains' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/accounts/' + encodeURIComponent(accountId) + '/domains/search', Object.assign({ query }, options), requestOptions)
}

// GET /api/accounts/:account_id/domains/:domain_id
export async function getAccountDomain(accountId, domainId, requestOptions = {}) {
	return Response.request('/api/accounts/' + encodeURIComponent(accountId) + '/domains/' + encodeURIComponent(domainId), null, requestOptions)
}

// GET /api/accounts/:account_id/services
export async function getAccountServices(accountId, requestOptions = {}) {
	return Response.request('/api/accounts/' + encodeURIComponent(accountId) + '/services', null, requestOptions)
}

export const ID_KIT = '31469638eba0574711fc6083865fdaffda14cde1'