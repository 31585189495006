import { Response, PaginatedResponse } from '..'
import { buildQuery } from '../../helpers'

// GET /api/autotask/configuration-item-relations
// POST /api/autotask/configuration-item-relations/search
export async function getConfigurationItemRelations(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/autotask/configuration-item-relations' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/autotask/configuration-item-relations/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/autotask/configuration-item-relations
export async function createConfigurationItemRelation(data, requestOptions = {}) {
	return Response.request('/api/autotask/configuration-item-relations', data, requestOptions)
}

// GET /api/autotask/configuration-item-relations/:id
export async function getConfigurationItemRelation(id, requestOptions = {}) {
	return Response.request('/api/autotask/configuration-item-relations/' + encodeURIComponent(id), null, requestOptions)
}