import { Response, PaginatedResponse } from '..'
import { buildQuery } from '../../helpers'

// GET /api/autotask/ticket-charges
// POST /api/autotask/ticket-charges/search
export async function getTicketCharges(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/autotask/ticket-charges' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/autotask/ticket-charges/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/autotask/ticket-charges
export async function createTicketCharge(data, requestOptions = {}) {
	return Response.request('/api/autotask/ticket-charges', data, requestOptions)
}

// GET /api/autotask/ticket-charges/:id
export async function getTicketCharge(id, requestOptions = {}) {
	return Response.request('/api/autotask/ticket-charges/' + encodeURIComponent(id), null, requestOptions)
}

// PUT /api/autotask/ticket-charges/:id
export async function updateTicketCharge(id, data, requestOptions = {}) {
	return Response.request('/api/autotask/ticket-charges/' + encodeURIComponent(id), data, Object.assign({ method: 'PUT' }, requestOptions))
}

// DELETE /api/autotask/tickets/:ticket_id/charges/:id
export async function deleteTicketCharge(ticket_id, id, requestOptions = {}) {
	return Response.request('/api/autotask/tickets/' + encodeURIComponent(ticket_id) + '/charges/' + encodeURIComponent(id), null, Object.assign({ method: 'DELETE' }, requestOptions))
}

export const CHARGE_TYPE_OPERATIONAL = 1;
export const CHARGE_TYPE_CAPITALIZED = 2;

export const STATUS_PENDING = 1;
export const STATUS_WAITING_APPROVAL = 2;
export const STATUS_NEED_TO_ORDER_OR_FULFILL = 3;
export const STATUS_ON_ORDER = 4;
export const STATUS_READY_TO_DELIVER_OR_SHIP = 6;
export const STATUS_DELIVERED_OR_SHIPPED_FULL = 7;
export const STATUS_CANCELED = 8;