import { request } from '../helpers'

export class ResponseError {
	code = null
	title = null
	message = null
}

export class ResponseDataInfo {
	source = null
	prev = null
	next = null
	limit = 50
	count = 0
	total_count = 0

	pages() {
		const limit = Math.max(1, Math.min(this.limit, 1000))
		return Math.ceil(this.count / limit)
	}
}

export class Response {
	success = false
	data_info = new ResponseDataInfo()
	data = null
	redirect = null
	error = null

	static async request(endpoint, data = null, requestOptions = {}) {
		const json = await request(endpoint, data, requestOptions)
		return new Response(json)
	}

	constructor(json) {
		if (typeof json === 'object' && json !== null) {
			this.success = json.success ?? null
			this.data = json.data ?? null
			if (typeof json.error === 'object' && json.error !== null) {
				this.error = new ResponseError()
				this.error.code = json.error.code ?? null
				this.error.title = json.error.title ?? null
				this.error.message = json.error.message ?? null
			}
			if (typeof json.data_info === 'object' && json.data_info !== null) {
				this.data_info.source = json.data_info.source ?? null
			}
			if (typeof json.redirect === 'string' && json.redirect.length > 0) {
				this.redirect = json.redirect
			}
		}
	}
}

export class PaginatedResponse extends Response {
	prev_res = null
	next_res = null

	constructor(json) {
		super(json)
		if (typeof json === 'object' && json !== null && typeof json.data_info === 'object' && json.data_info !== null) {
			this.data_info.prev = json.data_info.prev ?? null
			this.data_info.next = json.data_info.next ?? null
			this.data_info.limit = json.data_info.limit ?? null
			this.data_info.count = json.data_info.count ?? null
			this.data_info.total_count = json.data_info.total_count ?? null
		}
	}

	static async request(endpoint, data = null, requestOptions = {}) {
		const json = await request(endpoint, data, requestOptions)
		return new PaginatedResponse(json)
	}

	async preload(requestOptions = {}) {
		this.prev_res = this.prev(requestOptions)
		this.next_res = this.next(requestOptions)
		return Promise.allSettled([this.prev_res, this.next_res])
	}

	async prev(requestOptions = {}) {
		if (this.data_info.prev === null) {
			return Promise.reject(new Error('No more pages'))
		}
		if (this.prev_res !== null) {
			return this.prev_res
		}
		return PaginatedResponse.request(this.data_info.prev, null, requestOptions)
	}

	async next(requestOptions = {}) {
		if (this.data_info.next === null) {
			return Promise.reject(new Error('No more pages'))
		}
		if (this.next_res !== null) {
			return this.next_res
		}
		return PaginatedResponse.request(this.data_info.next, null, requestOptions)
	}

	async all(requestOptions = {}) {
		let res = [this]
		let cur = this
		while (true) {
			try {
				let next = await cur.next(requestOptions)
				res.push(next)
				cur = next
			} catch (e) {
				break
			}
		}
		return res
	}
}