import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { Segment, Container, Breadcrumb, Popup, Button, Icon, Rail, Sticky, Ref, Image, Grid, Header, Placeholder } from 'semantic-ui-react'
import Nav from './nav'
import Messages from './messages'
import { append, handleRedirect, isObject, navigate, preventDefault, request } from '../helpers'
import { Await, useLocation, useMatches, useNavigate, useNavigation } from 'react-router-dom'
import { useAuth, useBreadcrumbs, useMessages } from '../pages/root'
import BreadcrumbPlaceholder from './breadcrumb_placeholder'

// context value: [railVisible, setRail]
const RailContext = createContext()
export function useRail() {
	const ctx = useContext(RailContext)
	if (ctx === undefined) {
		throw new Error('useRail must be inside a RailProvider')
	}
	return ctx
}

export function DashContentLoading() {
	const matches = useMatches()
	const match = matches[matches.length - 1]
	const dashProps = isObject(match.handle) && isObject(match.handle.dash)
		? match.handle.dash
		: {}

	return <Segment vertical padded="very">
		<Container fluid={dashProps.fluid}>
			<Placeholder>
				<Placeholder.Header>
					<Placeholder.Line />
					<Placeholder.Line />
				</Placeholder.Header>
			</Placeholder>
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		</Container>
	</Segment>
}

export default function Dash({
	className,
	tabs,
	fluid,
	fullHeight,
	constrained,
	children,
	bottom,
	loader,
}) {
	className = className ?? null
	tabs = tabs ?? null
	fluid = fluid ?? false
	fullHeight = fullHeight ?? false
	constrained = constrained ?? false
	children = children ?? null
	bottom = bottom ?? null
	loader = loader ?? true

	const routerNavigateFn = useNavigate()
	const { user } = useAuth()
	const breadcrumbs = useBreadcrumbs()
	const [messages, setMessages] = useMessages()
	const { state: navigationState } = useNavigation()
	const location = useLocation()

	const contentRef = useRef(null)

	// scroll to top when messages or location changed
	useEffect(() => {
		contentRef.current.scrollTo(0, 0)
	}, [messages, location])

	const [navBecomingHidden, setNavBecomingHidden] = useState(true)
	const [navVisible, setNavVisible] = useState(true)
	useEffect(() => {
		const sessNavVisible = localStorage.getItem('kit-dash-nav-visible')
		if (sessNavVisible !== null) {
			setNavVisible(sessNavVisible === '1')
		}
	}, [])

	const railOuterRef = useRef(null)
	const railInnerRef = useRef(null)
	const railObserver = useRef(null)
	const [railVisible, setRailVisible] = useState(true)
	const [rail, setRail] = useState(null)
	useEffect(() => {
		if (rail) {
			if (railObserver.current === null) {
				railObserver.current = new IntersectionObserver(([entry]) => {
					setRailVisible(entry.isIntersecting)
				}, { threshold: 1.0 })
			}
			railObserver.current.observe(railInnerRef.current)
			return () => railObserver.current.disconnect()
		}
	}, [rail])

	const toggleNav = () => {
		localStorage.setItem('kit-dash-nav-visible', !navVisible ? '1' : '0')
		setNavVisible(!navVisible)
		setNavBecomingHidden(navVisible)
		if (navVisible) setTimeout(() => setNavBecomingHidden(false), 200)
	}

	const deauth = () => {
		request('/auth/logout', {})
			.then(res => {
				handleRedirect(res, routerNavigateFn)
			})
			.catch(e => {
				handleRedirect(e.res, routerNavigateFn)
				console.error('Failed to trigger logout:', e)
			})
	}

	let breadcrumbsElem = null
	if (navigationState === 'loading' && loader) {
		breadcrumbsElem = <Breadcrumb sections={[{key: 'loading', content: <BreadcrumbPlaceholder />}]} />
	} else if (typeof breadcrumbs === 'object' && Array.isArray(breadcrumbs)) {
		breadcrumbsElem = <Breadcrumb icon="right angle" sections={breadcrumbs.map(breadcrumb => {
			if (breadcrumb.icon) {
				breadcrumb = append(breadcrumb, {
					content: <>
						<Icon name={breadcrumb.icon} />
						{typeof breadcrumb.content === 'object' && typeof breadcrumb.content.then === 'function' ? <React.Suspense fallback={<BreadcrumbPlaceholder />}>
							<Await resolve={breadcrumb.content}>
								{content => content}
							</Await>
						</React.Suspense> : breadcrumb.content}
					</>,
				})
			} else {
				breadcrumb = append(breadcrumb, {
					content: typeof breadcrumb.content === 'object' && typeof breadcrumb.content.then === 'function' ? <React.Suspense fallback={<BreadcrumbPlaceholder />}>
						<Await resolve={breadcrumb.content}>
							{content => content}
						</Await>
					</React.Suspense> : breadcrumb.content,
				})
			}
			if (breadcrumb.href) {
				breadcrumb = append(breadcrumb, {
					onClick: preventDefault(e => navigate(breadcrumb.href, '_self', {}, routerNavigateFn)),
				})
			}
			return breadcrumb
		})} />
	} else {
		breadcrumbsElem = <Breadcrumb />
	}

	let messagesElem = null
	if (typeof messages === 'object' && Array.isArray(messages) && messages.length > 0) {
		messagesElem = <Messages
			attached={constrained || !!rail}
			messages={messages}
			onDismiss={key => setMessages(messages => messages.filter(message => message.key !== key))}
		/>
	}

	let userProfileElem = null
	if (isObject(user)) {
		userProfileElem = <Popup
			on="click"
			position="bottom right"
			className="kit-usermenu-popup"
			trigger={<Button type="button" className="plain kit-dash-usermenutoggle" onClick={preventDefault()}>
				{isObject(user.avatar) ? <Image
					avatar
					src={user.avatar.small.uri ?? user.avatar.original.uri}
					width={user.avatar.small.width ?? user.avatar.original.width}
					height={user.avatar.small.height ?? user.avatar.original.height}
				/> : null}
				<span>{user.name}</span>
			</Button>}
		>
			<Grid>
				<Grid.Row columns={2} verticalAlign="middle">
					{isObject(user.account) ? <Grid.Column textAlign="left"><span className='kit-usermenu-company-name'>{user.account.name}</span></Grid.Column> : null}
					<Grid.Column textAlign="right">
						<Button color="red" size="mini" onClick={preventDefault(() => deauth())}>
							<Icon name="log out" /> Logg ut
						</Button>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					{isObject(user.avatar) ? <Grid.Column width={5}>
						<Image
							style={{backgroundColor: '#f2711c40'}}
							avatar
							size="large"
							src={user.avatar.medium.uri ?? user.avatar.original.uri}
							width={user.avatar.medium.width ?? user.avatar.original.width}
							height={user.avatar.medium.height ?? user.avatar.original.height}
						/>
					</Grid.Column> : null}
					<Grid.Column width={11}>
						<Header><span className="kit-usermenu-name">{user.name}</span></Header>
						<p><span className="kit-usermenu-email">{user.email}</span></p>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Popup>
	}

	let content = null
	if (rail || bottom) {
		content = <>
			<div className={'kit-dash-segment-container' + (bottom || fullHeight ? ' kit-dash-scroll-container' : '')}>
				<div className={'kit-dash-segment-container' + (bottom || fullHeight ? ' kit-dash-scroll-content' : '')}>
					{messagesElem}
					{children}
				</div>
				{bottom}
			</div>

			{!rail ? null : <Ref innerRef={railOuterRef}>
				<Rail position="right">
					<div className="kit-dash-rail-observer" ref={railInnerRef}></div>
					{fullHeight ? <>
						<div className="kit-dash-scroll-content">
							{railVisible ? rail : null}
						</div>
					</> : <Sticky context={railOuterRef} offset={breadcrumbs ? 48 : 0}>
						{railVisible ? rail : null}
					</Sticky>}
				</Rail>
			</Ref>}
		</>
	} else {
		content = <>
			{!constrained && messagesElem ? <Segment vertical padded secondary>
				<Container>
					{messagesElem}
				</Container>
			</Segment> : messagesElem}
			{children}
		</>
	}

	return <div className={'kit-dash' + (constrained || !!rail ? ' kit-dash-constrained' : '') + (fluid ? ' kit-dash-fluid' : '') + (className ? ' ' + className : '')}>
		<Nav visible={navVisible} becomingHidden={navBecomingHidden} />

		<div className="kit-dash-container kit-dash-segment-container">
			<Segment secondary vertical className="kit-dash-breadcrumb-segment">
				<Container fluid={fluid || constrained || !!rail}>
					<Popup
						inverted
						content={navVisible ? 'Skjul meny' : 'Vis meny'}
						trigger={<Button
							type="button"
							className="plain kit-dash-navtoggle"
							icon={'angle double ' + (navVisible ? 'left' : 'right')}
							onClick={preventDefault(e => toggleNav(e))}
						/>}
					/>
					{userProfileElem}
					{breadcrumbsElem}
					{/* NOTE: A Semantic UI <Tab> element can be inserted here */}
				</Container>
			</Segment>
			<div className={'kit-dash-content' + (fullHeight || !!bottom ? ' kit-dash-content-fullheight' : '')} ref={contentRef}>
				<div className={'kit-dash-segment-container' + (constrained ? ' kit-dash-constrainer' : '')}>
					<RailContext.Provider value={[railVisible, setRail]}>
						{content}
					</RailContext.Provider>
				</div>
			</div>
		</div>
	</div>
}