import React, { useCallback, useState } from 'react'
import { Segment, Header, Container, Label, Icon, Popup, Button, Grid, Input, Form, Modal, Table, TextArea, Loader, Message } from 'semantic-ui-react'
import { filterTypes, objValOrDefault, append, omit, stripLast, preventDefault, handleRedirect, isObject } from '../../../helpers'
import DataTable from '../../../components/data_table'
import { getDomainDNSRecords, createDomainDNSRecord, updateDomainDNSRecord, deleteDomainDNSRecord, importDomainDNSRecord } from '../../../api/domains'
import { Await, useNavigate, useRouteLoaderData } from 'react-router-dom'
import { useMessages } from '../../root'
import Navigate from '../../../components/navigate'

const dnsTypeColors = {
	// IP
	'A': 'black',
	'AAAA': 'black',

	// DNS
	'CNAME': 'orange',
	'NAPTR': 'orange',
	'NS': 'orange',
	'PTR': 'orange',

	// Service discovery
	'MX': 'blue',
	'SRV': 'purple',

	// Informational
	'LOC': 'green',
	'TXT': 'green',

	// Security
	'CAA': 'grey',
	'CERT': 'grey',
	'SSHFP': 'grey',
	'TLSA': 'grey',
	'SMIMEA': 'grey',

	// DNSSEC
	'DNSKEY': 'red',
	'DS': 'red',

	// Misc.
	'HTTPS': 'brown',
	'SVCB': 'brown',
	'URI': 'brown',
}

const dnsTypeOptions = [
	{ value: 'A', text: 'A' },
	{ value: 'AAAA', text: 'AAAA' },
	{ value: 'CAA', text: 'CAA' },
	{ value: 'CERT', text: 'CERT' },
	{ value: 'CNAME', text: 'CNAME' },
	{ value: 'DNSKEY', text: 'DNSKEY' },
	{ value: 'DS', text: 'DS' },
	{ value: 'HTTPS', text: 'HTTPS' },
	{ value: 'LOC', text: 'LOC' },
	{ value: 'MX', text: 'MX' },
	{ value: 'NAPTR', text: 'NAPTR' },
	{ value: 'NS', text: 'NS' },
	{ value: 'PTR', text: 'PTR' },
	{ value: 'SMIMEA', text: 'SMIMEA' },
	{ value: 'SRV', text: 'SRV' },
	{ value: 'SSHFP', text: 'SSHFP' },
	{ value: 'SVCB', text: 'SVCB' },
	{ value: 'TLSA', text: 'TLSA' },
	{ value: 'TXT', text: 'TXT' },
	{ value: 'URI', text: 'URI' },
]

const dnsTTLOptions = [
	{ value: '1', text: 'Auto' },
	{ value: '60', text: '1 min' },
	{ value: '120', text: '2 min' },
	{ value: '300', text: '5 min' },
	{ value: '600', text: '10 min' },
	{ value: '900', text: '15 min' },
	{ value: '1800', text: '30 min' },
	{ value: '3600', text: '1 time' },
	{ value: '7200', text: '2 timer' },
	{ value: '18000', text: '5 timer' },
	{ value: '43200', text: '12 timer' },
	{ value: '86400', text: '1 dag' },
]

const newDNSRecordData = {
	'A': {
		id: 'new',
		type: 'A',
		proxied: false,
		ttl: '1',
		content: '',
	},
	'AAAA': {
		id: 'new',
		type: 'AAAA',
		proxied: false,
		ttl: '1',
		content: '',
	},
	'CAA': {
		id: 'new',
		type: 'CAA',
		ttl: '1',
		data: {
			flags: '0',
			tag: '',
			value: '',
		}
	},
	'CERT': {
		id: 'new',
		type: 'CERT',
		ttl: '1',
		data: {
			algorithm: '',
			certificate: '',
			key_tag: '',
			type: '',
		}
	},
	'CNAME': {
		id: 'new',
		type: 'CNAME',
		proxied: false,
		ttl: '1',
		content: '',
	},
	'DNSKEY': {
		id: 'new',
		type: 'DNSKEY',
		ttl: '1',
		data: {
			flags: '',
			algorithm: '',
			protocol: '3',
			public_key: '',
		}
	},
	'DS': {
		id: 'new',
		type: 'DS',
		ttl: '1',
		data: {
			algorithm: '',
			key_tag: '',
			digest: '',
			digest_type: '',
		}
	},
	'HTTPS': {
		id: 'new',
		type: 'HTTPS',
		ttl: '1',
		data: {
			priority: '',
			target: '',
			value: '',
		}
	},
	'SVCB': {
		id: 'new',
		type: 'SVCB',
		ttl: '1',
		data: {
			priority: '',
			target: '',
			value: '',
		}
	},
	'LOC': {
		id: 'new',
		type: 'LOC',
		ttl: '1',
		data: {
			altitude: '',
			lat_degrees: '',
			lat_direction: '',
			lat_minutes: '',
			lat_seconds: '',
			long_degrees: '',
			long_direction: '',
			long_minutes: '',
			long_seconds: '',
			precision_horz: '',
			precision_vert: '',
			size: '',
		}
	},
	'MX': {
		id: 'new',
		type: 'MX',
		priority: '0',
		content: '',
		ttl: '1',
	},
	'NAPTR': {
		id: 'new',
		type: 'NAPTR',
		ttl: '1',
		data: {
			service: '',
			flags: '',
			order: '',
			preference: '',
			regex: '',
			replacement: '',
		}
	},
	'NS': {
		id: 'new',
		type: 'NS',
		ttl: '1',
		content: '',
	},
	'PTR': {
		id: 'new',
		type: 'PTR',
		ttl: '1',
		content: '',
	},
	'SMIMEA': {
		id: 'new',
		type: 'SMIMEA',
		ttl: '1',
		data: {
			certificate: '',
			matching_type: '',
			selector: '',
			usage: '',
		}
	},
	'TLSA': {
		id: 'new',
		type: 'TLSA',
		ttl: '1',
		data: {
			certificate: '',
			matching_type: '',
			selector: '',
			usage: '',
		}
	},
	'SRV': {
		id: 'new',
		type: 'SRV',
		ttl: '1',
		data: {
			port: '',
			priority: '',
			target: '',
			weight: '',
		}
	},
	'SSHFP': {
		id: 'new',
		type: 'SSHFP',
		ttl: '1',
		data: {
			algorithm: '',
			type: '',
			fingerprint: '',
		}
	},
	'TXT': {
		id: 'new',
		type: 'TXT',
		ttl: '1',
		content: '',
	},
	'URI': {
		id: 'new',
		type: 'URI',
		ttl: '1',
		data: {
			weight: '',
			content: '',
		}
	},
}

const defaultDNSRecordType = 'A'
const initialDNSRecordEdits = {
	'new': newDNSRecordData[defaultDNSRecordType]
}

export default function DomainDnsRecordsTab() {
	const { domain } = useRouteLoaderData('domain')

	return <React.Suspense fallback={
		<Segment vertical padded="very">
			<Container>
				<DataTable
					className="kit-datatable-dns"
					striped={false}
					disabled
					loading
					compact
					columns={[
						{ key: 'type', text: 'Type', visible: true, sortable: true, searchable: true, width: 2, filterType: filterTypes.search },
						{ key: 'name', text: 'Navn', visible: true, sortable: true, searchable: true, width: 4, singleLineNoOverflow: true, filterType: filterTypes.search },
						{ key: 'content', text: 'Data', visible: true, sortable: true, searchable: true, width: 6, singleLineNoOverflow: true, filterType: filterTypes.search },
						{ key: 'ttl', text: 'TTL', visible: true, sortable: true, searchable: false, width: 2 },
						{ key: 4, text: '', visible: true, width: 2 },
					]}
					extraPanes={[
						{
							key: 'add',
							icon: 'add',
							title: 'Opprett DNS-peker',
							render: () => null,
						},
						{
							key: 'importexport',
							icon: 'exchange',
							title: 'Import/eksport',
							render: () => null,
						}
					]}
				/>
			</Container>
		</Segment>
	}>
		<Await resolve={domain}>
			{({redirect, data: domain}) => redirect !== null ? <Segment vertical padded="very">
				<Container>
					<Segment placeholder>
						<Loader active indeterminate>Sender deg videre, vennligst vent...</Loader>
					</Segment>
					<Navigate to={redirect} />
				</Container>
			</Segment> : <DomainDnsRecordsTabContent domain={domain} />}
		</Await>
	</React.Suspense>
}

function DomainDnsRecordsTabContent({ domain }) {
	const routerNavigateFn = useNavigate()
	const [, setMessages] = useMessages()

	const [importingDNSZoneFile, setImportingDNSZoneFile] = useState(false)
	const [dnsImportZoneFile, setDNSImportZoneFile] = useState('')
	const [dnsImportMessages, setDNSImportMessages] = useState('')
	const [lastDNSRecordAddType, setLastDNSRecordAddType] = useState(defaultDNSRecordType)
	const [dnsRecordEdits, setDNSRecordEdits] = useState(initialDNSRecordEdits)
	const [editingDNSRecords, setEditingDNSRecords] = useState([])
	const [savingDNSRecords, setSavingDNSRecords] = useState([])
	const [deletingDNSRecords, setDeletingDNSRecords] = useState([])
	const [promptDeleteDNSRecordData, setPromptDeleteDNSRecordData] = useState(null)
	const [activePane, setActivePane] = useState(null)
	const [dataTableRefresh, setDataTableRefresh] = useState(false)
	const [dataTableRefreshCallbacks, setDataTableRefreshCallbacks] = useState([])

	const toggleDNSRecordEdit = recordId => {
		setEditingDNSRecords(editingDNSRecords => {
			const editing = editingDNSRecords.includes(recordId)
			return editing ? editingDNSRecords.filter(id => id !== recordId) : append(editingDNSRecords, recordId)
		})
		setSavingDNSRecords(savingDNSRecords => {
			const saving = savingDNSRecords.includes(recordId)
			return saving ? savingDNSRecords.filter(id => id !== recordId) : savingDNSRecords
		})
		setDeletingDNSRecords(deletingDNSRecords => {
			const deleting = deletingDNSRecords.includes(recordId)
			return deleting ? deletingDNSRecords.filter(id => id !== recordId) : deletingDNSRecords
		})
		setDNSRecordEdits(dnsRecordEdits => {
			let newDNSRecordEdits = omit(recordId, dnsRecordEdits)
			if (recordId === 'new') {
				newDNSRecordEdits = append(newDNSRecordEdits, {
					[recordId]: newDNSRecordData[lastDNSRecordAddType],
				})
			}
			return newDNSRecordEdits
		})
	}

	const renderDNSRecordForm = (data) => {
		const stateRecordId = data === null ? 'new' : data.id

		const storeEdit = (field) => preventDefault((e, elemData) => {
			const dataField = field.startsWith('data.') ? field.substring(5) : null
			const newValue = typeof elemData === 'object' && elemData.hasOwnProperty('value') ? elemData.value : e.target.value

			setDNSRecordEdits(dnsRecordEdits => {
				const existingData = dnsRecordEdits.hasOwnProperty(stateRecordId) && isObject(dnsRecordEdits[stateRecordId]) ? dnsRecordEdits[stateRecordId] : {}

				const getNewValue = (newField, fallback) => {
					if (field === newField) {
						return newValue
					}
					if (existingData.hasOwnProperty(newField)) {
						return existingData[newField]
					}
					if (data !== null && data.hasOwnProperty(newField)) {
						return data[newField]
					}
					return fallback
				}

				// start with fresh data if creating a new record or type edited
				let newData = {}
				if (data === null && (!dnsRecordEdits.hasOwnProperty(stateRecordId) || !isObject(dnsRecordEdits[stateRecordId]) || field === 'type')) {
					newData = append(newDNSRecordData[getNewValue('type', lastDNSRecordAddType)], {
						name: getNewValue('name', '')
					})
				}

				// update data with current edit
				if (dataField !== null) {
					newData.data = append(getNewValue('data', {}), {
						[dataField]: newValue,
					})
				} else {
					newData[field] = newValue
				}

				let dnsRecordEdit = append(existingData, newData)

				// remove edited data if it matches existing data
				if (dataField !== null && data !== null && dnsRecordEdit.hasOwnProperty('data') && data.hasOwnProperty('data') && dnsRecordEdit.data[dataField] === data.data[dataField]) {
					delete dnsRecordEdit.data[field]
				} else if (dataField === null && data !== null && dnsRecordEdit[field] === data[field]) {
					delete dnsRecordEdit[field]
				}
				if (dnsRecordEdit.hasOwnProperty('data') && Object.keys(dnsRecordEdit.data).length === 0) {
					delete dnsRecordEdit.data
				}

				setLastDNSRecordAddType(getNewValue('type', lastDNSRecordAddType))
				let newDNSRecordEdits
				if (Object.keys(dnsRecordEdit).length === 0) {
					newDNSRecordEdits = omit(stateRecordId, dnsRecordEdits)

					if (stateRecordId === 'new') {
						newDNSRecordEdits = append(newDNSRecordEdits, {
							[stateRecordId]: newDNSRecordData[getNewValue('type', lastDNSRecordAddType)],
						})
					}
				} else {
					newDNSRecordEdits = append(dnsRecordEdits, {
						[stateRecordId]: dnsRecordEdit
					})
				}

				return newDNSRecordEdits
			})
		})

		const getValue = field => {
			const dataField = field.startsWith('data.') ? field.substring(5) : null
			if (dataField !== null) {
				if (dnsRecordEdits.hasOwnProperty(stateRecordId) && isObject(dnsRecordEdits[stateRecordId]) && dnsRecordEdits[stateRecordId].hasOwnProperty('data') && dnsRecordEdits[stateRecordId].data.hasOwnProperty(dataField)) {
					return dnsRecordEdits[stateRecordId].data[dataField].toString()
				}
				if (data !== null && data.hasOwnProperty('data') && data.data.hasOwnProperty(dataField)) {
					return data.data[dataField].toString()
				} else {
					return ''
				}
			} else {
				if (dnsRecordEdits.hasOwnProperty(stateRecordId) && isObject(dnsRecordEdits[stateRecordId]) && dnsRecordEdits[stateRecordId].hasOwnProperty(field)) {
					return dnsRecordEdits[stateRecordId][field].toString()
				}
				if (data !== null && data.hasOwnProperty(field)) {
					if (field === 'name') {
						return stripLast(data.name, '.' + (domain.ace ?? domain.domain))
					}
					return data[field].toString()
				} else {
					return ''
				}
			}
		}

		const deleteRecord = preventDefault(() => {
			// abort if already deleting
			if (deletingDNSRecords.includes(stateRecordId)) return
			// trigger delete record modal
			setPromptDeleteDNSRecordData(data)
		})

		const saveRecord = preventDefault(() => {
			// abort if already saving
			if (savingDNSRecords.includes(stateRecordId)) return
			// set loading state, disabling buttons
			setSavingDNSRecords(savingDNSRecords => append(savingDNSRecords, stateRecordId))

			const changes = dnsRecordEdits.hasOwnProperty(stateRecordId) && isObject(dnsRecordEdits[stateRecordId]) ? dnsRecordEdits[stateRecordId] : null
			if (changes === null) {
				toggleDNSRecordEdit(stateRecordId)
				return
			}
			if (data === null) {
				createDomainDNSRecord(domain.id, changes)
					.then(res => {
						handleRedirect(res, routerNavigateFn)
						setDataTableRefresh(true)
						setDataTableRefreshCallbacks(callbacks => append(callbacks, () => toggleDNSRecordEdit(stateRecordId)))
					})
					.catch(e => {
						handleRedirect(e.res, routerNavigateFn)
						console.error('Unable to create DNS record:', e)
						setMessages(messages => messages.concat({
							key: 'dns_record_create_error_' + Math.round(new Date() / 1000).toString(),
							dismissable: true,
							type: 'error',
							icon: 'exclamation circle',
							title: 'En feil oppstod ved oppretting av DNS-peker',
							content: e.message
						}))
						setSavingDNSRecords(savingDNSRecords => savingDNSRecords.filter(id => id !== stateRecordId))
					})
			} else {
				updateDomainDNSRecord(domain.id, stateRecordId, changes)
					.then(res => {
						handleRedirect(res, routerNavigateFn)
						setDataTableRefresh(true)
						setDataTableRefreshCallbacks(callbacks => append(callbacks, () => toggleDNSRecordEdit(stateRecordId)))
					})
					.catch(e => {
						handleRedirect(e.res, routerNavigateFn)
						console.error('Unable to update DNS record ID %s:', stateRecordId, e)
						setMessages(messages => messages.concat({
							key: 'dns_record_update_error_' + Math.round(new Date() / 1000).toString(),
							dismissable: true,
							type: 'error',
							icon: 'exclamation circle',
							title: 'En feil oppstod ved oppdatering av DNS-peker',
							content: e.message
						}))
						setSavingDNSRecords(savingDNSRecords => savingDNSRecords.filter(id => id !== stateRecordId))
					})
			}
		})

		const saving = savingDNSRecords.includes(stateRecordId)
		const deleting = deletingDNSRecords.includes(stateRecordId)
		const loading = saving || deleting

		const typeField = width => data === null ? <Form.Select
			options={dnsTypeOptions}
			width={width}
			fluid
			search
			disabled={loading}
			label="Type"
			value={getValue('type')}
			onChange={storeEdit('type')}
		/> : <Form.Field width={width} disabled={loading}>
			<label>Type</label>
			<Label color={dnsTypeColors.hasOwnProperty(data.type) ? dnsTypeColors[data.type] : null}>{data.type}</Label>
		</Form.Field>

		const nameField = width => <Form.Field width={width} disabled={loading}>
			<label>Navn</label>
			<Input
				disabled={loading}
				value={getValue('name')}
				onChange={storeEdit('name')}
			/>
			<p className="text-secondary">Bruk @ for rot-domenet</p>
		</Form.Field>

		const ttlField = width => <Form.Select
			options={dnsTTLOptions}
			width={width}
			fluid
			disabled={loading}
			label="TTL"
			value={getValue('ttl')}
			onChange={storeEdit('ttl')}
		/>

		let formContent = null
		switch (getValue('type')) {
		case 'CAA':
			let valueLabel
			switch (getValue('data.tag')) {
			case 'issue': valueLabel = 'Sertifikatutstederens domenenavn'; break;
			case 'issuewild': valueLabel = 'Sertifikatutstederens domenenavn'; break;
			case 'iodef': valueLabel = 'URL'; break;
			default: valueLabel = 'Verdi'; break;
			}
			formContent = <>
				<Form.Group>
					{typeField(2)}
					{nameField(5)}
					<Form.Input
						width={5}
						disabled
						readOnly
						label="Flagg"
						value={getValue('data.flags')}
						onChange={preventDefault()}
					/>
					{ttlField(2)}
				</Form.Group>
				<Form.Group>
					<Form.Select
						options={[
							{ value: 'issue', text: 'Tillat utstedelse av sertifikater for vertsnavn' },
							{ value: 'issuewild', text: 'Tillat utstedelse av sertifikater for jokertegn-vertsnavn' },
							{ value: 'iodef', text: 'Send overtredelses-rapporter til URL (http:, https: eller mailto:)' },
						]}
						width={7}
						fluid
						disabled={loading}
						label="Tag"
						value={getValue('data.tag')}
						onChange={storeEdit('data.tag')}
					/>
					<Form.Input
						width={5}
						disabled={loading}
						label={valueLabel}
						value={getValue('data.value')}
						onChange={storeEdit('data.value')}
					/>
				</Form.Group>
			</>
			break;

		case 'CERT':
			formContent = <>
				<Form.Group>
					{typeField(2)}
					{nameField(10)}
					{ttlField(2)}
				</Form.Group>
				<Form.Group>
					<Form.Field width={2} disabled={loading}>
						<label>Sertifikattype</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="65535"
							fluid
							disabled={loading}
							value={getValue('data.type')}
							onChange={storeEdit('data.type')}
						/>
						<p>0 - 65535</p>
					</Form.Field>
					<Form.Field width={2} disabled={loading}>
						<label>Nøkkel-tag</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="65535"
							fluid
							disabled={loading}
							value={getValue('data.key_tag')}
							onChange={storeEdit('data.key_tag')}
						/>
						<p>0 - 65535</p>
					</Form.Field>
					<Form.Field width={2} disabled={loading}>
						<label>Algoritme</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="255"
							fluid
							disabled={loading}
							value={getValue('data.algorithm')}
							onChange={storeEdit('data.algorithm')}
						/>
						<p>0 - 255</p>
					</Form.Field>
					<Form.Field width={10} disabled={loading}>
						<label>Sertifikat (base64)</label>
						<TextArea
							value={getValue('data.certificate')}
							onChange={storeEdit('data.certificate')}
						/>
						<p>Eksempel: <code>TEpBNFYyTGtWUVpsTHpaa0htQXVPd0...wxREdCM3BRTTNWbUwyVlRNNERKWg==</code></p>
					</Form.Field>
				</Form.Group>
			</>
			break;

		case 'DNSKEY':
			formContent = <>
				<Form.Group>
					{typeField(2)}
					{nameField(10)}
					{ttlField(2)}
				</Form.Group>
				<Form.Group>
					<Form.Field width={2} disabled={loading}>
						<label>Flagg</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="65535"
							fluid
							disabled={loading}
							value={getValue('data.flags')}
							onChange={storeEdit('data.flags')}
						/>
						<p>0 - 65535</p>
					</Form.Field>
					<Form.Select
						options={[
							{ value: '3', text: '3 - DNSSEC' },
						]}
						width={3}
						fluid
						disabled
						readOnly
						label="Protokoll"
						value={getValue('data.protocol')}
						onChange={preventDefault()}
					/>
					<Form.Field width={2} disabled={loading}>
						<label>Algoritme</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="255"
							fluid
							disabled={loading}
							value={getValue('data.algorithm')}
							onChange={storeEdit('data.algorithm')}
						/>
						<p>0 - 255</p>
					</Form.Field>
					<Form.Field width={9} disabled={loading}>
						<label>Offentlig nøkkel (base64)</label>
						<TextArea
							value={getValue('data.public_key')}
							onChange={storeEdit('data.public_key')}
						/>
						<p>Eksempel: <code>TEpBNFYyTGtWUVpsTHpaa0htQXVPd0...wxREdCM3BRTTNWbUwyVlRNNERKWg==</code></p>
					</Form.Field>
				</Form.Group>
			</>
			break;

		case 'DS':
			formContent = <>
				<Form.Group>
					{typeField(2)}
					{nameField(10)}
					{ttlField(2)}
				</Form.Group>
				<Form.Group>
					<Form.Field width={2} disabled={loading}>
						<label>Nøkkel-tag</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="65535"
							fluid
							disabled={loading}
							value={getValue('data.key_tag')}
							onChange={storeEdit('data.key_tag')}
						/>
						<p>0 - 65535</p>
					</Form.Field>
					<Form.Field width={2} disabled={loading}>
						<label>Algoritme</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="255"
							fluid
							disabled={loading}
							value={getValue('data.algorithm')}
							onChange={storeEdit('data.algorithm')}
						/>
						<p>0 - 255</p>
					</Form.Field>
					<Form.Select
						options={[
							{ value: '1', text: '1 - SHA-1' },
							{ value: '2', text: '2 - SHA-256' },
							{ value: '3', text: '3 - GOST R 34,11-94' },
							{ value: '4', text: '4 - SHA-384' },
						]}
						width={3}
						fluid
						disabled={loading}
						label="Sammendragstype"
						placeholder="Velg type..."
						value={getValue('data.digest_type')}
						onChange={storeEdit('data.digest_type')}
					/>
					<Form.Field width={9} disabled={loading}>
						<label>Kryptografisk sammendrag (hex)</label>
						<TextArea
							value={getValue('data.digest')}
							onChange={storeEdit('data.digest')}
						/>
						<p>Eksempel: <code>436c6f7564666c...61726520444e53</code></p>
					</Form.Field>
				</Form.Group>
			</>
			break;

		case 'HTTPS':
		case 'SVCB':
			formContent = <>
				<Form.Group>
					{typeField(2)}
					{nameField(10)}
					{ttlField(2)}
					<Form.Field width={2} disabled={loading}>
						<label>Prioritet</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="65535"
							fluid
							disabled={loading}
							value={getValue('data.priority')}
							onChange={storeEdit('data.priority')}
						/>
						<p>0 - 65535</p>
					</Form.Field>
				</Form.Group>
				<Form.Group>
					<Form.Input
						width={8}
						disabled={loading}
						label="Måldomene"
						value={getValue('data.value')}
						onChange={storeEdit('data.value')}
					/>
					<Form.Field width={8} disabled={loading}>
						<label>Verdi</label>
						<Input
							fluid
							disabled={loading}
							value={getValue('data.value')}
							onChange={storeEdit('data.value')}
						/>
						<p>Eksempel: <code>alpn="h3,h2" ipv4hint="127.0.0.1" ipv6hint="::1"</code></p>
					</Form.Field>
				</Form.Group>
			</>
			break;

		case 'LOC':
			formContent = <>
				<Form.Group>
					{typeField(2)}
					{nameField(10)}
					{ttlField(2)}
				</Form.Group>

				<Header size="small">Sett breddegrad</Header>
				<Form.Group>
					<Form.Field width={4} disabled={loading}>
						<label>Grader</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="90"
							fluid
							disabled={loading}
							value={getValue('data.lat_degrees')}
							onChange={storeEdit('data.lat_degrees')}
						/>
						<p>0 - 90</p>
					</Form.Field>
					<Form.Field width={4} disabled={loading}>
						<label>Minutter</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="59"
							fluid
							disabled={loading}
							value={getValue('data.lat_minutes')}
							onChange={storeEdit('data.lat_minutes')}
						/>
						<p>0 - 59</p>
					</Form.Field>
					<Form.Field width={4} disabled={loading}>
						<label>Sekunder</label>
						<Input
							type="number"
							step="0.001"
							min="0"
							max="59.999"
							fluid
							disabled={loading}
							value={getValue('data.lat_seconds')}
							onChange={storeEdit('data.lat_seconds')}
						/>
						<p>0 - 59.999</p>
					</Form.Field>
					<Form.Select
						options={[
							{ value: 'N', text: 'Nord' },
							{ value: 'S', text: 'Sør' },
						]}
						width={4}
						fluid
						disabled={loading}
						label="Retning"
						placeholder="Velg retning..."
						value={getValue('data.lat_direction')}
						onChange={storeEdit('data.lat_direction')}
					/>
				</Form.Group>

				<Header size="small">Sett lengdegrad</Header>
				<Form.Group>
					<Form.Field width={4} disabled={loading}>
						<label>Grader</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="180"
							fluid
							disabled={loading}
							value={getValue('data.long_degrees')}
							onChange={storeEdit('data.long_degrees')}
						/>
						<p>0 - 180</p>
					</Form.Field>
					<Form.Field width={4} disabled={loading}>
						<label>Minutter</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="59"
							fluid
							disabled={loading}
							value={getValue('data.long_minutes')}
							onChange={storeEdit('data.long_minutes')}
						/>
						<p>0 - 59</p>
					</Form.Field>
					<Form.Field width={4} disabled={loading}>
						<label>Sekunder</label>
						<Input
							type="number"
							step="0.001"
							min="0"
							max="59.999"
							fluid
							disabled={loading}
							value={getValue('data.long_seconds')}
							onChange={storeEdit('data.long_seconds')}
						/>
						<p>0 - 59.999</p>
					</Form.Field>
					<Form.Select
						options={[
							{ value: 'E', text: 'Øst' },
							{ value: 'W', text: 'Vest' },
						]}
						width={4}
						fluid
						disabled={loading}
						label="Retning"
						placeholder="Velg retning..."
						value={getValue('data.long_direction')}
						onChange={storeEdit('data.long_direction')}
					/>
				</Form.Group>

				<Header size="small">Presisjon (i meter)</Header>
				<Form.Group>
					<Form.Field width={4} disabled={loading}>
						<label>Horisontal</label>
						<Input
							type="number"
							step="0.01"
							min="0"
							max="90000000.00"
							fluid
							disabled={loading}
							value={getValue('data.precision_horz')}
							onChange={storeEdit('data.precision_horz')}
						/>
						<p>0 - 90000000.00</p>
					</Form.Field>
					<Form.Field width={4} disabled={loading}>
						<label>Vertikal</label>
						<Input
							type="number"
							step="0.01"
							min="0"
							max="90000000.00"
							fluid
							disabled={loading}
							value={getValue('data.precision_vert')}
							onChange={storeEdit('data.precision_vert')}
						/>
						<p>0 - 90000000.00</p>
					</Form.Field>
					<Form.Field width={4} disabled={loading}>
						<label>Høyde</label>
						<Input
							type="number"
							step="0.01"
							min="-100000.00"
							max="42849672.95"
							fluid
							disabled={loading}
							value={getValue('data.altitude')}
							onChange={storeEdit('data.altitude')}
						/>
						<p>-100000.00 - 42849672.95</p>
					</Form.Field>
					<Form.Field width={4} disabled={loading}>
						<label>Størrelse</label>
						<Input
							type="number"
							step="0.01"
							min="0"
							max="90000000.00"
							fluid
							disabled={loading}
							value={getValue('data.size')}
							onChange={storeEdit('data.size')}
						/>
						<p>0 - 90000000.00</p>
					</Form.Field>
				</Form.Group>
			</>
			break;

		case 'NAPTR':
			formContent = <>
				<Form.Group>
					{typeField(2)}
					{nameField(10)}
					{ttlField(2)}
				</Form.Group>
				<Form.Group>
					<Form.Field width={2} disabled={loading}>
						<label>Rekkefølge</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="65535"
							fluid
							disabled={loading}
							value={getValue('data.order')}
							onChange={storeEdit('data.order')}
						/>
						<p>0 - 65535</p>
					</Form.Field>
					<Form.Field width={2} disabled={loading}>
						<label>Preferanse</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="65535"
							fluid
							disabled={loading}
							value={getValue('data.preference')}
							onChange={storeEdit('data.preference')}
						/>
						<p>0 - 65535</p>
					</Form.Field>
					<Form.Field width={2} disabled={loading}>
						<label>Flagg</label>
						<Input
							fluid
							disabled={loading}
							value={getValue('data.flags')}
							onChange={storeEdit('data.flags')}
						/>
						<p>S, A, U, P</p>
					</Form.Field>
					<Form.Field width={8} disabled={loading}>
						<label>Tjeneste</label>
						<Input
							fluid
							disabled={loading}
							value={getValue('data.service')}
							onChange={storeEdit('data.service')}
						/>
						<p>Eksempel: <code>protocol=...</code></p>
					</Form.Field>
				</Form.Group>
				<Form.Group>
					<Form.Field width={6} disabled={loading}>
						<label>RegEx</label>
						<Input
							fluid
							disabled={loading}
							value={getValue('data.regex')}
							onChange={storeEdit('data.regex')}
						/>
						<p>Eksempel: <code>delim-char=...</code></p>
					</Form.Field>
					<Form.Input
						width={10}
						fluid
						disabled={loading}
						label="Erstatning"
						value={getValue('data.replacement')}
						onChange={storeEdit('data.replacement')}
					/>
				</Form.Group>
			</>
			break;

		case 'SMIMEA':
		case 'TLSA':
			formContent = <>
				<Form.Group>
					{typeField(2)}
					{nameField(10)}
					{ttlField(2)}
				</Form.Group>
				<Form.Group>
					<Form.Field width={2} disabled={loading}>
						<label>Bruk</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="255"
							fluid
							disabled={loading}
							value={getValue('data.usage')}
							onChange={storeEdit('data.usage')}
						/>
						<p>0 - 255</p>
					</Form.Field>
					<Form.Field width={2} disabled={loading}>
						<label>Velger</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="255"
							fluid
							disabled={loading}
							value={getValue('data.selector')}
							onChange={storeEdit('data.selector')}
						/>
						<p>0 - 255</p>
					</Form.Field>
					<Form.Field width={2} disabled={loading}>
						<label>Sammmenlikner</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="255"
							fluid
							disabled={loading}
							value={getValue('data.matching_type')}
							onChange={storeEdit('data.matching_type')}
						/>
						<p>0 - 255</p>
					</Form.Field>
					<Form.Field width={10} disabled={loading}>
						<label>Sertifikat (hex)</label>
						<TextArea
							value={getValue('data.certificate')}
							onChange={storeEdit('data.certificate')}
						/>
						<p>Eksempel: <code>436c6f7564666c...61726520444e53</code></p>
					</Form.Field>
				</Form.Group>
			</>
			break;

		case 'SRV':
			formContent = <>
				<Form.Group>
					{typeField(2)}
					{nameField(10)}
					{ttlField(2)}
					<Form.Field width={2} disabled={loading}>
						<label>Prioritet</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="65535"
							fluid
							disabled={loading}
							value={getValue('data.priority')}
							onChange={storeEdit('data.priority')}
						/>
						<p>0 - 65535</p>
					</Form.Field>
				</Form.Group>
				<Form.Group>
					<Form.Field width={2} disabled={loading}>
						<label>Vekt</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="65535"
							fluid
							disabled={loading}
							value={getValue('data.weight')}
							onChange={storeEdit('data.weight')}
						/>
						<p>0 - 65535</p>
					</Form.Field>
					<Form.Field width={2} disabled={loading}>
						<label>Port</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="65535"
							fluid
							disabled={loading}
							value={getValue('data.port')}
							onChange={storeEdit('data.port')}
						/>
						<p>0 - 65535</p>
					</Form.Field>
					<Form.TextArea
						width={10}
						disabled={loading}
						label="Mål"
						value={getValue('data.target')}
						onChange={storeEdit('data.target')}
					/>
				</Form.Group>
			</>
			break;

		case 'SSHFP':
			formContent = <>
				<Form.Group>
					{typeField(2)}
					{nameField(10)}
					{ttlField(2)}
				</Form.Group>
				<Form.Group>
					<Form.Field width={2} disabled={loading}>
						<label>Algoritme</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="255"
							fluid
							disabled={loading}
							value={getValue('data.algorithm')}
							onChange={storeEdit('data.algorithm')}
						/>
						<p>0 - 255</p>
					</Form.Field>
					<Form.Field width={2} disabled={loading}>
						<label>Type</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="255"
							fluid
							disabled={loading}
							value={getValue('data.type')}
							onChange={storeEdit('data.type')}
						/>
						<p>0 - 255</p>
					</Form.Field>
					<Form.Field width={12} disabled={loading}>
						<label>Fingeravtrykk (hex)</label>
						<TextArea
							value={getValue('data.fingerprint')}
							onChange={storeEdit('data.fingerprint')}
						/>
						<p>Eksempel: <code>436c6f7564666c...61726520444e53</code></p>
					</Form.Field>
				</Form.Group>
			</>
			break;

		case 'URI':
			formContent = <>
				<Form.Group>
					{typeField(2)}
					{nameField(10)}
					{ttlField(2)}
					<Form.Field width={2} disabled={loading}>
						<label>Prioritet</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="255"
							fluid
							disabled={loading}
							value={getValue('priority')}
							onChange={storeEdit('priority')}
						/>
						<p>0 - 255</p>
					</Form.Field>
				</Form.Group>
				<Form.Group>
					<Form.Field width={2} disabled={loading}>
						<label>Vekt</label>
						<Input
							type="number"
							step="1"
							min="0"
							max="65535"
							fluid
							disabled={loading}
							value={getValue('data.weight')}
							onChange={storeEdit('data.weight')}
						/>
						<p>0 - 65535</p>
					</Form.Field>
					<Form.TextArea
						width={14}
						disabled={loading}
						label="Mål"
						value={getValue('data.content')}
						onChange={storeEdit('data.content')}
					/>
				</Form.Group>
			</>
			break;

		case 'TXT':
			formContent = <>
				<Form.Group>
					{typeField(2)}
					{nameField(10)}
					{ttlField(2)}
				</Form.Group>
				<Form.Group>
					<Form.TextArea
						disabled={loading}
						width={16}
						label="Innhold"
						value={getValue('content')}
						onChange={storeEdit('content')}
					/>
				</Form.Group>
			</>
			break;

		case 'A':
		case 'AAAA':
		case 'CNAME':
		case 'MX':
		case 'NS':
		case 'PTR':
		default:
			const inputLabels = {
				'A': 'IPv4-adresse',
				'AAAA': 'IPv6-adresse',
				'CNAME': 'Måldomene',
				'MX': 'Mailserver',
				'NS': 'Navneserver',
				'PTR': 'Måldomene',
			}
			const inputLabel = inputLabels.hasOwnProperty(getValue('type')) ? inputLabels[getValue('type')] : 'Data'
			formContent = <Form.Group>
				{typeField(2)}
				{nameField(5)}
				<Form.Input
					width={5}
					disabled={loading}
					label={inputLabel}
					value={getValue('content')}
					onChange={storeEdit('content')}
				/>
				{ttlField(2)}
				{getValue('type') === 'MX' ? <Form.Field width={2} disabled={loading}>
					<label>Prioritet</label>
					<Input
						type="number"
						step="1"
						min="0"
						max="65535"
						fluid
						disabled={loading}
						value={getValue('priority')}
						onChange={storeEdit('priority')}
					/>
					<p>0 - 65535</p>
				</Form.Field> : null}
			</Form.Group>
			break;
		}

		return <Form className="kit-dns-edit-form" onSubmit={saveRecord}>
			{formContent}
			<Form.Group className="kit-va-bottom">
				{data === null ? null : <Form.Button
					size="small"
					disabled={loading}
					loading={deleting}
					content="Slett"
					className="kit-va-expand"
					onClick={deleteRecord}
				/>}
				<Form.Button
					size="small"
					disabled={loading}
					content="Avbryt"
					onClick={preventDefault(() => {
						if (stateRecordId === 'new') {
							setActivePane(null)
						}
						toggleDNSRecordEdit(stateRecordId)
					})}
				/>
				<Form.Button
					size="small"
					disabled={loading}
					loading={saving}
					content="Lagre"
					color="orange"
					type="submit"
				/>
			</Form.Group>
		</Form>
	}

	const renderDNSRecordRow = (data, showActions = true) => {
		const editing = editingDNSRecords.includes(data.id)
		const saving = savingDNSRecords.includes(data.id)
		const deleting = deletingDNSRecords.includes(data.id)
		const loading = saving || deleting

		let cells = [
			<Label color={dnsTypeColors.hasOwnProperty(data.type) ? dnsTypeColors[data.type] : null}>{data.type}</Label>,
			stripLast(data.name, '.' + (domain.ace ?? domain.domain)),
			data.content,
			objValOrDefault(dnsTTLOptions.find(option => option.value === data.ttl.toString()), 'text', data.ttl.toString()),
		]
		if (showActions) {
			cells.push(
				<div className="text-ha-right">
					<Button.Group size="small" compact>
						<Popup
							inverted
							content="Endre DNS-peker"
							trigger={<Button
								disabled={loading}
								onClick={preventDefault(() => toggleDNSRecordEdit(data.id))}
							>
								Endre <Icon name={'triangle ' + (editing ? 'down' : 'right')} />
							</Button>}
						/>
					</Button.Group>
				</div>
			)
		}
		return cells
	}

	const renderDNSRecordImportExportForm = () => {
		return <Grid stackable>
			<Grid.Row columns="equal" divided>
				<Grid.Column>
					<Header>Import</Header>
					<label className="ui button" htmlFor="import-zonefile">
						<Icon name="file" /> Last inn BIND-sonefil
					</label>
					<Form onSubmit={preventDefault(() => {
						setImportingDNSZoneFile(true)
						importDomainDNSRecord(domain.id, dnsImportZoneFile)
							.then(res => {
								handleRedirect(res, routerNavigateFn)

								let messages = ''
								if (isObject(res.data) && typeof res.data.messages === 'object' && Array.isArray(res.data.messages)) {
									for (const message of res.data.messages) {
										if (isObject(message) && typeof message.message === 'string' && message.message.length > 0) {
											messages += message.message
											messages += "\n"
										}
									}
								}
								messages = messages.trim()
								setDNSImportMessages(messages)

								if (messages.length === 0) {
									setActivePane(null)
									setDNSImportZoneFile('')
								}

								setDataTableRefresh(true)
							})
							.catch(e => {
								handleRedirect(e.res, routerNavigateFn)
								console.error('Unable to import DNS records:', e)
								setMessages(messages => messages.concat({
									key: 'dns_import_error_' + Math.round(new Date() / 1000).toString(),
									dismissable: true,
									type: 'error',
									icon: 'exclamation circle',
									title: 'En feil oppstod ved import av DNS-pekere',
									content: e.message
								}))
							})
							.finally(() => setImportingDNSZoneFile(false))
					})}>
						<Form.Field>
							<input
								type="file"
								id="import-zonefile"
								className="d-none"
								onChange={e => {
									const file = e.target.files[0]
									let reader = new FileReader()
									reader.onload = () => {
										setDNSImportZoneFile(reader.result)
									};
									reader.onerror = () => {
										setMessages(messages => messages.concat({
											key: 'dns_import_file_load_error_' + Math.round(new Date() / 1000).toString(),
											dismissable: true,
											type: 'error',
											icon: 'exclamation circle',
											content: 'Kunne ikke laste inn fil ' + file.name,
										}))
									};
									reader.readAsText(file, "UTF-8")
								}}
							/>
						</Form.Field>
						<Form.TextArea
							label="BIND-sonefil"
							value={dnsImportZoneFile}
							onChange={(_, data) => setDNSImportZoneFile(data.value)}
						/>
						<Form.Field>
							<p className="text-secondary">Alle DNS-pekere i sonefilen blir lagt til. Alle eksisterende DNS-pekere beholdes.</p>
							{dnsImportMessages.length > 0 ? <Message
								visible
								error
								content={<pre>{dnsImportMessages}</pre>}
								onDismiss={() => setDNSImportMessages('')}
							/> : null}
						</Form.Field>
						<Form.Button
							type="submit"
							color="orange"
							content="Importer"
							loading={importingDNSZoneFile}
							disabled={importingDNSZoneFile}
						/>
					</Form>
				</Grid.Column>
				<Grid.Column>
					<Header>Eksport</Header>
					<Button
						as="a"
						href={'/api/domains/' + encodeURIComponent(domain.id) + '/dns-records/export'}
						download={(domain.ace ?? domain.domain) + '.txt'}
					>
						<Icon name="download" /> Last ned BIND-sonefil
					</Button>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	}

	const renderDNSRecordDeleteModal = () => {
		if (!domain || !promptDeleteDNSRecordData) return null

		const deleteRecord = (recordId) => {
			// set loading state, disabling buttons
			setDeletingDNSRecords(deletingDNSRecords => append(deletingDNSRecords, recordId))
			setPromptDeleteDNSRecordData(null)

			deleteDomainDNSRecord(domain.id, recordId)
				.then(res => {
					handleRedirect(res, routerNavigateFn)
					setDataTableRefresh(true)
					setDataTableRefreshCallbacks(callbacks => append(callbacks, () => toggleDNSRecordEdit(recordId)))
				})
				.catch(e => {
					handleRedirect(e.res, routerNavigateFn)
					console.error('Unable to delete DNS record ID %s:', recordId, e)
					setMessages(messages => messages.concat({
						key: 'dns_record_delete_error_' + Math.round(new Date() / 1000).toString(),
						dismissable: true,
						type: 'error',
						icon: 'exclamation circle',
						title: 'En feil oppstod ved sletting av DNS-peker',
						content: e.message
					}))
					setDeletingDNSRecords(deletingDNSRecords => deletingDNSRecords.filter(id => id !== recordId))
				})
		}

		return <Modal
			open
			onClose={() => setPromptDeleteDNSRecordData(null)}
		>
			<Modal.Header>Bekreft sletting av DNS-peker</Modal.Header>
			<Modal.Content>
				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Type</Table.HeaderCell>
							<Table.HeaderCell>Navn</Table.HeaderCell>
							<Table.HeaderCell>Data</Table.HeaderCell>
							<Table.HeaderCell>TTL</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						<Table.Row>{renderDNSRecordRow(promptDeleteDNSRecordData, false).map((cellContent, i) => <Table.Cell key={i}>{cellContent}</Table.Cell>)}</Table.Row>
					</Table.Body>
				</Table>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={preventDefault(() => setPromptDeleteDNSRecordData(null))}>
					Avbryt
				</Button>
				<Button negative onClick={preventDefault(() => deleteRecord(promptDeleteDNSRecordData.id))}>
					Slett
				</Button>
			</Modal.Actions>
		</Modal>
	}

	const apiSearch = useCallback(async (query, options, requestOptions) => {
		const res = await getDomainDNSRecords(domain.id, query, options, requestOptions)
		handleRedirect(res, routerNavigateFn)
		return res
	}, [domain, routerNavigateFn])

	return <>
		<Segment vertical padded="very">
			<Container>
				<DataTable
					className="kit-datatable-dns"
					format="cloudflare"
					striped={false}
					compact
					columns={[
						{ key: 'type', text: 'Type', visible: true, sortable: true, searchable: true, width: 2, filterType: filterTypes.search },
						{ key: 'name', text: 'Navn', visible: true, sortable: true, searchable: true, width: 4, singleLineNoOverflow: true, filterType: filterTypes.search },
						{ key: 'content', text: 'Data', visible: true, sortable: true, searchable: true, width: 6, singleLineNoOverflow: true, filterType: filterTypes.search },
						{ key: 'ttl', text: 'TTL', visible: true, sortable: true, searchable: false, width: 2 },
						{ key: 4, text: '', visible: true, width: 2 },
					]}
					apiSearch={apiSearch}
					dirty={Object.keys(dnsRecordEdits).filter(key => key !== 'new').length > 0}
					onCancelEdit={() => setDNSRecordEdits({
						'new': newDNSRecordData[lastDNSRecordAddType],
					})}
					onCancelAdd={() => setDNSRecordEdits(dnsRecordEdits => append(dnsRecordEdits, {
						'new': newDNSRecordData[lastDNSRecordAddType],
					}))}
					renderRow={(data, columns, index) => renderDNSRecordRow(data)}
					renderEditRow={(data, columns, index) => renderDNSRecordForm(data)}
					isRowBeingEdited={(data, columns) => editingDNSRecords.includes(data.id)}
					extraPanes={[
						{
							key: 'add',
							icon: 'add',
							title: 'Opprett DNS-peker',
							render: () => renderDNSRecordForm(null),
						},
						{
							key: 'importexport',
							icon: 'exchange',
							title: 'Import/eksport',
							render: () => renderDNSRecordImportExportForm(),
						}
					]}
					activePane={activePane}
					onChangeActivePane={pane => {
						setActivePane(pane)
						if (pane === 'add') {
							toggleDNSRecordEdit('new')
						}
					}}
					refresh={dataTableRefresh}
					onRefresh={() => {
						dataTableRefreshCallbacks.forEach(callback => callback())
						setDataTableRefreshCallbacks([])
						setDataTableRefresh(false)
					}}
				/>
			</Container>
		</Segment>

		{renderDNSRecordDeleteModal()}
	</>
}