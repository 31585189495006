import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Icon, Message } from "semantic-ui-react"
import { navigate } from '../helpers'

export default function TaskExecutionLink({ id, header, onDismiss }) {
	const routerNavigateFn = useNavigate()

	return <Message success onDismiss={onDismiss}>
		{typeof header === 'string' || (header !== null && typeof header === 'object' && React.isValidElement(header)) ? <Message.Header>{header}</Message.Header> : null}
		<Message.Content>
			<p>Satt i gang ny bakgrunnsjobb #{id}.</p>
			<p>
				<Button
					as="a"
					color="green"
					href={'/tasks/executions/' + id}
					onClick={e => {
						if (!e.defaultPrevented) e.preventDefault()
						const { ctrlKey, shiftKey } = e
						const target = (ctrlKey || shiftKey) ? '_blank' : '_self'
						navigate('/tasks/executions/' + id, target, {}, routerNavigateFn)
					}}
				>
					Se fremdrift <Icon name="arrow right" />
				</Button>
			</p>
		</Message.Content>
	</Message>
}
