import React from 'react'
import { Segment, Header, Container, Menu, Placeholder } from 'semantic-ui-react'
import { renderOrgId, navigate, preventDefault, isObject } from '../../../helpers'
import { getAccount } from '../../../api/accounts'
import Link from '../../../components/link'
import { Await, Outlet, defer, useMatches, useNavigate, useRouteLoaderData } from 'react-router-dom'
import { preloadedEntity } from '../../root'
import Navigate from '../../../components/navigate'
import { Response } from '../../../api'

export async function loader({ params: { accountId } }) {
	if (isObject(preloadedEntity.current) && preloadedEntity.current.id === accountId) {
		const account = new Response({data: preloadedEntity.current})
		preloadedEntity.current = null
		return { accountId, account }
	}
	const account = getAccount(accountId).catch(e => {
		console.error('Unable to load account:', e)
		return null
	})
	return defer({ accountId, account })
}

export default function AccountPage({ children }) {
	const { accountId, account } = useRouteLoaderData('account')
	const routerNavigateFn = useNavigate()
	const matches = useMatches()
	const activeIds = matches.map(match => match.id)

	return <>
		<React.Suspense>
			<Await resolve={account}>
				{res => isObject(res) && res.redirect !== null ? <Navigate to={res.redirect} /> : null}
			</Await>
		</React.Suspense>

		<Segment vertical padded="very">
			<Container>
				<Header size="huge">
					<React.Suspense
						fallback={<Placeholder>
							<Placeholder.Header>
								<Placeholder.Line />
							</Placeholder.Header>
						</Placeholder>}
					>
						<Await resolve={account}>{res => isObject(res) && isObject(res.data) ? res.data.name : null}</Await>
					</React.Suspense>
				</Header>
				<React.Suspense
					fallback={<Placeholder>
						<Placeholder.Line />
						<Placeholder.Line />
						<Placeholder.Line />
						<Placeholder.Line />
						<Placeholder.Line />
						<Placeholder.Line />
					</Placeholder>}
				>
					<Await resolve={account}>{res => {
						if (!isObject(res) || !isObject(res.data)) return null
						const { data: account } = res
						return <>
							{account.org_id ? <p>Org.nr: {renderOrgId(account.org_id)}</p> : null}
							<p>
								{account.phone.number ? <>
									<Link href={account.phone.uri} target="_blank">{account.phone.number}</Link>
									<br/>
								</> : null}
								<Link href={'mailto:' + account.email} target="_blank">{account.email}</Link>
							</p>
							{isObject(account.address.invoice) && typeof account.address.invoice.street_address === 'string' ? <p>
								{account.address.invoice.street_address}<br/>
								{account.address.invoice.postal_code} {account.address.invoice.city}<br/>
								{account.address.invoice.country.name}
							</p> : null}
						</>
					}}</Await>
				</React.Suspense>
			</Container>
		</Segment>

		<Menu attached tabular>
			<Container>
				<Menu.Item
					link
					as="a"
					href={'/accounts/' + encodeURIComponent(accountId) + '/users'}
					onClick={preventDefault(e => navigate('/accounts/' + encodeURIComponent(accountId) + '/users', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
					active={activeIds.includes('accountUsers')}
					content="Brukere"
				/>
				<Menu.Item
					link
					as="a"
					href={'/accounts/' + encodeURIComponent(accountId) + '/domains'}
					onClick={preventDefault(e => navigate('/accounts/' + encodeURIComponent(accountId) + '/domains', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
					active={activeIds.includes('accountDomains')}
					content="Domener"
				/>
				<Menu.Item
					link
					as="a"
					href={'/accounts/' + encodeURIComponent(accountId) + '/tickets'}
					onClick={preventDefault(e => navigate('/accounts/' + encodeURIComponent(accountId) + '/tickets', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
					active={activeIds.includes('accountTickets')}
					content="Saker"
				/>
				<Menu.Item
					link
					as="a"
					href={'/accounts/' + encodeURIComponent(accountId) + '/services'}
					onClick={preventDefault(e => navigate('/accounts/' + encodeURIComponent(accountId) + '/services', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
					active={activeIds.includes('accountServices')}
					content="Tjenester"
				/>
			</Container>
		</Menu>

		<Segment vertical padded="very">
			<Container>
				{children}
				<Outlet />
			</Container>
		</Segment>
	</>
}