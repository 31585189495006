import { Response } from '.'
import { buildQuery } from '../helpers'

export const getPostalCodeSupportedCountryCodes = [
	'NO', // Norway
	'DK', // Denmark
	'SE', // Sweden
	'FI', // Finland
	'NL', // Netherlands
	'DE', // Germany
	'US', // United States
	'BE', // Belgium
	'FO', // Faroe Islands
	'GL', // Greenland
	'IS', // Iceland
	'SJ', // Svalbard and Jan Mayen
]

// GET /api/mybring/countries/:country_code/postal-codes/:postal_code
export async function getPostalCode(postal_code, country_code = 'NO', requestOptions = {}) {
	return Response.request('/api/mybring/countries/' + encodeURIComponent(country_code) + '/postal-codes/' + encodeURIComponent(postal_code), null, requestOptions)
}

// GET /api/mybring/countries/:country_code/pickup-points/:postal_code
export async function getPickupPoints(postal_code, country_code = 'NO', query = null, requestOptions = {}) {
	return Response.request('/api/mybring/countries/' + encodeURIComponent(country_code) + '/pickup-points/' + encodeURIComponent(postal_code) + buildQuery(query), null, requestOptions)
}

// POST /api/mybring/create-consignment
export async function createConsignment(data, requestOptions = {}) {
	return Response.request('/api/mybring/create-consignment', data, Object.assign({ method: 'POST' }, requestOptions))
}

// POST /api/mybring/estimate-consignment
export async function estimateConsignment(data, requestOptions = {}) {
	return Response.request('/api/mybring/estimate-consignment', data, Object.assign({ method: 'POST' }, requestOptions))
}