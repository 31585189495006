import { Response, PaginatedResponse } from '..'
import { buildQuery } from '../../helpers'

// GET /api/autotask/subscriptions
// POST /api/autotask/subscriptions/search
export async function getSubscriptions(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/autotask/subscriptions' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/autotask/subscriptions/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/autotask/subscriptions
export async function createSubscription(data, requestOptions = {}) {
	return Response.request('/api/autotask/subscriptions', data, requestOptions)
}

// GET /api/autotask/subscriptions/:id
export async function getSubscription(id, requestOptions = {}) {
	return Response.request('/api/autotask/subscriptions/' + encodeURIComponent(id), null, requestOptions)
}

// PUT /api/autotask/subscriptions/:id
export async function updateSubscription(id, data, requestOptions = {}) {
	return Response.request('/api/autotask/subscriptions/' + encodeURIComponent(id), data, Object.assign({ method: 'PUT' }, requestOptions))
}

// DELETE /api/autotask/subscriptions/:id
export async function deleteSubscription(id, requestOptions = {}) {
	return Response.request('/api/autotask/subscriptions/' + encodeURIComponent(id), null, Object.assign({ method: 'DELETE' }, requestOptions))
}

export const PERIOD_TYPE_ONE_TIME = 1;
export const PERIOD_TYPE_MONTHLY = 2;
export const PERIOD_TYPE_QUARTERLY = 3;
export const PERIOD_TYPE_SEMI_ANNUAL = 4;
export const PERIOD_TYPE_YEARLY = 5;

export const STATUS_ACTIVE = 1;
export const STATUS_CANCELLED = 2;
export const STATUS_INACTIVE = 3;