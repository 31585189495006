import React, { useCallback, useState } from 'react'
import { Segment, Header, Container, Button, Popup, Icon, Grid } from 'semantic-ui-react'
import Link from '../../../components/link'
import DataTable from '../../../components/data_table'
import { getDomain, getDomains } from '../../../api/mail1/domains'
import { formatNumber, handleRedirect, isObject, navigate, preventDefault } from '../../../helpers'
import { defer, useNavigate } from 'react-router-dom'
import { preloadedEntity, usePreloadedEntityDispatcher } from '../../root'
import { Response } from '../../../api'

export async function loader({ params: { domain } }) {
	if (isObject(preloadedEntity.current) && preloadedEntity.current.domain === domain) {
		const kitmailDomain = new Response({data: preloadedEntity.current})
		preloadedEntity.current = null
		return { domain, kitmailDomain }
	}
	const kitmailDomain = getDomain(domain).catch(e => {
		console.error('Unable to load domain:', e)
		return null
	})
	return defer({ domain, kitmailDomain })
}

export default function KitmailDomainsPage() {
	const [dataInfo, setDataInfo] = useState(null)
	const routerNavigateFn = useNavigate()
	const preloadEntity = usePreloadedEntityDispatcher()

	const apiSearch = useCallback(async (query, options, requestOptions) => {
		const res = await getDomains(query, options, requestOptions)
		handleRedirect(res, routerNavigateFn)
		if (query === null) {
			setDataInfo(res.data_info)
		}
		return res
	}, [routerNavigateFn])

	return <>
		<Segment vertical padded="very">
			<Container>
				<Grid stackable>
					<Grid.Row>
						<Grid.Column width={10}>
							<Header size="huge">
								KitMail-domener
								<Header.Subheader>Liste over KitMail-domener du kan administrere</Header.Subheader>
							</Header>
						</Grid.Column>
						<Grid.Column width={6} textAlign="right">
							<Button
								as="a"
								href="/kitmail/domains/new"
								onClick={preventDefault(e => navigate('/kitmail/domains/new', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
								color="orange"
								label={dataInfo ? formatNumber(dataInfo.count) : '0'}
								labelPosition="right"
								content={<><Icon name="add" /> Opprett nytt domene</>}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<p></p>

				<p><Link href="/kitmail/users">Se brukere fra alle domener &rarr;</Link>&emsp;&middot;&emsp;<Link href="/kitmail/forwardings">Se videresendinger fra alle domener &rarr;</Link></p>

				<DataTable
					columns={[
						{ key: 'domain', text: 'Domene', width: 8, visible: true, sortable: true, searchable: true },
						{ key: 'external', text: 'Type domene', width: 4, visible: true, sortable: true },
						{ key: 2, text: '', width: 4, visible: true },
					]}
					defaultOrder={{'domain': 'ASC'}}
					apiSearch={apiSearch}
					onClickRow={(data, index, { ctrlKey, shiftKey }) => {
						// preload domain data
						preloadEntity(data)
						// navigate to domain
						const url = '/kitmail/domains/' + data.domain
						const target = (ctrlKey || shiftKey) ? '_blank' : '_self'
						navigate(url, target, {}, routerNavigateFn)
					}}
					renderRow={data => [
						data.domain,
						data.external ? 'Eksternt' : 'Internt',
						<div className="text-ha-right">
							<Button.Group size="mini" compact>
								<Popup
									inverted
									content="Endre domene"
									trigger={<Button
										as="a"
										icon="edit"
										href={'/kitmail/domains/' + data.domain + '/edit'}
										onClick={preventDefault(e => {
											// preload domain data
											preloadEntity(data)
											// navigate to domain edit page
											navigate('/kitmail/domains/' + data.domain + '/edit', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn)
										})}
									/>}
								/>
								<Popup
									inverted
									content="Slett domene"
									trigger={<Button
										as="a"
										negative
										icon="delete"
										href={'/kitmail/domains/' + data.domain + '/delete'}
										onClick={preventDefault(e => {
											// preload domain data
											preloadEntity(data)
											// navigate to domain delete page
											navigate('/kitmail/domains/' + data.domain + '/delete', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn)
										})}
									/>}
								/>
							</Button.Group>
						</div>
					]}
				/>
			</Container>
		</Segment>
	</>
}