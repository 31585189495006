import { Response, PaginatedResponse } from '..'
import { buildQuery } from '../../helpers'

// GET /api/autotask/tickets
// POST /api/autotask/tickets/search
export async function getTickets(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/autotask/tickets' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/autotask/tickets/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/autotask/tickets
export async function createTicket(data, requestOptions = {}) {
	return Response.request('/api/autotask/tickets', data, requestOptions)
}

// GET /api/autotask/tickets/:id
export async function getTicket(id, requestOptions = {}) {
	return Response.request('/api/autotask/tickets/' + encodeURIComponent(id), null, requestOptions)
}

// PUT /api/autotask/tickets/:id
export async function updateTicket(id, data, requestOptions = {}) {
	return Response.request('/api/autotask/tickets/' + encodeURIComponent(id), data, Object.assign({ method: 'PUT' }, requestOptions))
}

export const APPROVAL_STATUS_NOT_ASSIGNED = 1;
export const APPROVAL_STATUS_ASSIGNED = 2;
export const APPROVAL_STATUS_REQUESTED = 3;
export const APPROVAL_STATUS_PARTIALLY_APPROVED = 4;
export const APPROVAL_STATUS_APPROVED = 5;
export const APPROVAL_STATUS_REJECTED = 6;

export const APPROVAL_TYPE_ALL_MUST_APPROVE = 1;
export const APPROVAL_TYPE_ONE_MUST_APPROVE = 2;

export const PERSON_TYPE_RESOURCE = 1;
export const PERSON_TYPE_CONTACT = 2;

export const RATING_HAPPY = 1;
export const RATING_NEUTRAL = 2;
export const RATING_UNHAPPY = 3;

export const ISSUE_TYPE_SERVER = 7; // Server
export const SUB_ISSUE_TYPE_SERVER_SLOWNESS = 124; // Tregheter
export const SUB_ISSUE_TYPE_SERVER_DATABASE = 125; // Database
export const SUB_ISSUE_TYPE_SERVER_CHANGE_AD = 213; // Endring (AD)
export const SUB_ISSUE_TYPE_SERVER_HARDWARE = 217; // Hardware
export const SUB_ISSUE_TYPE_SERVER_SOFTWARE = 218; // Programvare
export const SUB_ISSUE_TYPE_SERVER_CHANGE_GPO = 338; // Endring (GPO)
export const SUB_ISSUE_TYPE_SERVER_CHANGE_OS = 339; // Endring (OS)
export const SUB_ISSUE_TYPE_SERVER_CHANGE_OTHER = 340; // Endring (annet)
export const SUB_ISSUE_TYPE_SERVER_SETUP = 341; // Oppsett
export const SUB_ISSUE_TYPE_SERVER_DISKSPACE = 345; // Diskplass
export const SUB_ISSUE_TYPE_SERVER_DOWN = 346; // Utilgjengelig
export const SUB_ISSUE_TYPE_SERVER_FILE_SYSTEM = 351; // Filsystem
export const SUB_ISSUE_TYPE_SERVER_SECURITY = 391; // Antivirus/sikkerhet

export const ISSUE_TYPE_COMPUTER = 10; // Datamaskin
export const SUB_ISSUE_TYPE_COMPUTER_SOFTWARE_OTHER = 104; // Programvare (annet)
export const SUB_ISSUE_TYPE_COMPUTER_SETUP_NEW_PC = 105; // Oppsett (ny PC)
export const SUB_ISSUE_TYPE_COMPUTER_VIRUS_OTHER = 108; // Virus (annet)
export const SUB_ISSUE_TYPE_COMPUTER_SLOWNESS_OTHER = 109; // Tregheter (annet)
export const SUB_ISSUE_TYPE_COMPUTER_HARDWARE_COMPUTER = 152; // Hardware (datamaskin)
export const SUB_ISSUE_TYPE_COMPUTER_OS_OTHER = 153; // OS (annet)
export const SUB_ISSUE_TYPE_COMPUTER_DISKSPACE = 275; // Diskplass
export const SUB_ISSUE_TYPE_COMPUTER_SETUP_REINSTALL = 308; // Oppsett (reinstallasjon)
export const SUB_ISSUE_TYPE_COMPUTER_VIRUS_RANSOMWARE = 309; // Virus (løsepengevirus)
export const SUB_ISSUE_TYPE_COMPUTER_SLOWNESS_OUTLOOK = 310; // Tregheter (Outlook)
export const SUB_ISSUE_TYPE_COMPUTER_SLOWNESS_HARDWARE = 311; // Tregheter (hardware)
export const SUB_ISSUE_TYPE_COMPUTER_VIRUS_ADWARE = 313; // Virus (reklame)
export const SUB_ISSUE_TYPE_COMPUTER_OS_HELP = 314; // OS (veiledning)
export const SUB_ISSUE_TYPE_COMPUTER_HARDWARE_ACCESSORIES = 347; // Hardware (tilbehør)
export const SUB_ISSUE_TYPE_COMPUTER_SOFTWARE_ONEDRIVE = 353; // Programvare (OneDrive)
export const SUB_ISSUE_TYPE_COMPUTER_PRINTER_INSTALL = 355; // Printer-installasjon
export const SUB_ISSUE_TYPE_COMPUTER_OS_BLUESCREEN = 380; // OS (bluescreen)
export const SUB_ISSUE_TYPE_COMPUTER_SOFTWARE_BITDEFENDER = 383; // Programvare (Bitdefender)
export const SUB_ISSUE_TYPE_COMPUTER_SECURITY = 390; // Antivirus/sikkerhet

export const ISSUE_TYPE_NETWORK = 11; // Nettverk
export const SUB_ISSUE_TYPE_NETWORK_CHANGE_ROUTES = 112; // Endring (ruter)
export const SUB_ISSUE_TYPE_NETWORK_CABLING = 184; // Kabling
export const SUB_ISSUE_TYPE_NETWORK_CHANGE_OTHER = 185; // Endring (annet)
export const SUB_ISSUE_TYPE_NETWORK_DOWN_OTHER = 186; // Nede (annet)
export const SUB_ISSUE_TYPE_NETWORK_CHANGE_ISP = 188; // Bytte ISP
export const SUB_ISSUE_TYPE_NETWORK_DOWN_ISP = 189; // Nede (ISP)
export const SUB_ISSUE_TYPE_NETWORK_SLOWNESS = 192; // Tregheter
export const SUB_ISSUE_TYPE_NETWORK_VPN_OTHER = 198; // VPN (annet)
export const SUB_ISSUE_TYPE_NETWORK_CHANGE_SWITCH = 199; // Endring (switch)
export const SUB_ISSUE_TYPE_NETWORK_WIFI = 253; // WiFi
export const SUB_ISSUE_TYPE_NETWORK_DOWN_HARDWARE = 315; // Nede (hardware)
export const SUB_ISSUE_TYPE_NETWORK_DOWN_CONFIG = 316; // Nede (konfigurasjon)
export const SUB_ISSUE_TYPE_NETWORK_VPN_SETUP = 317; // VPN (oppsett)
export const SUB_ISSUE_TYPE_NETWORK_VPN_HELP = 318; // VPN (veiledning)
export const SUB_ISSUE_TYPE_NETWORK_SETUP = 354; // Oppsett

export const ISSUE_TYPE_VARSEL = 14; // RMM varsel
export const SUB_ISSUE_TYPE_VARSEL_ANTIVIRUS_STATUS_MONITOR = 222; // Antivirus Status Monitor
export const SUB_ISSUE_TYPE_VARSEL_BACKUP_MONITOR = 223; // Backup Monitor
export const SUB_ISSUE_TYPE_VARSEL_COMPONENT_MONITOR = 224; // Component Monitor
export const SUB_ISSUE_TYPE_VARSEL_CPU_MONITOR = 225; // CPU Monitor
export const SUB_ISSUE_TYPE_VARSEL_DISK_USAGE_MONITOR = 226; // Disk Usage Monitor
export const SUB_ISSUE_TYPE_VARSEL_END_CLIENT_AGENT = 227; // End Client Agent
export const SUB_ISSUE_TYPE_VARSEL_EVENT_LOG_MONITOR = 228; // Event Log Monitor
export const SUB_ISSUE_TYPE_VARSEL_FILE_OR_FOLDER_SIZE_MONITOR = 229; // File/Folder Size Monitor
export const SUB_ISSUE_TYPE_VARSEL_HARDWARE_MONITOR = 230; // Hardware Monitor
export const SUB_ISSUE_TYPE_VARSEL_ONLINE_STATUS_MONITOR = 231; // Online Status Monitor
export const SUB_ISSUE_TYPE_VARSEL_PATCH_MONITOR = 232; // Patch Monitor
export const SUB_ISSUE_TYPE_VARSEL_PING_MONITOR = 233; // Ping Monitor
export const SUB_ISSUE_TYPE_VARSEL_PROCESS_MONITOR = 234; // Process Monitor
export const SUB_ISSUE_TYPE_VARSEL_SECURITY_MANAGEMENT_MONITOR = 235; // Security Management Monitor
export const SUB_ISSUE_TYPE_VARSEL_SERVICE_MONITOR = 236; // Service Monitor
export const SUB_ISSUE_TYPE_VARSEL_SNMP_MONITOR = 237; // SNMP Monitor
export const SUB_ISSUE_TYPE_VARSEL_SOFTWARE_MONITOR = 238; // Software Monitor
export const SUB_ISSUE_TYPE_VARSEL_TEMPERATURE_SENSOR_MONITOR = 239; // Temperature Sensor Monitor
export const SUB_ISSUE_TYPE_VARSEL_WINDOWS_PERFORMANCE_MONITOR = 240; // Windows Performance Monitor
export const SUB_ISSUE_TYPE_VARSEL_WMI_MONITOR = 241; // WMI Monitor
export const SUB_ISSUE_TYPE_VARSEL_PRINTER_MONITOR = 242; // Printer Monitor
export const SUB_ISSUE_TYPE_VARSEL_MEMORY_MONITOR = 243; // Memory Monitor

export const ISSUE_TYPE_OTHER = 16; // Annet
export const SUB_ISSUE_TYPE_OTHER_OTHER = 220; // Annet

export const ISSUE_TYPE_CELLULAR = 19; // Telefoni
export const SUB_ISSUE_TYPE_CELLULAR_PROVIDER_SUBSCRIPTION = 250; // Operatør (abonnement)
export const SUB_ISSUE_TYPE_CELLULAR_HARDWARE = 251; // Hardware
export const SUB_ISSUE_TYPE_CELLULAR_APP = 252; // App
export const SUB_ISSUE_TYPE_CELLULAR_OS_OTHER = 326; // OS (annet)
export const SUB_ISSUE_TYPE_CELLULAR_OS_HELP = 327; // OS (veiledning)
export const SUB_ISSUE_TYPE_CELLULAR_PROVIDER_OWNER_CHANGE = 328; // Operatør (eierskifte)
export const SUB_ISSUE_TYPE_CELLULAR_PROVIDER_TRANSFER = 329; // Operatør (portering)
export const SUB_ISSUE_TYPE_CELLULAR_PROVIDER_NETWORK = 330; // Operatør (mobilnett)

export const ISSUE_TYPE_PRINTER = 20; // Printer
export const SUB_ISSUE_TYPE_PRINTER_HARDWARE = 255; // Hardware
export const SUB_ISSUE_TYPE_PRINTER_SUPPLIES_TONER_OR_INK = 256; // Rekvisita (toner/blekk)
export const SUB_ISSUE_TYPE_PRINTER_CHANGE_OTHER = 257; // Endring (annet)
export const SUB_ISSUE_TYPE_PRINTER_PRINT_OTHER = 258; // Utskrift (annet)
export const SUB_ISSUE_TYPE_PRINTER_SUPPLIES_OTHER = 331; // Rekvisita (annet)
export const SUB_ISSUE_TYPE_PRINTER_PRINT_CLIENT = 332; // Utskrift (klient)
export const SUB_ISSUE_TYPE_PRINTER_PRINT_NETWORK = 333; // Utskrift (nettverk)
export const SUB_ISSUE_TYPE_PRINTER_CHANGE_ADRESS_BOOK = 334; // Endring (adressebok)
export const SUB_ISSUE_TYPE_PRINTER_CHANGE_SCANTOMAIL = 335; // Endring (scan-to-mail)
export const SUB_ISSUE_TYPE_PRINTER_CHANGE_NETWORK = 336; // Endring (nettverk)
export const SUB_ISSUE_TYPE_PRINTER_SETUP = 337; // Oppsett
export const SUB_ISSUE_TYPE_PRINTER_DOWN = 364; // Utilgjengelig

export const ISSUE_TYPE_BACKUP = 21; // Backup
export const SUB_ISSUE_TYPE_BACKUP_SETUP = 259; // Oppsett
export const SUB_ISSUE_TYPE_BACKUP_CHANGE = 260; // Endring
export const SUB_ISSUE_TYPE_BACKUP_RESTORE = 261; // Gjenoppretting
export const SUB_ISSUE_TYPE_BACKUP_QUOTA_FULL = 360; // Kvote full
export const SUB_ISSUE_TYPE_BACKUP_ISSUE = 363; // Feil

export const ISSUE_TYPE_WEB = 22; // Web
export const SUB_ISSUE_TYPE_WEB_CHANGE_CONTENT = 262; // Endring (innhold)
export const SUB_ISSUE_TYPE_WEB_SETUP = 263; // Oppsett
export const SUB_ISSUE_TYPE_WEB_DOWN = 264; // Nede
export const SUB_ISSUE_TYPE_WEB_CHANGE_OTHER = 265; // Endring (annet)
export const SUB_ISSUE_TYPE_WEB_DELETION = 342; // Sletting
export const SUB_ISSUE_TYPE_WEB_ISSUE = 343; // Feil
export const SUB_ISSUE_TYPE_WEB_HELP_OTHER = 357; // Veiledning (annet)
export const SUB_ISSUE_TYPE_WEB_HELP_IVO = 358; // Veiledning (iVo)
export const SUB_ISSUE_TYPE_WEB_HELP_WORDPRESS = 359; // Veiledning (WordPress)
export const SUB_ISSUE_TYPE_WEB_MAINTENANCE = 382; // Vedlikehold

export const ISSUE_TYPE_EMAIL = 24; // E-post
export const SUB_ISSUE_TYPE_EMAIL_CLIENT_NETWORK_ISSUE = 269; // Klient (nettverksproblem)
export const SUB_ISSUE_TYPE_EMAIL_SPAM_AUTOTASK = 270; // Spam (autotask)
export const SUB_ISSUE_TYPE_EMAIL_CHANGE_OTHER = 272; // Endring (annet)
export const SUB_ISSUE_TYPE_EMAIL_CLIENT_OTHER = 273; // Klient (annet)
export const SUB_ISSUE_TYPE_EMAIL_MAILRISK = 303; // MailRisk
export const SUB_ISSUE_TYPE_EMAIL_SPAM_TARGETED = 304; // Spam (målrettet)
export const SUB_ISSUE_TYPE_EMAIL_SPAM_NON_TARGETED = 305; // Spam (ikke målrettet)
export const SUB_ISSUE_TYPE_EMAIL_CLIENT_ADD_OR_REMOVE_ACCOUNT = 306; // Klient (fjerne/legge til konto)
export const SUB_ISSUE_TYPE_EMAIL_CHANGE_ANTISPAM = 307; // Endring (anti-spam)
export const SUB_ISSUE_TYPE_EMAIL_TRACKING = 365; // E-postsporing

export const ISSUE_TYPE_USERADMIN = 25; // Brukeradministrasjon
export const SUB_ISSUE_TYPE_USERADMIN_NEW_USER = 276; // Ny bruker
export const SUB_ISSUE_TYPE_USERADMIN_REMOVE_USER = 277; // Slettet bruker
export const SUB_ISSUE_TYPE_USERADMIN_OTHER_CHANGE = 278; // Annen endring
export const SUB_ISSUE_TYPE_USERADMIN_COMPROMISED_ACCOUNT = 287; // Kompromittert konto
export const SUB_ISSUE_TYPE_USERADMIN_RESET_PASSWORD_OTHER = 288; // Tilbakestilt passord (annet)
export const SUB_ISSUE_TYPE_USERADMIN_RESET_PASSWORD_MS365 = 289; // Tilbakestilt passord (MS365)
export const SUB_ISSUE_TYPE_USERADMIN_RESET_PASSWORD_IVO = 290; // Tilbakestilt passord (Ivo)
export const SUB_ISSUE_TYPE_USERADMIN_RESET_PASSWORD_AD = 291; // Tilbakestilt passord (AD)
export const SUB_ISSUE_TYPE_USERADMIN_CONTACT_INFO = 292; // Kontaktinformasjon
export const SUB_ISSUE_TYPE_USERADMIN_PERMISSIONS_OTHER = 293; // Rettigheter (annet)
export const SUB_ISSUE_TYPE_USERADMIN_GROUPS_OTHER = 294; // Grupper (annet)
export const SUB_ISSUE_TYPE_USERADMIN_GROUPS_VPN = 295; // Grupper (VPN)
export const SUB_ISSUE_TYPE_USERADMIN_GROUPS_EMAIL_LIST = 296; // Grupper (e-postliste)
export const SUB_ISSUE_TYPE_USERADMIN_RESET_PASSWORD_HOSTING = 297; // Tilbakestilt passord (hosting)
export const SUB_ISSUE_TYPE_USERADMIN_RESET_PASSWORD_WORDPRESS = 298; // Tilbakestilt passord (Wordpress)
export const SUB_ISSUE_TYPE_USERADMIN_LICENSE_NEW = 348; // Lisenser: Ny
export const SUB_ISSUE_TYPE_USERADMIN_LICENSE_CHANGE = 349; // Lisenser: Endring
export const SUB_ISSUE_TYPE_USERADMIN_LICENSE_REMOVE = 350; // Lisenser: Slettet
export const SUB_ISSUE_TYPE_USERADMIN_RESET_MFA = 381; // Tilbakestilt MFA

export const ISSUE_TYPE_DOMAIN = 28; // Domene
export const SUB_ISSUE_TYPE_DOMAIN_REGISTER = 319; // Registrering
export const SUB_ISSUE_TYPE_DOMAIN_TRANSFER_OUT = 320; // Overføring (ut fra oss)
export const SUB_ISSUE_TYPE_DOMAIN_CHANGE_CONTACT_INFO = 321; // Endring (kontaktinfo)
export const SUB_ISSUE_TYPE_DOMAIN_CHANGE_NAMESERVERS = 322; // Endring (navnetjenere)
export const SUB_ISSUE_TYPE_DOMAIN_TRANSFER_IN = 323; // Overføring (inn til oss)
export const SUB_ISSUE_TYPE_DOMAIN_CHANGE_OWNER = 324; // Endring (eierskifte)
export const SUB_ISSUE_TYPE_DOMAIN_CHANGE_DNS = 325; // Endring (DNS)
export const SUB_ISSUE_TYPE_DOMAIN_EXPIRE = 356; // Oppsigelse

export const ISSUE_TYPE_MANAGEMENT = 30; // Administrasjon
export const SUB_ISSUE_TYPE_MANAGEMENT_IDEA = 368; // Idébank
export const SUB_ISSUE_TYPE_MANAGEMENT_CONTRACT_RENEWAL = 369; // Kontrakt - Fornyelse
export const SUB_ISSUE_TYPE_MANAGEMENT_CUSTOMER_NEW = 370; // Kunde - Ny
export const SUB_ISSUE_TYPE_MANAGEMENT_CUSTOMER_CHANGE = 371; // Kunde - Endring
export const SUB_ISSUE_TYPE_MANAGEMENT_CUSTOMER_CANCELLATION = 372; // Kunde - Terminering
export const SUB_ISSUE_TYPE_MANAGEMENT_ACCOUNTING_PAYMENTS = 373; // Regnskap - Betaling / betalingsutsettelse
export const SUB_ISSUE_TYPE_MANAGEMENT_ACCOUNTING_INVOICES_AND_CREDIT = 374; // Regnskap - Faktura / kreditnota
export const SUB_ISSUE_TYPE_MANAGEMENT_ACCOUNTING_LEDGER = 375; // Regnskap - Kundereskontro
export const SUB_ISSUE_TYPE_MANAGEMENT_CONTRACT_NEW = 376; // Kontrakt - Ny
export const SUB_ISSUE_TYPE_MANAGEMENT_CONTRACT_CHANGE = 377; // Kontrakt - Endring
export const SUB_ISSUE_TYPE_MANAGEMENT_CONTRACT_CANCELLATION = 378; // Kontrakt - Terminering
export const SUB_ISSUE_TYPE_MANAGEMENT_ROUTINE_WORK = 379; // Rutinearbeid
export const SUB_ISSUE_TYPE_MANAGEMENT_SALES = 392; // Salg

export const ISSUE_TYPE_SAAS_DEFENSE = 31; // SaaS Defence
export const SUB_ISSUE_TYPE_SAAS_DEFENSE_PHISHING = 385; // Phishing
export const SUB_ISSUE_TYPE_SAAS_DEFENSE_MALWARE = 386; // Malware
export const SUB_ISSUE_TYPE_SAAS_DEFENSE_RESTORE = 387; // Gjenopprettet fra karantene
export const SUB_ISSUE_TYPE_SAAS_DEFENSE_OTHER = 388; // Annet
export const SUB_ISSUE_TYPE_SAAS_DEFENSE_SPAM = 389; // SPAM

export const MONITOR_TYPE_ANTIVIRUS = 23;
export const MONITOR_TYPE_BACKUP = 13;
export const MONITOR_TYPE_COMPONENT = 4;
export const MONITOR_TYPE_CPU = 2;
export const MONITOR_TYPE_DISKUSAGE = 10;
export const MONITOR_TYPE_EVENTLOG = 7;
export const MONITOR_TYPE_FILE_FOLDER_SIZE = 11;
export const MONITOR_TYPE_HARDWARE = 19;
export const MONITOR_TYPE_MEMORY = 3;
export const MONITOR_TYPE_NETWORK = 16;
export const MONITOR_TYPE_NETWORK_OFFLINE = 17;
export const MONITOR_TYPE_ONLINE_STATUS = 1;
export const MONITOR_TYPE_PATCH = 20;
export const MONITOR_TYPE_PRINTER_STATUS = 14;
export const MONITOR_TYPE_PROCESS = 5;
export const MONITOR_TYPE_RANSOMWARE = 24;
export const MONITOR_TYPE_SECURITY_CENTER = 9;
export const MONITOR_TYPE_SECURITY_MANAGEMENT = 12;
export const MONITOR_TYPE_SERVICE = 6;
export const MONITOR_TYPE_SNMP = 15;
export const MONITOR_TYPE_SOFTWARE = 8;
export const MONITOR_TYPE_TEMPERATURE_SENSOR = 18;
export const MONITOR_TYPE_THREAT_DETECTION = 25;
export const MONITOR_TYPE_WINDOWS_PERFORMANCE = 21;
export const MONITOR_TYPE_WMI = 22;

export const PRIORITY_HIGH = 1;
export const PRIORITY_MEDIUM = 2;
export const PRIORITY_LOW = 3;
export const PRIORITY_CRITICAL = 4;

export const QUEUE_ID_CUSTOMER_PORTAL = 5; // Kundeportal
export const QUEUE_ID_SALES = 6; // Salg
export const QUEUE_ID_MONITORING = 8; // Overvåkningsvarsler
export const QUEUE_ID_CUSTOMER_SERVICE_L1 = 29682833; // Kundeservice
export const QUEUE_ID_CUSTOMER_SERVICE_L2 = 29682969; // Kundeservice - 2. linje
export const QUEUE_ID_REPEATING_TICKETS = 29683354; // Repeterende saker
export const QUEUE_ID_MANAGEMENT = 29683378; // Administrasjon
export const QUEUE_ID_MERGED_TICKETS = 29683479; // Sammenslåtte saker
export const QUEUE_ID_MAILRISK = 29683481; // MailRisk
export const QUEUE_ID_SETUP = 29683482; // Oppsett
export const QUEUE_ID_IDEAS = 29683483; // Idébank

export const RMA_STATUS_REQUESTED = 1; // Requested
export const RMA_STATUS_AUTHORIZED = 2; // Authorized
export const RMA_STATUS_DENIED = 3; // Denied
export const RMA_STATUS_OPEN = 4; // Open
export const RMA_STATUS_ON_HOLD = 5; // On Hold
export const RMA_STATUS_WITHDRAWN = 6; // Withdrawn
export const RMA_STATUS_WAITING_FOR_CUSTOMER = 7; // Waiting for Customer
export const RMA_STATUS_IN_PROGRESS = 8; // In Progress
export const RMA_STATUS_FINISHED = 9; // Finished
export const RMA_STATUS_FINISHED_NOT_RETURNED = 10; // Finished (Not Returned)

export const RMA_TYPE_RETURN = 1;
export const RMA_TYPE_REPAIR = 2;
export const RMA_TYPE_REPLACE = 3;

export const SOURCE_INSOURCED = -2;
export const SOURCE_CLIENT_PORTAL = -1;
export const SOURCE_VOICE_MAIL = 1;
export const SOURCE_PHONE = 2;
export const SOURCE_EMAIL = 4;
export const SOURCE_WEB_PORTAL = 5;
export const SOURCE_IN_PERSON_OR_ONSITE = 6;
export const SOURCE_MONITORING_ALERT = 8;
export const SOURCE_VERBAL = 11;
export const SOURCE_WEBSITE = 12;
export const SOURCE_RECURRING = 13;
export const SOURCE_INTERNAL = 14;

export const STATUS_NEW = 1;
export const STATUS_COMPLETE = 5;
export const STATUS_WAITING_CUSTOMER = 7;
export const STATUS_IN_PROGRESS = 8;
export const STATUS_WAITING_MATERIALS = 9;
export const STATUS_DISPATCHED = 10;
export const STATUS_ESCALATE = 11;
export const STATUS_WAITING_VENDOR = 12;
export const STATUS_WORK_COMPLETE = 16;
export const STATUS_ON_HOLD = 17;
export const STATUS_CUSTOMER_NOTE_ADDED = 19;
export const STATUS_ONSITE = 20;

export const CATEGORY_DATTO_RMM_ALERT = 2; // Datto RMM Alert
export const CATEGORY_DATTO_ALERT = 4; // Datto Alert
export const CATEGORY_RMA = 5; // RMA
export const CATEGORY_KIT_STANDARD = 103; // KIT Standard
export const CATEGORY_TERMINATE_USER = 104; // Terminere bruker

export const TYPE_SERVICE_REQUEST = 1; // Service Request
export const TYPE_INCIDENT = 2; // Incident
export const TYPE_PROBLEM = 3; // Problem
export const TYPE_CHANGE_REQUEST = 4; // Change Request
export const TYPE_ALERT = 5; // Alert