import React from 'react'

export default function Logo({ title, className }) {
	return <div className={'kit-logo-container' + (className ? ' ' + className : '')}>
		{(() => {if (!title) return null; return <p className="kit-logo-title">{title}</p>})()}
		<svg className="kit-logo" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 670 101.9" alt="Konsept-IT">
			<g className="kit-logo-text">
				<path className="kit-logo-part" d="M186.4,15.7h-18.5l-26.8,25.7V15.7H128v73h13.1V57.8l28.1,30.9h17L151,49.8L186.4,15.7z"/>
				<path className="kit-logo-part" d="M224.6,13.6c-11,0-20.6,3.8-28.4,11.2c-7.9,7.5-11.9,16.6-11.9,27c0,10.8,3.9,20.2,11.7,27.8S213.3,91,224.3,91c11.2,0,20.7-3.8,28.4-11.4c7.7-7.6,11.6-16.9,11.6-27.9c0-10.4-4-19.4-11.8-26.9C244.8,17.4,235.4,13.6,224.6,13.6L224.6,13.6z M225,77.4c-7.7,0-14-2.4-19.3-7.3s-7.8-10.7-7.8-17.8c0-7,2.5-12.8,7.6-17.8s11.1-7.5,18.4-7.5c7.6,0,13.8,2.4,19,7.3s7.7,10.8,7.7,18c0,6.9-2.5,12.7-7.6,17.7S232,77.4,225,77.4L225,77.4z"/>
				<path className="kit-logo-part" d="M323.6,62.9l-51.2-52.7v78.5h13.2V42.2l51.2,52.4V15.7h-13.2V62.9z"/>
				<path className="kit-logo-part" d="M389.7,53.9c-1.7-2.1-3.9-4.1-6.5-5.8s-6.2-3.6-10.7-5.5c-4.3-1.8-7.3-3.5-9-5c-1.4-1.2-2.1-2.6-2.1-4.2c0-1.8,0.8-3.2,2.4-4.4c1.8-1.3,4.2-1.9,7.2-1.9c2.7,0,4.8,0.4,6.1,1.3c1.5,0.9,3.2,2.4,5.2,4.4l1.2,1.2l10.4-8.3l-1.1-1.4c-2.8-3.6-6.3-6.3-10.4-8s-8.2-2.6-12.2-2.6c-6.1,0-11.4,1.9-15.7,5.7c-4.4,3.8-6.6,8.6-6.6,14.1c0,2.3,0.4,4.5,1.2,6.5c0.8,2,2,4,3.7,6c1.6,2,3.6,3.7,5.9,5.1c2.2,1.4,5.3,2.9,9.2,4.5c3.7,1.5,6.5,2.9,8.1,4s2.9,2.5,3.9,4.2c1,1.6,1.5,3.2,1.5,4.7c0,2.6-0.9,4.7-2.8,6.4c-1.9,1.8-4.3,2.6-7.3,2.6c-6.4,0-11-3.5-14.1-10.6l-0.7-1.5l-13,3.6l0.7,1.9c2.5,6.7,6.2,11.7,10.9,15.1c4.7,3.4,10.3,5.1,16.4,5.1c6.4,0,12-2.2,16.5-6.6s6.8-9.8,6.8-16c0-2.7-0.4-5.2-1.3-7.6C392.7,58.3,391.4,56,389.7,53.9L389.7,53.9z"/>
				<path className="kit-logo-part" d="M402.1,88.7h42V75.6h-29V55.5h29v-13h-29V28.9h29V15.7h-42V88.7z"/>
				<path className="kit-logo-part" d="M489.7,21.7c-5.1-3.9-11.9-5.9-20.1-5.9h-15.8v72.9h13.1V60.2c9.8-0.1,17.4-2.2,22.5-6.3c5.5-4.4,8.3-9.9,8.3-16.4S495,25.7,489.7,21.7z M479.9,45.5c-2.8,1.6-7.2,2.5-13,2.7V27.7c8.9,0.2,12.5,1.7,13.9,3c2.2,2,3.3,4.3,3.3,7C484.1,41.3,482.7,43.9,479.9,45.5L479.9,45.5z"/>
				<path className="kit-logo-part" d="M503,28.9h16.5v59.8h13V28.9H549V15.7h-46V28.9z"/>
				<path className="kit-logo-part" d="M560.9,47.5h17.4v6.9h-17.4V47.5z"/>
				<path className="kit-logo-part" d="M603.6,15.7h13.1v72.9h-13.1V15.7z"/>
				<path className="kit-logo-part" d="M624,15.7v13.2h16.4v59.8h13.1V28.9H670V15.7H624z"/>
			</g>
			<g className="kit-logo-ball">
				<circle className="kit-logo-background" cx="51" cy="51" r="40"/>
				<path className="kit-logo-part" d="M51,0C22.8,0,0,22.8,0,51c0,28.1,22.8,51,51,51c28.1,0,51-22.8,51-51C101.9,22.8,79.1,0,51,0z M73.6,40.2l-20.5,13l-9-10.9l22-10.7C66.1,31.6,73.6,40.2,73.6,40.2z M45.4,19.1l15.3-7.7L43.3,41.8l-12.6,5.7L45.4,19.1z M33.4,80.8l-8.3-11.4l5.8-20.8l8.7,12.9L33.4,80.8z M62.7,84.9L40.3,61.8l12.5-7.7l24.1,17.1C76.9,71.2,62.7,84.9,62.7,84.9z"/>
			</g>
		</svg>
	</div>
}