import React, { useCallback } from 'react'
import { Segment, Header, Container } from 'semantic-ui-react'
import DataTable from '../../components/data_table'
import { getSMSGateway, getSMSGateways } from '../../api/sms'
import { filterTypes, handleRedirect, isObject, navigate } from '../../helpers'
import { defer, useNavigate } from 'react-router-dom'
import { preloadedEntity, usePreloadedEntityDispatcher } from '../root'
import { Response } from '../../api'

export async function loader({ params: { gatewayId } }) {
	if (isObject(preloadedEntity.current) && preloadedEntity.current.id === gatewayId) {
		const gateway = new Response({data: preloadedEntity.current})
		preloadedEntity.current = null
		return { gatewayId, gateway }
	}
	const gateway = getSMSGateway(gatewayId).catch(e => {
		console.error('Unable to load SMS gateway:', e)
		return null
	})
	return defer({ gatewayId, gateway })
}

export default function SmsGatewaysPage() {
	const routerNavigateFn = useNavigate()
	const preloadEntity = usePreloadedEntityDispatcher()

	const apiSearch = useCallback(async (query, options, requestOptions) => {
		const res = await getSMSGateways(query, options, requestOptions)
		handleRedirect(res, routerNavigateFn)
		return res
	}, [routerNavigateFn])

	return <>
		<Segment vertical padded="very">
			<Container>
				<Header size="huge">
					SMS-gatewayer
					<Header.Subheader>Liste over tilgjengelige SMS-gatewayer</Header.Subheader>
				</Header>
				<DataTable
					columns={[
						{ key: 'default_sender', text: 'Standard avsender', visible: true, sortable: true, searchable: true, filterType: filterTypes.search },
						{ key: 'title', text: 'Tittel', visible: true, sortable: true, searchable: true, filterType: filterTypes.search },
						{ key: 'description', text: 'Beskrivelse', visible: true, sortable: true, searchable: true, filterType: filterTypes.search },
					]}
					defaultOrder={{'default_sender': 'ASC', 'title': 'ASC'}}
					apiSearch={apiSearch}
					onClickRow={(data, index, { ctrlKey, shiftKey }) => {
						// preload SMS gateway data
						preloadEntity(data)
						// navigate to SMS gateway
						const url = '/sms/gateways/' + encodeURIComponent(data.id) + '/messages'
						const target = (ctrlKey || shiftKey) ? '_blank' : '_self'
						navigate(url, target, {}, routerNavigateFn)
					}}
				/>
			</Container>
		</Segment>
	</>
}