import React from 'react'
import Navigate from '../../components/navigate'
import { useAuth } from '../root'

export default function SmsGatewayNavigatePage({ to, replace, state, relative }) {
	const { defaultSmsGatewayId } = useAuth()
	if (!to.startsWith('.') && !to.startsWith('/')) {
		if (defaultSmsGatewayId === null) {
			// not authenticated, /sms/<to> will redirect to /sms/gateways/<default_gateway_id>/<to> later
			to = '/auth?return=' + encodeURIComponent('/sms/' + to)
		} else {
			to = '/sms/gateways/' + defaultSmsGatewayId + '/' + to
		}
	}
	return <Navigate to={to} replace={replace} state={state} relative={relative} />
}