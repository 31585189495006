export default class WebsocketClient {
	static instance = null;
	static getInstance(url) {
		if (WebsocketClient.instance === null) {
			WebsocketClient.instance = new WebsocketClient(url);
		}
		return WebsocketClient.instance;
	}

	connection = null
	session = null
	isConnected = false
	eventHandlers = {
		open: {all: [], called: []},
		close: {all: [], called: []},
	}

	constructor(url) {
		window.ab.connect(url, session => {
			this.session = session
			this.isConnected = true
			this.eventHandlers.open.all.forEach(handler => handler(session))
			this.eventHandlers.open.called = this.eventHandlers.open.all
		}, () => {
			this.session = null
			this.isConnected = false
			this.eventHandlers.close.all.forEach(handler => handler())
			this.eventHandlers.close.called = this.eventHandlers.close.all
		})
	}

	addEventListener(eventName, handler) {
		if (!this.eventHandlers.hasOwnProperty(eventName)) return
		if (!this.eventHandlers[eventName].all.includes(handler)) {
			this.eventHandlers[eventName].all.push(handler)
		}
		if (
			!this.eventHandlers[eventName].called.includes(handler) && (
				(this.isConnected && eventName === 'open') ||
				(!this.isConnected && eventName === 'close')
			)
		) {
			handler(this.session)
		}
	}

	removeEventListener(eventName, handler) {
		if (!this.eventHandlers.hasOwnProperty(eventName)) return
		const index = this.eventHandlers[eventName].all.indexOf(handler)
		if (index !== -1) {
			this.eventHandlers[eventName].all.splice(index, 1)
		}
	}
}