import React from 'react'
import { Segment, Header, Container, List, Grid, Placeholder } from 'semantic-ui-react'
import { renderOrgId, isDomainExpired, getDomainRenewalDate, dateToString, isObject } from '../../../helpers'
import DomainContact from '../../../components/domain_contact'
import ResourceCard from '../../../components/resource_card'
import Link from '../../../components/link'
import { Await, useRouteLoaderData } from 'react-router-dom'
import { useAuth } from '../../root'

export default function DomainOverviewTab() {
	const { user } = useAuth()
	const { domain } = useRouteLoaderData('domain')

	return <Segment vertical padded="very">
		<Container>
			<Grid columns={2} stackable doubling>
				<Grid.Column width={6}>
					<Header size="large">Utløp</Header>
					<React.Suspense fallback={<Placeholder><Placeholder.Line /></Placeholder>}>
						<Await resolve={domain}>
							{res => isObject(res) && isObject(res.data) && res.data.expire_at ? <p>Domenet {(res.data.auto_renew ? 'fornyes ' + (res.data.domain_name_parts.min_register_period_y === 1 ? 'årlig' : 'hvert ' + res.data.domain_name_parts.min_register_period_y + '. år') + '. Neste fornyelsesdato er ' : (!isDomainExpired(res.data) ? 'løper ut ' : 'løp ut ')) + dateToString(getDomainRenewalDate(res.data), false, false, false)}.</p> : null}
						</Await>
					</React.Suspense>

					<Header size="large">DNS-servere</Header>
					<React.Suspense fallback={<>
						<Placeholder>
							<Placeholder.Line />
						</Placeholder>
						<Placeholder>
							<Placeholder.Line />
							<Placeholder.Line />
							<Placeholder.Line />
						</Placeholder>
						<Placeholder>
							<Placeholder.Line />
						</Placeholder>
					</>}>
						<Await resolve={domain}>
							{res => <>
								{isObject(res) && isObject(res.data) && res.data.services.dns ? <p>DNS-servere administeres av Konsept-IT.</p> : null}
								{isObject(res) && isObject(res.data) && Array.isArray(res.data.nameservers) ? <List
									bulleted
									items={res.data.nameservers.map(nameserver => ({key: nameserver.hostname, content: nameserver.hostname}))}
								/> : null}
								{isObject(res) && isObject(res.data) && isObject(res.data.services) && (res.data.services.dns || res.data.services.domain) ? <p>
									{res.data.services.dns ? <Link href={'/domains/' + encodeURIComponent(res.data.id) + '/dns-records'}>Endre DNS-pekere &rarr;</Link> : null}
									{res.data.services.dns && res.data.services.domain ? <span>&emsp;&middot;&emsp;</span> : null}
									{res.data.services.domain ? <Link href={'/domains/' + encodeURIComponent(res.data.id) + '/edit'}>Bytt DNS-servere &rarr;</Link> : null}
								</p> : null}
							</>}
						</Await>
					</React.Suspense>
				</Grid.Column>
				<Grid.Column width={10}>
					<Header size="large">Kontakter</Header>
					<React.Suspense fallback={<>
						<ResourceCard.Group stackable>
							<ResourceCard
								icon="building"
								title="Kunde"
							>
								<ResourceCard.Content>
									<Placeholder>
										<Placeholder.Header>
											<Placeholder.Line />
										</Placeholder.Header>
									</Placeholder>
									<Placeholder>
										<Placeholder.Line />
									</Placeholder>
									<Placeholder>
										<Placeholder.Line />
										<Placeholder.Line />
									</Placeholder>
								</ResourceCard.Content>
								<ResourceCard.Content>
									<Placeholder>
										<Placeholder.Line />
										<Placeholder.Line />
										<Placeholder.Line />
									</Placeholder>
								</ResourceCard.Content>
							</ResourceCard>

							<DomainContact
								type="owner"
								loading
							/>
						</ResourceCard.Group>

						<p></p>
						<Placeholder><Placeholder.Line /></Placeholder>
					</>}>
						<Await resolve={domain}>
							{res => <>
								<ResourceCard.Group stackable>
									{isObject(res) && isObject(res.data) && res.data.account.id === user.account.id ? null : <ResourceCard
										icon="building"
										title="Kunde"
										titleLink={{
											href: isObject(res) && isObject(res.data) ? '/accounts/' + encodeURIComponent(res.data.account.id) : '#',
											name: 'Vis',
											title: 'Vis kunde'
										}}
									>
										<ResourceCard.Content>
											{isObject(res) && isObject(res.data) ? <Header size="small">{res.data.account.name}</Header> : <Placeholder>
												<Placeholder.Header>
													<Placeholder.Line />
												</Placeholder.Header>
											</Placeholder>}
											{isObject(res) && isObject(res.data) ? (res.data.account.org_id ? <p>Org.nr: {renderOrgId(res.data.account.org_id)}</p> : null) : <Placeholder>
												<Placeholder.Line />
											</Placeholder>}
											{isObject(res) && isObject(res.data) ? <p>
												{res.data.account.phone.number ? <React.Fragment>
													<Link href={res.data.account.phone.uri} target="_blank">{res.data.account.phone.number}</Link>
													<br/>
												</React.Fragment> : null}
												<Link href={'mailto:' + res.data.account.email} target="_blank">{res.data.account.email}</Link>
											</p> : <Placeholder>
												<Placeholder.Line />
												<Placeholder.Line />
											</Placeholder>}
										</ResourceCard.Content>
										{!isObject(res) || !isObject(res.data) || res.data.account.address.invoice.street_address !== null ? <ResourceCard.Content>
											{isObject(res) && isObject(res.data) ? (res.data.account.address.invoice.street_address !== null ? <p>
												{res.data.account.address.invoice.street_address}<br/>
												{res.data.account.address.invoice.postal_code} {res.data.account.address.invoice.city}<br/>
												{res.data.account.address.invoice.country.name}
											</p> : null) : <Placeholder>
												<Placeholder.Line />
												<Placeholder.Line />
												<Placeholder.Line />
											</Placeholder>}
										</ResourceCard.Content> : null}
									</ResourceCard>}

									{isObject(res) && isObject(res.data) && !res.data.contacts.owner ? null : <DomainContact
										type="owner"
										loading={!isObject(res) || !isObject(res.data)}
										domainNameParts={isObject(res) && isObject(res.data) ? res.data.domain_name_parts : null}
										data={isObject(res) && isObject(res.data) ? res.data.contacts.owner : null}
										onChange={() => {}}
									/>}
								</ResourceCard.Group>

								<p></p>
								{isObject(res) && isObject(res.data) && isObject(res.data.services) ? (res.data.services.domain ? <p><Link href={'/domains/' + encodeURIComponent(res.data.id) + '/edit'}>Endre juridisk eier &rarr;</Link></p> : null) : <Placeholder><Placeholder.Line /></Placeholder>}
							</>}
						</Await>
					</React.Suspense>
				</Grid.Column>
			</Grid>
		</Container>
	</Segment>
}