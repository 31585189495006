import { Response, PaginatedResponse } from '../'
import { buildQuery } from '../../helpers'

// GET /api/mail1/forwardings
// POST /api/mail1/forwardings/search
export async function getForwardings(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/mail1/forwardings' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/mail1/forwardings/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/mail1/forwardings
export async function createForwarding(forwarding, requestOptions = {}) {
	return Response.request('/api/mail1/forwardings', forwarding, requestOptions)
}

// GET /api/mail1/forwardings/:source
export async function getForwarding(source, requestOptions = {}) {
	return Response.request('/api/mail1/forwardings/' + source, null, requestOptions)
}

// DELETE /api/mail1/forwardings/:source
export async function deleteForwarding(source, requestOptions = {}) {
	return Response.request('/api/mail1/forwardings/' + source, null, Object.assign({ method: 'DELETE' }, requestOptions))
}
