import React, { useState } from 'react'
import { Segment, Container, Header, Form, Input } from 'semantic-ui-react'
import { handleRedirect, isObject, navigate, preventDefault } from '../../../helpers'
import { updateUser } from '../../../api/mail1/users'
import EmailQuotaInput from '../../../components/email_quota_input'
import { Await, useNavigate, useRouteLoaderData } from 'react-router-dom'
import { useMessages } from '../../root'

export default function EditKitmailUserPage() {
	const userData = useRouteLoaderData('kitmailUser')
	const domainUserData = useRouteLoaderData('kitmailDomainUser')
	const { email, kitmailUser } = (userData ?? domainUserData)
	const [, setMessages] = useMessages()
	const [newPassword, setNewPassword] = useState('')
	const [newQuota, setNewQuota] = useState(null)
	const routerNavigateFn = useNavigate()

	const handleUpdateUser = () => {
		Promise.resolve(kitmailUser).then(({data: kitmailUser}) => {
			let changes = {}
			if (newPassword.length > 0) changes.password = newPassword
			if (newQuota !== null && newQuota !== kitmailUser.size.bytes.quota) changes.quota = newQuota
			return updateUser(email, changes)
		}).then(res => {
			setMessages(messages => messages.concat({
				key: 'success_' + Math.round(new Date() / 1000).toString(),
				dismissable: true,
				type: 'success',
				icon: 'check',
				content: 'Brukeren er endret.',
			}))
			navigate('/kitmail/users/' + email, '_self', {}, routerNavigateFn)
		}).catch(e => {
			setMessages(messages => messages.concat({
				key: 'error_' + Math.round(new Date() / 1000).toString(),
				dismissable: true,
				type: 'error',
				icon: 'exclamation circle',
				content: 'Brukeren kunne ikke endres: ' + (e && e.message ? e.message : 'Ukjent feil (sjekk JavaScript-konsollen)'),
			}))
			handleRedirect(e.res, routerNavigateFn)
		})
	}

	return <>
		<Segment vertical padded="very">
			<Container>
				<Header size="huge">Endre KitMail-bruker</Header>
				<Form onSubmit={preventDefault(() => handleUpdateUser())}>
					<Form.Field>
						<label>E-postadresse</label>
						<React.Suspense fallback={
							<Input
								readOnly
								disabled
								type="text"
							/>
						}>
							<Await resolve={kitmailUser}>
								{({data: kitmailUser}) => <Input
									readOnly
									disabled
									label={isObject(kitmailUser) && isObject(kitmailUser.email) ? '@' + kitmailUser.email.domain.domain : null}
									labelPosition={isObject(kitmailUser) && isObject(kitmailUser.email) ? 'right' : null}
									type={isObject(kitmailUser) && isObject(kitmailUser.email) ? 'text' : 'email'}
									value={isObject(kitmailUser) ? (isObject(kitmailUser.email) ? kitmailUser.email.local_part : email) : ''}
								/>}
							</Await>
						</React.Suspense>
					</Form.Field>
					<Form.Input
						label="Nytt passord"
						type="password"
						autoComplete="off"
						value={newPassword}
						onChange={(e, data) => setNewPassword(data.value)}
					/>
					<React.Suspense fallback={
						<EmailQuotaInput
							label="Ny lagringskvote"
							value={0}
						/>
					}>
						<Await resolve={kitmailUser}>
							{({data: kitmailUser}) => <EmailQuotaInput
								label="Ny lagringskvote"
								value={newQuota ?? kitmailUser.size.bytes.quota}
								onChange={newQuota => setNewQuota(newQuota)}
							/>}
						</Await>
					</React.Suspense>
					<React.Suspense fallback={
						<Form.Button
							disabled
							color="orange"
							content="Endre bruker"
						/>
					}>
						<Await resolve={kitmailUser}>
							{() => <Form.Button
								type="submit"
								color="orange"
								content="Endre bruker"
							/>}
						</Await>
					</React.Suspense>
				</Form>
			</Container>
		</Segment>
	</>
}