import React from 'react'
import { Segment, Header, Container, Placeholder, Message, Statistic, List } from 'semantic-ui-react'
import { getDomains } from '../api/domains'
import { getAccounts } from '../api/accounts'
import { getTickets } from '../api/autotask/tickets'
import { formatNumber, hasAccessScope, isObject } from '../helpers'
import ResourceCard from '../components/resource_card'
import Link from '../components/link'
import { useAuth, usePreloadedEntityDispatcher } from './root'
import { Await, defer, useAsyncError, useLoaderData } from 'react-router-dom'
import Navigate from '../components/navigate'

export async function loader() {
	return defer({
		domains: getDomains({op: 'eq', key: 'services.domain', val: true}, { limit: 5, order: '+created_at' }, false),
		accounts: getAccounts({ op: "eq", key: "is_customer", val: true }, { limit: 5, order: '+created_at' }, false),
		tickets: getTickets(null, { limit: 1, order: '+createDate' }, false),
	})
}

function ResourceCardErrorContent() {
	const error = useAsyncError()
	let errorMessage = 'En feil oppstod - prøv å laste inn siden på nytt'
	if (error.res && error.res.error) {
		if (error.res.error.code === 'not_found') {
			errorMessage = null
		} else if (error.res.error.message) {
			errorMessage = error.res.error.message
		}
	}
	return <ResourceCard.Content>
		<Message error>{errorMessage}</Message>
	</ResourceCard.Content>
}

function ResourceCardFallbackContent() {
	return <>
		<ResourceCard.Content textAlign="center">
			<Placeholder>
				<Placeholder.Header>
					<Placeholder.Line />
				</Placeholder.Header>
			</Placeholder>
		</ResourceCard.Content>
		<ResourceCard.Content>
			<Placeholder>
				<Placeholder.Header>
					<Placeholder.Line />
				</Placeholder.Header>
				<Placeholder.Line />
			</Placeholder>
		</ResourceCard.Content>
	</>
}

export default function OverviewPage() {
	const { user } = useAuth()
	const isAuthenticated = isObject(user) &&
		isObject(user.auth) &&
		typeof user.auth.token === 'string' &&
		user.auth.token.length > 0

	const {
		domains,
		accounts,
		tickets,
	} = useLoaderData()

	const preloadEntity = usePreloadedEntityDispatcher()

	const domainStatsCard = isAuthenticated && !hasAccessScope(user, 'domain_search') ? null : <ResourceCard
		icon="globe"
		title="Domener"
		titleLink={{href: '/domains', name: 'Vis alle', title: 'Vis alle domener'}}
	>
		<React.Suspense
			fallback={<ResourceCardFallbackContent />}
		>
			<Await
				resolve={domains}
				errorElement={<ResourceCardErrorContent />}
			>
				{domains => <>
					{domains.redirect ? <Navigate to={domains.redirect} /> : null}
					<ResourceCard.Content textAlign="center">
						<Statistic>
							<Statistic.Label>Antall domener</Statistic.Label>
							<Statistic.Value>{formatNumber(domains.data_info.count)}</Statistic.Value>
						</Statistic>
					</ResourceCard.Content>
					<ResourceCard.Content>
						<ResourceCard.Header>5 nyeste domener</ResourceCard.Header>
						<List>
							{domains.data.map(domain => <List.Item key={domain.id}>
								<Link href={'/domains/' + encodeURIComponent(domain.id)} onClick={() => preloadEntity(domain)}>{domain.domain}</Link>
							</List.Item>)}
						</List>
					</ResourceCard.Content>
				</>}
			</Await>
		</React.Suspense>
	</ResourceCard>

	const accountStatsCard = isAuthenticated && !hasAccessScope(user, 'account_search') ? null : <ResourceCard
		icon="building"
		title="Kunder"
		titleLink={{href: '/accounts', name: 'Vis alle', title: 'Vis alle kunder og leverandører'}}
	>
		<React.Suspense
			fallback={<ResourceCardFallbackContent />}
		>
			<Await
				resolve={accounts}
				errorElement={<ResourceCardErrorContent />}
			>
				{accounts => <>
					{accounts.redirect ? <Navigate to={accounts.redirect} /> : null}
					<ResourceCard.Content textAlign="center">
						<Statistic>
							<Statistic.Label>Antall kunder</Statistic.Label>
							<Statistic.Value>{formatNumber(accounts.data_info.count)}</Statistic.Value>
						</Statistic>
					</ResourceCard.Content>
					<ResourceCard.Content>
						<ResourceCard.Header>5 nyeste kunder</ResourceCard.Header>
						<List>
							{accounts.data.map(account => <List.Item key={account.id}>
								<Link href={'/accounts/' + encodeURIComponent(account.id)} onClick={() => preloadEntity(account)}>{account.name}</Link>
							</List.Item>)}
						</List>
					</ResourceCard.Content>
				</>}
			</Await>
		</React.Suspense>
	</ResourceCard>

	const ticketStatsCard = isAuthenticated && !hasAccessScope(user, 'ticket_search') ? null : <ResourceCard
		icon="clipboard list"
		title="Saker"
		titleLink={{href: '/tickets', name: 'Vis alle', title: 'Vis alle saker'}}
	>
		<React.Suspense
			fallback={<ResourceCardFallbackContent />}
		>
			<Await
				resolve={tickets}
				errorElement={<ResourceCardErrorContent />}
			>
				{tickets => <>
					{tickets.redirect ? <Navigate to={tickets.redirect} /> : null}
					<ResourceCard.Content textAlign="center">
						<Statistic>
							<Statistic.Label>Antall saker</Statistic.Label>
							<Statistic.Value>{formatNumber(tickets.data_info.count)}</Statistic.Value>
						</Statistic>
					</ResourceCard.Content>
					<ResourceCard.Content>
						<ResourceCard.Header>&nbsp;</ResourceCard.Header>
						<List>
							<List.Item>&nbsp;</List.Item>
							<List.Item>&nbsp;</List.Item>
							<List.Item>&nbsp;</List.Item>
							<List.Item>&nbsp;</List.Item>
							<List.Item>&nbsp;</List.Item>
						</List>
					</ResourceCard.Content>
				</>}
			</Await>
		</React.Suspense>
	</ResourceCard>

	return <>
		<Segment vertical padded="very">
			<Container fluid>
				<Header size="huge">
					Oversikt
					<Header.Subheader>Nøkkeltall</Header.Subheader>
				</Header>
			</Container>
		</Segment>
		<Segment vertical padded="very" secondary>
			<Container fluid>
				<ResourceCard.Group stackable>
					{accountStatsCard}
					{domainStatsCard}
					{ticketStatsCard}
				</ResourceCard.Group>
			</Container>
		</Segment>
	</>
}