import React from 'react'
import { Label, Segment } from 'semantic-ui-react'

export default function ProductButton({ active, onClick, labelPosition, label, children }) {
	labelPosition = labelPosition ?? 'bottom'
	return <button className={'ui very plain fluid button kit-product-button' + (active ? ' active' : '')} onClick={onClick}>
		{labelPosition === 'top' && label ? <Segment attached="top" secondary={!active}>
			<Label attached="top">{label}</Label>
		</Segment> : null}
		<Segment attached={labelPosition === 'bottom' ? 'top' : 'bottom'} secondary={!active}>
			{children}
		</Segment>
		{labelPosition === 'bottom' && label ? <Segment attached="bottom" secondary={!active}>
			<Label attached="bottom">{label}</Label>
		</Segment> : null}
	</button>
}