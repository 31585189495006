import { Response, PaginatedResponse } from '..'
import { buildQuery } from '../../helpers'

// GET /api/autotask/contracts
// POST /api/autotask/contracts/search
export async function getContracts(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/autotask/contracts' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/autotask/contracts/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/autotask/contracts
export async function createContract(data, requestOptions = {}) {
	return Response.request('/api/autotask/contracts', data, requestOptions)
}

// GET /api/autotask/contracts/:id
export async function getContract(id, requestOptions = {}) {
	return Response.request('/api/autotask/contracts/' + encodeURIComponent(id), null, requestOptions)
}

// PUT /api/autotask/contracts/:id
export async function updateContract(id, data, requestOptions = {}) {
	return Response.request('/api/autotask/contracts/' + encodeURIComponent(id), data, Object.assign({ method: 'PUT' }, requestOptions))
}

export const STATUS_INACTIVE = 0;
export const STATUS_ACTIVE = 1;

export const SLA_ID_DEFAULT = 1;
export const SLA_ID_BASIC = 2;
export const SLA_ID_STANDARD = 3;
export const SLA_ID_PREMIUM = 4;
export const SLA_ID_MS365_STANDARD = 5;

export const TYPE_TIME_MATERIALS = 1; // Time & Materials
export const TYPE_FIXED_PRICE = 3; // Fixed Price
export const TYPE_BLOCK_HOURS = 4; // Block Hours
export const TYPE_RETAINER = 6; // Retainer
export const TYPE_RECURRING_SERVICE = 7; // Recurring Service
export const TYPE_PER_TICKET = 8; // Per Ticket

export const PERIOD_TYPE_MONTHLY = 2; // Monthly
export const PERIOD_TYPE_QUARTERLY = 3; // Quarterly
export const PERIOD_TYPE_SEMIANNUAL = 4; // Semi-Annual
export const PERIOD_TYPE_YEARLY = 5; // Yearly

export const CATEGORY_STANDARD = 6; // Standard
export const CATEGORY_MAINTENANCE = 9; // Maintenance
export const CATEGORY_CUSTOM = 10; // Custom
export const CATEGORY_KIT_MANAGED_CLIENT_BASIC = 11; // KIT Managed Client Basic
export const CATEGORY_KIT_MANAGED_CLIENT_STANDARD = 12; // KIT Managed Client Standard
export const CATEGORY_KIT_MANAGED_CLIENT_PREMIUM = 13; // KIT Managed Client Premium
export const CATEGORY_DISCOUNTED_LABOR = 14; // Discounted Labor
export const CATEGORY_BLOCK_HOUR = 15; // Block Hour
export const CATEGORY_WEBSITE = 16; // Nettside
export const CATEGORY_MS365 = 17; // MS365
export const CATEGORY_KIT_MANAGED_NETWORK = 18; // KIT Managed Network
export const CATEGORY_ADOBE_VIP = 19; // Adobe VIP
export const CATEGORY_KIT_MANAGED_SERVER = 20; // KIT Managed Server
export const CATEGORY_KIT_MANAGED_BACKUP_365 = 21; // KIT Managed Backup 365
export const CATEGORY_MS365_NCE_MONTHLYBILLING_YEARLYBINDING = 22; // Microsoft 365 NCE (månedlig, årlig binding)
export const CATEGORY_MS365_NCE_MONTHLYBILLING_NOBINDING = 23; // Microsoft 365 NCE (månedlig, ingen binding)
export const CATEGORY_MS365_NCE_YEARLYBILLING_YEARLYBINDING = 24; // Microsoft 365 NCE (årlig, årlig binding)
export const CATEGORY_KIT_MANAGED_BACKUP = 25; // KIT Managed Backup
export const CATEGORY_DIGITAL_SIGNAGE = 26; // Infoskjerm
export const CATEGORY_KIT_MANAGED_INTERNET = 27; // KIT Managed Internet
export const CATEGORY_KIT_TRANSACTIONAL_EMAIL = 28; // KIT Transactional Email
export const CATEGORY_MAILRISK = 29; // MailRisk

export const BILLING_PREF_IMMEDIATE_WITHOUT_REVIEW = 1; // Immediately without review
export const BILLING_PREF_MANUAL = 2; // Manually
export const BILLING_PREF_ON_TIMESHEET_APPROVAL = 3; // On timesheet approval

export const TIMEREPORTING_STARTSTOP_TRUE = 1;
export const TIMEREPORTING_STARTSTOP_FALSE = 0;

export const EXCLUSION_SET_KIT_MANAGED_CLIENT_BASIC_STANDARD_MS365 = 1; // KIT Managed Client Basic/Standard + MS365
export const EXCLUSION_SET_KIT_MANAGED_CLIENT_PREMIUM_MS365 = 2; // KIT Managed Client Premium + MS365
export const EXCLUSION_SET_MS365 = 3; // MS365
export const EXCLUSION_SET_NON_BILLABLE = 4; // Ikke fakturerbar
export const EXCLUSION_SET_KIT_MANAGED_NETWORK = 5; // KIT Managed Network
export const EXCLUSION_SET_ADOBE_VIP = 6; // Adobe VIP
export const EXCLUSION_SET_KIT_MANAGED_CLIENT_BASIC = 7; // KIT Managed Client Basic
export const EXCLUSION_SET_KIT_MANAGED_CLIENT_STANDARD = 8; // KIT Managed Client Standard
export const EXCLUSION_SET_KIT_MANAGED_CLIENT_PREMIUM = 9; // KIT Managed Client Premium
export const EXCLUSION_SET_KIT_MANAGED_SERVER = 10; // KIT Managed Server
export const EXCLUSION_SET_KIT_MANAGED_BACKUP_365 = 11; // KIT Managed Backup 365
export const EXCLUSION_SET_MS365_NCE = 12; // Microsoft 365 NCE
export const EXCLUSION_SET_KIT_MANAGED_ANTIVIRUS = 13; // KIT Managed Antivirus
export const EXCLUSION_SET_KIT_MANAGED_BACKUP = 14; // KIT Managed Backup
export const EXCLUSION_SET_DIGITAL_SIGNAGE = 15; // Infoskjerm
export const EXCLUSION_SET_KITCLOUD_SYNC = 16; // KitCloud Sync
export const EXCLUSION_SET_KIT_MANAGED_INTERNET = 17; // KIT Managed Internet
export const EXCLUSION_SET_KIT_TRANSACTIONAL_EMAIL = 18; // KIT Transactional Email
export const EXCLUSION_SET_WEBSITE_WITH_MAINTENANCE = 19; // Nettside: Vedlikehold inkl.