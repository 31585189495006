import { Response, PaginatedResponse } from '../'
import { buildQuery } from '../../helpers'

// GET /api/mail1/domains
// POST /api/mail1/domains/search
export async function getDomains(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/mail1/domains' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/mail1/domains/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/mail1/domains
export async function createDomain(domain, requestOptions = {}) {
	return Response.request('/api/mail1/domains', domain, requestOptions)
}

// GET /api/mail1/domains/:domain
export async function getDomain(domain_name, requestOptions = {}) {
	return Response.request('/api/mail1/domains/' + domain_name, null, requestOptions)
}

// PUT /api/mail1/domains/:domain
export async function updateDomain(domain_name, domain, requestOptions = {}) {
	return Response.request('/api/mail1/domains/' + domain_name, domain, Object.assign({ method: 'PUT' }, requestOptions))
}

// DELETE /api/mail1/domains/:domain
export async function deleteDomain(domain_name, requestOptions = {}) {
	return Response.request('/api/mail1/domains/' + domain_name, null, Object.assign({ method: 'DELETE' }, requestOptions))
}
