import React, { useCallback, useState } from 'react'
import { Header, Button, Popup, Icon, Grid } from 'semantic-ui-react'
import { formatNumber, dateToString, filterTypes, navigate, preventDefault, handleRedirect, buildQuery } from '../../../helpers'
import DataTable from '../../../components/data_table'
import { getAccountDomains } from '../../../api/accounts'
import { Await, useNavigate, useRouteLoaderData } from 'react-router-dom'
import { usePreloadedEntityDispatcher } from '../../root'

const defaultQuery = {op: 'or', grp: [
	{op: 'eq', key: 'services.domain', val: true},
	{op: 'eq', key: 'services.dns', val: true},
]}

function AccountDomainsDataTable({ loading, account, setDataInfo }) {
	const routerNavigateFn = useNavigate()
	const preloadEntity = usePreloadedEntityDispatcher()
	const apiSearch = useCallback(async (query, options, requestOptions) => {
		const res = await getAccountDomains(account.id, query, options, requestOptions)
		handleRedirect(res, routerNavigateFn)
		if (JSON.stringify(query) === JSON.stringify(defaultQuery)) {
			setDataInfo(res.data_info)
		}
		return res
	}, [account, setDataInfo, routerNavigateFn])

	const now = new Date()

	return <DataTable
		columns={[
			{ key: 'domain', text: 'Domene', visible: true, sortable: true, searchable: true, filterType: filterTypes.search, filterSearchColumns: ['domain', 'ace'] },
			{ key: 'ace', text: 'Domene (ren tekst)', searchable: true },
			{ key: 'contacts.owner.name', text: 'Juridisk eier', visible: true, searchable: true, filterType: filterTypes.search, filterSearchColumns: ['contacts.owner.name', 'contacts.owner.organization_name'] },
			{ key: 'contacts.owner.organization_name', text: 'Juridisk eier (Firma)', searchable: true },
			{ key: 'expire_at', text: 'Utløp', visible: true, sortable: true, searchable: true, filterType: filterTypes.date },
			{ key: 5, text: '', visible: true },
		]}
		query={defaultQuery}
		defaultOrder={{domain: 'ASC'}}
		disabled={account === null}
		loading={loading}
		apiSearch={apiSearch}
		onClickRow={(data, index, { ctrlKey, shiftKey }) => {
			const url = '/domains/' + encodeURIComponent(data.id)
			const target = (ctrlKey || shiftKey) ? '_blank' : '_self'
			navigate(url, target, {}, routerNavigateFn)
		}}
		renderRow={data => {
			const expireDate = !data.expire_at ? null : new Date(data.expire_at)
			const nowBeforeExpireDate = expireDate !== null && now < expireDate
			let renewalDate = expireDate !== null ? new Date(expireDate) : null
			if (renewalDate !== null && !nowBeforeExpireDate && data.domain_name_parts.min_register_period_y !== null && data.auto_renew) {
				renewalDate.setFullYear(renewalDate.getFullYear() + data.domain_name_parts.min_register_period_y)
			}
			const hasOwner = data.contacts.owner !== null
			const hasOwnerOrgName = hasOwner && typeof data.contacts.owner.organization_name === 'string' && data.contacts.owner.organization_name.trim().length > 0
			const hasOwnerName = hasOwner && typeof data.contacts.owner.name === 'string' && data.contacts.owner.name.trim().length > 0
			return [
				<>
					{data.domain}
					{data.ace ? <><br/><span className="text-secondary">{data.ace}</span></> : null}
				</>,
				<>
					{hasOwnerOrgName ? data.contacts.owner.organization_name : null}
					{hasOwnerOrgName && hasOwnerName ? <br/> : null}
					{hasOwnerName ? (hasOwnerOrgName ? <span className="text-secondary">Kontaktperson: {data.contacts.owner.name}</span> : data.contacts.owner.name) : null}
				</>,
				!expireDate ? 'Ukjent utløp' : ((data.auto_renew ? 'Fornyes ' : (nowBeforeExpireDate ? 'Løper ut ' : 'Løp ut ')) + dateToString(renewalDate, false)),
				<div className="text-ha-right">
					<Button.Group size="mini" compact>
						{!data.services.dns || (data.services.domain && expireDate && !nowBeforeExpireDate && !data.auto_renew) ? null : <Popup
							inverted
							content="Endre DNS-pekere"
							trigger={<Button
								as="a"
								color="blue"
								href={'/domains/' + encodeURIComponent(data.id) + '/dns-records'}
								onClick={preventDefault(e => {
									// preload domain data
									preloadEntity(data)
									// navigate to domain DNS records page
									navigate('/domains/' + encodeURIComponent(data.id) + '/dns-records', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn)
								})}
							>DNS</Button>}
						/>}
						{!data.services.domain || !expireDate || (!nowBeforeExpireDate && !data.auto_renew) ? null : <Popup
							inverted
							content="Endre domene"
							trigger={<Button
								as="a"
								icon="edit"
								href={'/domains/' + encodeURIComponent(data.id) + '/edit'}
								onClick={preventDefault(e => {
									// preload domain data
									preloadEntity(data)
									// navigate to domain edit page
									navigate('/domains/' + encodeURIComponent(data.id) + '/edit', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn)
								})}
							/>}
						/>}
						{!data.services.domain || !data.auto_renew ? null : <Popup
							inverted
							content="Si opp domene"
							trigger={<Button
								as="a"
								icon="x"
								negative
								href={'/domains/' + encodeURIComponent(data.id) + '/delete'}
								onClick={preventDefault(e => {
									// preload domain data
									preloadEntity(data)
									// navigate to domain expire page
									navigate('/domains/' + encodeURIComponent(data.id) + '/delete', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn)
								})}
							/>}
						/>}
					</Button.Group>
				</div>
			]
		}}
	/>
}

export default function AccountDomainsTab() {
	const { accountId, account } = useRouteLoaderData('account')
	const [dataInfo, setDataInfo] = useState(null)
	const routerNavigateFn = useNavigate()

	return <>
		<Grid stackable>
			<Grid.Row verticalAlign="bottom">
				<Grid.Column width={10}>
					<Header size="large">Domener</Header>
				</Grid.Column>
				<Grid.Column width={6} textAlign="right">
					<Button
						as="a"
						compact
						href={'/domains/new' + buildQuery({account_id: accountId})}
						onClick={preventDefault(e => navigate('/domains/new' + buildQuery({account_id: accountId}), (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
						color="orange"
						label={dataInfo ? formatNumber(dataInfo.count) : '0'}
						labelPosition="right"
						content={<><Icon name="add" /> Opprett eller flytt domene</>}
					/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
		<p></p>

		<React.Suspense
			fallback={<AccountDomainsDataTable loading account={null} />}
		>
			<Await resolve={account}>
				{({data: account}) => <AccountDomainsDataTable
					account={account}
					setDataInfo={setDataInfo}
				/>}
			</Await>
		</React.Suspense>
	</>
}