import React, { useCallback } from 'react'
import { Segment, Header, Container } from 'semantic-ui-react'
import DataTable from '../components/data_table'
import { filterTypes, formatNumber, handleRedirect } from '../helpers'
import { getProducts } from '../api/tripletex/products'
import { useNavigate } from 'react-router-dom'

export default function ProductsPage() {
	const routerNavigateFn = useNavigate()

	const apiSearch = useCallback(async (query, options, requestOptions) => {
		const res = await getProducts(query, options, requestOptions)
		handleRedirect(res, routerNavigateFn)
		return res
	}, [routerNavigateFn])

	return <>
		<Segment vertical padded="very">
			<Container>
				<Header size="huge">
					Produkter
					<Header.Subheader>Liste over alle produkter og lagerstatus</Header.Subheader>
				</Header>

				<DataTable
					format="tripletex"
					columns={[
						{ key: 'number', text: 'Produktnr.', visible: true, sortable: true, filterType: filterTypes.search },
						{ key: 'name', text: 'Produktnavn', visible: true, sortable: true, searchable: true, filterType: filterTypes.search },
						{ key: 'priceExcludingVatCurrency', text: 'Pris (eks. mva)', visible: true },
						{ key: 'priceIncludingVatCurrency', text: 'Pris (inkl. mva)', visible: true },
						{ key: 'stockOfGoods', text: 'Ant. på lager', filterText: 'På lager', visible: true },
						{ key: 'availableStock', text: 'Tilgj. antall', visible: true },
					]}
					defaultOrder={{'Name': 'ASC'}}
					apiSearch={apiSearch}
					renderRow={data => [
						data.number,
						data.name,
						formatNumber(data.priceExcludingVatCurrency, { precision: 2 }) + ' kr',
						formatNumber(data.priceIncludingVatCurrency, { precision: 2 }) + ' kr',
						data.stockOfGoods,
						data.availableStock,
					]}
				/>
			</Container>
		</Segment>
	</>
}