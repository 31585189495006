import React from 'react'
import { Header, List, Message, Placeholder } from 'semantic-ui-react'
import ResourceCard from './resource_card'
import { isObject } from '../helpers'

export default function DomainNameserverServiceCard({ loading, value }) {
	loading = loading ?? false
	value = value ?? null

	let content = <ResourceCard.Content>Skriv inn et domenenavn...</ResourceCard.Content>
	if (loading) {
		content = <ResourceCard.Content>
			<Placeholder>
				<Placeholder.Line />
			</Placeholder>
		</ResourceCard.Content>
	} else if (isObject(value)) {
		content = <ResourceCard.Content>
			<Header size="small">{value.name}</Header>
			<List bulleted>{value.hosts.map((host, i) => <List.Item key={'nameserver_host_' + i}><code>{host.hostname}</code></List.Item>)}</List>
		</ResourceCard.Content>
	} else {
		content = <ResourceCard.Content>
			<Message error content="Fant ingen navnetjenere på domenet." />
		</ResourceCard.Content>
	}

	return <ResourceCard
		icon="globe"
		title="Navnetjenere"
	>
		{content}
	</ResourceCard>
}