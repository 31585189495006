import { Response, PaginatedResponse } from '..'
import { buildQuery } from '../../helpers'

// GET /api/autotask/companies
// POST /api/autotask/companies/search
export async function getCompanies(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/autotask/companies' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/autotask/companies/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/autotask/companies
export async function createCompany(data, requestOptions = {}) {
	return Response.request('/api/autotask/companies', data, requestOptions)
}

// GET /api/autotask/companies/:id
export async function getCompany(id, requestOptions = {}) {
	return Response.request('/api/autotask/companies/' + encodeURIComponent(id), null, requestOptions)
}

// PUT /api/autotask/companies/:id
export async function updateCompany(id, data, requestOptions = {}) {
	return Response.request('/api/autotask/companies/' + encodeURIComponent(id), data, Object.assign({ method: 'PUT' }, requestOptions))
}

export const ID_VENDOR_ALTIBOX_AS = 1410; // Altibox AS

export const BILLTO_NONE = 0;
export const BILLTO_ACCOUNT = 1;
export const BILLTO_ACCOUNTBILLING = 2;
export const BILLTO_PARENT = 3;
export const BILLTO_PARENTBILLING = 4;

export const CLASSIFICATION_TERMINATED = 9; // Avsluttet kundeforhold
export const CLASSIFICATION_BASIC_MANAGED = 16; // Basic Managed
export const CLASSIFICATION_STANDARD_MANAGED = 17; // Standard Managed
export const CLASSIFICATION_PREMIUM_MANAGED = 18; // Premium Managed
export const CLASSIFICATION_BLOCK_HOUR = 5; // Block Hour Client
export const CLASSIFICATION_DELINQUENT = 10; // Delinquent
export const CLASSIFICATION_GOLD_MANAGED_SERVICE = 15; // Gold Managed Service
export const CLASSIFICATION_HOSTED_SERVICE = 19; // Hosted Service
export const CLASSIFICATION_JEOPARDY = 14; // Jeopardy Company
export const CLASSIFICATION_RESIDENTIAL = 13; // Residential
export const CLASSIFICATION_ENDCUSTOMER_AGENT = 203; // Sluttkunde via agent
export const CLASSIFICATION_ENDCUSTOMER_RESELLER = 202; // Sluttkunde via forhandler
export const CLASSIFICATION_SMALL = 200; // Småkunde
export const CLASSIFICATION_T_AND_M = 12; // T&M
export const CLASSIFICATION_TARGET = 7; // Target
export const CLASSIFICATION_NO_AGREEMENT = 201; // Uten serviceavtale

export const TYPE_CUSTOMER = 1;
export const TYPE_LEAD = 2;
export const TYPE_PROSPECT = 3;
export const TYPE_DEAD = 4;
export const TYPE_CANCELLATION = 6;
export const TYPE_VENDOR = 7;
export const TYPE_PARTNER = 8;

export const INVOICE_TYPE_NONE = 1;
export const INVOICE_TYPE_PRINT = 2;
export const INVOICE_TYPE_EMAIL = 3;
export const INVOICE_TYPE_PRINT_AND_EMAIL = 4;

export const MARKETSEGMENT_NONPROFIT = 29682847; // Not For Profit
export const MARKETSEGMENT_ACCOUNTING = 29683399; // Accounting
export const MARKETSEGMENT_CONSTRUCTION = 29683400; // Construction
export const MARKETSEGMENT_DISTRIBUTION = 29683401; // Distribution
export const MARKETSEGMENT_EDUCATION = 29683402; // Education
export const MARKETSEGMENT_FINANCIAL = 29683403; // Financial
export const MARKETSEGMENT_GOVERNMENT = 29683404; // Government
export const MARKETSEGMENT_HEALTHCARE = 29683405; // Healthcare
export const MARKETSEGMENT_HOSPITALITY = 29683406; // Hospitality
export const MARKETSEGMENT_INSURANCE = 29683407; // Insurance
export const MARKETSEGMENT_LEGAL = 29683408; // Legal
export const MARKETSEGMENT_MANUFACTURING = 29683409; // Manufacturing
export const MARKETSEGMENT_REALESTATE = 29683410; // Real Estate
export const MARKETSEGMENT_RESIDENTIAL = 29683411; // Residential
export const MARKETSEGMENT_RETAIL = 29683412; // Retail
export const MARKETSEGMENT_TRANSPORTATION = 29683413; // Transportation

export const PO_TEMPLATE_ID_DEFAULT = 102;

export const TERRITORY_ID_NORTH = 29682778;
export const TERRITORY_ID_EAST = 29683452;
export const TERRITORY_ID_WEST = 29683453;
export const TERRITORY_ID_SOUTH = 29683454;

export const UDF_USERNAME_FORMAT_FIRSTNAME = "firstname"; // fornavn
export const UDF_USERNAME_FORMAT_FIRSTNAME_DOT_LASTNAME = "firstname.surname"; // fornavn.etternavn
export const UDF_USERNAME_FORMAT_FIRST_LETTER_OF_FIRSTNAME = "f"; // Første bokstav i fornavn
export const UDF_USERNAME_FORMAT_FIRST_LETTER_OF_ALL_NAMES = "fms"; // Første bokstav i fornavn, mellomnavn og etternavn
export const UDF_USERNAME_FORMAT_FIRST_LETTER_OF_FIRSTNAME_LASTNAME = "ffss"; // Første to bokstaver i fornavn og etternavn

export const UDF_ALIAS_FORMAT_FIRSTNAME = "firstname"; // fornavn
export const UDF_ALIAS_FORMAT_FIRSTNAME_DOT_LASTNAME = "firstname.surname"; // fornavn.etternavn
export const UDF_ALIAS_FORMAT_FIRST_LETTER_OF_FIRSTNAME = "f"; // Første bokstav i fornavn
export const UDF_ALIAS_FORMAT_FIRST_LETTER_OF_ALL_NAMES = "fms"; // Første bokstav i fornavn, mellomnavn og etternavn
export const UDF_ALIAS_FORMAT_FIRST_LETTER_OF_FIRSTNAME_LASTNAME = "ffss"; // Første to bokstaver i fornavn og etternavn
export const UDF_ALIAS_FORMAT_NONE = "disabled"; // Ingen alias

export const UDF_MS365_DEFAULTLICENSE_EXCHANGE_P1 = "exchange"; // Exchange Online (plan 1)
export const UDF_MS365_DEFAULTLICENSE_BUSINESS_BASIC = "business_basic"; // Microsoft 365 Business Basic
export const UDF_MS365_DEFAULTLICENSE_BUSINESS_PREMIUM = "business_premium"; // Microsoft 365 Business Premium
export const UDF_MS365_DEFAULTLICENSE_BUSINESS_STANDARD = "business_standard"; // Microsoft 365 Business Standard
export const UDF_MS365_DEFAULTLICENSE_E3 = "e3"; // Microsoft 365 E3

export const UDF_BRREG_STATUS_ACTIVE = "aktiv"; // Aktiv
export const UDF_BRREG_STATUS_BANKRUPT = "konkurs"; // Konkurs
export const UDF_BRREG_STATUS_UNKNOWN = "ukjent"; // Ukjent status
export const UDF_BRREG_STATUS_UNDER_LIQUIDATION = "underAvvikling"; // Under Avvikling
export const UDF_BRREG_STATUS_UNDER_FORCED_LIQUIDATION_OR_DISSOLUTION = "underTvangsavviklingEllerTvangsopplosning"; // Under Tvangsavvikling eller tvangsoppløsning

export const UDF_BILLING_PERIOD_ANNUAL = "annual"; // Årlig
export const UDF_BILLING_PERIOD_BIANNUAL = "biannual"; // Halvårlig
export const UDF_BILLING_PERIOD_QUARTERLY = "quarterly"; // Kvartalsvis
export const UDF_BILLING_PERIOD_MONTHLY = "monthly"; // Månedlig

export const UDF_INVOICE_TYPE_EHF = "ehf"; // EHF
export const UDF_INVOICE_TYPE_EMAIL = "email"; // Email
export const UDF_INVOICE_TYPE_MAIL = "mail"; // Mail

export const UDF_KIT_MANAGED_BACKUP_365_NONE = "disabled"; // Deaktivert
export const UDF_KIT_MANAGED_BACKUP_365_YEARLYBINDING = "tbr"; // KIT Managed Backup 365 (årlig binding)
export const UDF_KIT_MANAGED_BACKUP_365_MONTHLYBINDING = "tbr_monthly"; // KIT Managed Backup 365 (månedlig binding)
export const UDF_KIT_MANAGED_BACKUP_365_UNLIMITED_YEARLYBINDING = "icr"; // KIT Managed Backup 365 Unlimited (årlig binding)
export const UDF_KIT_MANAGED_BACKUP_365_UNLIMITED_MONTHLYBINDING = "icr_monthly"; // KIT Managed Backup 365 Unlimited (månedlig binding)

export const UDF_KIT_MANAGED_BACKUP_365_AUTOADD_OFF = "off"; // Av
export const UDF_KIT_MANAGED_BACKUP_365_AUTOADD_ON = "on"; // På

export const UDF_LEAD_SOURCE_ADVERTISING = "Advertising"; // Advertising
export const UDF_LEAD_SOURCE_NETWORKING = "Networking"; // Networking
export const UDF_LEAD_SOURCE_REFERRAL = "Referral"; // Referral
export const UDF_LEAD_SOURCE_WALK_IN = "Walk-in"; // Walk-in
export const UDF_LEAD_SOURCE_WEBSITE = "Web site"; // Web site

export const UDF_NCE_TYPEFORM_STATUS_COMPLETE = "complete"; // Ferdig (alle tjenester ligger i Autotask)
export const UDF_NCE_TYPEFORM_STATUS_COMPLETE_NORESPONSE = "complete_no_response"; // Ferdig (ikke fått respons på Typeform, alt ligger i Autotask)
export const UDF_NCE_TYPEFORM_STATUS_OTHERSERVICES_NORESPONSE = "other_services_no_response"; // Ferdig (ikke fått respons på Typeform, kunde har andre tjenester som må legges i Autotask)
export const UDF_NCE_TYPEFORM_STATUS_NCE = "nce"; // Kun NCE kontrakt gjenstår
export const UDF_NCE_TYPEFORM_STATUS_OTHERSERVICES = "other_services"; // NCE og Backup 365 kontrakt er fullført, kunde har andre tjenester som må legges inn i Autotask
export const UDF_NCE_TYPEFORM_STATUS_NCE_BACKUP = "nce_backup"; // NCE og KIT Managed Backup 365 kontrakt gjenstår
export const UDF_NCE_TYPEFORM_STATUS_IN_PROGRESS = "in_progress"; // Under arbeid