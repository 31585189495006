import React, { useCallback, useMemo, useState } from 'react'
import { Header, Progress, Button, Popup, Grid, Icon } from 'semantic-ui-react'
import { getUsers } from '../../../api/mail1/users'
import { formatNumber, handleRedirect, navigate, preventDefault } from '../../../helpers'
import DataTable from '../../../components/data_table'
import { useNavigate, useRouteLoaderData } from 'react-router-dom'

export default function KitmailDomainUsersPage() {
	const { domain } = useRouteLoaderData('kitmailDomain')
	const [dataInfo, setDataInfo] = useState(null)
	const defaultQuery = useMemo(() => ({op: 'like', key: 'email.address', val: '*@' + domain}), [domain])
	const routerNavigateFn = useNavigate()

	const apiSearch = useCallback(async (query, options, requestOptions) => {
		const res = await getUsers(query, options, requestOptions)
		handleRedirect(res, routerNavigateFn)
		if (JSON.stringify(query) === JSON.stringify(defaultQuery)) {
			setDataInfo(res.data_info)
		}
		return res
	}, [defaultQuery, routerNavigateFn])

	return <>
		<Grid stackable>
			<Grid.Row verticalAlign="bottom">
				<Grid.Column width={10}>
					<Header size="large">Brukere</Header>
				</Grid.Column>
				<Grid.Column width={6} textAlign="right">
					<Button
						as="a"
						compact
						href={'/kitmail/domains/' + domain + '/users/new'}
						onClick={preventDefault(e => navigate('/kitmail/domains/' + domain + '/users/new', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
						color="orange"
						label={dataInfo ? formatNumber(dataInfo.count) : '0'}
						labelPosition="right"
						content={<><Icon name="add" /> Opprett ny bruker</>}
					/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
		<p></p>

		<DataTable
			columns={[
				{ key: 'email.address', text: 'E-postadresse', width: 3, visible: true, sortable: true, searchable: true },
				{ key: 'size.bytes.quota', text: 'Størrelse', width: 11, visible: true },
				{ key: 2, text: '', width: 2, visible: true },
			]}
			query={defaultQuery}
			defaultOrder={{'email.domain.domain': 'ASC'}}
			apiSearch={apiSearch}
			onClickRow={(data, index, { ctrlKey, shiftKey }) => {
				const url = '/kitmail/domains/' + data.email.domain.domain + '/users/' + data.email.address
				const target = (ctrlKey || shiftKey) ? '_blank' : '_self'
				navigate(url, target, {}, routerNavigateFn)
			}}
			renderRow={data => [
				data.email.address,
				<>
					<Progress
						percent={data.size.percent.usage * 100}
						warning={data.size.percent.usage >= 0.95 && data.size.percent.usage < 0.99}
						error={data.size.percent.usage >= 0.99}
						precision={0}
						progress='percent'
						label={data.size.human.usage + ' / ' + data.size.human.quota}
					/>
				</>,
				<div className="text-ha-right">
					<Button.Group size="mini" compact>
						<Popup
							inverted
							content="Endre bruker"
							trigger={<Button
								as="a"
								icon="edit"
								href={'/kitmail/domains/' + data.email.domain.domain + '/users/' + data.email.address + '/edit'}
								onClick={preventDefault(e => navigate('/kitmail/domains/' + data.email.domain.domain + '/users/' + data.email.address + '/edit', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
							/>}
						/>
						<Popup
							inverted
							content="Slett bruker"
							trigger={<Button
								as="a"
								negative
								icon="delete"
								href={'/kitmail/domains/' + data.email.domain.domain + '/users/' + data.email.address + '/delete'}
								onClick={preventDefault(e => navigate('/kitmail/domains/' + data.email.domain.domain + '/users/' + data.email.address + '/delete', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
							/>}
						/>
					</Button.Group>
				</div>
			]}
		/>
	</>
}