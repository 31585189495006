import React from 'react'
import { Segment, Container, Header, Progress, Grid, Icon, Button, List, Placeholder } from 'semantic-ui-react'
import { dateToRelativeString, dateToString, isObject, navigate, preventDefault } from '../../../helpers'
import { getUser } from '../../../api/mail1/users'
import { Await, defer, useNavigate, useRouteLoaderData } from 'react-router-dom'
import { preloadedEntity } from '../../root'
import { Response } from '../../../api'

export async function loader({ params: { email } }) {
	if (isObject(preloadedEntity.current) && isObject(preloadedEntity.current.email) && preloadedEntity.current.email.address === email) {
		const kitmailUser = new Response({data: preloadedEntity.current})
		preloadedEntity.current = null
		return { email, kitmailUser }
	}
	const kitmailUser = getUser(email).catch(e => {
		console.error('Unable to load user:', e)
		return null
	})
	return defer({ email, kitmailUser })
}

export default function KitmailUserPage() {
	const userData = useRouteLoaderData('kitmailUser')
	const domainUserData = useRouteLoaderData('kitmailDomainUser')
	const { email, kitmailUser } = (userData ?? domainUserData)
	const routerNavigateFn = useNavigate()

	return <>
		<Segment vertical padded="very">
			<Container>
				<Grid stackable>
					<Grid.Row>
						<Grid.Column width={10}>
							<Header size="huge">
								KitMail-bruker <code>{email}</code>
								<React.Suspense>
									<Await resolve={kitmailUser}>
										{res => isObject(res) && isObject(res.data) && isObject(res.data.email) && isObject(res.data.email.domain) && res.data.email.domain.external ? <Header.Subheader>Eksternt domene (kun SMTP)</Header.Subheader> : null}
									</Await>
								</React.Suspense>
							</Header>
						</Grid.Column>
						<React.Suspense fallback={
							<Grid.Column width={6} textAlign="right">
								<Button
									disabled
									content={<><Icon name="edit" /> Endre bruker</>}
								/>
								<Button
									negative
									disabled
									content={<><Icon name="delete" /> Slett bruker</>}
								/>
							</Grid.Column>
						}>
							<Await resolve={kitmailUser}>
								{res => {
									if (!isObject(res) || !isObject(res.data)) return null
									const { data: kitmailUser } = res
									const linkBase = '/kitmail' + (domainUserData !== null ? '/domains/' + kitmailUser.email.domain.domain : '') + '/users/' + email
									return <Grid.Column width={6} textAlign="right">
										<Button
											as="a"
											href={linkBase + '/edit'}
											onClick={preventDefault(e => navigate(linkBase + '/edit', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
											content={<><Icon name="edit" /> Endre bruker</>}
										/>
										<Button
											as="a"
											negative
											href={linkBase + '/delete'}
											onClick={preventDefault(e => navigate(linkBase + '/delete', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
											content={<><Icon name="delete" /> Slett bruker</>}
										/>
									</Grid.Column>
								}}
							</Await>
						</React.Suspense>
					</Grid.Row>
					<Grid.Row>
						<React.Suspense fallback={
							<Grid.Column width={16}>
								<Progress
									percent={0}
									precision={0}
									progress='percent'
									label={'... / ...'}
								/>
							</Grid.Column>
						}>
							<Await resolve={kitmailUser}>
								{res => {
									if (!isObject(res) || !isObject(res.data)) return null
									const { data: kitmailUser } = res
									return <Grid.Column width={16}>
										<Progress
											percent={kitmailUser.size.percent.usage * 100}
											warning={kitmailUser.size.percent.usage >= 0.95 && kitmailUser.size.percent.usage < 0.99}
											error={kitmailUser.size.percent.usage >= 0.99}
											precision={0}
											progress='percent'
											label={kitmailUser.size.human.usage + ' / ' + kitmailUser.size.human.quota}
										/>
										{kitmailUser.usage_alerted_at !== null ? <p>Brukeren ble varslet om lite tilgjengelig plass {dateToRelativeString(kitmailUser.usage_alerted_at)} ({dateToString(kitmailUser.usage_alerted_at)}).</p> : null}
									</Grid.Column>
								}}
							</Await>
						</React.Suspense>
					</Grid.Row>
				</Grid>

				<Header size="large">Alias</Header>
				<React.Suspense fallback={
					<Placeholder>
						<Placeholder.Line />
					</Placeholder>
				}>
					<Await resolve={kitmailUser}>
						{res => (isObject(res) && isObject(res.data) && Array.isArray(res.data.aliases)) ? <List bulleted items={res.data.aliases.map(alias => alias.source.address)} /> : <p>Ingen alias er lagt til.</p>}
					</Await>
				</React.Suspense>

				<Header size="large">Videresending</Header>
				<React.Suspense fallback={
					<Placeholder>
						<Placeholder.Line />
					</Placeholder>
				}>
					<Await resolve={kitmailUser}>
						{res => isObject(res) && isObject(res.data) && isObject(res.data.forwarding) && isObject(res.data.forwarding.destination) ? <p>All e-post videresendes til {res.data.forwarding.destination.address}</p> : <p>Ingen videresending er lagt til.{isObject(res) && isObject(res.data) && isObject(res.data.email) && isObject(res.data.email.domain) && res.data.email.domain.external ? ' Brukeren kan ikke motta e-post grunnet at domenet er eksternt.' : ' E-post leveres direkte til brukerens postboks.'}</p>}
					</Await>
				</React.Suspense>
			</Container>
		</Segment>
	</>
}