import { Response, PaginatedResponse } from '..'
import { buildQuery } from '../../helpers'

// GET /api/autotask/company-locations
// POST /api/autotask/company-locations/search
export async function getCompanyLocations(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/autotask/company-locations' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/autotask/company-locations/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/autotask/company-locations
export async function createCompanyLocation(data, requestOptions = {}) {
	return Response.request('/api/autotask/company-locations', data, requestOptions)
}

// GET /api/autotask/company-locations/:id
export async function getCompanyLocation(id, requestOptions = {}) {
	return Response.request('/api/autotask/company-locations/' + encodeURIComponent(id), null, requestOptions)
}

// PUT /api/autotask/company-locations/:id
export async function updateCompanyLocation(id, data, requestOptions = {}) {
	return Response.request('/api/autotask/company-locations/' + encodeURIComponent(id), data, Object.assign({ method: 'PUT' }, requestOptions))
}

// DELETE /api/autotask/company-locations/:id
export async function deleteCompanyLocation(id, requestOptions = {}) {
	return Response.request('/api/autotask/company-locations/' + encodeURIComponent(id), null, Object.assign({ method: 'DELETE' }, requestOptions))
}