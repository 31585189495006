import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Segment, Container, Header, List, Accordion, Icon, Form, Input, Message, TextArea, Grid, Dropdown } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { useRail } from '../../components/dash'
import DataTable from '../../components/data_table'
import DataSelect from '../../components/data_select'
import { formatNumber, formatNumberForInputField, dateToString, dateLocales, recalculateSubscriptionPeriodPrice, formatPeriodPrice, getProductPeriodPriceFormat, getPeriodPriceLabel, getSubscriptionPeriodPriceFormat, preventDefault, handleRedirect } from '../../helpers'
import { getDomains, getDomainHostingServiceById } from '../../api/domains'
import { createConfigurationItem, getConfigurationItems, updateConfigurationItem, TYPE_WEBSHOP as CI_TYPE_WEBSHOP, TYPE_WEBSITE as CI_TYPE_WEBSITE, CATEGORY_IVO_WEBSITE_DUDA as CI_CATEGORY_IVO_WEBSITE_DUDA } from '../../api/autotask/configuration_items'
import { createConfigurationItemRelation, getConfigurationItemRelations } from '../../api/autotask/configuration_item_relations'
import { createSubscription, getSubscriptions, updateSubscription, PERIOD_TYPE_MONTHLY as SUBSCRIPTION_PERIOD_TYPE_MONTHLY, PERIOD_TYPE_YEARLY as SUBSCRIPTION_PERIOD_TYPE_YEARLY, STATUS_ACTIVE as SUBSCRIPTION_STATUS_ACTIVE } from '../../api/autotask/subscriptions'
import { getProducts, CATEGORY_WEBSITE as PRODUCT_CATEGORY_WEBSITE, CATEGORY_WEBSITE_HOSTING as PRODUCT_CATEGORY_WEBSITE_HOSTING, CATEGORY_WEBSITE_HOSTING_MANAGED as PRODUCT_CATEGORY_WEBSITE_HOSTING_MANAGED, CATEGORY_WEBSITE_IVO as PRODUCT_CATEGORY_WEBSITE_IVO, CATEGORY_WEBSITE_IVO_DUDA as PRODUCT_CATEGORY_WEBSITE_IVO_DUDA } from '../../api/autotask/products'
import { getCompanies } from '../../api/autotask/companies'
import { getContacts } from '../../api/autotask/contacts'
import Link from '../../components/link'
import { useNavigate } from 'react-router-dom';
import { useMessages } from '../root';

export default function AutotaskImportWebsiteToolPage() {
	const [, setMessages] = useMessages()
	const [railVisible, setRail] = useRail()

	const [showDescription, setShowDescription] = useState(false)

	const [selectedDomain, setSelectedDomain] = useState(null)
	const [selectedAliasDomains, setSelectedAliasDomains] = useState([])
	const [selectedProduct, setSelectedProduct] = useState(null)

	const [loadingHostingService, setLoadingHostingService] = useState(false)
	const [hostingService, setHostingService] = useState(null)

	const [loadingCustomer, setLoadingCustomer] = useState(false)
	const [customer, setCustomer] = useState(null)
	const [primaryContact, setPrimaryContact] = useState(null)

	const [loadingServerConfigurationItem, setLoadingServerConfigurationItem] = useState(false)
	const [serverConfigurationItem, setServerConfigurationItem] = useState(null)

	const [loadingConfigurationItem, setLoadingConfigurationItem] = useState(false)
	const [configurationItem, setConfigurationItem] = useState(null)

	const [doNotCreateSubscription, setDoNotCreateSubscription] = useState(false)
	const [loadingSubscription, setLoadingSubscription] = useState(false)
	const [subscription, setSubscription] = useState(null)

	const [importing, setImporting] = useState(false)

	const [price, setPrice] = useState('0')
	const [periodType, setPeriodType] = useState(SUBSCRIPTION_PERIOD_TYPE_MONTHLY.toString())
	const [configurationItemTypeInput, setConfigurationItemTypeInput] = useState(CI_TYPE_WEBSITE.toString())
	const [dudaSiteId, setDudaSiteId] = useState('')
	const [description, setDescription] = useState('')
	const [purchaseOrderNumber, setPurchaseOrderNumber] = useState('')
	const [effectiveDateInput, setEffectiveDateInput] = useState(null)

	const routerNavigateFn = useNavigate()
	const wrapApiSearchMethodWithHandleRedirect = useCallback(apiSearchFn => async (query, options, requestOptions) => {
		const res = await apiSearchFn(query, options, requestOptions)
		handleRedirect(res, routerNavigateFn)
		return res
	}, [routerNavigateFn])

	const reset = useCallback(selectedDomain => {
		setSelectedDomain(selectedDomain)
		setSelectedAliasDomains([])
		setSelectedProduct(null)

		setLoadingHostingService(!!selectedDomain)
		setHostingService(null)

		setLoadingCustomer(!!selectedDomain)
		setCustomer(null)
		setPrimaryContact(null)

		setLoadingServerConfigurationItem(!!selectedDomain)
		setServerConfigurationItem(null)

		setLoadingConfigurationItem(!!selectedDomain)
		setConfigurationItem(null)

		setDoNotCreateSubscription(false)
		setLoadingSubscription(!!selectedDomain)
		setSubscription(null)

		setImporting(false)

		setPrice('0')
		setPeriodType(SUBSCRIPTION_PERIOD_TYPE_MONTHLY.toString())
		setConfigurationItemTypeInput(CI_TYPE_WEBSITE.toString())
		setDudaSiteId('')
		setDescription(!!selectedDomain ? selectedDomain.domain : '')
		setPurchaseOrderNumber('')
		setEffectiveDateInput('')
	}, [])

	const handleSelectDomain = (selectedDomain) => {
		if (importing) return

		reset(selectedDomain)
		getCompanies([{
			field: 'KitCloud UUID',
			op: 'eq',
			value: selectedDomain.account.id,
			udf: true
		}, {
			field: 'isActive',
			op: 'eq',
			value: true
		}], { limit: 1 })
			.then(res => {
				handleRedirect(res, routerNavigateFn)
				if (!res.data || res.data.length === 0) {
					setLoadingCustomer(false)
					return
				}
				const customer = res.data[0]
				getContacts([{
					field: 'primaryContact',
					op: 'eq',
					value: true
				}, {
					field: 'companyID',
					op: 'eq',
					value: customer.id
				}, {
					field: 'isActive',
					op: 'eq',
					value: true
				}], { limit: 1 })
					.then(res => {
						handleRedirect(res, routerNavigateFn)
						if (!res.data || res.data.length === 0) {
							setCustomer(customer)
							setLoadingCustomer(false)
							return
						}
						const primaryContact = res.data[0]
						setCustomer(customer)
						setPrimaryContact(primaryContact)
						setLoadingCustomer(false)
					})
					.catch(e => {
						handleRedirect(e.res, routerNavigateFn)
						console.error('Error getting primary contact:', e)
						setCustomer(customer)
						setLoadingCustomer(false)
					})
			})
			.catch(e => {
				handleRedirect(e.res, routerNavigateFn)
				console.error('Error getting customer:', e)
				setLoadingCustomer(false)
			})

		getDomainHostingServiceById(selectedDomain.id)
			.then(res => {
				handleRedirect(res, routerNavigateFn)
				if (!res.data || res.data.length === 0) {
					setLoadingHostingService(false)
					setLoadingServerConfigurationItem(false)
					return
				}
				const hostingService = res.data
				setHostingService(hostingService)
				setLoadingHostingService(false)
				setLoadingServerConfigurationItem(hostingService && hostingService.kit_server_hostname)

				if (hostingService && hostingService.kit_server_hostname) {
					getConfigurationItems([{
						field: 'referenceTitle',
						op: 'eq',
						value: hostingService.kit_server_hostname,
					}, {
						field: 'isActive',
						op: 'eq',
						value: true
					}], { limit: 1 })
						.then(res => {
							handleRedirect(res, routerNavigateFn)
							if (!res.data || res.data.length === 0) {
								setLoadingServerConfigurationItem(false)
								return
							}
							const serverConfigurationItem = res.data[0]
							setServerConfigurationItem(serverConfigurationItem)
							setLoadingServerConfigurationItem(false)
						})
						.catch(e => {
							handleRedirect(e.res, routerNavigateFn)
							console.error('Error getting configuration item:', e)
							setLoadingServerConfigurationItem(false)
						})
				}
			})
			.catch(e => {
				handleRedirect(e.res, routerNavigateFn)
				console.error('Error getting domain email service:', e)
				setLoadingHostingService(false)
				setLoadingServerConfigurationItem(false)
			})

		getConfigurationItems([{
			field: 'Hoveddomene',
			udf: true,
			op: 'eq',
			value: selectedDomain.domain,
		}, {
			field: 'isActive',
			op: 'eq',
			value: true
		}], { limit: 1 })
			.then(res => {
				handleRedirect(res, routerNavigateFn)
				if (!res.data || res.data.length === 0) {
					setLoadingConfigurationItem(false)
					setLoadingSubscription(false)
					return
				}
				const configurationItem = res.data[0]
				setConfigurationItem(configurationItem)
				setLoadingConfigurationItem(false)

				getSubscriptions([{
					field: 'configurationItemID',
					op: 'eq',
					value: configurationItem.id
				}, {
					field: 'status',
					op: 'eq',
					value: SUBSCRIPTION_STATUS_ACTIVE
				}], { limit: 1 })
					.then(res => {
						handleRedirect(res, routerNavigateFn)
						if (!res.data || res.data.length === 0) {
							setDescription('')
							setPurchaseOrderNumber('')
							setLoadingSubscription(false)
							return
						}
						const subscription = res.data[0]
						setSubscription(subscription)
						setLoadingSubscription(false)
						setDescription(subscription.description)
						setPurchaseOrderNumber(subscription.purchaseOrderNumber)
						setPrice(subscription.periodPrice)
					})
					.catch(e => {
						handleRedirect(e.res, routerNavigateFn)
						console.error('Error getting subscription:', e)
						setLoadingSubscription(false)
					})
			})
			.catch(e => {
				handleRedirect(e.res, routerNavigateFn)
				console.error('Error getting configuration item:', e)
				setLoadingConfigurationItem(false)
				setLoadingSubscription(false)
			})
	}

	const handleSelectProduct = selectedProduct => {
		setSelectedProduct(selectedProduct)
		setPrice(subscription ? subscription.periodPrice.toString() : (selectedProduct ? selectedProduct.unitPrice.toString() : price))
		setPeriodType(subscription && subscription.periodType ? subscription.periodType.value.toString() : (selectedProduct && selectedProduct.periodType ? selectedProduct.periodType.value.toString() : periodType))
	}

	const handleImportWebsite = () => {
		if (loadingHostingService || loadingCustomer || loadingServerConfigurationItem || loadingConfigurationItem || loadingSubscription || importing || !selectedDomain || !selectedProduct || !customer || !effectiveDateInput) return

		// XXX: Convert Effective Date (datepicker input) to UTC
		// XXX: Set time to 08:00 due to Autotask API bug which sets date back 1 day if time is 00:00
		const effectiveDate = new Date(Date.UTC(effectiveDateInput.getFullYear(), effectiveDateInput.getMonth(), effectiveDateInput.getDate(), 8))
		setImporting(true)

		// Expiration Date = Effective Date + 10 years - 1 day
		const expirationDate = new Date(Date.UTC(effectiveDate.getUTCFullYear() + 10, effectiveDate.getUTCMonth(), effectiveDate.getUTCDate() - 1, 8))

		const configurationItemType = parseInt(configurationItemTypeInput)
		const newCI = {
			parentConfigurationItemID: selectedProduct.defaultInstalledProductCategoryID === CI_CATEGORY_IVO_WEBSITE_DUDA ? null : (serverConfigurationItem ? serverConfigurationItem.id : null),
			configurationItemCategoryID: selectedProduct.defaultInstalledProductCategoryID,
			configurationItemType,
			referenceTitle: selectedDomain.domain,
			productID: selectedProduct.id,
			companyID: customer.id,
			contactID: primaryContact ? primaryContact.id : null,
			isActive: configurationItem ? configurationItem.isActive : true,
			installDate: configurationItem ? configurationItem.installDate : (new Date()).toISOString(),
			userDefinedFields: {
				'Hoveddomene': selectedDomain.domain,
				'Aliasdomener': selectedAliasDomains.map(selectedAliasDomain => selectedAliasDomain.domain).join("\n"),
				'Duda Site ID': dudaSiteId,
			}
		}

		let promise
		if (configurationItem) {
			promise = updateConfigurationItem(configurationItem.id, newCI)
		} else {
			promise = createConfigurationItem(newCI)
		}

		promise.then(res => {
			handleRedirect(res, routerNavigateFn)
			if (!res.data || res.data.itemId === null) {
				// Add failure message (did not create a configuration item)
				console.error('Error importing website ' + selectedDomain.domain + ': Could not create configuration item')
				setMessages(messages => messages.concat({
					key: 'import_error_no_ci_' + selectedDomain.id + '_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'error',
					icon: 'exclamation circle',
					title: 'Import feilet',
					content: 'Kunne ikke opprette configuration item for nettsiden ' + selectedDomain.domain
				}))
				return
			}
			const configurationItemID = res.data.itemId

			// Create domain relations
			const domains = [selectedDomain].concat(selectedAliasDomains)
			const domainRelationPromises = domains.map(domain => getConfigurationItemRelations([
				{op: 'or', items: [
					{op: 'and', items: [
						{field: 'configurationItemID', op: 'eq', value: configurationItemID},
						{field: 'relatedConfigurationItemID', op: 'eq', value: domain.autotask_ci_id},
					]},
					{op: 'and', items: [
						{field: 'configurationItemID', op: 'eq', value: domain.autotask_ci_id},
						{field: 'relatedConfigurationItemID', op: 'eq', value: configurationItemID},
					]},
				]},
			])
			.then(relations => {
				handleRedirect(relations, routerNavigateFn)
				if (relations.data_info.count < 1) return createConfigurationItemRelation({
					configurationItemID,
					relatedConfigurationItemID: domain.autotask_ci_id,
					relationship: 1,
				})
			}))

			const newSubscription = {
				configurationItemID,
				subscriptionName: selectedProduct.name,
				description,
				effectiveDate: effectiveDate.toISOString(),
				expirationDate: expirationDate.toISOString(),
				vendorID: selectedProduct.defaultVendorID,
				periodType: parseInt(periodType),
				periodPrice: parseFloat(price.toString()),
				periodCost: formatNumber(recalculateSubscriptionPeriodPrice(selectedProduct.unitCost, selectedProduct.periodType.value, periodType), {asString: false, precision: 2}),
				purchaseOrderNumber,
				status: subscription ? subscription.status : 1,
				materialCodeID: subscription ? subscription.materialCodeID : selectedProduct.productBillingCodeID
			}

			if (subscription) {
				return Promise.all([Promise.resolve(configurationItemID), updateSubscription(subscription.id, newSubscription)].concat(domainRelationPromises))
			} else {
				return Promise.all([Promise.resolve(configurationItemID), doNotCreateSubscription ? null : createSubscription(newSubscription)].concat(domainRelationPromises))
			}
		}).then(([configurationItemID, res]) => {
			handleRedirect(res, routerNavigateFn)
			if (!doNotCreateSubscription && (!res || !res.data || res.data.itemId === null)) {
				// Add failure message (did not create a subscription)
				console.error('Error importing website ' + selectedDomain.domain + ': Could not create subscription')
				setMessages(messages => messages.concat({
					key: 'import_error_no_subscription_' + selectedDomain.id + '_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'error',
					icon: 'exclamation circle',
					title: 'Import feilet',
					content: 'Kunne ikke opprette abonnement for nettsiden ' + selectedDomain.domain
				}))
				return
			}
			const subscriptionID = res && res.data && res.data.itemId ? res.data.itemId : null

			// Add success message
			console.info('Imported website ' + selectedDomain.domain + ' successfully')
			setMessages(messages => messages.concat({
				key: 'import_success_' + selectedDomain.id + '_' + Math.round(new Date() / 1000).toString(),
				dismissable: true,
				type: 'success',
				icon: 'check',
				title: 'Import vellykket',
				content: <>
					<p>Importerte nettsiden {selectedDomain.domain} uten feil.</p>
					<p><Link href={'https://ww19.autotask.net/Autotask/AutotaskExtend/ExecuteCommand.aspx?Code=OpenInstalledProduct&InstalledProductID=' + encodeURIComponent(configurationItemID)} target="tmp" popupOtions={{width: 1, height: 1}}>Åpne CI i Autotask</Link> (ID <code>{configurationItemID}</code>){subscriptionID ? <>&nbsp;&mdash; Abonnement ID <code>{subscriptionID}</code></> : null}</p>
				</>
			}))
		}).catch(e => {
			handleRedirect(e.res, routerNavigateFn)
			// Add failure message (unknown error)
			console.error('Error importing website ' + selectedDomain.domain + ':', e)
			setMessages(messages => messages.concat({
				key: 'import_error_' + selectedDomain.id + '_' + Math.round(new Date() / 1000).toString(),
				dismissable: true,
				type: 'error',
				icon: 'exclamation circle',
				title: 'Import feilet',
				content: 'Kunne ikke importere nettside ' + selectedDomain.domain + ': Ukjent feil (sjekk JavaScript-konsollen)'
			}))
		}).finally(() => reset(null))
	}

	const now = new Date()

	// decide messages
	let customerInfo = 'Velg et hoveddomene.'
	let productInfo = 'Velg et produkt.'
	let hostingServiceInfo = 'Velg et hoveddomene.'
	let serverConfigurationItemInfo = 'Velg et hoveddomene.'
	let configurationItemInfo = 'Velg et hoveddomene.'
	let subscriptionInfo = 'Velg et hoveddomene.'
	if (selectedDomain) {
		// decide "Kunde" message
		if (loadingCustomer) {
			customerInfo = <div>Ser etter kunde...</div>
		} else if (customer) {
			customerInfo = <div>Fant kunde <Link href={'https://ww19.autotask.net/Autotask/AutotaskExtend/ExecuteCommand.aspx?Code=OpenAccount&AccountID=' + encodeURIComponent(customer.id)} target="tmp" popupOptions={{width: 1, height: 1}}>{customer.companyName}</Link> (ID <code>{customer.id}</code>), nettsiden vil knyttes til denne kunden.</div>
		} else {
			customerInfo = <div>Fant ikke noe kunde i Autotask for <Link href={'/accounts/' + encodeURIComponent(selectedDomain.account.id)} target="_blank">{selectedDomain.account.name}</Link>, du må opprette/synke kunden først, deretter velge hoveddomenet på nytt.</div>
		}

		// decide "Produkt" message
		if (selectedProduct) {
			productInfo = <div>Valgt produkt <Link href={'https://ww19.autotask.net/Autotask/AutotaskExtend/ExecuteCommand.aspx?Code=OpenProduct&ProductID=' + encodeURIComponent(selectedProduct.id)} target="tmp" popupOptions={{width: 1, height: 1}}>{selectedProduct.name}</Link> (ID <code>{selectedProduct.id}</code>), dette vil knyttes til nettsiden.</div>
		}

		// decide "Hosting-tjeneste" message
		if (loadingHostingService) {
			hostingServiceInfo = <div>Ser etter hosting-tjeneste...</div>
		} else if (hostingService) {
			hostingServiceInfo = <div>Fant hosting-tjeneste {hostingService.name}{hostingService.type === 'kit_redir' ? <span> (til <Link href={hostingService.redirect.url} target="_blank" rel="noopener nofollow noreferrer">{hostingService.redirect.url}</Link>)</span> : ''}</div>
		} else {
			hostingServiceInfo = <div>Fant ikke noe hosting-tjeneste</div>
		}

		// decide "Server CI" message
		if (loadingServerConfigurationItem) {
			serverConfigurationItemInfo = <div>Ser etter server CI...</div>
		} else if (serverConfigurationItem) {
			serverConfigurationItemInfo = <div>Fant <Link href={'https://ww19.autotask.net/Autotask/AutotaskExtend/ExecuteCommand.aspx?Code=OpenInstalledProduct&InstalledProductID=' + encodeURIComponent(serverConfigurationItem.id)} target="tmp" popupOptions={{width: 1, height: 1}}>CI for serveren</Link> (ID <code>{serverConfigurationItem.id}</code>), dette vil knyttes til nettsiden.</div>
		} else {
			serverConfigurationItemInfo = <div>Fant ikke server eller fant ikke CI for serveren.</div>
		}

		// decide "CI" message
		if (loadingConfigurationItem || loadingCustomer) {
			configurationItemInfo = <div>Ser etter eksisterende CI...</div>
		} else if (configurationItem && customer) {
			configurationItemInfo = <div>Fant <Link href={'https://ww19.autotask.net/Autotask/AutotaskExtend/ExecuteCommand.aspx?Code=OpenInstalledProduct&InstalledProductID=' + encodeURIComponent(configurationItem.id)} target="tmp" popupOptions={{width: 1, height: 1}}>eksisterende CI for nettsiden</Link> (ID <code>{configurationItem.id}</code>){configurationItem.companyID === customer.id ? ', dette vil bli oppdatert.' : ', men den tilhører en annen kunde. Denne CIen må deaktiveres før nettside kan importeres.'}</div>
		} else {
			configurationItemInfo = <div>Fant ikke noe CI for nettsiden, et nytt vil bli opprettet.</div>
		}

		// decide "Abonnement" message
		if (loadingSubscription) {
			subscriptionInfo = <div>Ser etter eksisterende abonnement...</div>
		} else if (subscription) {
			subscriptionInfo = <div>Fant eksisterende abonnement for nettsiden (ID <code>{subscription.id}</code>), nettsiden vil knyttes til hoveddomenet.</div>
		} else {
			subscriptionInfo = <div>Fant ikke noe abonnement for nettsiden, et nytt vil{doNotCreateSubscription ? <b>&nbsp;ikke</b> : null} bli opprettet.</div>
		}
	}

	const statusMessages = useMemo(() => <>
		<Grid stackable columns={2}>
			<Grid.Column>
				<Message
					info={!selectedDomain || loadingCustomer}
					success={selectedDomain && !loadingCustomer && !!customer}
					error={selectedDomain && !loadingCustomer && !customer}
					icon={loadingCustomer ? <Icon loading name="spinner" /> : (selectedDomain && !!customer ? 'check' : (selectedDomain && !customer ? 'exclamation circle' : 'info circle'))}
					header="Kunde"
					content={customerInfo}
				/>
				<Message
					warning={!selectedProduct}
					success={!!selectedProduct}
					icon={selectedProduct ? 'check' : 'exclamation triangle'}
					header="Produkt"
					content={productInfo}
				/>
				<Message
					info={!selectedDomain || loadingHostingService}
					success={selectedDomain && !loadingHostingService && !!hostingService}
					error={selectedDomain && !loadingHostingService && !hostingService}
					icon={loadingHostingService ? <Icon loading name="spinner" /> : (selectedDomain && !!hostingService ? 'check' : (selectedDomain && !hostingService ? 'exclamation circle' : 'info circle'))}
					header="Hosting-tjeneste"
					content={hostingServiceInfo}
				/>
			</Grid.Column>
			<Grid.Column>
				<Message
					info={!selectedDomain || loadingServerConfigurationItem}
					success={selectedDomain && !loadingServerConfigurationItem && !!serverConfigurationItem}
					warning={selectedDomain && !loadingServerConfigurationItem && !serverConfigurationItem}
					icon={loadingServerConfigurationItem ? <Icon loading name="spinner" /> : (selectedDomain && !!serverConfigurationItem ? 'check' : (!selectedDomain ? 'info circle' : 'exclamation triangle'))}
					header="Server CI"
					content={serverConfigurationItemInfo}
				/>
				<Message
					info={!selectedDomain || loadingConfigurationItem || !configurationItem || !customer}
					success={selectedDomain && !loadingConfigurationItem && !!configurationItem && !!customer && configurationItem.companyID === customer.id}
					error={selectedDomain && !loadingConfigurationItem && !!configurationItem && !!customer && configurationItem.companyID !== customer.id}
					icon={loadingConfigurationItem || loadingCustomer ? <Icon loading name="spinner" /> : (selectedDomain && !!configurationItem && !!customer ? (configurationItem.companyID === customer.id ? 'check' : 'exclamation circle') : 'info circle')}
					header="CI"
					content={configurationItemInfo}
				/>
				<Message
					info={!selectedDomain || loadingSubscription || !subscription}
					success={selectedDomain && !loadingSubscription && !!subscription}
					icon={loadingSubscription ? <Icon loading name="spinner" /> : (selectedDomain && !!subscription ? 'check' : 'info circle')}
					header="Abonnement"
					content={subscriptionInfo}
				/>
			</Grid.Column>
		</Grid>
	</>, [
		configurationItem,
		configurationItemInfo,
		customer,
		customerInfo,
		hostingService,
		hostingServiceInfo,
		loadingConfigurationItem,
		loadingCustomer,
		loadingHostingService,
		loadingServerConfigurationItem,
		loadingSubscription,
		productInfo,
		selectedDomain,
		selectedProduct,
		serverConfigurationItem,
		serverConfigurationItemInfo,
		subscription,
		subscriptionInfo,
	])

	useEffect(() => {
		setRail(statusMessages)
		return () => setRail(null)
	}, [statusMessages, setRail])

	return <>
		<Segment vertical padded="very">
			<Container>
				<Header size="huge">Importer nettside til Autotask</Header>
				<Accordion>
					<Accordion.Title
						active={showDescription}
						onClick={() => setShowDescription(showDescription => !showDescription)}
					>
						<Icon name="dropdown" /> Hva gjør verktøyet?
					</Accordion.Title>
					<Accordion.Content active={showDescription}>
						<List bulleted>
							<List.Item>
								Oppretter et ConfigurationItem for nettsiden
								<List bulleted>
									<List.Item><b>Parent CI:</b> CI for serveren som hoster nettsiden</List.Item>
									<List.Item><b>Configuration Item Category:</b> Default Configuration Item Category fra Product</List.Item>
									<List.Item><b>Configuration Item Type:</b> Må velges: Nettside (ID <code>{CI_TYPE_WEBSITE}</code>) / Nettbutikk (ID <code>{CI_TYPE_WEBSHOP}</code>)</List.Item>
									<List.Item><b>Description:</b> Hoveddomene i UTF8-format</List.Item>
									<List.Item><b>Product:</b> Må velges fra listen på denne siden</List.Item>
									<List.Item><b>Company:</b> Kunden som eier domenet</List.Item>
									<List.Item><b>Contact:</b> Primær-kontakt hos kunden</List.Item>
									<List.Item><b>Status:</b> Active</List.Item>
									<List.Item><b>Install Date:</b> Nå</List.Item>
									<List.Item><b>Hoveddomene:</b> Domenenavnet til hoveddomenet du velger</List.Item>
									<List.Item><b>Aliasdomener:</b> Domenenavn til eventuelle aliasdomener du velger</List.Item>
								</List>
							</List.Item>
							<List.Item>
								Oppretter en Subscription for ConfigurationItem som ble opprettet
								<List bulleted>
									<List.Item><b>Name:</b> Samme som produkt-navn</List.Item>
									<List.Item><b>Description:</b> Beskrivelse som legges på fakturaen, kan fylles ut på denne siden (valgfritt, standard: hoveddomene i UTF8-format)</List.Item>
									<List.Item><b>Effective Date:</b> Må fylles ut, betyr første dato abonnementet skal gjelde fra (typisk dem 1. i neste faktura-måned)</List.Item>
									<List.Item><b>Expiration Date:</b> Effective Date + 10 år - 1 dag</List.Item>
									<List.Item><b>Period Type:</b> Monthly (ID <code>2</code>) eller Yearly (ID <code>5</code>)</List.Item>
									<List.Item><b>Period Price:</b> Må fylles ut, betyr pris per år/måned (avhengig av Period Type)</List.Item>
									<List.Item><b>Period Cost:</b> Samme som over, men innkjøpspris (valgfritt)</List.Item>
									<List.Item><b>Purchase Order Number:</b> Innkjøpsnummer mottatt fra kunde, kan fylles ut på denne siden (valgfritt)</List.Item>
									<List.Item><b>Status:</b> Aktiv (ID <code>1</code>)</List.Item>
									<List.Item><b>Material Code:</b> Hentes fra produkt</List.Item>
								</List>
							</List.Item>
						</List>
					</Accordion.Content>
				</Accordion>
			</Container>
		</Segment>

		<Segment vertical padded="very">
			<Container>
				<Header>Velg hoveddomene</Header>
				<p>Søk på domenenavn eller kundenavn, deretter velg domenet ved å trykke på det.</p>
				<p>Domenet må først opprettes i Autotask, noe som skjer automatisk ved oppretting av domener eller flytting av domener til oss. Søkeresultater viser kun domener som finnes i Autotask.</p>

				<DataTable
					searchOnly
					limit={5}
					columns={[
						{ key: 'domain', text: 'Domene', visible: true, sortable: true, searchable: true },
						{ key: 'ace', text: 'Domene (ren tekst)', searchable: true },
						{ key: 'account.name', text: 'Kunde', visible: true, sortable: true, searchable: true },
						{ key: 'expire_at', text: 'Utløp', visible: true, sortable: true, searchable: true },
					]}
					query={{op: 'ne', key: 'autotask_ci_id', val: null}}
					defaultOrder={{'account.name': 'ASC', 'domain': 'ASC'}}
					apiSearch={wrapApiSearchMethodWithHandleRedirect(getDomains)}
					onClickRow={data => handleSelectDomain(data)}
					isRowActive={data => selectedDomain !== null && data.id === selectedDomain.id}
					renderRow={data => {
						const expireDate = !data.expire_at ? null : new Date(data.expire_at)
						const nowBeforeExpireDate = expireDate !== null && now < expireDate
						let renewalDate = expireDate !== null ? new Date(expireDate) : null
						if (renewalDate !== null && !nowBeforeExpireDate && data.domain_name_parts.min_register_period_y !== null && data.auto_renew) {
							renewalDate.setFullYear(renewalDate.getFullYear() + data.domain_name_parts.min_register_period_y)
						}
						return [
							<>
								{data.domain}
								{data.ace ? <><br/><span className="text-secondary">{data.ace}</span></> : null}
							</>,
							<Link href={'/accounts/' + encodeURIComponent(data.account.id)}>{data.account.name}</Link>,
							!expireDate ? 'Ukjent utløp' : ((data.auto_renew ? 'Fornyes ' : (nowBeforeExpireDate ? 'Løper ut ' : 'Løp ut ')) + dateToString(renewalDate, false)),
						]
					}}
				/>

				<Header>Velg produkt</Header>
				<DataTable
					format="autotask"
					limit={5}
					columns={[
						{ key: 'name', text: 'Navn', visible: true, searchable: true },
						{ key: 'description', text: 'Beskrivelse', visible: false, searchable: true },
						{ key: 'unitPrice', text: 'Pris', visible: true },
					]}
					query={[
						{field: 'productCategory', op: 'in', value: [
							PRODUCT_CATEGORY_WEBSITE,
							PRODUCT_CATEGORY_WEBSITE_HOSTING,
							PRODUCT_CATEGORY_WEBSITE_HOSTING_MANAGED,
							PRODUCT_CATEGORY_WEBSITE_IVO_DUDA,
							PRODUCT_CATEGORY_WEBSITE_IVO,
						]},
						{field: 'isActive', op: 'eq', value: true},
					]}
					defaultOrder={{'id': 'ASC'}}
					apiSearch={wrapApiSearchMethodWithHandleRedirect(getProducts)}
					onClickRow={data => handleSelectProduct(data)}
					isRowActive={data => selectedProduct !== null && data.id === selectedProduct.id}
					renderRow={data => {
						let { description: productDescription } = data
						productDescription = (productDescription ?? '').replace(/\n/g, ' ').trim()
						if (productDescription.length > 60) {
							productDescription = productDescription.substring(0, 57) + '...'
						}
						return [
							data.name + (productDescription.length > 0 ? ' (' + productDescription + ')' : ''),
							data.periodType ? formatPeriodPrice(data.unitPrice ?? 0.0, getProductPeriodPriceFormat(data.periodType.value)) : formatPeriodPrice(data.unitPrice ?? 0.0),
						]
					}}
				/>
			</Container>
		</Segment>

		<Segment vertical padded="very">
			<Container>
				<Header>Se gjennom og importer</Header>
				<p>Vær oppmerksom på at all informasjon du skriver inn her, vil vises på faktura til kunden.</p>

				{railVisible ? null : <>
					{statusMessages}
					<p>&nbsp;</p>
				</>}

				<Form onSubmit={preventDefault(() => handleImportWebsite())}>
					<Form.Group>
						<Form.Field width={6} required>
							<label>Hoveddomene</label>
							<input
								onFocus={e => e.target.select()}
								placeholder="Velg et hoveddomene."
								value={selectedDomain ? selectedDomain.ace ?? selectedDomain.domain : ''}
								readOnly
							/>
						</Form.Field>
						<Form.Field width={5} required>
							<label>CI Type</label>
							<Dropdown
								selection
								value={configurationItemTypeInput}
								options={[
									{ key: 'website', text: 'Nettside', value: CI_TYPE_WEBSITE.toString() },
									{ key: 'webshop', text: 'Nettbutikk', value: CI_TYPE_WEBSHOP.toString() },
								]}
								onChange={(_, {value}) => setConfigurationItemTypeInput(value)}
							/>
						</Form.Field>
						<Form.Field width={5} required>
							<label>Pris</label>
							<Input
								value={price}
								type="number"
								step="0.01"
								onChange={(e, data) => setPrice(data.value.replace(',', '.'))}
								placeholder="0"
								labelPosition="right"
								label={<Dropdown
									value={periodType}
									options={[
										{
											key: 'monthly',
											text: getPeriodPriceLabel(getSubscriptionPeriodPriceFormat(SUBSCRIPTION_PERIOD_TYPE_MONTHLY)),
											value: SUBSCRIPTION_PERIOD_TYPE_MONTHLY.toString()
										},
										{
											key: 'yearly',
											text: getPeriodPriceLabel(getSubscriptionPeriodPriceFormat(SUBSCRIPTION_PERIOD_TYPE_YEARLY)),
											value: SUBSCRIPTION_PERIOD_TYPE_YEARLY.toString()
										},
									]}
									onChange={(_, {value}) => {
										setPeriodType(value)
										setPrice(formatNumberForInputField(recalculateSubscriptionPeriodPrice(price, periodType, value)))
									}}
								/>}
							/>
						</Form.Field>
					</Form.Group>
					{price === '0' && !loadingSubscription && !subscription ? <Form.Checkbox
						label="Opprett abonnement"
						checked={!doNotCreateSubscription}
						onChange={(_, data) => setDoNotCreateSubscription(!data.checked)}
					/> : null}
					<Form.Field>
						<label>Aliasdomener</label>
						<DataSelect
							multiple
							clearable
							disabled={!selectedDomain}
							placeholder={selectedDomain ? null : 'Velg et hoveddomene.'}
							query={!selectedDomain ? null : {op: 'and', grp: [
								{ key: 'autotask_ci_id', op: 'ne', val: null },
								{ key: 'account.id', op: 'eq', val: selectedDomain.account.id },
								{ key: 'id', op: 'ne', val: selectedDomain.id },
							]}}
							order={{'account.name': 'ASC', 'domain': 'ASC'}}
							searchColumns={['domain', 'ace', 'account.name']}
							apiSearch={wrapApiSearchMethodWithHandleRedirect(getDomains)}
							valueKey="id"
							textKey="domain"
							value={selectedAliasDomains}
							onChange={selectedAliasDomains => setSelectedAliasDomains(selectedAliasDomains)}
						/>
					</Form.Field>
					<Form.Field>
						<label>Server</label>
						<input
							onFocus={e => e.target.select()}
							placeholder={!selectedDomain ? 'Velg et hoveddomene.' : 'Fant ikke server eller fant ikke CI for serveren.'}
							value={selectedDomain && serverConfigurationItem ? serverConfigurationItem.rmmDeviceAuditHostname + ' (' + serverConfigurationItem.rmmDeviceAuditDescription + ')' : ''}
							readOnly
						/>
					</Form.Field>
					<Form.Group>
						<Form.Field width={10}>
							<label>Innkjøpsnummer</label>
							<Input value={purchaseOrderNumber} onChange={(e, data) => setPurchaseOrderNumber(data.value)} />
						</Form.Field>
						<SemanticDatepicker
							label="Effective Date"
							required
							width={6}
							locale="nb-NO"
							format={dateLocales.no.dateFormat}
							value={effectiveDateInput}
							onChange={(e, data) => setEffectiveDateInput(data.value)}
						/>
					</Form.Group>
					{selectedProduct && selectedProduct.defaultInstalledProductCategoryID === CI_CATEGORY_IVO_WEBSITE_DUDA ? <Form.Field required>
						<label>Duda Site ID</label>
						<Input value={dudaSiteId} onChange={(e, data) => setDudaSiteId(data.value)} />
					</Form.Field> : null}
					<Form.Field>
						<label>Beskrivelse</label>
						<TextArea value={description} onChange={(e, data) => setDescription(data.value)} />
					</Form.Field>
					<Form.Button
						color="orange"
						loading={loadingHostingService || loadingCustomer || loadingConfigurationItem || loadingServerConfigurationItem || loadingSubscription || importing}
						disabled={!selectedDomain || !selectedProduct || !customer || loadingHostingService || loadingConfigurationItem || loadingServerConfigurationItem || loadingSubscription || (!!customer && !!configurationItem && configurationItem.companyID !== customer.id) || !effectiveDateInput || (selectedProduct && selectedProduct.defaultInstalledProductCategoryID === CI_CATEGORY_IVO_WEBSITE_DUDA && dudaSiteId.trim().length === 0) || importing}
					>
						Importer nettside <Icon name="exchange" className="right" />
					</Form.Button>
				</Form>
			</Container>
		</Segment>
	</>
}