import React, { useState } from 'react'
import { Segment, Container, Header, Form, Input } from 'semantic-ui-react'
import { emailLocalPartRegex, emailRegex, handleRedirect, navigate, preventDefault } from '../../../helpers'
import { createUser } from '../../../api/mail1/users'
import EmailQuotaInput from '../../../components/email_quota_input'
import { useNavigate, useRouteLoaderData } from 'react-router-dom'
import { useMessages } from '../../root'

const defaultKitmailDomainLoaderData = {
	domain: '',
	kitmailDomain: null,
}

export default function NewKitmailUserPage() {
	const { domain } = useRouteLoaderData('kitmailDomain') ?? defaultKitmailDomainLoaderData
	const [, setMessages] = useMessages()
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [quota, setQuota] = useState(5368709120) // 5 GiB
	const routerNavigateFn = useNavigate()

	const handleCreateUser = () => {
		const kitmailUser = {
			email,
			password,
			quota,
		}
		if (!(emailRegex.test(kitmailUser.email)) || kitmailUser.password.length === 0) return
		createUser(kitmailUser).then(res => {
			setMessages(messages => messages.concat({
				key: 'success_' + Math.round(new Date() / 1000).toString(),
				dismissable: true,
				type: 'success',
				icon: 'check',
				content: 'Brukeren er opprettet.',
			}))
			navigate('/kitmail/users/' + kitmailUser.email, '_self', {}, routerNavigateFn)
		}).catch(e => {
			setMessages(messages => messages.concat({
				key: 'error_' + Math.round(new Date() / 1000).toString(),
				dismissable: true,
				type: 'error',
				icon: 'exclamation circle',
				content: 'Brukeren kunne ikke opprettes: ' + (e && e.message ? e.message : 'Ukjent feil (sjekk JavaScript-konsollen)'),
			}))
			handleRedirect(e.res, routerNavigateFn)
		})
	}

	return <>
		<Segment vertical padded="very">
			<Container>
				<Header size="huge">Opprett KitMail-bruker</Header>
				<Form onSubmit={preventDefault(() => handleCreateUser())}>
					<Form.Field>
						<label>E-postadresse</label>
						<Input
							label={typeof domain === 'string' && domain.length > 0 ? '@' + domain : null}
							labelPosition={typeof domain === 'string' && domain.length > 0 ? 'right' : null}
							placeholder={typeof domain === 'string' && domain.length > 0 ? 'ola.nordmann' : 'ola.nordmann@example.com'}
							type={typeof domain === 'string' && domain.length > 0 ? 'text' : 'email'}
							autoComplete="off"
							pattern={typeof domain === 'string' && domain.length > 0 ? emailLocalPartRegex.source : null}
							required
							value={typeof domain === 'string' && domain.length > 0 ? email.replace('@' + domain, '') : email}
							onChange={(e, data) => setEmail(typeof domain === 'string' && domain.length > 0 ? data.value.replace(/@.*$/g, '') + '@' + domain : data.value)}
						/>
					</Form.Field>
					<Form.Input
						label="Passord"
						type="password"
						autoComplete="off"
						required
						value={password}
						onChange={(e, data) => setPassword(data.value)}
					/>
					<EmailQuotaInput
						label="Lagringskvote"
						value={quota}
						onChange={value => setQuota(value)}
					/>
					<Form.Button
						type="submit"
						color="orange"
						disabled={!(emailRegex.test(email)) || password.length === 0}
						content="Opprett bruker"
					/>
				</Form>
			</Container>
		</Segment>
	</>
}