import React, { useCallback, useState } from 'react'
import { Segment, Header, Container, Button, Popup, Icon, Progress, Grid } from 'semantic-ui-react'
import DataTable from '../../../components/data_table'
import { getUsers } from '../../../api/mail1/users'
import { formatNumber, handleRedirect, navigate, preventDefault } from '../../../helpers'
import Link from '../../../components/link'
import { useNavigate } from 'react-router-dom'
import { usePreloadedEntityDispatcher } from '../../root'

export default function KitmailUsersPage() {
	const [dataInfo, setDataInfo] = useState(null)
	const routerNavigateFn = useNavigate()
	const preloadEntity = usePreloadedEntityDispatcher()

	const apiSearch = useCallback(async (query, options, requestOptions) => {
		const res = await getUsers(query, options, requestOptions)
		handleRedirect(res, routerNavigateFn)
		if (query === null) {
			setDataInfo(res.data_info)
		}
		return res
	}, [routerNavigateFn])

	return <>
		<Segment vertical padded="very">
			<Container>
				<Grid stackable>
					<Grid.Row>
						<Grid.Column width={10}>
							<Header size="huge">
								KitMail-brukere
								<Header.Subheader>Liste over KitMail-brukere du kan administrere</Header.Subheader>
							</Header>
						</Grid.Column>
						<Grid.Column width={6} textAlign="right">
							<Button
								as="a"
								href="/kitmail/users/new"
								onClick={preventDefault(e => navigate('/kitmail/users/new', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
								color="orange"
								label={dataInfo ? formatNumber(dataInfo.count) : '0'}
								labelPosition="right"
								content={<><Icon name="add" /> Opprett ny bruker</>}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<p></p>

				<DataTable
					columns={[
						{ key: 'email.address', text: 'E-postadresse', width: 3, visible: true, sortable: true, searchable: true },
						{ key: 'email.domain.domain', text: 'Domene', width: 3, visible: true },
						{ key: 'size.bytes.quota', text: 'Størrelse', width: 8, visible: true },
						{ key: 2, text: '', width: 2, visible: true },
					]}
					defaultOrder={{'email.address': 'ASC'}}
					apiSearch={apiSearch}
					onClickRow={(data, index, { ctrlKey, shiftKey }) => {
						// preload user data
						preloadEntity(data)
						// navigate to user
						const url = '/kitmail/users/' + data.email.address
						const target = (ctrlKey || shiftKey) ? '_blank' : '_self'
						navigate(url, target, {}, routerNavigateFn)
					}}
					renderRow={data => [
						data.email.address,
						data.email.domain ? <Link href={'/kitmail/domains/' + encodeURIComponent(data.email.domain.domain)}>{data.email.domain.domain}</Link> : null,
						<>
							<Progress
								percent={data.size.percent.usage * 100}
								warning={data.size.percent.usage >= 0.95 && data.size.percent.usage < 0.99}
								error={data.size.percent.usage >= 0.99}
								precision={0}
								progress='percent'
								label={data.size.human.usage + ' / ' + data.size.human.quota}
							/>
						</>,
						<div className="text-ha-right">
							<Button.Group size="mini" compact>
								<Popup
									inverted
									content="Endre bruker"
									trigger={<Button
										as="a"
										icon="edit"
										href={'/kitmail/users/' + data.email.address + '/edit'}
										onClick={preventDefault(e => {
											// preload user data
											preloadEntity(data)
											// navigate to user edit page
											navigate('/kitmail/users/' + data.email.address + '/edit', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn)
										})}
									/>}
								/>
								<Popup
									inverted
									content="Slett bruker"
									trigger={<Button
										as="a"
										negative
										icon="delete"
										href={'/kitmail/users/' + data.email.address + '/delete'}
										onClick={preventDefault(e => {
											// preload user data
											preloadEntity(data)
											// navigate to user delete page
											navigate('/kitmail/users/' + data.email.address + '/delete', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn)
										})}
									/>}
								/>
							</Button.Group>
						</div>
					]}
				/>
			</Container>
		</Segment>
	</>
}