import React, { useCallback, useMemo, useState } from 'react'
import { Segment, Header, Container, Button, Popup, Icon, Grid } from 'semantic-ui-react'
import DataTable from '../../components/data_table'
import { getSMSMessages, STATUS_CODES } from '../../api/sms'
import { dateToString, formatNumber, filterTypes, preventDefault, navigate, handleRedirect } from '../../helpers'
import { useNavigate, useRouteLoaderData } from 'react-router-dom'

export default function SmsMessagesPage() {
	const { gatewayId } = useRouteLoaderData('smsGateway')
	const [dataInfo, setDataInfo] = useState(null)
	const routerNavigateFn = useNavigate()

	const defaultQuery = useMemo(() => ({op: 'eq', key: 'gateway.id', val: gatewayId}), [gatewayId])

	const apiSearch = useCallback(async (query, options, requestOptions) => {
		const res = await getSMSMessages(query, options, requestOptions)
		handleRedirect(res, routerNavigateFn)
		if (JSON.stringify(query) === JSON.stringify(defaultQuery)) {
			setDataInfo(res.data_info)
		}
		return res
	}, [defaultQuery, routerNavigateFn])

	return <>
		<Segment vertical padded="very">
			<Container fluid>
				<Grid stackable>
					<Grid.Row>
						<Grid.Column width={10}>
							<Header size="huge">
								Tekstmeldinger
								<Header.Subheader>Liste over sendte tekstmeldinger (SMS)</Header.Subheader>
							</Header>
						</Grid.Column>
						<Grid.Column width={6} textAlign="right">
							<Button
								as="a"
								href={'/sms/gateways/' + encodeURIComponent(gatewayId) + '/messages/new'}
								onClick={preventDefault(e => navigate('/sms/gateways/' + encodeURIComponent(gatewayId) + '/messages/new', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
								color="orange"
								label={dataInfo ? formatNumber(dataInfo.count) : '0'}
								labelPosition="right"
								content={<><Icon name="send" /> Ny tekstmelding</>}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<p></p>

				<DataTable
					columns={[
						{ key: 'created_at', text: 'Sendt', width: 1, visible: true, sortable: true, filterType: filterTypes.date },
						{ key: 'sender', text: 'Avsender', width: 1, visible: true, sortable: true, searchable: true, filterType: filterTypes.search },
						{ key: 'recipient', text: 'Mottaker', width: 2, visible: true, sortable: true, searchable: true, filterType: filterTypes.search },
						{ key: 'contents', text: 'Melding', width: 11, visible: true, sortable: true, searchable: true, filterType: filterTypes.search },
						{ key: 'delivery_status', text: 'Status', width: 1, visible: true, sortable: true, filterType: filterTypes.search },
					]}
					query={defaultQuery}
					defaultOrder={{'created_at': 'DESC'}}
					apiSearch={apiSearch}
					renderRow={data => [
						dateToString(data.created_at),
						data.sender,
						data.recipient,
						data.contents,
						STATUS_CODES.no.hasOwnProperty(data.delivery_status) ? <Popup inverted content={STATUS_CODES.no[data.delivery_status]} trigger={<span>{data.delivery_status}</span>} /> : data.delivery_status,
					]}
				/>
			</Container>
		</Segment>
	</>
}