import { Response, PaginatedResponse } from '..'
import { buildQuery } from '../../helpers'

// GET /api/autotask/products
// POST /api/autotask/products/search
export async function getProducts(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/autotask/products' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/autotask/products/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/autotask/products
export async function createProduct(data, requestOptions = {}) {
	return Response.request('/api/autotask/products', data, requestOptions)
}

// GET /api/autotask/products/:id
export async function getProduct(id, requestOptions = {}) {
	return Response.request('/api/autotask/products/' + encodeURIComponent(id), null, requestOptions)
}

// PUT /api/autotask/products/:id
export async function updateProduct(id, data, requestOptions = {}) {
	return Response.request('/api/autotask/products/' + encodeURIComponent(id), data, Object.assign({ method: 'PUT' }, requestOptions))
}

export const BILLING_TYPE_STANDARD = 1;
export const BILLING_TYPE_PER_CONTACT = 2;
export const BILLING_TYPE_PER_INSTALLED_PRODUCT = 3;

export const PERIOD_TYPE_ONE_TIME = 1; // One-Time
export const PERIOD_TYPE_MONTHLY = 2; // Monthly
export const PERIOD_TYPE_QUARTERLY = 3; // Quarterly
export const PERIOD_TYPE_SEMIANNUAL = 4; // Semi-Annual
export const PERIOD_TYPE_YEARLY = 5; // Yearly

export const PRICE_COST_METHOD_FLAT = 1;
export const PRICE_COST_METHOD_TIERED = 2;
export const PRICE_COST_METHOD_VOLUME = 3;

export const CATEGORY_SERVER = 1; // Server
export const CATEGORY_ANTI_VIRUS = 3; // Anti-Virus
export const CATEGORY_PRINTER = 6; // Printer
export const CATEGORY_UPS = 8; // UPS
export const CATEGORY_NETWORK = 10; // Nettverk
export const CATEGORY_NETWORK_GATEWAY_FIREWALL = 5; // Nettverk>Ruter / Brannmur
export const CATEGORY_NETWORK_ACCESS_POINT = 9; // Nettverk>Aksesspunkt
export const CATEGORY_NETWORK_VPN = 32; // Nettverk>VPN
export const CATEGORY_NETWORK_SWITCH = 33; // Nettverk>Switch
export const CATEGORY_WORKSTATION = 11; // Workstation
export const CATEGORY_SOFTWARE = 14; // Programvare
export const CATEGORY_MISC = 15; // Diverse
export const CATEGORY_EMAIL = 17; // E-post
export const CATEGORY_NAS = 26; // NAS
export const CATEGORY_DIGITAL_SIGNAGE = 27; // Infoskjerm
export const CATEGORY_DOMAIN = 4; // Domene
export const CATEGORY_DOMAIN_NAME = 19; // Domene>Årsavgift
export const CATEGORY_DOMAIN_OWNER_CHANGE = 20; // Domene>Eierskifte
export const CATEGORY_DOMAIN_TRANSFER = 36; // Domene>Flytteavgift
export const CATEGORY_SSL = 12; // SSL
export const CATEGORY_WEBSITE = 13; // Nettside
export const CATEGORY_WEBSITE_HOSTING = 21; // Nettside>Hosting
export const CATEGORY_WEBSITE_IVO = 22; // Nettside>iVo Nettside (egen plattform)
export const CATEGORY_WEBSITE_IVO_DUDA = 23; // Nettside>iVo Nettside (Duda)
export const CATEGORY_WEBSITE_HOSTING_MANAGED = 24; // Nettside>Hosting>Managed Hosting
export const CATEGORY_WEBSITE_ADDON = 25; // Nettside>Nettside tilbehør
export const CATEGORY_INTERNET = 16; // Internett
export const CATEGORY_INTERNET_KITNET = 28; // Internett>KitNet
export const CATEGORY_INTERNET_KITNET_IP = 29; // Internett>KitNet IP-adresse
export const CATEGORY_INTERNET_KIT_MANAGED_INTERNET = 30; // Internett>KIT Managed Internet
export const CATEGORY_BACKUP = 18; // Backup