import { Response, PaginatedResponse } from '..'
import { buildQuery } from '../../helpers'

// GET /api/autotask/countries
// POST /api/autotask/countries/search
export async function getCountries(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/autotask/countries' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/autotask/countries/search', Object.assign({ query }, options), requestOptions)
}

// GET /api/autotask/countries/:id
export async function getCountry(id, requestOptions = {}) {
	return Response.request('/api/autotask/countries/' + encodeURIComponent(id), null, requestOptions)
}
