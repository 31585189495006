import React, { useState } from 'react'
import { Segment, Container, Header, Button, Form } from 'semantic-ui-react'
import { handleRedirect, navigate, preventDefault } from '../../../helpers'
import { deleteUser } from '../../../api/mail1/users'
import { useNavigate, useRouteLoaderData } from 'react-router-dom'
import { useMessages } from '../../root'

export default function DeleteKitmailUserPage() {
	const userData = useRouteLoaderData('kitmailUser')
	const domainUserData = useRouteLoaderData('kitmailDomainUser')
	const { email, kitmailUser } = (userData ?? domainUserData)
	const [, setMessages] = useMessages()
	const [emailConfirmInput, setEmailConfirmInput] = useState('')
	const routerNavigateFn = useNavigate()

	const handleDeleteUser = () => {
		if (emailConfirmInput !== email) return
		deleteUser(email).then(res => {
			setMessages(messages => messages.concat({
				key: 'success_' + Math.round(new Date() / 1000).toString(),
				dismissable: true,
				type: 'success',
				icon: 'check',
				content: 'Brukeren er slettet.',
			}))
			Promise.resolve(kitmailUser).then(({data: kitmailUser}) => navigate(kitmailUser.email.domain ? '/kitmail/domains/' + kitmailUser.email.domain.domain : '/kitmail/users', '_self', {}, routerNavigateFn))
		}).catch(e => {
			setMessages(messages => messages.concat({
				key: 'error_' + Math.round(new Date() / 1000).toString(),
				dismissable: true,
				type: 'error',
				icon: 'exclamation circle',
				content: 'Brukeren kunne ikke slettes: ' + (e && e.message ? e.message : 'Ukjent feil (sjekk JavaScript-konsollen)'),
			}))
			handleRedirect(e.res, routerNavigateFn)
		})
	}

	return <>
		<Segment vertical padded="very">
			<Container>
				<Header size="huge">
					Slette bruker <code>{email}</code>?
				</Header>

				<Form onSubmit={preventDefault(() => handleDeleteUser())}>
					<Form.Input
						type="email"
						autoComplete="off"
						label="Skriv inn e-postadressen for å bekrefte sletting"
						placeholder="user@example.com"
						value={emailConfirmInput}
						onChange={(e, data) => setEmailConfirmInput(data.value)}
					/>
					<Form.Field>
						<Button
							as="a"
							href={'/kitmail/users/' + email}
							onClick={preventDefault(e => navigate('/kitmail/users/' + email, (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
							content="Avbryt"
						/>
						<Button
							type="submit"
							negative
							content="Bekreft"
							disabled={emailConfirmInput !== email}
						/>
					</Form.Field>
				</Form>
			</Container>
		</Segment>
	</>
}