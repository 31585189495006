import { Response, PaginatedResponse } from '../'
import { buildQuery } from '../../helpers'

// GET /api/tripletex/products
// POST /api/tripletex/products/search
export async function getProducts(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/tripletex/products' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/tripletex/products/search', Object.assign({ query }, options), requestOptions)
}

// GET /api/tripletex/products/:account_id
export async function getProduct(articleId, requestOptions = {}) {
	return Response.request('/api/tripletex/products/' + encodeURIComponent(articleId), null, requestOptions)
}
