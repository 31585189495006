import React, { useState, useEffect } from 'react'
import { Segment, Header, Popup, Grid, Card, List, Loader, Message } from 'semantic-ui-react'
import { dateToString, getSubscriptionPeriodPriceFormat, getContractPeriodPriceFormat, formatPeriodPrice, isObject } from '../../../helpers'
import { getAccountServices } from '../../../api/accounts'
import Link from '../../../components/link'
import { Await, defer, useAsyncError, useLoaderData } from 'react-router-dom'
import Navigate from '../../../components/navigate'

const loadingServicesTexts = [
	'Kobler til server og henter data fra KitCloud',
	'Henter data fra Autotask',
	'Sammenfatter og sorterer tjenestedata',
	'Laster inn tjenesteoversikt',
	'Dette tar lenger tid enn forventet, vennligst vent...',
]

export async function loader({ params: { accountId } }) {
	return defer({
		services: getAccountServices(accountId).catch(e => {
			console.error('Unable to load account services:', e)
			return null
		})
	})
}

function AccountServicesErrorMessage() {
	const error = useAsyncError()
	return <Message error>
		<Message.Header>Noe gikk galt ved innlastning av tjenester</Message.Header>
		<p>{error.message}</p>
	</Message>
}

export default function AccountServicesTab() {
	const { services } = useLoaderData()
	const [loadingServicesText, setLoadingServicesText] = useState(loadingServicesTexts[0])

	useEffect(() => {
		let loadingServicesTextIndex = 0
		const loadingServicesTextChangeInterval = setInterval(() => {
			if (loadingServicesTextIndex < loadingServicesTexts.length) {
				loadingServicesTextIndex++
				setLoadingServicesText(loadingServicesTexts[loadingServicesTextIndex])
			}
			if (loadingServicesTextIndex === loadingServicesTexts.length - 1) {
				clearInterval(loadingServicesTextChangeInterval)
			}
		}, 2000)
		return () => clearInterval(loadingServicesTextChangeInterval)
	}, [])

	return <>
		<React.Suspense>
			<Await resolve={services}>
				{res => isObject(res) && res.redirect !== null ? <Navigate to={res.redirect} /> : null}
			</Await>
		</React.Suspense>

		<Grid stackable>
			<Grid.Row verticalAlign="bottom">
				<Grid.Column width={10}>
					<Header size="large">Tjenester</Header>
				</Grid.Column>
				<Grid.Column width={6} textAlign="right"></Grid.Column>
			</Grid.Row>
		</Grid>
		<p></p>

		<React.Suspense
			fallback={<Segment placeholder>
				<Loader active indeterminate>{loadingServicesText}</Loader>
			</Segment>}
		>
			<Await
				resolve={services}
				errorElement={<AccountServicesErrorMessage />}
			>
				{res => {
					if (!isObject(res) || !Array.isArray(res.data) || res.data.length === 0) return <Segment placeholder>
						<Header textAlign="center">Fant ingen tjenester.</Header>
					</Segment>

					const { data: services } = res

					return <Card.Group stackable>
						{services.map(service => {
							const price_format = service.id.startsWith('subscription:') ? getSubscriptionPeriodPriceFormat(service.billing.periodType) : getContractPeriodPriceFormat(service.billing.periodType)

							const relatedLinks = [
								service.relations.configurationItemLink ? <Popup
									key="configurationItemLink"
									inverted
									content="Gå til Configuration Item i nytt vindu"
									trigger={<Link
										key="configurationItemLink"
										target="tmp"
										rel="noopener nofollow noreferrer"
										href={service.relations.configurationItemLink}
										popupOptions={{width: 1, height: 1}}
									>
										CI
									</Link>}
								/> : null,
								service.relations.subscriptionLink ? <Popup
									key="subscriptionLink"
									inverted
									content="Gå til abonnement i ny fane"
									trigger={<Link
										key="subscriptionLink"
										target="_blank"
										rel="noopener nofollow noreferrer"
										href={service.relations.subscriptionLink}
									>
										Abbn
									</Link>}
								/> : null,
								service.relations.productLink ? <Popup
									key="productLink"
									inverted
									content="Gå til produkt i ny fane"
									trigger={<Link
										key="productLink"
										target="_blank"
										rel="noopener nofollow noreferrer"
										href={service.relations.productLink}
									>
										Produkt
									</Link>}
								/> : null,
								service.relations.contractLink ? <Popup
									key="contractLink"
									inverted
									content="Gå til kontrakt i nytt vindu"
									trigger={<Link
										key="contractLink"
										target="tmp"
										rel="noopener nofollow noreferrer"
										href={service.relations.contractLink}
										popupOptions={{width: 1, height: 1}}
									>
										Kontrakt
									</Link>}
								/> : null,
								service.relations.contactLink ? <Popup
									key="contactLink"
									inverted
									content="Gå til kontaktperson i nytt vindu"
									trigger={<Link
										key="contactLink"
										target="tmp"
										rel="noopener nofollow noreferrer"
										href={service.relations.contactLink}
										popupOptions={{width: 1, height: 1}}
									>
										KP
									</Link>}
								/> : null,
							].filter(elem => elem !== null).flatMap((elem, i) => [<span key={'spacer' + i}>&ensp;</span>, elem]).slice(1)

							return <Card key={service.id}>
								<Card.Content>
									<Card.Header>{service.variant !== null ? service.group : service.name}</Card.Header>
									{service.variant !== null ? <Card.Meta>{service.variant}</Card.Meta> : null}
								</Card.Content>
								<Card.Content>
									<Card.Description>
										<List>
											<List.Item>
												<List.Icon name="calendar" />
												<List.Content>
													{dateToString(service.billing.startDate, false, false, true, false)}
													&emsp;&mdash;&emsp;
													{dateToString(service.billing.endDate, false, false, true, false)}
												</List.Content>
											</List.Item>
											<List.Item>
												<List.Icon name="dollar" />
												<List.Content>
													{formatPeriodPrice(service.billing.periodPrice, price_format)}
												</List.Content>
											</List.Item>
											{service.description !== null ? <List.Item>
												<List.Icon name="sticky note" />
												<List.Content>
													{service.description}
												</List.Content>
											</List.Item> : null}
										</List>
									</Card.Description>
								</Card.Content>
								<Card.Content extra>{relatedLinks}</Card.Content>
							</Card>
						})}
					</Card.Group>
				}
			}
			</Await>
		</React.Suspense>
	</>
}