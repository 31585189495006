import { Response, PaginatedResponse } from './'
import { buildQuery } from '../helpers'

// GET /api/tasks
export async function getTasks(options = {}, requestOptions = {}) {
	return PaginatedResponse.request('/api/tasks' + buildQuery(options), null, requestOptions)
}

// GET /api/tasks/executions
// POST /api/tasks/executions/search
export async function getTaskExecutions(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/tasks/executions' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/tasks/executions/search', Object.assign({ query }, options), requestOptions)
}

// GET /api/tasks/executions/:execution_id
export async function getTaskExecution(executionId, requestOptions = {}) {
	return Response.request('/api/tasks/executions/' + encodeURIComponent(executionId), null, requestOptions)
}

// POST /api/tasks/executions/:execution_id/retry
export async function retryTaskExecution(executionId, requestOptions = {}) {
	return Response.request('/api/tasks/executions/' + encodeURIComponent(executionId) + '/retry', null, Object.assign({ method: 'POST' }, requestOptions))
}

// GET /api/tasks/executions/:execution_id/log-entries
// POST /api/tasks/executions/:execution_id/log-entries/search
export async function getTaskExecutionLogEntries(taskExecutionId, query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/tasks/executions/' + encodeURIComponent(taskExecutionId) + '/log-entries' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/tasks/executions/' + encodeURIComponent(taskExecutionId) + '/log-entries/search', Object.assign({ query }, options), requestOptions)
}

// GET /api/tasks/executions/:execution_id/log-entries/:log_entry_id
export async function getTaskExecutionLogEntry(executionId, logEntryId, requestOptions = {}) {
	return Response.request('/api/tasks/executions/' + encodeURIComponent(executionId) + '/log-entries/' + encodeURIComponent(logEntryId), null, requestOptions)
}

// PUT /api/tasks/executions/:execution_id
export async function updateTaskExecution(executionId, execution, requestOptions = {}) {
	return Response.request('/api/tasks/executions/' + encodeURIComponent(executionId), execution, Object.assign({ method: 'PUT' }, requestOptions))
}