import React from 'react'
import { Message } from 'semantic-ui-react'

export default function Messages({ messages, attached, onDismiss }) {
	if (typeof messages !== 'object' || !Array.isArray(messages)) return null

	return messages.map((message, i) => {
		let messageAttachType = null
		if (attached === 'top' && i === 0) {
			messageAttachType = 'top'
		} else if (attached === 'bottom' && i === messages.length - 1) {
			messageAttachType = 'bottom'
		} else if (attached) {
			messageAttachType = true
		}

		return <Message
			key={message.key}
			onDismiss={message.dismissable && typeof onDismiss === 'function' ? () => onDismiss(message.key) : null}
			attached={messageAttachType}
			color={message.color}
			className={((message.className ?? '') + (!message.title ? ' small-icon' : '')).trim()}
			compact={message.compact}
			size={message.size}
			error={message.type === 'error'}
			warning={message.type === 'warning'}
			success={message.type === 'success'}
			info={message.type === 'info'}
			visible={message.visible}
			icon={message.icon}
			header={message.title}
			content={message.html ? <div dangerouslySetInnerHTML={{__html: message.content}}></div> : message.content}
		/>
	})
}