import React from 'react'
import { Container, Grid } from 'semantic-ui-react'
import Copyright from './copyright'

export default function Footer() {
	return <Grid padded>
		<Grid.Column color="black">
			<Container className="w-500">
				<Copyright inverted />
			</Container>
		</Grid.Column>
	</Grid>
}