import { Response, PaginatedResponse } from './'
import { buildQuery } from '../helpers'

// GET /api/domains
// POST /api/domains/search
export async function getDomains(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/domains' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/domains/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/domains
export async function createDomain(domain, requestOptions = {}) {
	return Response.request('/api/domains', domain, requestOptions)
}

// GET /api/domains/:domain_id
export async function getDomain(domainId, requestOptions = {}) {
	return Response.request('/api/domains/' + encodeURIComponent(domainId), null, requestOptions)
}

// GET /api/domains/:domain_id/nameserver-service
export async function getDomainNameserverServiceById(domainId, requestOptions = {}) {
	return Response.request('/api/domains/' + encodeURIComponent(domainId) + '/nameserver-service', null, requestOptions)
}

// GET /api/domains/:domain_id/email-service
export async function getDomainEmailServiceById(domainId, requestOptions = {}) {
	return Response.request('/api/domains/' + encodeURIComponent(domainId) + '/email-service', null, requestOptions)
}

// GET /api/domains/:domain_id/hosting-service
export async function getDomainHostingServiceById(domainId, requestOptions = {}) {
	return Response.request('/api/domains/' + encodeURIComponent(domainId) + '/hosting-service', null, requestOptions)
}

// GET /api/domains/:domain_id/registrar-service
export async function getDomainRegistrarServiceById(domainId, requestOptions = {}) {
	return Response.request('/api/domains/' + encodeURIComponent(domainId) + '/registrar-service', null, requestOptions)
}

// POST /api/domains/nameserver-service
export async function getDomainNameserverServiceByName(domainName, requestOptions = {}) {
	return Response.request('/api/domains/nameserver-service', { domain: domainName }, requestOptions)
}

// POST /api/domains/email-service
export async function getDomainEmailServiceByName(domainName, requestOptions = {}) {
	return Response.request('/api/domains/email-service', { domain: domainName }, requestOptions)
}

// POST /api/domains/hosting-service
export async function getDomainHostingServiceByName(domainName, requestOptions = {}) {
	return Response.request('/api/domains/hosting-service', { domain: domainName }, requestOptions)
}

// POST /api/domains/registrar-service
export async function getDomainRegistrarServiceByName(domainName, requestOptions = {}) {
	return Response.request('/api/domains/registrar-service', { domain: domainName }, requestOptions)
}

// POST /api/domains/request-transfer-token
export async function requestTransferToken(domainName, requestOptions = {}) {
	return Response.request('/api/domains/request-transfer-token', { domain: domainName }, requestOptions)
}

// PUT /api/domains/:domain_id
export async function updateDomain(domainId, domain, requestOptions = {}) {
	return Response.request('/api/domains/' + encodeURIComponent(domainId), domain, Object.assign({ method: 'PUT' }, requestOptions))
}

// DELETE /api/domains/:domain_id
export async function expireDomain(domainId, options = {}, requestOptions = {}) {
	return Response.request('/api/domains/' + encodeURIComponent(domainId) + buildQuery(options), null, Object.assign({ method: 'DELETE' }, requestOptions))
}

// POST /api/domains/:domain_id/restore
export async function restoreExpiringDomain(domainId, requestOptions = {}) {
	return Response.request('/api/domains/' + encodeURIComponent(domainId) + '/restore', null, Object.assign({ method: 'POST' }, requestOptions))
}

// GET /api/domains/:domain_id/dns-records
// POST /api/domains/:domain_id/dns-records/search
export async function getDomainDNSRecords(domainId, query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/domains/' + encodeURIComponent(domainId) + '/dns-records' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/domains/' + encodeURIComponent(domainId) + '/dns-records/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/domains/:domain_id/dns-records
export async function createDomainDNSRecord(domainId, dnsRecord, requestOptions = {}) {
	return Response.request('/api/domains/' + encodeURIComponent(domainId) + '/dns-records', dnsRecord, requestOptions)
}

// GET /api/domains/:domain_id/dns-records/:dns_record_id
export async function getDomainDNSRecord(domainId, dnsRecordId, requestOptions = {}) {
	return Response.request('/api/domains/' + encodeURIComponent(domainId) + '/dns-records/' + encodeURIComponent(dnsRecordId), null, requestOptions)
}

// PUT /api/domains/:domain_id/dns-records/:dns_record_id
export async function updateDomainDNSRecord(domainId, dnsRecordId, dnsRecord, requestOptions = {}) {
	return Response.request('/api/domains/' + encodeURIComponent(domainId) + '/dns-records/' + encodeURIComponent(dnsRecordId), dnsRecord, Object.assign({ method: 'PUT' }, requestOptions))
}

// DELETE /api/domains/:domain_id/dns-records/:dns_record_id
export async function deleteDomainDNSRecord(domainId, dnsRecordId, requestOptions = {}) {
	return Response.request('/api/domains/' + encodeURIComponent(domainId) + '/dns-records/' + encodeURIComponent(dnsRecordId), null, Object.assign({ method: 'DELETE' }, requestOptions))
}

// GET /api/domains/:domain_id/dns-records/:dns_record_id/import
export async function importDomainDNSRecord(domainId, zoneFile, requestOptions = {}) {
	return Response.request('/api/domains/' + encodeURIComponent(domainId) + '/dns-records/import', zoneFile, Object.assign({ headers: { 'Content-Type': 'text/plain; charset=utf-8' } }, requestOptions))
}

// GET /api/domains/contacts
// POST /api/domains/contacts/search
export async function getDomainContacts(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/domains/contacts' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/domains/contacts/search', Object.assign({ query }, options), requestOptions)
}

// GET /api/domains/contacts/:contact_id
export async function getDomainContact(domainContactId, requestOptions = {}) {
	return Response.request('/api/domains/contacts/' + encodeURIComponent(domainContactId), null, requestOptions)
}