import { QRCodeSVG } from 'qrcode.react'
import React from 'react'

export default function AssetLabel({
	id,
	className,
	name,
	deviceType,
	qrCodeValue,
	onChange
}) {
	className = ['kit-assetlabel', className].filter(className => typeof className === 'string' && className.length > 0).join(' ')
	name = name ?? ''
	qrCodeValue = qrCodeValue ?? ''
	onChange = onChange ?? (({name, meta1, meta2}) => {})

	return <div className={className} id={id}>
		<div className="kit-assetlabel-inner">
			<div className="kit-assetlabel-row kit-assetlabel-row-devicetype">
				<div className="kit-assetlabel-column">
				</div>
			</div>
			<div className="kit-assetlabel-row kit-assetlabel-row-name">
				<div className="kit-assetlabel-column">
					<input type="text" title="Navn" value={name} onChange={e => onChange({nm: e.target.value})} className="kit-assetlabel-text-primary text-bold" />
				</div>
			</div>
			<div className="kit-assetlabel-row kit-assetlabel-row-content">
				<div className="kit-assetlabel-column kit-assetlabel-column-contactinfo">
					<p className="kit-assetlabel-text-secondary kit-assetlabel-device-type">{deviceType}</p>
					<p className="kit-assetlabel-text-secondary text-bold">
						<svg className="kit-assetlabel-logo" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 670 101.9">
							<g>
								<polygon fill="currentColor" points="186.4,15.7 167.9,15.7 141.1,41.4 141.1,15.7 128,15.7 128,88.7 141.1,88.7 141.1,57.8 169.2,88.7 186.2,88.7 151,49.8 "></polygon>
								<path fill="currentColor" d="M224.6,13.6c-11,0-20.6,3.8-28.4,11.2c-7.9,7.5-11.9,16.6-11.9,27c0,10.8,3.9,20.2,11.7,27.8c7.8,7.6,17.3,11.4,28.3,11.4c11.2,0,20.7-3.8,28.4-11.4c7.7-7.6,11.6-16.9,11.6-27.9c0-10.4-4-19.4-11.8-26.9C244.8,17.4,235.4,13.6,224.6,13.6z M225,77.4c-7.7,0-14-2.4-19.3-7.3c-5.3-4.9-7.8-10.7-7.8-17.8c0-7,2.5-12.8,7.6-17.8c5.1-5,11.1-7.5,18.4-7.5c7.6,0,13.8,2.4,19,7.3c5.2,4.9,7.7,10.8,7.7,18c0,6.9-2.5,12.7-7.6,17.7C237.9,75,232,77.4,225,77.4z"></path>
								<polygon fill="currentColor" points="323.6,62.9 272.4,10.2 272.4,88.7 285.6,88.7 285.6,42.2 336.8,94.6 336.8,15.7 323.6,15.7 "></polygon>
								<path fill="currentColor" d="M389.7,53.9c-1.7-2.1-3.9-4.1-6.5-5.8c-2.6-1.7-6.2-3.6-10.7-5.5c-4.3-1.8-7.3-3.5-9-5c-1.4-1.2-2.1-2.6-2.1-4.2c0-1.8,0.8-3.2,2.4-4.4c1.8-1.3,4.2-1.9,7.2-1.9c2.7,0,4.8,0.4,6.1,1.3c1.5,0.9,3.2,2.4,5.2,4.4l1.2,1.2l10.4-8.3l-1.1-1.4c-2.8-3.6-6.3-6.3-10.4-8c-4.1-1.7-8.2-2.6-12.2-2.6c-6.1,0-11.4,1.9-15.7,5.7c-4.4,3.8-6.6,8.6-6.6,14.1c0,2.3,0.4,4.5,1.2,6.5c0.8,2,2,4,3.7,6c1.6,2,3.6,3.7,5.9,5.1c2.2,1.4,5.3,2.9,9.2,4.5c3.7,1.5,6.5,2.9,8.1,4c1.6,1.1,2.9,2.5,3.9,4.2c1,1.6,1.5,3.2,1.5,4.7c0,2.6-0.9,4.7-2.8,6.4c-1.9,1.8-4.3,2.6-7.3,2.6c-6.4,0-11-3.5-14.1-10.6l-0.7-1.5l-13,3.6l0.7,1.9c2.5,6.7,6.2,11.7,10.9,15.1c4.7,3.4,10.3,5.1,16.4,5.1c6.4,0,12-2.2,16.5-6.6c4.5-4.4,6.8-9.8,6.8-16c0-2.7-0.4-5.2-1.3-7.6C392.7,58.3,391.4,56,389.7,53.9z"></path>
								<polygon fill="currentColor" points="402.1,88.7 444.1,88.7 444.1,75.6 415.1,75.6 415.1,55.5 444.1,55.5 444.1,42.5 415.1,42.5 415.1,28.9 444.1,28.9 444.1,15.7 402.1,15.7 "></polygon>
								<path fill="currentColor" d="M489.7,21.7c-5.1-3.9-11.9-5.9-20.1-5.9h-15.8v72.9h13.1V60.2c9.8-0.1,17.4-2.2,22.5-6.3c5.5-4.4,8.3-9.9,8.3-16.4C497.7,31,495,25.7,489.7,21.7z M479.9,45.5c-2.8,1.6-7.2,2.5-13,2.7V27.7c8.9,0.2,12.5,1.7,13.9,3c2.2,2,3.3,4.3,3.3,7C484.1,41.3,482.7,43.9,479.9,45.5z"></path>
								<polygon fill="currentColor" points="503,28.9 519.5,28.9 519.5,88.7 532.5,88.7 532.5,28.9 549,28.9 549,15.7 503,15.7 "></polygon>
								<rect fill="currentColor" x="560.9" y="47.5" width="17.4" height="6.9"></rect>
								<rect fill="currentColor" x="603.6" y="15.7" width="13.1" height="72.9"></rect>
								<polygon fill="currentColor" points="624,15.7 624,28.9 640.4,28.9 640.4,88.7 653.5,88.7 653.5,28.9 670,28.9 670,15.7 "></polygon>
							</g>
							<path fill="currentColor" d="M51,0C22.8,0,0,22.8,0,51c0,28.1,22.8,51,51,51c28.1,0,51-22.8,51-51C101.9,22.8,79.1,0,51,0z M73.6,40.2l-20.5,13l-9-10.9l22-10.7L73.6,40.2z M45.4,19.1l15.3-7.7L43.3,41.8l-12.6,5.7L45.4,19.1z M33.4,80.8l-8.3-11.4l5.8-20.8l8.7,12.9L33.4,80.8z M62.7,84.9L40.3,61.8l12.5-7.7l24.1,17.1L62.7,84.9z"></path>
						</svg>
					</p>
					<p className="kit-assetlabel-text-secondary">+47 31 30 90 40</p>
					<p className="kit-assetlabel-text-secondary">support@konsept-it.no</p>
				</div>

				<div className="kit-assetlabel-column kit-assetlabel-column-qrcode">
					{typeof qrCodeValue === 'string' && qrCodeValue.length > 0 ? <QRCodeSVG value={qrCodeValue} height="100%" width="100%" level="M" /> : null }
				</div>
			</div>
		</div>
	</div>
}