import React, { useEffect } from 'react'
import { Navigate as RouterNavigate, useNavigate, useSearchParams } from 'react-router-dom'
import { navigate } from '../helpers'

export default function Navigate({ to, replace, state, relative }) {
	const routerNavigateFn = useNavigate()
	const [searchParams,] = useSearchParams()
	useEffect(() => {
		if (to.includes('://')) {
			navigate(to, '_self', {}, routerNavigateFn)
		}
	}, [to, routerNavigateFn])
	if (to.includes('://')) {
		return null
	}
	return <RouterNavigate to={{
		pathname: to,
		search: searchParams.toString(),
	}} replace={replace} state={state} relative={relative} />
}