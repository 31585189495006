import React, { useRef, useState } from 'react'
import { Progress } from 'semantic-ui-react'

export default function Slider({
	label,
	value,
	min,
	max,
	steps,
	stepSnapThreshold,
	onlySteps,
	precision,
	onChange,
}) {
	label = label ?? null
	value = value ?? 0
	min = min ?? 0
	max = max ?? 100
	steps = steps ?? []
	stepSnapThreshold = stepSnapThreshold ?? 0.025
	onlySteps = onlySteps ?? false
	precision = precision ?? 2 // amount of decimals
	onChange = onChange ?? (value => {})

	const [grabbed, setGrabbed] = useState(false)
	const sliderRef = useRef(null)

	function onMouseDown(e) {
		const slider = sliderRef.current
		const progressBar = slider.getElementsByClassName('bar')[0]

		const progressWidth = slider.getBoundingClientRect().width
		const progressOffsetLeft = (progressBar.getBoundingClientRect().left + window.scrollX)
		const grabberWidth = parseInt(window.getComputedStyle(progressBar, ':after').width.replace('px', ''))

		setGrabbed(true)

		function onMouseMove(e) {
			if (!e.defaultPrevented) e.preventDefault()

			// if LMB held
			if (e.buttons & 1) {
				const relativePos = e.clientX + (grabberWidth / 2) - progressOffsetLeft
				const fraction = (relativePos / progressWidth)
				const rawValue = Math.min(max, Math.max(min, fraction * max))
				const newValue = parseFloat(rawValue.toFixed(precision ?? 0).replace('.' + '0'.repeat(precision ?? 0), ''))

				// snap to steps
				let snapValue = null
				outer: for (let mul = 0; mul < 10; mul++) {
					for (const step of steps) {
						if (newValue === step || newValue > (step - stepSnapThreshold * mul * max) && newValue < (step + stepSnapThreshold * mul * max)) {
							snapValue = step
							break outer
						}
					}
				}

				if (onlySteps && snapValue !== null) {
					onChange(snapValue)
				} else if (!onlySteps) {
					onChange(snapValue ?? newValue)
				}
			}
		}

		function onMouseUp(e) {
			setGrabbed(false)
			document.removeEventListener('mouseup', onMouseUp)
			document.removeEventListener('mousemove', onMouseMove)
		}

		document.addEventListener('mouseup', onMouseUp)
		document.addEventListener('mousemove', onMouseMove)
		onMouseMove(e)
	}

	return <div className={'ui' + (grabbed ? ' grabbed' : '') + ' slider'} onMouseDown={onMouseDown}  ref={sliderRef}>
		<Progress size="tiny" percent={(value / max) * 100} label={label} />
	</div>
}