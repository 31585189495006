import React from 'react'
import { Metadata } from 'libphonenumber-js/min'
import PhoneInputWithCountrySelect, { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input'
import { Dropdown, Flag, Form } from 'semantic-ui-react'
import { omit, pick, countryOptions } from '../helpers'

function CountrySelectDropdown(props) {
	const { options, value, onChange, /* iconComponent: CountryIcon */ } = props
	const remappedOptions = options.map(({ value, label }) => {
		const option = countryOptions.no.find(option => option.value === value)
		let text = option ? option.text : label
		value = typeof value === 'undefined' || value === undefined ? 'international' : value
		if (value !== 'international') {
			try {
				const callingCode = getCountryCallingCode(value)
				text += value !== 'international' ? ' (+' + callingCode + ')' : ''
			} catch {}
		}
		const flagCountryCode = value.toLowerCase()
			.replace('bq', 'nl')
			.replace('cw', 'nl')
			.replace('gg', 'uk')
			.replace('im', 'uk')
			.replace('je', 'uk')
			.replace('xk', 'rs')
			.replace('bl', 'fr')
			.replace('mf', 'fr')
			.replace('sx', 'nl')
			.replace('ss', 'sd')
			.replace('ta', 'sh')
			.replace('ac', 'sh')
		return { value, text, image: value !== 'international' ? <Flag name={flagCountryCode} /> : null }
	})
	const uniqueOptions = remappedOptions.filter((v, i , ar) => ar.findIndex(v2 => v2.value === v.value) === i)
	return <Dropdown
		{...omit(['options', 'value', 'onChange', 'iconComponent'], props)}
		selection
		search
		options={uniqueOptions}
		value={typeof value === 'undefined' || value === undefined ? 'international' : value}
		onChange={(e, data) => onChange(data.value === 'international' ? undefined : data.value)}
	/>
}

const formFieldUniqueProps = [
	'inline',
	'required',
	'width',
]

export default function PhoneInput(props) {
	const value = props.value ?? ''
	const isDirty = props.isDirty ?? false
	const { label, error, required } = props

	const isEmpty = typeof value !== 'string' ||
		value.trim().length === 0 ||
		new Metadata().metadata.country_calling_codes.hasOwnProperty(value.trim().replace('+', ''))

	return <Form.Field
		{...pick(['disabled', ...formFieldUniqueProps], props)}
		error={error || (((isDirty && required) || !isEmpty) && !isValidPhoneNumber(value))}
	>
		{typeof label === 'string' ? <label>{label}</label> : label}
		<PhoneInputWithCountrySelect
			{...omit([
				'isDirty',
				'className',
				'international',
				'defaultCountry',
				'countryOptionsOrder',
				'countrySelectComponent',
				'addInternationalOption',
				'label',
				...formFieldUniqueProps,
			], props)}
			defaultCountry="NO"
			international={null}
			addInternationalOption={false}
			initialValueFormat="national"
			countryOptionsOrder={["NO", "DK", "SE", "FI", "..."]}
			countrySelectComponent={CountrySelectDropdown}
			className="ui left action input"
		/>
	</Form.Field>
}