import { Response, PaginatedResponse } from './'
import { buildQuery } from '../helpers'

// GET /api/sms/prices
// POST /api/sms/prices/search
export async function getSMSPrices(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/sms/prices' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/sms/prices/search', Object.assign({ query }, options), requestOptions)
}

// GET /api/sms/gateways
// POST /api/sms/gateways/search
export async function getSMSGateways(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/sms/gateways' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/sms/gateways/search', Object.assign({ query }, options), requestOptions)
}

// GET /api/sms/gateways/:gateway_id
export async function getSMSGateway(gatewayId, requestOptions = {}) {
	return Response.request('/api/sms/gateways/' + encodeURIComponent(gatewayId), null, requestOptions)
}

// GET /api/sms/gateways/:gateway_id/messages
// POST /api/sms/gateways/:gateway_id/messages/search
export async function getSMSGatewayMessages(gatewayId, query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/sms/gateways/' + encodeURIComponent(gatewayId) + '/messages' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/sms/gateways/' + encodeURIComponent(gatewayId) + '/messages/search', Object.assign({ query }, options), requestOptions)
}

// GET /api/sms/gateways/:gateway_id/messages/:sms_id
export async function getSMSGatewayMessage(gatewayId, smsId, requestOptions = {}) {
	return Response.request('/api/sms/gateways/' + encodeURIComponent(gatewayId) + '/messages/' + encodeURIComponent(smsId), null, requestOptions)
}

// GET /api/sms/messages
// POST /api/sms/messages/search
export async function getSMSMessages(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/sms/messages' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/sms/messages/search', Object.assign({ query }, options), requestOptions)
}

// GET /api/sms/messages/:sms_id
export async function getSMSMessage(gatewayId, smsId, requestOptions = {}) {
	return Response.request('/api/sms/messages/' + encodeURIComponent(smsId), null, requestOptions)
}

// POST /api/sms/gateways/:gateway_id/messages
export async function sendSMSMessage(gatewayId, recipient, contents, requestOptions = {}) {
	return PaginatedResponse.request('/api/sms/gateways/' + encodeURIComponent(gatewayId) + '/messages', { recipient, contents }, requestOptions)
}

export const STATUS_CODES = {
	'en': {
		"0": "Unknown error",
		"1000": "Message in transit, not yet delivered",
		"2000": "Message has been successfully delivered to recipient",
		"3000": "Billing failed, reason not available",
		"3001": "Subscriber is temporarily barred",
		"3002": "Subscriber has insufficient funds",
		"3003": "Subscriber is too young",
		"3004": "The maximum number of messages to this account has been exceeded",
		"4000": "Barred, reason not available",
		"4001": "Subscriber account barred",
		"4002": "Subscriber account closed",
		"4003": "Subscriber is reserved against receiving overcharged content",
		"5000": "Access failed",
		"6000": "Message rejected",
		"6001": "Invalid recipient MSISDN",
		"6002": "Invalid sender-ID",
		"6003": "Invalid price",
		"6004": "Invalid content",
		"7000": "Message undeliverable",
		"7001": "Message unroutable (usually invalid MSISDN)",
		"7002": "Rejected by operator: Rejected payload",
		"7003": "Technical error",
		"7005": "Rejected by operator: Unknown subscriber",
		"9000": "Expired",
		"9001": "Premium rate transaction refunded",
	},
	'no': {
		"0": "Ukjent feil",
		"1000": "Melding er under transport, ikke levert enda",
		"2000": "Melding er levert til mottaker",
		"3000": "Betailing feilet, ukjent årsak",
		"3001": "Abonnent er midlertidig utestengt",
		"3002": "Abonnent har utilstrekkelige midler",
		"3003": "Abonnent er for ung",
		"3004": "Maksimalt antall meldinger til denne kontoen er overskredet",
		"4000": "Utestengt, ukjent årsak",
		"4001": "Abonnentens konto er sperret",
		"4002": "Abonnentens konto er stengt",
		"4003": "Abonnenten er reservert mot å motta overbelastet innhold",
		"5000": "Adgang nektet",
		"6000": "Melding avvist",
		"6001": "Ugyldig mottaker MSISDN",
		"6002": "Ugyldig avsender-ID",
		"6003": "Ugyldig pris",
		"6004": "Ugyldig melding",
		"7000": "Melding kan ikke leveres",
		"7001": "Melding kan ikke rutes (vanligvis ugyldig MSISDN)",
		"7002": "Avvist av operatør: Avvist nyttelast",
		"7003": "Teknisk feil",
		"7005": "Avvist av operatør: Ukjent abonnent",
		"9000": "Utløpt",
		"9001": "Premium-priset transaksjon refundert",
	}
}