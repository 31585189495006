import React, { useState } from 'react'
import { Segment, Container, Button, Form, Header, Icon, Input, Breadcrumb, List, Loader } from 'semantic-ui-react'
import { append, buildQuery, handleRedirect, isObject, preventDefault, request } from '../helpers'
import Align from '../components/align'
import Logo from '../components/logo'
import Footer from '../components/footer'
import Messages from '../components/messages'
import { Await, defer, useLoaderData, useNavigate } from 'react-router-dom'
import Navigate from '../components/navigate'
import { Response } from '../api'

export const defaultAuthState = {
	messages: [],
	language: 'nb_NO',
	defaultSmsGatewayId: null,
	user: null,
	nav: null,
}

export async function loader({ request: { url } }) {
	const { pathname, searchParams } = new URL(url)
	const isAuth = pathname === '/auth' || pathname.startsWith('/auth/')
	const returnPath = searchParams.get('return')
	let authSearchQuery = {
		return: returnPath
	}
	if (isAuth) {
		authSearchQuery['redirect'] = '1'
	}
	const auth = Response.request('/auth/state.json' + buildQuery(authSearchQuery), null, { signal: null })
		.then(res => {
			res.data = append(defaultAuthState, res.data)
			return res
		})

	return defer({ returnPath, auth })
}

function AuthPageContent({ returnPath, user, messages: initialMessages }) {
	const routerNavigateFn = useNavigate()
	const [messages, setMessages] = useState(initialMessages)
	const [email, setEmail] = useState(isObject(user) ? user.email : '')
	const [authenticating, setAuthenticating] = useState(false)
	const [emailEmptyError, setEmailEmptyError] = useState(false)

	const isAuthenticated = isObject(user) &&
		isObject(user.auth) &&
		typeof user.auth.token === 'string' &&
		user.auth.token.length > 0

	if (isAuthenticated) {
		// XXX: Only needed when directly visiting '/auth' route, because
		//      all other routes will redirect via state loader
		return <>
			<Segment placeholder>
				<Loader active indeterminate>Sender deg videre, vennligst vent...</Loader>
			</Segment>
			<Navigate to={returnPath ?? '/'} />
		</>
	}

	const handleLogin = () => {
		// check for empty e-mail address
		if (isObject(user) && email.trim() === '') {
			setEmailEmptyError(true)
			setMessages([{
				key: 'invalid',
				content: 'Brukernavn-feltet kan ikke være tomt',
				icon: 'exclamation',
				type: 'error',
				visible: true
			}])
			return
		}

		setAuthenticating(true)

		// submit username to server for authentication
		request('/auth/login' + buildQuery({ return: returnPath }), { email })
			.then(res => {
				setMessages([{
					key: 'info',
					content: 'Sender deg videre til innlogging, vennligst vent...',
					icon: 'sign in alternate',
					type: 'info',
					visible: true
				}])
				handleRedirect(res, routerNavigateFn)
			})
			.catch(e => {
				setAuthenticating(false)
				setMessages([{
					key: 'invalid',
					content: e && e.message ? e.message : 'En feil oppstod - prøv igjen',
					icon: 'ban',
					type: 'error',
					visible: true
				}])
				handleRedirect(e.res, routerNavigateFn)
			})
	}

	const handleLogout = () => {
		setAuthenticating(true)

		// submit username to server for authentication
		request('/auth/logout', {})
			.then(res => {
				setMessages([{
					key: 'info',
					content: 'Logger deg ut, vennligst vent...',
					icon: 'sign in alternate',
					type: 'info',
					visible: true
				}])
				handleRedirect(res, routerNavigateFn)
			})
			.catch(e => {
				setAuthenticating(false)
				setMessages([{
					key: 'invalid',
					content: e && e.message ? e.message : 'En feil oppstod - prøv igjen',
					icon: 'ban',
					type: 'error',
					visible: true
				}])
				handleRedirect(e.res, routerNavigateFn)
			})
	}

	const authContent = <>
		<Segment attached="top" className={'field with-header' + (emailEmptyError ? ' error' : '')}>
			<Header className="section" size="tiny">Brukernavn</Header>
			<Input
				placeholder="brukernavn@konsept-it.no"
				type="email"
				autoComplete="email"
				autoFocus
				size="massive"
				fluid
				tabIndex="1"
				value={email}
				onChange={(e, data) => {
					setEmail(data.value)
					setEmailEmptyError(false)
					setMessages([])
				}}
			/>
		</Segment>
		<Segment attached="bottom" secondary clearing>
			<Button type="submit" floated="right" loading={authenticating} disabled={authenticating} tabIndex="3"><Icon name="sign in" /> Logg inn</Button>
		</Segment>
	</>

	const reauthContent = <>
		<Segment attached="top" secondary>
			<Button type="button" circular className="plain middle aligned" icon="left arrow" title="Logg ut" onClick={preventDefault(() => handleLogout())} />
			<Breadcrumb>
				<Breadcrumb.Section>{email}</Breadcrumb.Section>
				<Breadcrumb.Divider icon="right angle" />
				<Breadcrumb.Section active>Logg inn på nytt</Breadcrumb.Section>
			</Breadcrumb>
		</Segment>
		<Segment attached padded clearing>
			<p>Du har blitt logget ut på grunn av en av følgende årsaker:</p>
			<List bulleted>
				<List.Item>Inaktivitet (1 time eller mer)</List.Item>
				<List.Item>Endret nettverk (IP-adresse)</List.Item>
				<List.Item>Endret nettleser/versjon</List.Item>
			</List>
			<p>Vi ber derfor om at du logger inn på nytt.</p>
		</Segment>
		<Segment attached="bottom" secondary clearing>
			<Button type="button" basic loading={authenticating} disabled={authenticating} onClick={preventDefault(() => handleLogout())}><Icon name="sign out" /> Logg ut</Button>
			<Button type="submit" floated="right" loading={authenticating} disabled={authenticating}><Icon name="sign in" /> Logg inn</Button>
		</Segment>
	</>

	return <>
		<Messages
			messages={messages}
			onDismiss={key => setMessages(messages => messages.filter(message => message.key !== key))}
		/>
		<Form onSubmit={preventDefault(() => handleLogin())}>
			{isObject(user) ? reauthContent : authContent}
		</Form>
	</>
}

export default function AuthPage() {
	const { returnPath, auth } = useLoaderData()

	return <Align>
		<Align vertical="middle" horizontal="center" verticalExpand>
			<Container className="w-500">
				<Logo/>

				<React.Suspense>
					<Await resolve={auth}>
						{({redirect, data: auth}) => redirect !== null ? <>
							<Segment placeholder>
								<Loader active indeterminate>Sender deg videre, vennligst vent...</Loader>
							</Segment>
							<Navigate to={redirect} />
						</> : <AuthPageContent
							returnPath={returnPath}
							user={auth.user}
							messages={auth.messages}
						/>}
					</Await>
				</React.Suspense>
			</Container>
		</Align>

		<Footer/>
	</Align>
}