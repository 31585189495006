import React, { useState } from 'react'
import { Segment, Container, Header, Form } from 'semantic-ui-react'
import { handleRedirect, navigate, preventDefault } from '../../../helpers'
import { updateDomain } from '../../../api/mail1/domains'
import { Await, useNavigate, useRouteLoaderData } from 'react-router-dom'
import { useMessages } from '../../root'

export default function EditKitmailDomainPage() {
	const { domain, kitmailDomain } = useRouteLoaderData('kitmailDomain')
	const [, setMessages] = useMessages()
	const [transport, setTransport] = useState(null)
	const [external, setExternal] = useState(null)
	const routerNavigateFn = useNavigate()

	const handleUpdateDomain = () => {
		updateDomain(domain, { transport, external }).then(_res => {
			setMessages(messages => messages.concat({
				key: 'success_' + Math.round(new Date() / 1000).toString(),
				dismissable: true,
				type: 'success',
				icon: 'check',
				content: 'Domenet er endret.',
			}))
			navigate('/kitmail/domains/' + domain, '_self', {}, routerNavigateFn)
		}).catch(e => {
			setMessages(messages => messages.concat({
				key: 'error_' + Math.round(new Date() / 1000).toString(),
				dismissable: true,
				type: 'error',
				icon: 'exclamation circle',
				content: 'Domenet kunne ikke endres: ' + (e && e.message ? e.message : 'Ukjent feil (sjekk JavaScript-konsollen)'),
			}))
			handleRedirect(e.res, routerNavigateFn)
		})
	}

	return <>
		<Segment vertical padded="very">
			<Container>
				<Header size="huge">Endre KitMail-domene <code>{domain}</code></Header>
				<Form onSubmit={preventDefault(() => handleUpdateDomain())}>
					<Form.Input
						label="Domene"
						value={domain}
						readOnly
						disabled
					/>
					<React.Suspense fallback={
						<Form.Input
							label="Transportmetode"
							placeholder="maildrop"
							disabled
							loading
						/>
					}>
						<Await resolve={kitmailDomain}>
							{({data: kitmailDomain}) => <Form.Input
								label="Transportmetode"
								placeholder="maildrop"
								value={transport === null ? (kitmailDomain.transport ?? '') : transport}
								onChange={(e, data) => setTransport(data.value)}
							/>}
						</Await>
					</React.Suspense>
					<React.Suspense fallback={
						<Form.Checkbox
							label="Eksternt domene (kun SMTP)"
							disabled
						/>
					}>
						<Await resolve={kitmailDomain}>
							{({data: kitmailDomain}) => <Form.Checkbox
								label="Eksternt domene (kun SMTP)"
								checked={external === null ? (kitmailDomain.external ?? false) : external}
								onChange={(e, data) => setExternal(data.checked)}
							/>}
						</Await>
					</React.Suspense>
					<Form.Button
						type="submit"
						color="orange"
						disabled={kitmailDomain.transport === transport && kitmailDomain.external === external}
						content="Endre domene"
					/>
				</Form>
			</Container>
		</Segment>
	</>
}