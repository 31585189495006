import React, { useEffect } from 'react'
import { Segment, Container, Button, Header, Icon, Message } from 'semantic-ui-react'
import Align from '../components/align'
import Logo from '../components/logo'
import Footer from '../components/footer'
import { preventDefault } from '../helpers'
import { useNavigate, useRouteError } from 'react-router-dom'

export default function ErrorPage() {
	const error = useRouteError()
	const routerNavigateFn = useNavigate()
	useEffect(() => {
		console.error(error)
	}, [error])

	return <Align>
		<Align vertical="middle" horizontal="center" verticalExpand>
			<Container className="w-500">
				<Logo />

				<Message attached="top" size="huge" error>
					<Header size="huge">En uforventet feil oppstod</Header>
					<p>{error.statusText || error.message}</p>
				</Message>
				<Segment attached="bottom" secondary>
					<Button type="button" onClick={preventDefault(() => routerNavigateFn(-1))}><Icon name="left arrow" /> Gå tilbake</Button>
					<Button type="button" onClick={preventDefault(() => routerNavigateFn('/'))}><Icon name="home" /> Gå hjem</Button>
				</Segment>
			</Container>
		</Align>

		<Footer />
	</Align>
}