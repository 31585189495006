import { Response, PaginatedResponse } from '../'
import { buildQuery } from '../../helpers'

// GET /api/mail1/users
// POST /api/mail1/users/search
export async function getUsers(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/mail1/users' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/mail1/users/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/mail1/users
export async function createUser(user, requestOptions = {}) {
	return Response.request('/api/mail1/users', user, requestOptions)
}

// GET /api/mail1/users/:email
export async function getUser(email, requestOptions = {}) {
	return Response.request('/api/mail1/users/' + email, null, requestOptions)
}

// PUT /api/mail1/users/:email
export async function updateUser(email, user, requestOptions = {}) {
	return Response.request('/api/mail1/users/' + email, user, Object.assign({ method: 'PUT' }, requestOptions))
}

// DELETE /api/mail1/users/:email
export async function deleteUser(email, requestOptions = {}) {
	return Response.request('/api/mail1/users/' + email, null, Object.assign({ method: 'DELETE' }, requestOptions))
}
