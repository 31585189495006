import React, { useState } from 'react'
import { Segment, Container, Header, Button, Form } from 'semantic-ui-react'
import { emailDomainRegex, handleRedirect, navigate, preventDefault } from '../../../helpers'
import { deleteDomain } from '../../../api/mail1/domains'
import { useNavigate, useRouteLoaderData } from 'react-router-dom'
import { useMessages } from '../../root'

export default function DeleteKitmailDomainPage() {
	const { domain } = useRouteLoaderData('kitmailDomain')
	const [, setMessages] = useMessages()
	const [domainConfirmInput, setDomainConfirmInput] = useState('')
	const routerNavigateFn = useNavigate()

	const handleDeleteDomain = () => {
		if (domainConfirmInput !== domain) return
		deleteDomain(domain).then(res => {
			handleRedirect(res, routerNavigateFn)
			setMessages(messages => messages.concat({
				key: 'success_' + Math.round(new Date() / 1000).toString(),
				dismissable: true,
				type: 'success',
				icon: 'check',
				content: 'Domenet er slettet.',
			}))
			navigate('/kitmail/domains', '_self', {}, routerNavigateFn)
		}).catch(e => {
			handleRedirect(e.res, routerNavigateFn)
			setMessages(messages => messages.concat({
				key: 'error_' + Math.round(new Date() / 1000).toString(),
				dismissable: true,
				type: 'error',
				icon: 'exclamation circle',
				content: 'Domenet kunne ikke slettes: ' + (e && e.message ? e.message : 'Ukjent feil (sjekk JavaScript-konsollen)'),
			}))
		})
	}

	return <>
		<Segment vertical padded="very">
			<Container>
				<Header size="huge">
					Slette domene <code>{domain}</code>?
				</Header>

				<Form onSubmit={preventDefault(() => handleDeleteDomain())}>
					<Form.Input
						type="text"
						pattern={emailDomainRegex.source}
						autoComplete="off"
						label="Skriv inn domenet for å bekrefte sletting"
						placeholder="example.com"
						value={domainConfirmInput}
						onChange={(e, data) => setDomainConfirmInput(data.value)}
					/>
					<Form.Field>
						<Button
							as="a"
							href={'/kitmail/domains/' + domain}
							onClick={preventDefault(e => navigate('/kitmail/domains/' + domain, (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
							content="Avbryt"
						/>
						<Button
							type="submit"
							negative
							content="Bekreft"
							disabled={domainConfirmInput !== domain}
						/>
					</Form.Field>
				</Form>
			</Container>
		</Segment>
	</>
}