import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Segment, Container, Header, List, Accordion, Icon, Form, Input, Message, Grid, Select, Checkbox } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { useRail } from '../../components/dash'
import DataTable from '../../components/data_table'
import { formatNumberForInputField, dateToRelativeString, dateLocales, recalculateServicePeriodPrice, formatPeriodPrice, getProductPeriodPriceFormat, getPeriodPriceLabel, getServicePeriodPriceFormat, preventDefault, handleRedirect } from '../../helpers'
import { getConfigurationItems, createConfigurationItem, updateConfigurationItem, TYPE_INTERNET as CI_TYPE_INTERNET, SLA_ID_DEFAULT as CI_SLA_ID_DEFAULT, getConfigurationItemUserDefinedFieldPicklistValues } from '../../api/autotask/configuration_items'
import { getContracts, createContract, updateContract, CATEGORY_KIT_MANAGED_NETWORK as CONTRACT_CATEGORY_KIT_MANAGED_NETWORK, CATEGORY_KIT_MANAGED_INTERNET as CONTRACT_CATEGORY_KIT_MANAGED_INTERNET, EXCLUSION_SET_KIT_MANAGED_NETWORK as CONTRACT_EXCLUSION_SET_KIT_MANAGED_NETWORK, EXCLUSION_SET_KIT_MANAGED_INTERNET as CONTRACT_EXCLUSION_SET_KIT_MANAGED_INTERNET, TYPE_RECURRING_SERVICE as CONTRACT_TYPE_RECURRING_SERVICE, STATUS_ACTIVE as CONTRACT_STATUS_ACTIVE, PERIOD_TYPE_MONTHLY as CONTRACT_PERIOD_TYPE_MONTHLY, PERIOD_TYPE_QUARTERLY as CONTRACT_PERIOD_TYPE_QUARTERLY, PERIOD_TYPE_SEMIANNUAL as CONTRACT_PERIOD_TYPE_SEMIANNUAL, PERIOD_TYPE_YEARLY as CONTRACT_PERIOD_TYPE_YEARLY, SLA_ID_DEFAULT as CONTRACT_SLA_ID_DEFAULT, TIMEREPORTING_STARTSTOP_TRUE as CONTRACT_TIMEREPORTING_STARTSTOP_TRUE } from '../../api/autotask/contracts'
import { getContractServices, createContractService, updateContractService } from '../../api/autotask/contract_services'
import { createContractServiceAdjustment } from '../../api/autotask/contract_service_adjustments'
import { getServices, ID_KIT_MANAGED_NETWORK as SERVICE_ID_KIT_MANAGED_NETWORK, PERIOD_TYPE_MONTHLY as SERVICE_PERIOD_TYPE_MONTHLY } from '../../api/autotask/services'
import { getProducts, CATEGORY_INTERNET_KITNET as PRODUCT_CATEGORY_INTERNET_KITNET, CATEGORY_INTERNET_KIT_MANAGED_INTERNET as PRODUCT_CATEGORY_INTERNET_KIT_MANAGED_INTERNET } from '../../api/autotask/products'
import { getCompanies, ID_VENDOR_ALTIBOX_AS as COMPANY_ID_VENDOR_ALTIBOX_AS, TYPE_CUSTOMER as COMPANY_TYPE_CUSTOMER } from '../../api/autotask/companies'
import { ID_SERVICE_KIT_MANAGED_INTERNET as BILLING_CODE_ID_SERVICE_KIT_MANAGED_INTERNET } from '../../api/autotask/billing_codes'
import { getCompanyLocations } from '../../api/autotask/company_locations'
import { getTickets, updateTicket } from '../../api/autotask/tickets'
import { createTicketAdditionalConfigurationItem } from '../../api/autotask/ticket_additional_configuration_items'
import { getContacts } from '../../api/autotask/contacts'
import Link from '../../components/link'
import { Await, defer, useLoaderData, useNavigate } from 'react-router-dom';
import { useMessages } from '../root';

const initialCompanyQuery = [
	{field: 'isActive', op: 'eq', value: true},
	{field: 'companyType', op: 'eq', value: COMPANY_TYPE_CUSTOMER},
	{field: 'companyNumber', op: 'noteq', value: '0'},
	{field: 'companyNumber', op: 'noteq', value: 'AUTO'},
]

const initialServicesQuery = [
	{field: 'billingCodeID', op: 'eq', value: BILLING_CODE_ID_SERVICE_KIT_MANAGED_INTERNET},
]

const initialProductsQuery = [
	{field: 'productCategory', op: 'in', value: [
		PRODUCT_CATEGORY_INTERNET_KITNET,
		PRODUCT_CATEGORY_INTERNET_KIT_MANAGED_INTERNET,
	]},
	{field: 'isActive', op: 'eq', value: true},
]

export async function loader() {
	const udfPicklistValues = getConfigurationItemUserDefinedFieldPicklistValues()
	return defer({ udfPicklistValues })
}

export default function AutotaskImportInternetToolPage() {
	const [, setMessages] = useMessages()
	const [railVisible, setRail] = useRail()

	const [showDescription, setShowDescription] = useState(false)

	const [selectedCompany, setSelectedCompany] = useState(null)
	const [selectedCompanyLocation, setSelectedCompanyLocation] = useState(null)
	const [selectedProduct, setSelectedProduct] = useState(null)
	const [selectedService, setSelectedService] = useState(null)
	const [selectedTicket, setSelectedTicket] = useState(null)

	const [loadingPrimaryContact, setLoadingPrimaryContact] = useState(null)
	const [primaryContact, setPrimaryContact] = useState(null)

	const [loadingInternetContract, setLoadingInternetContract] = useState(false)
	const [internetContract, setInternetContract] = useState(null)
	const [loadingInternetContractService, setLoadingInternetContractService] = useState(false)
	const [internetContractService, setInternetContractService] = useState(null)
	const [loadingInternetConfigurationItem, setLoadingInternetConfigurationItem] = useState(false)
	const [internetConfigurationItem, setInternetConfigurationItem] = useState(null)

	const [loadingNetworkContract, setLoadingNetworkContract] = useState(false)
	const [networkContract, setNetworkContract] = useState(null)
	const [loadingNetworkContractService, setLoadingNetworkContractService] = useState(false)
	const [networkContractService, setNetworkContractService] = useState(null)

	const [internetStartDateInput, setInternetStartDateInput] = useState(null)
	const [internetCommitmentPeriodMonthsInput, setInternetCommitmentPeriodMonthsInput] = useState('36')
	const [internetPeriodTypeInput, setInternetPeriodTypeInput] = useState(CONTRACT_PERIOD_TYPE_MONTHLY.toString())
	const [internetSetupFeeInput, setInternetSetupFeeInput] = useState('0')
	const [internetUnitCostInput, setInternetUnitCostInput] = useState('0')
	const [internetUnitPriceInput, setInternetUnitPriceInput] = useState('0')

	const [referenceNumber, setReferenceNumber] = useState('')
	const [udfISP, setUdfISP] = useState('')
	const [udfISPType, setUdfISPType] = useState('')
	const [bandwidthDownMbps, setBandwidthDownMbps] = useState('')
	const [bandwidthUpMbps, setBandwidthUpMbps] = useState('')
	const [enableDhcp, setEnableDhcp] = useState(false)
	const [linknetNetworkCIDR, setLinknetNetworkCIDR] = useState('')
	const [linknetIPv4, setLinknetIPv4] = useState('')
	const [linknetIPv6, setLinknetIPv6] = useState('')
	const [linknetGatewayIPv4, setLinknetGatewayIPv4] = useState('')
	const [linknetGatewayIPv6, setLinknetGatewayIPv6] = useState('')
	const [linknetDNS1IP, setLinknetDNS1IP] = useState('')
	const [linknetDNS2IP, setLinknetDNS2IP] = useState('')
	const [extranetNetworkCIDR, setExtranetNetworkCIDR] = useState('')
	const [extranetGatewayIP, setExtranetGatewayIP] = useState('')

	const [enableNetwork, setEnableNetwork] = useState(false)
	const [networkStartDateInput, setNetworkStartDateInput] = useState(null)
	const [networkEndDateInput, setNetworkEndDateInput] = useState(null)
	const [networkPeriodTypeInput, setNetworkPeriodTypeInput] = useState(CONTRACT_PERIOD_TYPE_MONTHLY.toString())
	const [networkSetupFeeInput, setNetworkSetupFeeInput] = useState('0')
	const [networkUnitCostInput, setNetworkUnitCostInput] = useState('0')
	const [networkUnitPriceInput, setNetworkUnitPriceInput] = useState('0')

	const [importing, setImporting] = useState(false)

	const { udfPicklistValues } = useLoaderData()
	useEffect(() => {
		Promise.resolve(udfPicklistValues).then(res => {
			const { 'ISP': udfISPPicklistValues, 'ISP - Type': udfISPTypePicklistValues } = res.data
			setUdfISP(udfISPPicklistValues.length > 0 ? udfISPPicklistValues[0].value : '')
			setUdfISPType(udfISPTypePicklistValues.length > 0 ? udfISPTypePicklistValues[0].value : '')
		})
	}, [udfPicklistValues])

	const routerNavigateFn = useNavigate()
	const wrapApiSearchMethodWithHandleRedirect = useCallback(apiSearchFn => async (query, options, requestOptions) => {
		const res = await apiSearchFn(query, options, requestOptions)
		handleRedirect(res, routerNavigateFn)
		return res
	}, [routerNavigateFn])

	const reset = useCallback(ready => {
		let bandwidth = null;
		if (selectedProduct) {
			bandwidth = selectedProduct.name.match(/^.* (\d+)\/(\d+) Mbps (.*)$/i)
		} else if (selectedService) {
			bandwidth = selectedService.name.match(/^.* (\d+)\/(\d+) Mbps$/i)
		}

		Promise.resolve(udfPicklistValues).then(res => {
			const { 'ISP': udfISPPicklistValues, 'ISP - Type': udfISPTypePicklistValues } = res.data

			let udfISPType = ''
			if (typeof bandwidth === 'object' && Array.isArray(bandwidth) && bandwidth.length > 3) {
				const bandwidthISPType = udfISPTypePicklistValues.find(value => value.label === bandwidth[3])
				if (bandwidthISPType) {
					udfISPType = bandwidthISPType.value
				}
			}
			if (udfISPType === '' && udfISPTypePicklistValues.length > 0) {
				udfISPType = udfISPTypePicklistValues[0].value
			}

			setUdfISPType(udfISPType)
			setUdfISP(udfISPPicklistValues.length > 0 ? udfISPPicklistValues[0].value : null)
		})

		setLoadingInternetContract(ready)
		setInternetContract(null)
		setLoadingInternetContractService(ready)
		setInternetContractService(null)
		setLoadingInternetConfigurationItem(ready)
		setInternetConfigurationItem(null)

		setLoadingNetworkContract(ready)
		setNetworkContract(null)
		setLoadingNetworkContractService(ready)
		setNetworkContractService(null)

		setInternetStartDateInput(null)
		setInternetCommitmentPeriodMonthsInput('36')
		setInternetPeriodTypeInput(selectedService ? selectedService.periodType.value.toString() : CONTRACT_PERIOD_TYPE_MONTHLY.toString())
		setInternetSetupFeeInput('0')
		setInternetUnitCostInput(selectedService ? formatNumberForInputField(recalculateServicePeriodPrice(selectedService.unitCost ?? 0.0, selectedService.periodType.value, SERVICE_PERIOD_TYPE_MONTHLY)) : '0')
		setInternetUnitPriceInput(selectedService ? formatNumberForInputField(recalculateServicePeriodPrice(selectedService.unitPrice ?? 0.0, selectedService.periodType.value, SERVICE_PERIOD_TYPE_MONTHLY)) : '0')

		setReferenceNumber('')
		setBandwidthDownMbps(typeof bandwidth === 'object' && Array.isArray(bandwidth) && bandwidth.length > 2 ? bandwidth[1] : '')
		setBandwidthUpMbps(typeof bandwidth === 'object' && Array.isArray(bandwidth) && bandwidth.length > 2 ? bandwidth[2] : '')
		setEnableDhcp(false)
		setLinknetNetworkCIDR('')
		setLinknetIPv4('')
		setLinknetIPv6('')
		setLinknetGatewayIPv4('')
		setLinknetGatewayIPv6('')
		setLinknetDNS1IP('')
		setLinknetDNS2IP('')
		setExtranetNetworkCIDR('')
		setExtranetGatewayIP('')

		setEnableNetwork(false)
		setNetworkStartDateInput(null)
		setNetworkEndDateInput(null)
		setNetworkPeriodTypeInput(CONTRACT_PERIOD_TYPE_MONTHLY.toString())
		setNetworkSetupFeeInput('0')
		setNetworkUnitCostInput('0')
		setNetworkUnitPriceInput('0')

		setImporting(false)
	}, [selectedProduct, selectedService, udfPicklistValues])

	const cachedSelectedCompany = useRef(null)
	const cachedSelectedCompanyLocation = useRef(null)
	const cachedSelectedProduct = useRef(null)
	const cachedSelectedService = useRef(null)
	useEffect(() => {
		let changed = false
		if (JSON.stringify(selectedCompany) !== JSON.stringify(cachedSelectedCompany.current)) {
			changed = true
		}
		if (JSON.stringify(selectedCompanyLocation) !== JSON.stringify(cachedSelectedCompanyLocation.current)) {
			changed = true
		}
		if (JSON.stringify(selectedProduct) !== JSON.stringify(cachedSelectedProduct.current)) {
			changed = true
		}
		if (JSON.stringify(selectedService) !== JSON.stringify(cachedSelectedService.current)) {
			changed = true
		}
		cachedSelectedCompany.current = selectedCompany
		cachedSelectedCompanyLocation.current = selectedCompanyLocation
		cachedSelectedProduct.current = selectedProduct
		cachedSelectedService.current = selectedService
		if (importing || !changed) return

		const ready = !!selectedCompany && !!selectedCompanyLocation && !!selectedProduct && !!selectedService
		reset(ready)
		if (!ready) return

		getContracts([
			{ field: 'companyID', op: 'eq', value: selectedCompany.id },
			{ field: 'contractCategory', op: 'eq', value: CONTRACT_CATEGORY_KIT_MANAGED_INTERNET },
			{ field: 'contractType', op: 'eq', value: CONTRACT_TYPE_RECURRING_SERVICE },
			{ field: 'status', op: 'eq', value: CONTRACT_STATUS_ACTIVE },
			{ field: 'contractName', op: 'eq', value: selectedCompany.companyName + ' - KIT Managed Internet - ' + selectedCompanyLocation.name },
		])
			.then(res => {
				handleRedirect(res, routerNavigateFn)
				if (!res.data || res.data.length === 0) {
					setLoadingInternetContract(false)
					setLoadingInternetContractService(false)
					return
				}
				const contract = res.data[0]

				setLoadingInternetContract(false)
				setInternetContract(contract)
				setInternetSetupFeeInput(formatNumberForInputField(contract.setupFee))
				setInternetStartDateInput(contract.startDate === null ? null : new Date(contract.startDate))
				setInternetPeriodTypeInput(contract.contractPeriodType.value.toString())

				getContractServices([
					{ field: 'contractID', op: 'eq', value: contract.id },
					{ field: 'serviceID', op: 'eq', value: selectedService.id },
				])
					.then(res => {
						handleRedirect(res, routerNavigateFn)
						if (!res.data || res.data.length === 0) {
							setLoadingInternetContractService(false)
							return
						}
						const contractService = res.data[0]
						setLoadingInternetContractService(false)
						setInternetContractService(contractService)
						setInternetUnitCostInput(formatNumberForInputField(recalculateServicePeriodPrice(contractService.unitCost, selectedService.periodType.value, SERVICE_PERIOD_TYPE_MONTHLY)))
						setInternetUnitPriceInput(formatNumberForInputField(recalculateServicePeriodPrice(contractService.unitPrice, selectedService.periodType.value, SERVICE_PERIOD_TYPE_MONTHLY)))
					})
					.catch(e => {
						handleRedirect(e.res, routerNavigateFn)
						console.error('Error getting internet contract service:', e)
						setLoadingInternetContractService(false)
					})
			})
			.catch(e => {
				handleRedirect(e.res, routerNavigateFn)
				console.error('Error getting internet contract:', e)
				setLoadingInternetContract(false)
				setLoadingInternetContractService(false)
			})

		getConfigurationItems([
			{ field: 'companyID', op: 'eq', value: selectedCompany.id },
			{ field: 'companyLocationID', op: 'eq', value: selectedCompanyLocation.id },
			{ field: 'productID', op: 'eq', value: selectedProduct.id },
			{ field: 'configurationItemCategoryID', op: 'eq', value: selectedProduct.defaultInstalledProductCategoryID },
			{ field: 'configurationItemType', op: 'eq', value: CI_TYPE_INTERNET },
		])
			.then(res => {
				handleRedirect(res, routerNavigateFn)
				if (!res.data || res.data.length === 0) {
					setLoadingInternetConfigurationItem(false)
					return
				}
				const configurationItem = res.data[0]

				setLoadingInternetConfigurationItem(false)
				setInternetConfigurationItem(configurationItem)

				setUdfISP(configurationItem.userDefinedFields['ISP'])
				setUdfISPType(configurationItem.userDefinedFields['ISP - Type'])
				setBandwidthDownMbps(Math.ceil(parseFloat(configurationItem.userDefinedFields['ISP - Båndbredde ned (Mbps)'])).toString())
				setBandwidthUpMbps(Math.ceil(parseFloat(configurationItem.userDefinedFields['ISP - Båndbredde opp (Mbps)'])).toString())
				setEnableDhcp(configurationItem.userDefinedFields['ISP - Linknett IPv4 WAN Nettverk (/CIDR)'].toString().trim() === 'DHCP')
				setLinknetNetworkCIDR(configurationItem.userDefinedFields['ISP - Linknett IPv4 WAN Nettverk (/CIDR)'])
				setLinknetIPv4(configurationItem.userDefinedFields['ISP - Linknett IPv4 WAN IP'])
				setLinknetGatewayIPv4(configurationItem.userDefinedFields['ISP - Linknett IPv4 WAN Gateway'])
				setLinknetDNS1IP(configurationItem.userDefinedFields['ISP - Linknett IPv4 DNS 1'])
				setLinknetDNS2IP(configurationItem.userDefinedFields['ISP - Linknett IPv4 DNS 2'])
				setExtranetNetworkCIDR(configurationItem.userDefinedFields['ISP - Ekstra IP-nett IPv4 (/CIDR)'])
				setExtranetGatewayIP(configurationItem.userDefinedFields['ISP - Ekstra IP-nett IPv4 Gateway'])
				setLinknetIPv6(configurationItem.userDefinedFields['ISP - Linknett IPv6 WAN IP (/CIDR)'])
				setLinknetGatewayIPv6(configurationItem.userDefinedFields['ISP - Linknett IPv6 WAN Gateway'])
			})
			.catch(e => {
				handleRedirect(e.res, routerNavigateFn)
				console.error('Error getting internet configuration item:', e)
				setLoadingInternetConfigurationItem(false)
			})
		getContracts([
			{ field: 'companyID', op: 'eq', value: selectedCompany.id },
			{ field: 'contractCategory', op: 'eq', value: CONTRACT_CATEGORY_KIT_MANAGED_NETWORK },
			{ field: 'contractType', op: 'eq', value: CONTRACT_TYPE_RECURRING_SERVICE },
			{ field: 'status', op: 'eq', value: CONTRACT_STATUS_ACTIVE },
			{ field: 'contractName', op: 'eq', value: selectedCompany.companyName + ' - KIT Managed Network - ' + selectedCompanyLocation.name },
		])
			.then(res => {
				handleRedirect(res, routerNavigateFn)
				if (!res.data || res.data.length === 0) {
					setLoadingNetworkContract(false)
					setLoadingNetworkContractService(false)
					return
				}
				const contract = res.data[0]

				setEnableNetwork(true)
				setLoadingNetworkContract(false)
				setNetworkContract(contract)
				setNetworkSetupFeeInput(formatNumberForInputField(contract.setupFee))
				setNetworkStartDateInput(contract.startDate === null ? null : new Date(contract.startDate))
				setNetworkPeriodTypeInput(contract.contractPeriodType.value.toString())

				getContractServices([
					{ field: 'contractID', op: 'eq', value: contract.id },
					{ field: 'serviceID', op: 'eq', value: SERVICE_ID_KIT_MANAGED_NETWORK },
				])
					.then(res => {
						handleRedirect(res, routerNavigateFn)
						if (!res.data || res.data.length === 0) {
							setLoadingNetworkContractService(false)
							return
						}
						const contractService = res.data[0]
						setLoadingNetworkContractService(false)
						setNetworkContractService(contractService)
						setNetworkUnitCostInput(formatNumberForInputField(contractService.unitCost)) // XXX: periodType is monthly, input is price/month
						setNetworkUnitPriceInput(formatNumberForInputField(contractService.unitPrice)) // XXX: periodType is monthly, input is price/month
					})
					.catch(e => {
						handleRedirect(e.res, routerNavigateFn)
						console.error('Error getting network contract service:', e)
						setLoadingNetworkContractService(false)
					})
			})
			.catch(e => {
				handleRedirect(e.res, routerNavigateFn)
				console.error('Error getting network contract:', e)
				setLoadingNetworkContract(false)
				setLoadingNetworkContractService(false)
			})
	}, [importing, reset, selectedCompany, selectedCompanyLocation, selectedProduct, selectedService, routerNavigateFn])

	const handleImportData = () => {
		if (importing || !selectedCompany || !selectedCompanyLocation || !selectedProduct || !selectedService || loadingPrimaryContact || loadingInternetContract || loadingInternetContractService || loadingInternetConfigurationItem || loadingNetworkContract || loadingNetworkContractService) return
		setImporting(true)

		const internetCommitmentPeriodMonths = parseInt(internetCommitmentPeriodMonthsInput)
		const internetPeriodType = parseInt(internetPeriodTypeInput)
		const internetSetupFee = parseFloat(internetSetupFeeInput)
		const internetUnitCost = recalculateServicePeriodPrice(parseFloat(internetUnitCostInput), SERVICE_PERIOD_TYPE_MONTHLY, selectedService.periodType.value)
		const internetUnitPrice = recalculateServicePeriodPrice(parseFloat(internetUnitPriceInput), SERVICE_PERIOD_TYPE_MONTHLY, selectedService.periodType.value)

		const networkPeriodType = parseInt(networkPeriodTypeInput)
		const networkSetupFee = parseFloat(networkSetupFeeInput)
		const networkUnitCost = parseFloat(networkUnitCostInput) // XXX: periodType is monthly, input is price/month
		const networkUnitPrice = parseFloat(networkUnitPriceInput) // XXX: periodType is monthly, input is price/month

		// XXX: KIT Managed Internet: Convert Start Date (datepicker input) to UTC
		// XXX: Set time to 08:00 due to Autotask API bug which sets date back 1 day if time is 00:00
		const internetStartDate = new Date(Date.UTC(internetStartDateInput.getFullYear(), internetStartDateInput.getMonth(), internetStartDateInput.getDate(), 8))

		// KIT Managed Internet: End Date = Effective Date + commitment period (months) - 1 day
		// XXX: Set time to 08:00 due to Autotask API bug which sets date back 1 day if time is 00:00
		let internetEndDate = new Date(internetStartDate)
		internetEndDate.setUTCMonth(internetEndDate.getUTCMonth() + internetCommitmentPeriodMonths)
		internetEndDate.setUTCDate(internetEndDate.getUTCDate() - 1)
		internetEndDate.setUTCHours(8)

		// XXX: KIT Managed Network: Convert Start Date (datepicker input) to UTC
		// XXX: Set time to 08:00 due to Autotask API bug which sets date back 1 day if time is 00:00
		const networkStartDate = new Date(Date.UTC(networkStartDateInput.getFullYear(), networkStartDateInput.getMonth(), networkStartDateInput.getDate(), 8))

		// XXX: KIT Managed Network: Convert End Date (datepicker input) to UTC
		// XXX: Set time to 08:00 due to Autotask API bug which sets date back 1 day if time is 00:00
		const networkEndDate = new Date(Date.UTC(networkEndDateInput.getFullYear(), networkEndDateInput.getMonth(), networkEndDateInput.getDate(), 8))

		const newInternetContract = {
			contractName: selectedCompany.companyName + ' - KIT Managed Internet - ' + selectedCompanyLocation.name,
			companyID: selectedCompany.id,
			contactID: primaryContact ? primaryContact.id : null,
			contractPeriodType: internetPeriodType,
			startDate: internetStartDate,
			endDate: internetEndDate,
			contractCategory: CONTRACT_CATEGORY_KIT_MANAGED_INTERNET,
			serviceLevelAgreementID: CONTRACT_SLA_ID_DEFAULT,
			status: CONTRACT_STATUS_ACTIVE,
			timeReportingRequiresStartAndStopTimes: CONTRACT_TIMEREPORTING_STARTSTOP_TRUE,
			contractType: CONTRACT_TYPE_RECURRING_SERVICE,
			setupFee: internetSetupFee,
			contractExclusionSetID: CONTRACT_EXCLUSION_SET_KIT_MANAGED_INTERNET,
		}

		const newNetworkContract = enableNetwork ? {
			contractName: selectedCompany.companyName + ' - KIT Managed Network - ' + selectedCompanyLocation.name,
			companyID: selectedCompany.id,
			contactID: primaryContact ? primaryContact.id : null,
			contractPeriodType: networkPeriodType,
			startDate: networkStartDate,
			endDate: networkEndDate,
			contractCategory: CONTRACT_CATEGORY_KIT_MANAGED_NETWORK,
			serviceLevelAgreementID: CONTRACT_SLA_ID_DEFAULT,
			status: CONTRACT_STATUS_ACTIVE,
			timeReportingRequiresStartAndStopTimes: CONTRACT_TIMEREPORTING_STARTSTOP_TRUE,
			contractType: CONTRACT_TYPE_RECURRING_SERVICE,
			setupFee: networkSetupFee,
			contractExclusionSetID: CONTRACT_EXCLUSION_SET_KIT_MANAGED_NETWORK,
		} : null

		let promises = []
		if (internetContract) {
			promises.push(updateContract(internetContract.id, newInternetContract))
		} else {
			promises.push(createContract(newInternetContract))
		}
		if (enableNetwork) {
			if (networkContract) {
				promises.push(updateContract(networkContract.id, newNetworkContract))
			} else {
				promises.push(createContract(newNetworkContract))
			}
		} else {
			promises.push(Promise.resolve(null))
		}

		Promise.all(promises).then(([internetContractRes, networkContractRes]) => {
			handleRedirect(internetContractRes, routerNavigateFn)
			handleRedirect(networkContractRes, routerNavigateFn)
			if (!internetContractRes.data || internetContractRes.data.itemId === null) {
				// Add failure message (did not create a contract)
				console.error('Error importing internet: Could not create internet contract')
				setMessages(messages => messages.concat({
					key: 'import_error_no_internet_contract_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'error',
					icon: 'exclamation circle',
					title: 'Import feilet',
					content: 'Kunne ikke opprette kontrakt "' + internetContract.name + '"'
				}))
				return
			}
			const internetContractItemID = internetContractRes.data.itemId
			const newInternetContractService = {
				contractID: internetContractItemID,
				serviceID: selectedService.id,
				unitCost: internetUnitCost,
				invoiceDescription: referenceNumber + ' ' + selectedCompanyLocation.address1 + ', ' + selectedCompanyLocation.postalCode + ' ' + selectedCompanyLocation.city,
			}

			if (enableNetwork && (!networkContractRes.data || networkContractRes.data.itemId === null)) {
				// Add failure message (did not create a contract)
				console.error('Error importing internet: Could not create network contract')
				setMessages(messages => messages.concat({
					key: 'import_error_no_network_contract_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'error',
					icon: 'exclamation circle',
					title: 'Import feilet',
					content: 'Kunne ikke opprette kontrakt "' + networkContract.name + '"'
				}))
				return
			}
			const networkContractItemID = enableNetwork ? networkContractRes.data.itemId : null
			const newNetworkContractService = enableNetwork ? {
				contractID: networkContractItemID,
				serviceID: SERVICE_ID_KIT_MANAGED_NETWORK,
				unitCost: networkUnitCost,
				invoiceDescription: referenceNumber + ' ' + selectedCompanyLocation.address1 + ', ' + selectedCompanyLocation.postalCode + ' ' + selectedCompanyLocation.city,
			} : null

			return Promise.all([
				Promise.resolve(internetContractItemID),
				internetContractService ? updateContractService(internetContractService.id, newInternetContractService) : createContractService(newInternetContractService),

				Promise.resolve(networkContractItemID),
				enableNetwork ? (networkContractService ? updateContractService(networkContractService.id, newNetworkContractService) : createContractService(newNetworkContractService)) : Promise.resolve(null),
			])
		}).then(([internetContractItemID, internetContractServiceRes, networkContractItemID, networkContractServiceRes]) => {
			handleRedirect(internetContractServiceRes, routerNavigateFn)
			handleRedirect(networkContractServiceRes, routerNavigateFn)
			if (!internetContractServiceRes.data || internetContractServiceRes.data.itemId === null) {
				// Add failure message (did not create a contract service)
				console.error('Error importing internet: Could not add KIT Managed Internet service to internet contract')
				setMessages(messages => messages.concat({
					key: 'import_error_no_internet_contract_service_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'error',
					icon: 'exclamation circle',
					title: 'Import feilet',
					content: 'Kunne ikke legge til tjeneste KIT Managed Internet på tilhørende kontrakt'
				}))
				return
			}
			const internetContractServiceItemID = internetContractServiceRes.data.itemId

			const internetContractServiceAdjustment = {
				contractID: internetContractItemID,
				serviceID: selectedService.id,
				contractServiceID: internetContractServiceItemID,
				unitChange: 1,
				effectiveDate: internetStartDate,
				adjustedUnitCost: internetUnitCost,
				adjustedUnitPrice: internetUnitPrice,
			}

			if (enableNetwork && (!networkContractServiceRes.data || networkContractServiceRes.data.itemId === null)) {
				// Add failure message (did not create a contract service)
				console.error('Error importing internet: Could not add KIT Managed Network service to network contract')
				setMessages(messages => messages.concat({
					key: 'import_error_no_network_contract_service_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'error',
					icon: 'exclamation circle',
					title: 'Import feilet',
					content: 'Kunne ikke legge til tjeneste KIT Managed Network på tilhørende kontrakt'
				}))
				return
			}
			const networkContractServiceItemID = enableNetwork ? networkContractServiceRes.data.itemId : null

			const newInternetConfigurationItem = {
				isActive: true,
				referenceNumber,
				configurationItemCategoryID: selectedProduct.defaultInstalledProductCategoryID,
				configurationItemType: CI_TYPE_INTERNET,
				productID: selectedProduct.id,
				companyID: selectedCompany.id,
				companyLocationID: selectedCompanyLocation.id,
				contactID: primaryContact ? primaryContact.id : null,
				contractID: internetContractItemID,
				contractServiceID: internetContractServiceItemID,
				vendorID: COMPANY_ID_VENDOR_ALTIBOX_AS,
				installDate: internetStartDate,
				warrantyExpirationDate: internetEndDate,
				serviceLevelAgreementID: CI_SLA_ID_DEFAULT,
				userDefinedFields: {
					'ISP': udfISP,
					'ISP - Type': udfISPType,
					'ISP - Båndbredde ned (Mbps)': bandwidthDownMbps,
					'ISP - Båndbredde opp (Mbps)': bandwidthUpMbps,
					'ISP - Linknett IPv4 WAN Nettverk (/CIDR)': enableDhcp ? 'DHCP' : linknetNetworkCIDR,
					'ISP - Linknett IPv4 WAN IP': linknetIPv4,
					'ISP - Linknett IPv4 WAN Gateway': linknetGatewayIPv4,
					'ISP - Linknett IPv4 DNS 1': linknetDNS1IP,
					'ISP - Linknett IPv4 DNS 2': linknetDNS2IP,
					'ISP - Ekstra IP-nett IPv4 (/CIDR)': extranetNetworkCIDR,
					'ISP - Ekstra IP-nett IPv4 Gateway': extranetGatewayIP,
					'ISP - Linknett IPv6 WAN IP (/CIDR)': linknetIPv6,
					'ISP - Linknett IPv6 WAN Gateway': linknetGatewayIPv6,
				},
			}

			return Promise.all([
				Promise.resolve(internetContractItemID),
				Promise.resolve(internetContractServiceItemID),
				Promise.resolve(networkContractItemID),
				Promise.resolve(networkContractServiceItemID),
				createContractServiceAdjustment(internetContractServiceAdjustment),
				internetConfigurationItem ? updateConfigurationItem(internetConfigurationItem.id, newInternetConfigurationItem) : createConfigurationItem(newInternetConfigurationItem),
				networkContractServiceItemID !== null && networkContractItemID !== null ? createContractServiceAdjustment({
					contractID: networkContractItemID,
					serviceID: SERVICE_ID_KIT_MANAGED_NETWORK,
					contractServiceID: networkContractServiceItemID,
					unitChange: 1,
					effectiveDate: networkStartDate,
					adjustedUnitCost: networkUnitCost,
					adjustedUnitPrice: networkUnitPrice,
				}) : Promise.resolve(null)
			])
		}).then(([internetContractItemID, internetContractServiceItemID, networkContractItemID, networkContractServiceItemID, internetContractServiceAdjustmentRes, internetConfigurationItemRes, networkContractServiceAdjustmentRes]) => {
			handleRedirect(internetContractServiceAdjustmentRes, routerNavigateFn)
			handleRedirect(networkContractServiceAdjustmentRes, routerNavigateFn)
			if (!internetContractServiceAdjustmentRes.data || internetContractServiceAdjustmentRes.data.itemId === null) {
				// Add failure message (did not create a contract service adjustment)
				console.error('Error importing internet: Could not create KIT Managed Internet contract service adjustment')
				setMessages(messages => messages.concat({
					key: 'import_error_no_internet_contract_service_adjustment_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'error',
					icon: 'exclamation circle',
					title: 'Import feilet',
					content: 'Kunne ikke justere enhetsantall på KIT Managed Internet kontrakt-tjeneten'
				}))
				return
			}

			if (!internetConfigurationItemRes.data || internetConfigurationItemRes.data.itemId === null) {
				// Add failure message (did not create a configuration item)
				console.error('Error importing internet: Could not create KIT Managed Internet configuration item')
				setMessages(messages => messages.concat({
					key: 'import_error_no_internet_ci_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'error',
					icon: 'exclamation circle',
					title: 'Import feilet',
					content: 'Kunne ikke opprette configuration item for KIT Managed Internet'
				}))
				return
			}
			const internetConfigurationItemID = internetConfigurationItemRes.data.itemId

			if (networkContractServiceItemID !== null && networkContractItemID !== null && (!networkContractServiceAdjustmentRes.data || networkContractServiceAdjustmentRes.data.itemId === null)) {
				// Add failure message (did not create a contract service adjustment)
				console.error('Error importing internet: Could not create KIT Managed Network contract service adjustment')
				setMessages(messages => messages.concat({
					key: 'import_error_no_network_contract_service_adjustment_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'error',
					icon: 'exclamation circle',
					title: 'Import feilet',
					content: 'Kunne ikke justere enhetsantall på KIT Managed Network kontrakt-tjeneten'
				}))
				return
			}

			if (selectedTicket) {
				return Promise.all([
					Promise.resolve(internetContractItemID),
					Promise.resolve(internetContractServiceItemID),
					Promise.resolve(networkContractItemID),
					Promise.resolve(networkContractServiceItemID),
					Promise.resolve(internetConfigurationItemID),
					selectedTicket.configurationItemID === null ? updateTicket(selectedTicket.id, {
						configurationItemID: internetConfigurationItemID,
					}) : createTicketAdditionalConfigurationItem({
						ticketID: selectedTicket.id,
						configurationItemID: internetConfigurationItemID,
					}),
				])
			} else {
				return Promise.all([
					Promise.resolve(internetContractItemID),
					Promise.resolve(internetContractServiceItemID),
					Promise.resolve(networkContractItemID),
					Promise.resolve(networkContractServiceItemID),
					Promise.resolve(internetConfigurationItemID),
					Promise.resolve(null),
				])
			}
		}).then(([internetContractItemID, internetContractServiceItemID, networkContractItemID, networkContractServiceItemID, internetConfigurationItemID, ticketRes]) => {
			handleRedirect(ticketRes, routerNavigateFn)
			if (ticketRes && (!ticketRes.data || ticketRes.data.itemId === null)) {
				// Add failure message (did not update ticket)
				console.error('Error importing internet: Could not update ticket')
				setMessages(messages => messages.concat({
					key: 'import_error_no_internet_ci_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'warning',
					icon: 'exclamation triangle',
					title: 'Import vellykket, oppdatering av sak feilet',
					content: 'Kunne ikke oppdatere oppsett-sak med ny configuration item'
				}))
				return
			}

			// Add success message
			console.info('Imported internet successfully')
			setMessages(messages => messages.concat({
				key: 'import_success_' + Math.round(new Date() / 1000).toString(),
				dismissable: true,
				type: 'success',
				icon: 'check',
				title: 'Import vellykket',
				content: <>
					<p>Importerte internett-linjen uten feil.</p>
					<p>
						<Link
							href={'https://ww19.autotask.net/Autotask/AutotaskExtend/ExecuteCommand.aspx?Code=OpenInstalledProduct&InstalledProductID=' + encodeURIComponent(internetConfigurationItemID)}
							target="tmp"
							popupOptions={{width: 1, height: 1}}
						>
							Åpne CI i Autotask
						</Link> (ID <code>{internetConfigurationItemID}</code>)
						&nbsp;&mdash; KIT Managed Internet kontrakt ID <code>{internetContractItemID}</code>
						{enableNetwork ? <>
							&nbsp;&mdash; KIT Managed Network kontrakt ID <code>{networkContractItemID}</code>
						</> : null}
					</p>
				</>
			}))
			reset(false)
		}).catch(e => {
			handleRedirect(e.res, routerNavigateFn)
			// Add failure message (unknown error)
			console.error('Error importing internet:', e)
			setMessages(messages => messages.concat({
				key: 'import_error_' + Math.round(new Date() / 1000).toString(),
				dismissable: true,
				type: 'error',
				icon: 'exclamation circle',
				title: 'Import feilet',
				content: 'Kunne ikke importere internett-linje: Ukjent feil (sjekk JavaScript-konsollen)'
			}))
		})
	}

	const companyLocationsQuery = useMemo(() => selectedCompany ? [
		{field: 'isActive', op: 'eq', value: true},
		{field: 'companyID', op: 'eq', value: selectedCompany.id},
	] : null, [selectedCompany])

	const ticketsQuery = useMemo(() => selectedCompany ? [
		{field: 'companyID', op: 'eq', value: selectedCompany.id}
	] : null, [selectedCompany])

	// decide "Kunde" message
	let customerInfo = 'Velg en kunde.'
	if (selectedCompany) {
		customerInfo = <div>
			Valgt kunde <Link
				href={'https://ww19.autotask.net/Autotask/AutotaskExtend/ExecuteCommand.aspx?Code=OpenAccount&AccountID=' + encodeURIComponent(selectedCompany.id)}
				target="tmp"
				popupOptions={{width: 1, height: 1}}
			>
				{selectedCompany.companyName}
			</Link> (ID <code>{selectedCompany.id}</code>), internett-linjen vil knyttes til denne kunden.
		</div>
	}
	let primaryContactInfo = 'Velg en kunde først.'
	if (loadingPrimaryContact) {
		primaryContactInfo = 'Ser etter primær-kontakt...'
	} else if (primaryContact) {
		primaryContactInfo = <div>
			Fant primær-kontakt <Link
				href={'https://ww19.autotask.net/Autotask/AutotaskExtend/ExecuteCommand.aspx?Code=OpenContact&ContactID=' + encodeURIComponent(primaryContact.id)}
				target="tmp"
				popupOptions={{width: 1, height: 1}}
			>
				{[[primaryContact.firstName, primaryContact.middleInitial].join(' ').trim(), primaryContact.lastName].join(' ').trim()}
			</Link> (ID <code>{primaryContact.id}</code>), internett-linjen vil knyttes til denne kontakten.
		</div>
	} else if (selectedCompany) {
		primaryContactInfo = 'Fant ingen primær-kontakt'
	}

	// decide "Kunde" message
	let locationInfo = 'Velg en kunde først.'
	if (selectedCompany) {
		if (selectedCompanyLocation) {
			locationInfo = <div>
				Valgt plassering {selectedCompanyLocation.name} (ID <code>{selectedCompanyLocation.id}</code>), internett-linjen vil knyttes til denne plasseringen.
			</div>
		} else {
			locationInfo = 'Velg en plassering.'
		}
	}

	// decide "Tjeneste" message
	let serviceInfo = 'Velg en tjeneste.'
	if (selectedService) {
		serviceInfo = <div>
			Valgt tjeneste {selectedService.name} (ID <code>{selectedService.id}</code>), denne vil {selectedCompany && selectedCompanyLocation && selectedProduct && selectedService && !loadingInternetContractService && !!internetContractService ? 'oppdateres' : 'opprettes'} i kontrakten.
		</div>
	}

	// decide "Produkt" message
	let productInfo = 'Velg et produkt.'
	if (selectedProduct) {
		productInfo = <div>
			Valgt produkt <Link
				href={'https://ww19.autotask.net/Autotask/AutotaskExtend/ExecuteCommand.aspx?Code=OpenProduct&ProductID=' + encodeURIComponent(selectedProduct.id)}
				target="tmp"
				popupOptions={{width: 1, height: 1}}
			>
				{selectedProduct.name}
			</Link> (ID <code>{selectedProduct.id}</code>), dette vil knyttes til internett-linjen.
		</div>
	}

	// decide "Oppsett-sak" message
	let ticketInfo = 'Velg en kunde først.'
	if (selectedCompany) {
		if (selectedTicket) {
			ticketInfo = <div>
				Valgt sak <Link
					href={'https://ww19.autotask.net/Autotask/AutotaskExtend/ExecuteCommand.aspx?Code=OpenTicketDetail&TicketId=' + encodeURIComponent(selectedTicket.id)}
					target="tmp"
					popupOptions={{width: 1, height: 1}}
				>
					{selectedTicket.title}
				</Link> (ID <code>{selectedTicket.id}</code>), internett-linjen vil knyttes til denne saken.
			</div>
		} else {
			ticketInfo = 'Velg en sak hvis du vil at CI skal tilknyttes.'
		}
	}

	let internetContractInfo = 'Velg kunde, plassering, produkt og tjeneste først.';
	if (loadingInternetContract) {
		internetContractInfo = 'Ser etter eksisterende kontrakt...'
	} else if (internetContract) {
		internetContractInfo = <div>
			Fant eksisterende kontrakt <Link
				href={'https://ww19.autotask.net/contracts/views/contractView.asp?contractID=' + encodeURIComponent(internetContract.id)}
				target={'contract_id_' + internetContract.id}
			>
				{internetContract.contractName}
			</Link> (ID <code>{internetContract.id}</code>), denne vil bli oppdatert.
		</div>
	} else if (selectedCompany && selectedCompanyLocation && selectedProduct && selectedService) {
		internetContractInfo = 'Fant ikke eksisterende kontrakt, ny vil bli opprettet.'
	}

	let internetContractServiceInfo = 'Velg kunde, plassering, produkt og tjeneste først.';
	if (loadingInternetContractService) {
		internetContractServiceInfo = 'Ser etter eksisterende kontrakt-tjeneste...'
	} else if (internetContractService) {
		internetContractServiceInfo = <div>Fant eksisterende kontrakt-tjeneste (ID <code>{internetContractService.id}</code>), denne vil bli oppdatert.</div>
	} else if (selectedCompany && selectedCompanyLocation && selectedProduct && selectedService) {
		internetContractServiceInfo = 'Fant ikke eksisterende kontrakt-tjeneste, ny vil bli opprettet.'
	}

	let networkContractInfo = 'Velg kunde, plassering, produkt og tjeneste først.';
	if (loadingNetworkContract) {
		networkContractInfo = 'Ser etter eksisterende kontrakt...'
	} else if (networkContract) {
		networkContractInfo = <div>
			Fant eksisterende kontrakt <Link
				href={'https://ww19.autotask.net/contracts/views/contractView.asp?contractID=' + encodeURIComponent(networkContract.id)}
				target={'contract_id_' + networkContract.id}
			>
				{networkContract.contractName}
			</Link> (ID <code>{networkContract.id}</code>), denne vil bli oppdatert.
		</div>
	} else if (selectedCompany && selectedCompanyLocation && selectedProduct && selectedService) {
		networkContractInfo = 'Fant ikke eksisterende kontrakt, ny vil' + (enableNetwork ? '' : ' ikke') + ' bli opprettet.'
	}

	let networkContractServiceInfo = 'Velg kunde, plassering, produkt og tjeneste først.';
	if (loadingNetworkContractService) {
		networkContractServiceInfo = 'Ser etter eksisterende kontrakt-tjeneste...'
	} else if (networkContractService) {
		networkContractServiceInfo = <div>Fant eksisterende kontrakt-tjeneste (ID <code>{networkContractService.id}</code>), denne vil bli oppdatert.</div>
	} else if (selectedCompany && selectedCompanyLocation && selectedProduct && selectedService) {
		networkContractServiceInfo = 'Fant ikke eksisterende kontrakt-tjeneste, ny vil' + (enableNetwork ? '' : ' ikke') + ' bli opprettet.'
	}

	let internetConfigurationItemInfo = 'Velg kunde, plassering, produkt og tjeneste først.';
	if (loadingInternetConfigurationItem) {
		internetConfigurationItemInfo = 'Ser etter eksisterende CI...'
	} else if (internetConfigurationItem) {
		internetConfigurationItemInfo = <div>
			Fant <Link
				href={'https://ww19.autotask.net/Autotask/AutotaskExtend/ExecuteCommand.aspx?Code=OpenInstalledProduct&InstalledProductID=' + encodeURIComponent(internetConfigurationItem.id)}
				target="tmp"
				popupOptions={{width: 1, height: 1}}
			>
				eksisterende CI for internett-linjen
			</Link> (ID <code>{internetConfigurationItem.id}</code>), denne vil bli oppdatert.
		</div>
	} else if (selectedCompany && selectedCompanyLocation && selectedProduct && selectedService) {
		internetConfigurationItemInfo = 'Fant ikke eksisterende CI, ny vil bli opprettet.'
	}

	const statusMessages = useMemo(() => <>
		<Grid stackable columns={2}>
			<Grid.Column>
				<Message
					warning={!selectedCompany}
					success={!!selectedCompany}
					icon={selectedCompany ? 'check' : 'exclamation triangle'}
					header="Kunde"
					content={customerInfo}
				/>
				<Message
					info={!primaryContact}
					success={!!primaryContact}
					icon={loadingPrimaryContact ? <Icon loading name="spinner" /> : (selectedCompany && !!primaryContact ? 'check' : 'info circle')}
					header="Primær-kontakt"
					content={primaryContactInfo}
				/>
				<Message
					info={!selectedCompany}
					warning={!!selectedCompany && !selectedCompanyLocation}
					success={!!selectedCompanyLocation}
					icon={selectedCompanyLocation ? 'check' : (selectedCompany ? 'exclamation triangle' : 'info circle')}
					header="Plassering"
					content={locationInfo}
				/>
				<Message
					warning={!selectedService}
					success={!!selectedService}
					icon={selectedService ? 'check' : 'exclamation triangle'}
					header="Tjeneste"
					content={serviceInfo}
				/>
				<Message
					warning={!selectedProduct}
					success={!!selectedProduct}
					icon={selectedProduct ? 'check' : 'exclamation triangle'}
					header="Produkt"
					content={productInfo}
				/>
				<Message
					info={!selectedTicket}
					success={!!selectedTicket}
					icon={selectedTicket ? 'check' : 'info circle'}
					header="Oppsett-sak"
					content={ticketInfo}
				/>
			</Grid.Column>
			<Grid.Column>
				<Message
					info={!selectedCompany || !selectedCompanyLocation || !selectedProduct || !selectedService || loadingInternetContract || !internetContract}
					success={selectedCompany && selectedCompanyLocation && selectedProduct && selectedService && !loadingInternetContract && !!internetContract}
					icon={loadingInternetContract ? <Icon loading name="spinner" /> : (selectedCompany && selectedCompanyLocation && selectedProduct && selectedService && !!internetContract ? 'check' : (selectedCompany && selectedCompanyLocation && selectedProduct && selectedService && !internetContract ? 'exclamation circle' : 'info circle'))}
					header="KIT Managed Internet kontrakt"
					content={internetContractInfo}
				/>
				<Message
					info={!selectedCompany || !selectedCompanyLocation || !selectedProduct || !selectedService || loadingInternetContract || loadingInternetContractService || !internetContractService}
					success={selectedCompany && selectedCompanyLocation && selectedProduct && selectedService && !loadingInternetContractService && !!internetContractService}
					icon={loadingInternetContract || loadingInternetContractService ? <Icon loading name="spinner" /> : (selectedCompany && selectedCompanyLocation && selectedProduct && selectedService && !!internetContractService ? 'check' : (selectedCompany && selectedCompanyLocation && selectedProduct && selectedService && !internetContractService ? 'exclamation circle' : 'info circle'))}
					header="KIT Managed Internet kontrakt-tjeneste"
					content={internetContractServiceInfo}
				/>
				<Message
					info={!selectedCompany || !selectedCompanyLocation || loadingNetworkContract || !networkContract}
					success={selectedCompany && selectedCompanyLocation  && !loadingNetworkContract && !!networkContract}
					icon={loadingNetworkContract ? <Icon loading name="spinner" /> : (selectedCompany && selectedCompanyLocation  && !!networkContract ? 'check' : (selectedCompany && selectedCompanyLocation  && !networkContract ? 'exclamation circle' : 'info circle'))}
					header="KIT Managed Network kontrakt"
					content={networkContractInfo}
				/>
				<Message
					info={!selectedCompany || !selectedCompanyLocation || !selectedProduct || !selectedService || loadingNetworkContract || loadingNetworkContractService || !networkContractService}
					success={selectedCompany && selectedCompanyLocation && selectedProduct && selectedService && !loadingNetworkContractService && !!networkContractService}
					icon={loadingNetworkContract || loadingNetworkContractService ? <Icon loading name="spinner" /> : (selectedCompany && selectedCompanyLocation && selectedProduct && selectedService && !!networkContractService ? 'check' : (selectedCompany && selectedCompanyLocation && selectedProduct && selectedService && !networkContractService ? 'exclamation circle' : 'info circle'))}
					header="KIT Managed Network kontrakt-tjeneste"
					content={networkContractServiceInfo}
				/>
				<Message
					info={!selectedCompany || !selectedCompanyLocation || !selectedProduct || loadingInternetConfigurationItem || !internetConfigurationItem}
					success={selectedCompany && selectedCompanyLocation && selectedProduct && !loadingInternetConfigurationItem && !!internetConfigurationItem}
					icon={loadingInternetConfigurationItem ? <Icon loading name="spinner" /> : (selectedCompany && selectedCompanyLocation && selectedProduct && !!internetConfigurationItem ? 'check' : (selectedCompany && selectedCompanyLocation && selectedProduct && !internetConfigurationItem ? 'exclamation circle' : 'info circle'))}
					header="KIT Managed Internet CI"
					content={internetConfigurationItemInfo}
				/>
			</Grid.Column>
		</Grid>
	</>, [
		customerInfo,
		internetConfigurationItem,
		internetConfigurationItemInfo,
		internetContract,
		internetContractInfo,
		internetContractService,
		internetContractServiceInfo,
		loadingInternetConfigurationItem,
		loadingInternetContract,
		loadingInternetContractService,
		loadingNetworkContract,
		loadingNetworkContractService,
		loadingPrimaryContact,
		locationInfo,
		networkContract,
		networkContractInfo,
		networkContractService,
		networkContractServiceInfo,
		primaryContact,
		primaryContactInfo,
		productInfo,
		selectedCompany,
		selectedCompanyLocation,
		selectedProduct,
		selectedService,
		selectedTicket,
		serviceInfo,
		ticketInfo,
	])

	useEffect(() => {
		setRail(statusMessages)
		return () => setRail(null)
	}, [statusMessages, setRail])

	return <>
		<Segment vertical padded="very">
			<Container>
				<Header size="huge">Importer internett-linje til Autotask</Header>
				<Accordion>
					<Accordion.Title
						active={showDescription}
						onClick={() => setShowDescription(showDescription => !showDescription)}
					>
						<Icon name="dropdown" /> Hva gjør verktøyet?
					</Accordion.Title>
					<Accordion.Content active={showDescription}>
						<List bulleted>
							<List.Item>
								Oppretter en Contract for KIT Managed Internet
								<List bulleted>
									<List.Item><b>Company:</b> Valgt Company</List.Item>
									<List.Item><b>Contract Type:</b> Recurring Service</List.Item>
									<List.Item><b>Contract Name:</b> <code>Company Name</code> - <code>Service Name</code> - <code>Location Name</code></List.Item>
									<List.Item><b>Contract Description:</b> Blank</List.Item>
									<List.Item><b>External Contract Number:</b> Blank</List.Item>
									<List.Item><b>Service Level Agreement:</b> Default SLA</List.Item>
									<List.Item><b>Contract Status:</b> Active</List.Item>
									<List.Item><b>Contract Category:</b> KIT Managed Internet (ID <code>{CONTRACT_CATEGORY_KIT_MANAGED_INTERNET}</code>)</List.Item>
									<List.Item><b>Contact:</b> Primær-kontakt hos kunden</List.Item>
									<List.Item><b>Approve &amp; Post Labor:</b> Manually</List.Item>
									<List.Item><b>Contract Period Type:</b> Standard-verdi hentes fra Company UDF "Fakturering: Periode", kan overstyres</List.Item>
									<List.Item><b>Start Date:</b> Standard-verdi hentes fra Company UDF "Fakturering: Start dato", kan overstyres</List.Item>
									<List.Item><b>End Date:</b> Valgt startdato + Valgt bindingstid - 1 dag</List.Item>
									<List.Item><b>Purchase Order Number:</b> Blank</List.Item>
									<List.Item><b>Setup Fee:</b> Valgt etableringskostnad</List.Item>
									<List.Item><b>Exclusion Set:</b> KIT Managed Internet (ID <code>{CONTRACT_EXCLUSION_SET_KIT_MANAGED_INTERNET}</code>)</List.Item>
								</List>
							</List.Item>
							<List.Item>
								Oppretter en ContractService for KIT Managed Internet
								<List bulleted>
									<List.Item><b>Contract:</b> KIT Managed Internet Contract opprettet tidligere</List.Item>
									<List.Item><b>Service:</b> Valgt Service</List.Item>
									<List.Item><b>Invoice Description:</b> <code>Sambandsnummer</code> <code>Adresse</code>, <code>Postnummer</code> <code>By</code></List.Item>
								</List>
							</List.Item>
							<List.Item>
								Oppretter et ConfigurationItem for KIT Managed Internet
								<List bulleted>
									<List.Item><b>Configuration Item Category:</b> Default Configuration Item Category fra Product</List.Item>
									<List.Item><b>Configuration Item Type:</b> Internett (ID <code>{CI_TYPE_INTERNET}</code>)</List.Item>
									<List.Item><b>Description:</b> Blank</List.Item>
									<List.Item><b>Product:</b> Valgt Product</List.Item>
									<List.Item><b>Company:</b> Valgt Company</List.Item>
									<List.Item><b>Contact:</b> Primær-kontakt hos kunden</List.Item>
									<List.Item><b>Status:</b> Active</List.Item>
									<List.Item><b>Reference Number:</b> Sambandsnummer fra skjema</List.Item>
									<List.Item><b>Install Date:</b> Valgt startdato</List.Item>
									<List.Item><b>Installed By:</b> API-bruker</List.Item>
									<List.Item><b>Warranty Expiration Date:</b> Valgt startdato + Valgt bindingstid - 1 dag</List.Item>
									<List.Item><b>Vendor:</b> Altibox AS (ID <code>{COMPANY_ID_VENDOR_ALTIBOX_AS}</code>)</List.Item>
									<List.Item><b>Location:</b> Valgt Location</List.Item>
									<List.Item><b>Area:</b> Blank</List.Item>
									<List.Item><b>Number of Users:</b> Blank</List.Item>
									<List.Item><b>Contract:</b> KIT Managed Internet Contract opprettet tidligere</List.Item>
									<List.Item><b>Service or Bundle:</b> KIT Managed Internet ContractService opprettet tidligere</List.Item>
									<List.Item><b>Service Level Agreement:</b> Default SLA</List.Item>
									<List.Item><b>UDF "ISP":</b> "Altibox (Viken Fiber)" (<code>altibox</code>)</List.Item>
									<List.Item><b>UDF "ISP - Type":</b> "Fiber" (<code>fiber</code>)</List.Item>
									<List.Item><b>UDF "ISP - Båndbredde ned (Mbps)":</b> Fra Service-navn <code>KIT Managed Internet [NED-hastighet]/[OPP-hastighet] Mbps [MEDIETYPE (f.eks Fiber)]</code></List.Item>
									<List.Item><b>UDF "ISP - Båndbredde opp (Mbps)":</b> Fra Service-navn <code>KIT Managed Internet [NED-hastighet]/[OPP-hastighet] Mbps [MEDIETYPE (f.eks Fiber)]</code></List.Item>
									<List.Item><b>UDF "ISP - Linknett IPv4 WAN Nettverk (/CIDR)":</b> Fra skjema</List.Item>
									<List.Item><b>UDF "ISP - Linknett IPv4 WAN IP":</b> Fra skjema</List.Item>
									<List.Item><b>UDF "ISP - Linknett IPv4 WAN Gateway":</b> Fra skjema</List.Item>
									<List.Item><b>UDF "ISP - Linknett IPv4 WAN DNS 1":</b> Fra skjema</List.Item>
									<List.Item><b>UDF "ISP - Linknett IPv4 WAN DNS 2":</b> Fra skjema</List.Item>
									<List.Item><b>UDF "ISP - Ekstra IP-nett IPv4 (/CIDR)":</b> Blank</List.Item>
									<List.Item><b>UDF "ISP - Ekstra IP-nett IPv4 Gateway":</b> Blank</List.Item>
									<List.Item><b>UDF "ISP - Linknett IPv6 WAN IP (/CIDR)":</b> Blank</List.Item>
									<List.Item><b>UDF "ISP - Linknett IPv6 WAN Gateway":</b> Blank</List.Item>
								</List>
							</List.Item>
							<List.Item>
								Oppretter en Contract for KIT Managed Network (hvis KIT Managed Network er valgt/aktivert)
								<List bulleted>
									<List.Item><b>Company:</b> Valgt Company</List.Item>
									<List.Item><b>Contract Type:</b> Recurring Service</List.Item>
									<List.Item><b>Contract Name:</b> <code>Company Name</code> - <code>Service Name</code> - <code>Location Name</code></List.Item>
									<List.Item><b>Contract Description:</b> Blank</List.Item>
									<List.Item><b>External Contract Number:</b> Blank</List.Item>
									<List.Item><b>Service Level Agreement:</b> Default SLA</List.Item>
									<List.Item><b>Contract Status:</b> Active</List.Item>
									<List.Item><b>Contract Category:</b> KIT Managed Network (ID <code>{CONTRACT_CATEGORY_KIT_MANAGED_NETWORK}</code>)</List.Item>
									<List.Item><b>Contact:</b> Primær-kontakt hos kunden</List.Item>
									<List.Item><b>Approve &amp; Post Labor:</b> Manually</List.Item>
									<List.Item><b>Contract Period Type:</b> Standard-verdi hentes fra Company UDF "Fakturering: Periode", kan overstyres</List.Item>
									<List.Item><b>Start Date:</b> Standard-verdi hentes fra Company UDF "Fakturering: Start dato", kan overstyres</List.Item>
									<List.Item><b>End Date:</b> Valgt startdato + 1 år - 1 dag</List.Item>
									<List.Item><b>Purchase Order Number:</b> Blank</List.Item>
									<List.Item><b>Setup Fee:</b> 0,00</List.Item>
									<List.Item><b>Exclusion Set:</b> KIT Managed Network (ID <code>{CONTRACT_EXCLUSION_SET_KIT_MANAGED_NETWORK}</code>)</List.Item>
								</List>
							</List.Item>
							<List.Item>
								Oppretter en ContractService for KIT Managed Network (hvis KIT Managed Network er valgt/aktivert)
								<List bulleted>
									<List.Item><b>Contract:</b> KIT Managed Network Contract opprettet tidligere</List.Item>
									<List.Item><b>Service:</b> KIT Managed Network (ID <code>{SERVICE_ID_KIT_MANAGED_NETWORK}</code>)</List.Item>
									<List.Item><b>Invoice Description:</b> <code>Adresse</code>, <code>Postnummer</code> <code>By</code></List.Item>
								</List>
							</List.Item>
						</List>
					</Accordion.Content>
				</Accordion>
			</Container>
		</Segment>

		<Segment vertical padded="very">
			<Container>
				<Header>Velg kunde</Header>
				<p>
					Søk på kundenavn, kundenummer eller org.nr, deretter velg kunden ved å trykke på den.{' '}
					<Link
						href={'https://ww19.autotask.net/Autotask/AutotaskExtend/ExecuteCommand.aspx?Code=NewAccount'}
						target="tmp"
						popupOptions={{width: 1, height: 1}}
					>
						<b>Opprett ny kunde</b>
					</Link>
				</p>

				<DataTable
					format="autotask"
					searchOnly
					limit={5}
					columns={[
						{ key: 'companyNumber', text: 'Kundenummer', visible: true, searchable: true },
						{ key: 'companyName', text: 'Kundenavn', visible: true, searchable: true },
						{ key: 'taxID', text: 'Organisasjonsnummer', visible: true, searchable: true },
					]}
					query={initialCompanyQuery}
					apiSearch={wrapApiSearchMethodWithHandleRedirect(getCompanies)}
					onClickRow={data => {
						const sameCompany = selectedCompany !== null && data.id === selectedCompany.id
						setSelectedCompany(data)
						setSelectedCompanyLocation(selectedCompanyLocation => sameCompany ? selectedCompanyLocation : null)
						setLoadingPrimaryContact(loadingPrimaryContact => sameCompany ? loadingPrimaryContact : true)
						setPrimaryContact(primaryContact => sameCompany ? primaryContact : null)
						if (!sameCompany) {
							getContacts([
								{ field: 'primaryContact', op: 'eq', value: true },
								{ field: 'companyID', op: 'eq', value: data.id },
								{ field: 'isActive', op: 'eq', value: true },
							], { limit: 1 })
								.then(res => {
									handleRedirect(res, routerNavigateFn)
									if (!res.data || res.data.length === 0) {
										setLoadingPrimaryContact(false)
										return
									}
									const primaryContact = res.data[0]
									setPrimaryContact(primaryContact)
									setLoadingPrimaryContact(false)
								})
								.catch(e => {
									handleRedirect(e.res, routerNavigateFn)
									console.error('Error getting primary contact:', e)
									setLoadingPrimaryContact(false)
								})
						}
					}}
					isRowActive={data => selectedCompany !== null && data.id === selectedCompany.id}
				/>

				<Header>Velg plassering</Header>
				<p>
					Søk på navn, adresse, postnummer eller by, deretter velg plasseringen ved å trykke på den.{' '}
					{!selectedCompany ? null : <Link
						href={'https://ww19.autotask.net/Mvc/CRM/AccountLocation.mvc/New?accountId=' + encodeURIComponent(selectedCompany.id)}
						target="new_location"
						popupOptions={{popup: true, resizable: true, scrollbars: true, width: 860, height: 950}}
					>
						<b>Opprett ny plassering</b>
					</Link>}
				</p>

				{!selectedCompany ? <Message
					info
					icon="info circle"
					className="small-icon"
					content="Velg en kunde først."
				/> : <DataTable
					format="autotask"
					limit={5}
					columns={[
						{ key: 'name', text: 'Navn', visible: true, searchable: true },
						{ key: 'address1', text: 'Adresse 1', visible: true, searchable: true },
						{ key: 'city', text: 'Postnummer / by', visible: true, searchable: true },
						{ key: 'postalCode', text: '', visible: false, searchable: true },
						{ key: 'isPrimary', text: 'Primær', visible: true },
					]}
					query={companyLocationsQuery}
					apiSearch={wrapApiSearchMethodWithHandleRedirect(getCompanyLocations)}
					onClickRow={data => setSelectedCompanyLocation(data)}
					isRowActive={data => selectedCompanyLocation !== null && data.id === selectedCompanyLocation.id}
					renderRow={data => [
						data.name,
						data.address1,
						(data.postalCode.padStart(4, '0') + ' ' + data.city).trim(),
						data.isPrimary ? <Icon name="check" color="green" /> : <Icon name="x" color="red" />,
					]}
				/>}

				<Header>Velg tjeneste</Header>

				<DataTable
					format="autotask"
					limit={5}
					columns={[
						{ key: 'name', text: 'Tjenestenavn', visible: true, searchable: true },
						{ key: 'description', text: 'Beskrivelse', visible: false, searchable: true },
						{ key: 'unitPrice', text: 'Pris', visible: true },
					]}
					query={initialServicesQuery}
					apiSearch={wrapApiSearchMethodWithHandleRedirect(getServices)}
					onClickRow={data => {
						setSelectedService(data)
						setInternetPeriodTypeInput(data.periodType.value.toString())
						setInternetUnitCostInput(formatNumberForInputField(recalculateServicePeriodPrice(data.unitCost ?? 0.0, data.periodType.value, SERVICE_PERIOD_TYPE_MONTHLY)))
						setInternetUnitPriceInput(formatNumberForInputField(recalculateServicePeriodPrice(data.unitPrice ?? 0.0, data.periodType.value, SERVICE_PERIOD_TYPE_MONTHLY)))
					}}
					isRowActive={data => selectedService !== null && data.id === selectedService.id}
					renderRow={data => {
						let { description: serviceDescription } = data
						serviceDescription = (serviceDescription ?? '').replace(/\n/g, ' ').trim()
						if (serviceDescription.length > 60) {
							serviceDescription = serviceDescription.substring(0, 57) + '...'
						}
						return [
							data.name + (serviceDescription.length > 0 ? ' (' + serviceDescription + ')' : ''),
							formatPeriodPrice(data.unitPrice ?? 0.0, getProductPeriodPriceFormat(data.periodType.value)),
						]
					}}
				/>

				<Header>Velg produkt</Header>

				<DataTable
					format="autotask"
					limit={5}
					columns={[
						{ key: 'name', text: 'Navn', visible: true, searchable: true },
						{ key: 'description', text: 'Beskrivelse', visible: false, searchable: true },
					]}
					query={initialProductsQuery}
					apiSearch={wrapApiSearchMethodWithHandleRedirect(getProducts)}
					onClickRow={data => setSelectedProduct(data)}
					isRowActive={data => selectedProduct !== null && data.id === selectedProduct.id}
					renderRow={data => {
						let { description: productDescription } = data
						productDescription = (productDescription ?? '').replace(/\n/g, ' ').trim()
						if (productDescription.length > 60) {
							productDescription = productDescription.substring(0, 57) + '...'
						}
						return [
							data.name + (productDescription.length > 0 ? ' (' + productDescription + ')' : ''),
						]
					}}
				/>

				<Header>Velg oppsett-sak (valgfritt)</Header>
				<p>Hvis du vil at opprettet CI tilordnes en sak automatisk, velg denne saken. Du kan søke på saksnummer, tittel eller beskrivelse. Viser kun saker for den valgte kunden.</p>
				<p>Hvis du vil angre valg av sak, trykk på den valgte saken på nytt for å fjerne valget.</p>

				{!selectedCompany ? <Message
					info
					icon="info circle"
					className="small-icon"
					content="Velg en kunde først."
				/> : <DataTable
					format="autotask"
					limit={5}
					columns={[
						{ key: 'ticketNumber', text: 'Saksnummer', visible: true, searchable: true },
						{ key: 'title', text: 'Tittel', visible: true, searchable: true },
						{ key: 'description', text: 'Beskrivelse', visible: false, searchable: true },
						{ key: 'lastActivityDate', text: 'Siste aktititet', visible: true },
					]}
					query={ticketsQuery}
					apiSearch={wrapApiSearchMethodWithHandleRedirect(getTickets)}
					onClickRow={data => setSelectedTicket(selectedTicket => selectedTicket !== null && selectedTicket.id === data.id ? null : data)}
					isRowActive={data => selectedTicket !== null && data.id === selectedTicket.id}
					renderRow={data => [
						data.ticketNumber,
						data.title,
						dateToRelativeString(data.lastActivityDate),
					]}
				/>}
			</Container>
		</Segment>

		<Segment vertical padded="very">
			<Container>
				<Header>Se gjennom og importer</Header>
				<p>Vær oppmerksom på at all informasjon du skriver inn her, vil vises på faktura til kunden.</p>

				{railVisible ? null : statusMessages}
				<p>&nbsp;</p>

				<Form onSubmit={preventDefault(() => handleImportData())}>
					<Header size="small" dividing>KIT Managed Internet</Header>

					<Form.Group widths={4}>
						<SemanticDatepicker
							label="Startdato"
							required
							width={4}
							locale="nb-NO"
							format={dateLocales.no.dateFormat}
							value={internetStartDateInput}
							onChange={(e, data) => {
								const internetStartDate = data.value
								let networkEndDate = null
								if (internetStartDate !== null) {
									networkEndDate = new Date(internetStartDate)
									networkEndDate.setFullYear(networkEndDate.getFullYear() + 1)
									networkEndDate.setDate(networkEndDate.getDate() - 1)
								}
								setInternetStartDateInput(internetStartDate)
								setNetworkStartDateInput(internetStartDate)
								setNetworkEndDateInput(networkEndDate)
							}}
						/>
						<Form.Field width={4} required>
							<label>Bindingstid</label>
							<Input
								value={internetCommitmentPeriodMonthsInput}
								onChange={(e, data) => setInternetCommitmentPeriodMonthsInput(data.value)}
								placeholder="0"
								label="mnd"
								labelPosition="right"
								type="number"
								step="1"
								min="0"
							/>
						</Form.Field>
						<Form.Field width={8} required>
							<label>Betalingsperiode</label>
							<Select
								options={[
									{ value: CONTRACT_PERIOD_TYPE_MONTHLY.toString(), text: 'Månedlig' },
									{ value: CONTRACT_PERIOD_TYPE_QUARTERLY.toString(), text: 'Kvartalsvis' },
									{ value: CONTRACT_PERIOD_TYPE_SEMIANNUAL.toString(), text: 'Halvårlig' },
									{ value: CONTRACT_PERIOD_TYPE_YEARLY.toString(), text: 'Årlig' },
								]}
								value={internetPeriodTypeInput}
								onChange={(e, data) => {
									setInternetPeriodTypeInput(data.value)
									setNetworkPeriodTypeInput(data.value)
								}}
								fluid
							/>
						</Form.Field>
					</Form.Group>

					<Form.Group>
						<Form.Field width={4} required>
							<label>Etableringskostnad</label>
							<Input
								value={internetSetupFeeInput}
								onChange={(e, data) => setInternetSetupFeeInput(data.value.replace(',', '.'))}
								placeholder="0"
								label="kr"
								labelPosition="right"
								type="number"
								step="0.01"
							/>
						</Form.Field>
						<Form.Field width={6} required>
							<label>Innpris <span className="text-secondary">(eks mva)</span></label>
							<Input
								value={internetUnitCostInput}
								onChange={(e, data) => setInternetUnitCostInput(data.value.replace(',', '.'))}
								placeholder="0"
								label={getPeriodPriceLabel(getServicePeriodPriceFormat(SERVICE_PERIOD_TYPE_MONTHLY))}
								labelPosition="right"
								type="number"
								step="0.01"
							/>
						</Form.Field>
						<Form.Field width={6} required>
							<label>Utpris <span className="text-secondary">(eks mva)</span></label>
							<Input
								value={internetUnitPriceInput}
								onChange={(e, data) => setInternetUnitPriceInput(data.value.replace(',', '.'))}
								placeholder="0"
								label={getPeriodPriceLabel(getServicePeriodPriceFormat(SERVICE_PERIOD_TYPE_MONTHLY))}
								labelPosition="right"
								type="number"
								step="0.01"
							/>
						</Form.Field>
					</Form.Group>

					<Form.Group>
						<Form.Field width={4} required>
							<label>ISP</label>
							<React.Suspense fallback={<Select fluid disabled />}>
								<Await resolve={udfPicklistValues}>
									{({ data: { 'ISP': udfISPPicklistValues } }) => {
										return <Select
											options={udfISPPicklistValues.map(value => ({ value: value.value, text: value.label }))}
											value={udfISP}
											onChange={(e, data) => setUdfISP(data.value)}
											fluid
										/>
									}}
								</Await>
							</React.Suspense>
						</Form.Field>
						<Form.Field width={4} required>
							<label>ISP - Type</label>
							<React.Suspense fallback={<Select fluid disabled />}>
								<Await resolve={udfPicklistValues}>
									{({ data: { 'ISP - Type': udfISPTypePicklistValues } }) => {
										return <Select
											options={udfISPTypePicklistValues.map(value => ({ value: value.value, text: value.label }))}
											value={udfISPType}
											onChange={(e, data) => setUdfISPType(data.value)}
											fluid
										/>
									}}
								</Await>
							</React.Suspense>
						</Form.Field>
						<Form.Field width={4} required>
							<label>Båndbredde ned</label>
							<Input
								value={bandwidthDownMbps}
								onChange={(e, data) => setBandwidthDownMbps(data.value)}
								placeholder="0"
								label="Mbps"
								labelPosition="right"
								type="number"
								step="1"
								min="0"
							/>
						</Form.Field>
						<Form.Field width={4} required>
							<label>Båndbredde opp</label>
							<Input
								value={bandwidthUpMbps}
								onChange={(e, data) => setBandwidthUpMbps(data.value)}
								placeholder="0"
								label="Mbps"
								labelPosition="right"
								type="number"
								step="1"
								min="0"
							/>
						</Form.Field>
					</Form.Group>

					<Form.Group>
						<Form.Field width={4} required>
							<label>Sambandsnummer</label>
							<Input
								value={referenceNumber}
								onChange={(e, data) => setReferenceNumber(data.value)}
								placeholder="S400012345"
								type="text"
							/>
						</Form.Field>
						<Form.Field width={4}>
							<label>Dynamisk IP-adresse</label>
							<Checkbox
								toggle
								label="DHCP"
								checked={enableDhcp}
								onChange={(e, data) => setEnableDhcp(data.checked)}
							/>
						</Form.Field>
					</Form.Group>

					{enableDhcp ? null : <Form.Group>
						<Form.Field width={6} required>
							<label>Linknett IPv4 Nettverk (/CIDR)</label>
							<Input
								value={linknetNetworkCIDR}
								onChange={(e, data) => setLinknetNetworkCIDR(data.value)}
								placeholder="192.0.2.0/30"
								type="text"
								pattern="^((?:(?:^|\.)(?:25[0-5]|2[0-4][0-9]|[01][0-9][0-9]|0?[1-9][0-9]|(?:00)?[0-9])){0,4})\/([0-9]|[1-2][0-9]|3[0-2])$"
							/>
						</Form.Field>
						<Form.Field width={5} required>
							<label>Linknett IPv4 IP</label>
							<Input
								value={linknetIPv4}
								onChange={(e, data) => setLinknetIPv4(data.value)}
								placeholder="192.0.2.2"
								type="text"
								pattern="^((?:(?:^|\.)(?:25[0-5]|2[0-4][0-9]|[01][0-9][0-9]|0?[1-9][0-9]|(?:00)?[0-9])){1,4})$"
							/>
						</Form.Field>
						<Form.Field width={5} required>
							<label>Linknett IPv4 Gateway</label>
							<Input
								value={linknetGatewayIPv4}
								onChange={(e, data) => setLinknetGatewayIPv4(data.value)}
								placeholder="192.0.2.1"
								type="text"
								pattern="^((?:(?:^|\.)(?:25[0-5]|2[0-4][0-9]|[01][0-9][0-9]|0?[1-9][0-9]|(?:00)?[0-9])){1,4})$"
							/>
						</Form.Field>
					</Form.Group>}

					{enableDhcp ? null : <Form.Group>
						<Form.Field width={8} required>
							<label>Linknett IPv4 DNS 1</label>
							<Input
								value={linknetDNS1IP}
								onChange={(e, data) => setLinknetDNS1IP(data.value)}
								placeholder="192.0.2.3"
								type="text"
								pattern="^((?:(?:^|\.)(?:25[0-5]|2[0-4][0-9]|[01][0-9][0-9]|0?[1-9][0-9]|(?:00)?[0-9])){1,4})$"
							/>
						</Form.Field>
						<Form.Field width={8} required>
							<label>Linknett IPv4 DNS 2</label>
							<Input
								value={linknetDNS2IP}
								onChange={(e, data) => setLinknetDNS2IP(data.value)}
								placeholder="192.0.2.4"
								type="text"
								pattern="^((?:(?:^|\.)(?:25[0-5]|2[0-4][0-9]|[01][0-9][0-9]|0?[1-9][0-9]|(?:00)?[0-9])){1,4})$"
							/>
						</Form.Field>
					</Form.Group>}

					{enableDhcp ? null : <Form.Group>
						<Form.Field width={8}>
							<label>Ekstra IP-nett IPv4 (/CIDR)</label>
							<Input
								value={extranetNetworkCIDR}
								onChange={(e, data) => setExtranetNetworkCIDR(data.value)}
								placeholder="192.0.2.0/30"
								type="text"
								pattern="^((?:(?:^|\.)(?:25[0-5]|2[0-4][0-9]|[01][0-9][0-9]|0?[1-9][0-9]|(?:00)?[0-9])){0,4})\/([0-9]|[1-2][0-9]|3[0-2])$"
							/>
						</Form.Field>
						<Form.Field width={8}>
							<label>Ekstra IP-nett IPv4 Gateway</label>
							<Input
								value={extranetGatewayIP}
								onChange={(e, data) => setExtranetGatewayIP(data.value)}
								placeholder="192.0.2.1"
								type="text"
								pattern="^((?:(?:^|\.)(?:25[0-5]|2[0-4][0-9]|[01][0-9][0-9]|0?[1-9][0-9]|(?:00)?[0-9])){1,4})$"
							/>
						</Form.Field>
					</Form.Group>}

					{enableDhcp ? null : <Form.Group>
						<Form.Field width={8}>
							<label>Linknett IPv6 IP (/CIDR)</label>
							<Input
								value={linknetIPv6}
								onChange={(e, data) => setLinknetIPv6(data.value)}
								placeholder="2001:DB8::2/48"
								type="text"
								pattern="^((?:[0-9a-fA-F]{1,4}:){7}(?:[0-9a-fA-F]{1,4}|:)|(?:[0-9a-fA-F]{1,4}:){6}(?::[0-9a-fA-F]{1,4}|:)|(?:[0-9a-fA-F]{1,4}:){5}(?:(?::[0-9a-fA-F]{1,4}){1,2}|:)|(?:[0-9a-fA-F]{1,4}:){4}(?:(?::[0-9a-fA-F]{1,4}){1,3}|:)|(?:[0-9a-fA-F]{1,4}:){3}(?:(?::[0-9a-fA-F]{1,4}){1,4}|:)|(?:[0-9a-fA-F]{1,4}:){2}(?:(?::[0-9a-fA-F]{1,4}){1,5}|:)|(?:[0-9a-fA-F]{1,4}:){1}(?:(?::[0-9a-fA-F]{1,4}){1,6}|:)|(?::(?:(?::[0-9a-fA-F]{1,4}){1,7}|:)))?\/(1[0-1][0-9]|12[0-8]|[1-9][0-9]|[0-9])$"
							/>
						</Form.Field>
						<Form.Field width={8}>
							<label>Linknett IPv6 Gateway</label>
							<Input
								value={linknetGatewayIPv6}
								onChange={(e, data) => setLinknetGatewayIPv6(data.value)}
								placeholder="2001:DB8::1"
								type="text"
								pattern="^((?:[0-9a-fA-F]{1,4}:){7}(?:[0-9a-fA-F]{1,4}|:)|(?:[0-9a-fA-F]{1,4}:){6}(?::[0-9a-fA-F]{1,4}|:)|(?:[0-9a-fA-F]{1,4}:){5}(?:(?::[0-9a-fA-F]{1,4}){1,2}|:)|(?:[0-9a-fA-F]{1,4}:){4}(?:(?::[0-9a-fA-F]{1,4}){1,3}|:)|(?:[0-9a-fA-F]{1,4}:){3}(?:(?::[0-9a-fA-F]{1,4}){1,4}|:)|(?:[0-9a-fA-F]{1,4}:){2}(?:(?::[0-9a-fA-F]{1,4}){1,5}|:)|(?:[0-9a-fA-F]{1,4}:){1}(?:(?::[0-9a-fA-F]{1,4}){1,6}|:)|(?::(?:(?::[0-9a-fA-F]{1,4}){1,7}|:)))$"
							/>
						</Form.Field>
					</Form.Group>}

					<p>&nbsp;</p>
					<Header size="small" dividing>KIT Managed Network</Header>
					<Form.Checkbox
						toggle
						label="Aktiver tjeneste"
						checked={enableNetwork}
						onChange={(e, data) => setEnableNetwork(data.checked)}
					/>

					{!enableNetwork ? null : <Form.Group widths={4}>
						<SemanticDatepicker
							label="Startdato"
							width={4}
							locale="nb-NO"
							format={dateLocales.no.dateFormat}
							value={networkStartDateInput}
							onChange={(e, data) => {
								const networkStartDate = data.value
								let networkEndDate = null
								if (networkStartDate !== null) {
									networkEndDate = new Date(networkStartDate)
									networkEndDate.setFullYear(networkEndDate.getFullYear() + 1)
									networkEndDate.setDate(networkEndDate.getDate() - 1)
								}
								setNetworkStartDateInput(networkStartDate)
								setNetworkEndDateInput(networkEndDate)
							}}
						/>
						<SemanticDatepicker
							label="Sluttdato"
							width={4}
							locale="nb-NO"
							format={dateLocales.no.dateFormat}
							value={networkEndDateInput}
							onChange={(e, data) => setNetworkEndDateInput(data.value)}
						/>
						<Form.Field width={8}>
							<label>Betalingsperiode</label>
							<Select
								options={[
									{ value: CONTRACT_PERIOD_TYPE_MONTHLY.toString(), text: 'Månedlig' },
									{ value: CONTRACT_PERIOD_TYPE_QUARTERLY.toString(), text: 'Kvartalsvis' },
									{ value: CONTRACT_PERIOD_TYPE_SEMIANNUAL.toString(), text: 'Halvårlig' },
									{ value: CONTRACT_PERIOD_TYPE_YEARLY.toString(), text: 'Årlig' },
								]}
								value={networkPeriodTypeInput}
								onChange={(e, data) => setNetworkPeriodTypeInput(data.value)}
								fluid
							/>
						</Form.Field>
					</Form.Group>}

					{!enableNetwork ? null : <Form.Group>
						<Form.Field width={4} required>
							<label>Etableringskostnad</label>
							<Input
								value={networkSetupFeeInput}
								onChange={(e, data) => setNetworkSetupFeeInput(data.value.replace(',', '.'))}
								placeholder="0"
								label="kr"
								labelPosition="right"
								type="number"
								step="0.01"
							/>
						</Form.Field>
						<Form.Field width={6} required>
							<label>Innpris <span className="text-secondary">(eks mva)</span></label>
							<Input
								value={networkUnitCostInput}
								onChange={(e, data) => setNetworkUnitCostInput(data.value.replace(',', '.'))}
								placeholder="0"
								label={getPeriodPriceLabel(getServicePeriodPriceFormat(SERVICE_PERIOD_TYPE_MONTHLY))}
								labelPosition="right"
								type="number"
								step="0.01"
							/>
						</Form.Field>
						<Form.Field width={6} required>
							<label>Utpris <span className="text-secondary">(eks mva)</span></label>
							<Input
								value={networkUnitPriceInput}
								onChange={(e, data) => setNetworkUnitPriceInput(data.value.replace(',', '.'))}
								placeholder="0"
								label={getPeriodPriceLabel(getServicePeriodPriceFormat(SERVICE_PERIOD_TYPE_MONTHLY))}
								labelPosition="right"
								type="number"
								step="0.01"
							/>
						</Form.Field>
					</Form.Group>}

					<p>&nbsp;</p>

					<Form.Button
						type="submit"
						color="orange"
						disabled={importing || !selectedCompany || !selectedCompanyLocation || !selectedProduct || !selectedService || loadingPrimaryContact || loadingInternetContract || loadingInternetContractService || loadingInternetConfigurationItem || loadingNetworkContract || loadingNetworkContractService}
						loading={importing}
					>
						Importer internett-linje <Icon name="exchange" className="right" />
					</Form.Button>
				</Form>
			</Container>
		</Segment>
	</>
}