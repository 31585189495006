import React, { useCallback } from 'react'
import { Segment, Header, Container } from 'semantic-ui-react'
import DataTable from '../../components/data_table'
import { dateToString, handleRedirect, navigate } from '../../helpers'
import { getTaskExecutions } from '../../api/tasks'
import { useNavigate } from 'react-router-dom'
import { usePreloadedEntityDispatcher } from '../root'

export default function TaskExecutionsPage() {
	const routerNavigateFn = useNavigate()
	const preloadEntity = usePreloadedEntityDispatcher()

	const apiSearch = useCallback(async (query, options, requestOptions) => {
		const res = await getTaskExecutions(query, options, requestOptions)
		handleRedirect(res, routerNavigateFn)
		return res
	}, [routerNavigateFn])

	return <>
		<Segment vertical padded="very">
			<Container>
				<Header size="huge">
					Bakgrunnsjobber
					<Header.Subheader>Liste over kjøringer av bakgrunnsjobber</Header.Subheader>
				</Header>
				<p>Søkefeltet lar deg slå opp en spesifikk ID (f.eks <code>118</code>) eller jobb (f.eks <code>KitSAP\Tasks\TestTask</code>).</p>
				<DataTable
					columns={[
						{ key: 'id', text: '#', visible: true, sortable: true, searchable: true },
						{ key: 'task', text: 'Jobb', visible: true, sortable: true, searchable: true },
						{ key: 'object_display_name', text: 'Objekt', visible: true },
						{ key: 'state', text: 'Status', visible: true, sortable: true },
						{ key: 'task_state', text: 'Jobb-status', visible: true, sortable: true },
						{ key: 'started_at', text: 'Startet', visible: true, sortable: true },
						{ key: 'stopped_at', text: 'Stoppet', visible: true, sortable: true },
					]}
					defaultOrder={{'id': 'DESC'}}
					apiSearch={apiSearch}
					websocketDisablePerAccountEvents
					onClickRow={(data, index, { ctrlKey, shiftKey }) => {
						// preload execution data
						preloadEntity(data)
						// navigate to execution
						const url = '/tasks/executions/' + encodeURIComponent(data.id)
						const target = (ctrlKey || shiftKey) ? '_blank' : '_self'
						navigate(url, target, {}, routerNavigateFn)
					}}
					renderRow={data => [
						data.id,
						data.task_display_name ?? 'n/a',
						data.object_display_name ?? 'n/a',
						data.state_display_name ?? 'n/a',
						data.task_state_display_name ?? 'n/a',
						data.started_at === null ? 'Ikke startet' : dateToString(data.started_at, true, true),
						data.stopped_at === null ? 'Ikke stoppet' : dateToString(data.stopped_at, true, true),
					]}
				/>
			</Container>
		</Segment>
	</>
}