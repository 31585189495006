import React, { useEffect, useRef, useState } from 'react'
import { Menu, Placeholder, Segment } from 'semantic-ui-react'
import Copyright from './copyright'
import Logo from './logo'
import Link from './link'
import { useMatches, useNavigate } from 'react-router-dom'
import { isObject, navigate } from '../helpers'
import { useAuth } from '../pages/root'

export default function Nav({
	className,
	visible,
	becomingHidden,
}) {
	className = className ?? null
	visible = visible ?? true
	becomingHidden = becomingHidden ?? false

	const { nav } = useAuth()
	const loadingNavItems = nav === null
	const navItems = isObject(nav) && typeof nav.items === 'object' && Array.isArray(nav.items) ? nav.items : []

	const matches = useMatches()
	const routerNavigateFn = useNavigate()
	const navRef = useRef(null)
	const [hovered, setHovered] = useState(false)
	const [shouldAnimate, setShouldAnimate] = useState(false)

	function hoverdetectOnMouseEnter(e) {
		if (visible) return
		if (e.nativeEvent.offsetX <= e.target.offsetWidth) {
			setHovered(true)
		}
	}

	function hoverdetectOnMouseLeave(e) {
		if (
			!e.target.classList ||
			(e.target.classList.contains('kit-nav-hoverdetect') && e.nativeEvent.offsetX >= e.target.offsetWidth) ||
			(e.target.classList.contains('kit-nav-container') && e.nativeEvent.pageX <= 0) ||
			(navRef.current !== null && e.nativeEvent.pageX >= navRef.current.offsetWidth)
		) {
			setHovered(false)
		}
	}

	useEffect(() => {
		function hoverdetectOnMouseLeave(e) {
			if (
				!e.target.classList ||
				(e.target.classList.contains('kit-nav-hoverdetect') && e.nativeEvent.offsetX >= e.target.offsetWidth) ||
				(e.target.classList.contains('kit-nav-container') && e.nativeEvent.pageX <= 0) ||
				e.nativeEvent.pageX >= navRef.current.offsetWidth
			) {
				setHovered(false)
			}
		}
		document.addEventListener('mouseleave', hoverdetectOnMouseLeave)
		setTimeout(() => setShouldAnimate(true), 200)
		return () => document.removeEventListener('mouseleave', hoverdetectOnMouseLeave)
	}, [])

	useEffect(() => {
		if (becomingHidden) setHovered(false)
	}, [becomingHidden])

	const itemToElem = item => {
		if (item.items) {
			return <Menu.Item key={item.key}>
				{item.content ? <Menu.Header children={item.content} /> : null}
				<Menu.Menu children={navElems(item.items)} />
			</Menu.Item>
		}
		let onClick = e => {
			if (!e.defaultPrevented) e.preventDefault()
			if (typeof item.onClick === 'function') {
				item.onClick(e)
			} else if (typeof item.link === 'string') {
				navigate(item.link, (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn)
			}
		}
		return <Menu.Item key={item.key} content={item.content} active={itemIsActive(item.key)} icon={item.icon} href={item.link} target={item.target} onClick={onClick} link />
	}

	const navElems = (items, isBottomSection = null) => {
		if (!items) return null
		if (isBottomSection === null) return items.map(itemToElem)
		return items.filter(item => !!item.bottom === isBottomSection).map(itemToElem)
	}

	const itemIsActive = key => matches.findIndex(match => match.id === key) !== -1

	return <div className={'kit-nav-container' + (visible ? ' visible' : (hovered ? ' hovered' : '')) + (becomingHidden ? ' becoming-hidden' : '') + (shouldAnimate ? ' should-animate' : '')} onMouseLeave={hoverdetectOnMouseLeave}>
		<div className="kit-nav-hoverdetect" onMouseEnter={hoverdetectOnMouseEnter} onMouseLeave={hoverdetectOnMouseLeave}></div>
		<div className="kit-nav-menu-container">
			<Menu vertical inverted className={'kit-nav' + (className ? ' ' + className : '')} ref={navRef}>
				<div className="kit-nav-header">
					<Segment vertical inverted color="orange">
						<Link href="/" target="_blank">
							<Logo />
						</Link>
					</Segment>
				</div>
				<div className="kit-nav-content">
					{!loadingNavItems ? null : <>
						<Menu.Item><Placeholder inverted><Placeholder.Header><Placeholder.Line /></Placeholder.Header></Placeholder></Menu.Item>
						<Menu.Item><Placeholder inverted><Placeholder.Header><Placeholder.Line /></Placeholder.Header></Placeholder></Menu.Item>
						<Menu.Item><Placeholder inverted><Placeholder.Header><Placeholder.Line /></Placeholder.Header></Placeholder></Menu.Item>
						<Menu.Item><Placeholder inverted><Placeholder.Header><Placeholder.Line /></Placeholder.Header></Placeholder></Menu.Item>
						<Menu.Item><Placeholder inverted><Placeholder.Header><Placeholder.Line /></Placeholder.Header></Placeholder></Menu.Item>
						<Menu.Item>
							<Placeholder inverted>
								<Placeholder.Header>
									<Placeholder.Line />
								</Placeholder.Header>
							</Placeholder>
							<Placeholder inverted>
								<Placeholder.Line />
								<Placeholder.Line />
								<Placeholder.Line />
							</Placeholder>
						</Menu.Item>
						<Menu.Item>
							<Placeholder inverted>
								<Placeholder.Header>
									<Placeholder.Line />
								</Placeholder.Header>
							</Placeholder>
							<Placeholder inverted>
								<Placeholder.Line />
								<Placeholder.Line />
								<Placeholder.Line />
							</Placeholder>
						</Menu.Item>
					</>}
					{navElems(navItems, false)}
					<div className="fill"></div>
					{navElems(navItems, true)}
				</div>
				<Menu.Item className="kit-nav-copyright-item">
					<Copyright inverted />
				</Menu.Item>
			</Menu>
		</div>
	</div>
}