import React from 'react'
import { navigate, omit, preventDefault } from '../helpers'
import { useNavigate } from 'react-router-dom'
import { usePreloadedEntityDispatcher } from '../pages/root'

export default function Link(props) {
	const href = props.href
	const children = props.children ?? null
	const target = props.target ?? '_self'
	const popupOptions = props.popupOptions ?? {}
	const entityToPreload = props.preload ?? null
	const disabled = props.disabled ?? false
	const className = ((props.className ?? '') + (disabled ? ' link-secondary link-disabled' : '')).trim()
	const routerNavigateFn = useNavigate()
	const preloadEntity = usePreloadedEntityDispatcher()

	return <a
		{...omit(['popupOptions', 'onClick', 'children', 'className'], props)}
		className={className}
		onClick={preventDefault(e => {
			if (typeof props.onClick === 'function') {
				props.onClick(e)
			}
			const eventTarget = (e.ctrlKey || e.shiftKey) ? '_blank' : (target ?? '_self')
			preloadEntity(entityToPreload)
			navigate(href, eventTarget, popupOptions, routerNavigateFn)
		})}
	>{children}</a>
}