import { Response, PaginatedResponse } from '..'
import { buildQuery } from '../../helpers'

// GET /api/autotask/tasks
// POST /api/autotask/tasks/search
export async function getTasks(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/autotask/tasks' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/autotask/tasks/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/autotask/tasks
export async function createTask(data, requestOptions = {}) {
	return Response.request('/api/autotask/tasks', data, requestOptions)
}

// GET /api/autotask/tasks/:id
export async function getTask(id, requestOptions = {}) {
	return Response.request('/api/autotask/tasks/' + encodeURIComponent(id), null, requestOptions)
}

// PUT /api/autotask/tasks/:id
export async function updateTask(id, data, requestOptions = {}) {
	return Response.request('/api/autotask/tasks/' + encodeURIComponent(id), data, Object.assign({ method: 'PUT' }, requestOptions))
}

export const PRIORITY_HIGH = 1;
export const PRIORITY_MEDIUM = 2;
export const PRIORITY_LOW = 3;
export const PRIORITY_CRITICAL = 4;

export const STATUS_NEW = 1;
export const STATUS_COMPLETE = 5;
export const STATUS_WAITING_CUSTOMER = 7;
export const STATUS_IN_PROGRESS = 8;
export const STATUS_WAITING_MATERIALS = 9;
export const STATUS_DISPATCHED = 10;
export const STATUS_ESCALATE = 11;
export const STATUS_WAITING_VENDOR = 12;
export const STATUS_WORK_COMPLETE = 16;
export const STATUS_ON_HOLD = 17;
export const STATUS_CUSTOMER_NOTE_ADDED = 19;
export const STATUS_ONSITE = 20;

export const CATEGORY_DATTO_RMM_ALERT = 2; // Datto RMM Alert
export const CATEGORY_DATTO_ALERT = 4; // Datto Alert
export const CATEGORY_RMA = 5; // RMA
export const CATEGORY_KIT_STANDARD = 103; // KIT Standard
export const CATEGORY_TERMINATE_USER = 104; // Terminere bruker

export const TYPE_FIXED_WORK = 1; // FixedWork
export const TYPE_FIXED_DURATION = 2; // FixedDuration

export const PERSON_TYPE_RESOURCE = 1; // Resource
export const PERSON_TYPE_CONTACT = 2; // Contact

export const CATEGORY_STANDARD_NON_EDITABLE = 1; // Standard (non-editable)
export const CATEGORY_STANDARD = 2; // Standard