import { Response, PaginatedResponse } from '..'
import { buildQuery } from '../../helpers'

// GET /api/autotask/contacts
// POST /api/autotask/contacts/search
export async function getContacts(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/autotask/contacts' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/autotask/contacts/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/autotask/contacts
export async function createContact(data, requestOptions = {}) {
	return Response.request('/api/autotask/contacts', data, requestOptions)
}

// GET /api/autotask/contacts/:id
export async function getContact(id, requestOptions = {}) {
	return Response.request('/api/autotask/contacts/' + encodeURIComponent(id), null, requestOptions)
}

// PUT /api/autotask/contacts/:id
export async function updateContact(id, data, requestOptions = {}) {
	return Response.request('/api/autotask/contacts/' + encodeURIComponent(id), data, Object.assign({ method: 'PUT' }, requestOptions))
}