import React, { useCallback, useRef, useState } from 'react'
import { Segment, Header, Container, Button, Icon, Placeholder, Grid } from 'semantic-ui-react'
import DataTable from '../../components/data_table'
import { getTickets } from '../../api/autotask/tickets'
import { getContact } from '../../api/autotask/contacts'
import { formatNumber, dateToRelativeString, append, genUUID, navigate, preventDefault, handleRedirect } from '../../helpers'
import { useNavigate } from 'react-router-dom'

const initialTicketContacts = {}

export default function TicketsPage() {
	const [dataInfo, setDataInfo] = useState(null)
	const [ticketContacts, setTicketContacts] = useState(initialTicketContacts)
	const routerNavigateFn = useNavigate()

	const renderContactName = contact => {
		if (!contact) return null
		return [contact.namePrefix, contact.firstName, contact.middleInitial, contact.lastName, contact.nameSuffix].filter(v => typeof v === 'string' && v.trim().length > 0).join(' ');
	}

	const ticketContactIDs = useRef([])
	const apiSearch = useCallback(async (query, options, requestOptions) => {
		const res = await getTickets(query, options, requestOptions)
		handleRedirect(res, routerNavigateFn)
		if (query.length === 0) {
			setDataInfo(res.data_info)
		}
		if (res.data) {
			for (const ticket of res.data) {
				if (ticketContactIDs.current.includes(ticket.contactID) || !ticket.contactID) continue
				ticketContactIDs.current.push(ticket.contactID)
				getContact(ticket.contactID).then(res => {
					handleRedirect(res, routerNavigateFn)
					setTicketContacts(ticketContacts => append(ticketContacts, {[ticket.contactID]: res.data}))
				}).catch(e => {
					handleRedirect(e.res, routerNavigateFn)
					setTicketContacts(ticketContacts => append(ticketContacts, {[ticket.contactID]: null}))
				})
			}
		}
		return res
	}, [routerNavigateFn])

	return <>
		<Segment vertical padded="very">
			<Container>
				<Grid stackable>
					<Grid.Row>
						<Grid.Column width={10}>
							<Header size="huge">
								Saker
								<Header.Subheader>Liste over alle saker</Header.Subheader>
							</Header>
						</Grid.Column>
						<Grid.Column width={6} textAlign="right">
							<Button
								as="a"
								target="_blank"
								href="https://ww19.autotask.net/Autotask/AutotaskExtend/ExecuteCommand.aspx?Code=NewTicket"
								onClick={preventDefault(e => navigate('https://ww19.autotask.net/Autotask/AutotaskExtend/ExecuteCommand.aspx?Code=NewTicket', '_blank', {}, routerNavigateFn))}
								color="orange"
								label={dataInfo ? formatNumber(dataInfo.count) : '0'}
								labelPosition="right"
								content={<><Icon name="add" /> Opprett ny sak</>}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<p></p>

				<DataTable
					format="autotask"
					columns={[
						{ key: 'ticketNumber', text: 'Saksnummer', visible: true, searchable: true },
						{ key: 'title', text: 'Tittel', visible: true, searchable: true },
						{ key: 'description', text: 'Beskrivelse', visible: false, searchable: true },
						{ key: 'contactID', text: 'Kontakt', visible: true },
						{ key: 'status.label', text: 'Status', visible: true },
						{ key: 'lastActivityDate', text: 'Siste aktititet', visible: true },
					]}
					apiSearch={apiSearch}
					onClickRow={data => navigate('https://ww19.autotask.net/Autotask/AutotaskExtend/ExecuteCommand.aspx?Code=OpenTicketDetail&TicketId=' + encodeURIComponent(data.id), 'open_ticket_' + genUUID(), { width: 1, height: 1 }, routerNavigateFn)}
					renderRow={data => [
						data.ticketNumber,
						data.title,
						!data.contactID ? null : (ticketContacts.hasOwnProperty(data.contactID) ? renderContactName(ticketContacts[data.contactID]) : <Placeholder><Placeholder.Line /></Placeholder>),
						data.status.label,
						dateToRelativeString(data.lastActivityDate),
					]}
				/>
			</Container>
		</Segment>
	</>
}