import { Response, PaginatedResponse } from '..'
import { buildQuery } from '../../helpers'

// GET /api/autotask/ticket-note-attachments
// POST /api/autotask/ticket-note-attachments/search
export async function getTicketNoteAttachments(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/autotask/ticket-note-attachments' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/autotask/ticket-note-attachments/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/autotask/ticket-note-attachments
export async function createTicketNoteAttachment(data, requestOptions = {}) {
	return Response.request('/api/autotask/ticket-note-attachments', data, requestOptions)
}

// GET /api/autotask/ticket-note-attachments/:id
export async function getTicketNoteAttachment(id, requestOptions = {}) {
	return Response.request('/api/autotask/ticket-note-attachments/' + encodeURIComponent(id), null, requestOptions)
}

// PUT /api/autotask/ticket-note-attachments/:id
export async function updateTicketNoteAttachment(id, data, requestOptions = {}) {
	return Response.request('/api/autotask/ticket-note-attachments/' + encodeURIComponent(id), data, Object.assign({ method: 'PUT' }, requestOptions))
}

// DELETE /api/autotask/tickets/:ticket_id/attachments/:id
export async function deleteTicketNoteAttachment(ticket_note_id, id, requestOptions = {}) {
	return Response.request('/api/autotask/ticket-notes/' + encodeURIComponent(ticket_note_id) + '/attachments/' + encodeURIComponent(id), null, Object.assign({ method: 'DELETE' }, requestOptions))
}

export const PUBLISH_ALL_AUTOTASK_USERS = 1; // All Autotask Users
export const PUBLISH_INTERNAL = 2; // Internal Users Only
export const PUBLISH_INTERNAL_AND_CO_MANAGED = 4; // Internal & Co-Managed