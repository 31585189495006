import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { isObject, setGlobalState } from './helpers'
import './styles'

// import pages
import Navigate from './components/navigate'
import AccountPage, { loader as accountLoader } from "./pages/accounts/account"
import AccountsPage from "./pages/accounts"
import AuthPage, { loader as authLoader } from "./pages/auth"
import DomainPage, { loader as domainLoader } from "./pages/domains/domain"
import EditDomainPage from "./pages/domains/edit"
import DomainsPage from "./pages/domains"
import ErrorPage from "./pages/error"
import DeleteKitmailDomainPage from "./pages/kitmail/domains/delete"
import DeleteKitmailForwardingPage, { loader as kitmailForwardingLoader } from "./pages/kitmail/forwardings/delete"
import DeleteKitmailUserPage from "./pages/kitmail/users/delete"
import KitmailDomainPage from "./pages/kitmail/domains/domain"
import KitmailDomainsPage, { loader as kitmailDomainLoader } from "./pages/kitmail/domains"
import EditKitmailDomainPage from "./pages/kitmail/domains/edit"
import EditKitmailUserPage from "./pages/kitmail/users/edit"
import KitmailForwardingsPage from "./pages/kitmail/forwardings"
import NewKitmailDomainPage from "./pages/kitmail/domains/new"
import NewKitmailForwardingPage from "./pages/kitmail/forwardings/new"
import NewKitmailUserPage from "./pages/kitmail/users/new"
import KitmailUserPage, { loader as kitmailUserLoader } from "./pages/kitmail/users/user"
import KitmailUsersPage from "./pages/kitmail/users"
import OverviewPage, { loader as overviewLoader } from "./pages/overview"
import ProductsPage from "./pages/products"
import SmsGatewaysPage, { loader as smsGatewayLoader } from "./pages/sms"
import SmsMessagesPage from "./pages/sms/messages"
import NewSmsPage, { loader as newSmsLoader } from "./pages/sms/new"
import TaskExecutionPage, { loader as taskExecutionLoader } from "./pages/tasks/execution"
import TaskExecutionsPage from "./pages/tasks"
import TicketsPage from "./pages/tickets"
import TicketShippingLabelPage, { loader as ticketShippingLabelLoader } from "./pages/tickets/shipping_label"
import AutotaskCallToInteractionToolPage, { loader as autotaskCallToInteractionLoader } from "./pages/tools/cti_autotask"
import GenerateLabelToolPage, { loader as generateLabelLoader } from "./pages/tools/generate_label"
import AutotaskImportInternetToolPage, { loader as autotaskImportInternetLoader } from "./pages/tools/import_internet_autotask"
import AutotaskImportWebsiteToolPage from "./pages/tools/import_website_autotask"
import InvoicingToolPage from "./pages/tools/invoicing"
import LookupDomainToolPage, { loader as lookupDomainLoader } from "./pages/tools/lookup_domain"
import KitmailDomainUsersPage from './pages/kitmail/domains/users'
import KitmailDomainForwardingsPage from './pages/kitmail/domains/forwardings'
import AccountUsersTab from './pages/accounts/account/users'
import AccountDomainsTab from './pages/accounts/account/domains'
import AccountTicketsTab, { loader as accountTicketsLoader } from './pages/accounts/account/tickets'
import AccountServicesTab, { loader as accountServicesLoader } from './pages/accounts/account/services'
import RootPage from './pages/root'
import SmsGatewayNavigatePage from './pages/sms/gateway_navigate'
import TicketPage, { loader as ticketLoader } from './pages/tickets/ticket'
import DomainOverviewTab from './pages/domains/domain/overview'
import DomainDnsRecordsTab from './pages/domains/domain/dns_records'
import CredentialsPage from './pages/credentials'
import DeleteDomainPage from './pages/domains/domain/delete'
import NewDomainPage, { loader as newDomainLoader } from './pages/domains/new'

// create global functions, to be called after loading application JS bundle
window.kit = {
	setCSRFToken: csrf => setGlobalState({ csrf }),
	renderApp: async rootElement => {
		const router = createBrowserRouter([
			{
				id: 'auth',
				path: '/auth',
				loader: authLoader,
				handle: {
					breadcrumb: {
						content: 'Logg inn',
					},
					auth: {
						required: false,
					},
				},
				element: <AuthPage />,
				errorElement: <ErrorPage />,
			},
			{
				id: 'root',
				path: '/',
				loader: authLoader,
				element: <RootPage />,
				errorElement: <ErrorPage />,
				children: [
					{
						id: 'overview',
						index: true,
						loader: overviewLoader,
						handle: {
							breadcrumb: {
								content: 'Oversikt',
							},
							auth: {
								required: true
							},
							dash: {
								fluid: true,
							},
							loader: false,
						},
						element: <OverviewPage />,
					},
					{
						id: 'accounts',
						path: 'accounts',
						handle: {
							breadcrumb: {
								content: 'Kunder og leverandører',
							},
						},
						children: [
							{
								index: true,
								handle: {
									auth: {
										required: true,
										scopes: ['account_read'],
									},
								},
								element: <AccountsPage />,
							},
							{
								id: 'account',
								path: ':accountId',
								loader: accountLoader,
								handle: {
									breadcrumb: ({ data: { account } }) => ({
										// if account is null, return a promise that never resolves, resulting in <Dash /> showing a <BreadcrumbPlaceholder />
										content: Promise.resolve(account).then(res => isObject(res) && isObject(res.data) ? res.data.name : new Promise(() => {})),
										link: false,
									}),
									auth: {
										required: true,
										scopes: ['account_read'],
									},
								},
								element: <AccountPage />,
								children: [
									{
										index: true,
										element: <Navigate to="users" replace />,
									},
									{
										id: 'accountUsers',
										path: 'users',
										handle: {
											breadcrumb: {
												content: 'Brukere',
											},
											auth: {
												required: true,
												scopes: ['user_search'],
											},
											dash: {
												loader: false,
											}
										},
										element: <AccountUsersTab />,
									},
									{
										id: 'accountDomains',
										path: 'domains',
										handle: {
											breadcrumb: {
												content: 'Domener',
											},
											dash: {
												loader: false,
											},
											auth: {
												required: true,
												scopes: ['domain_search'],
											},
										},
										element: <AccountDomainsTab />,
									},
									{
										id: 'accountTickets',
										path: 'tickets',
										handle: {
											breadcrumb: {
												content: 'Saker',
											},
											auth: {
												required: true,
												scopes: ['ticket_search'],
											},
											dash: {
												loader: false,
											}
										},
										loader: accountTicketsLoader,
										element: <AccountTicketsTab />,
									},
									{
										id: 'accountServices',
										path: 'services',
										loader: accountServicesLoader,
										handle: {
											breadcrumb: {
												content: 'Tjenester',
											},
											auth: {
												required: true,
												scopes: [
													'configuration_item_read',
													'contract_read',
													'contract_service_read',
													'service_read',
													'product_read'
												],
											},
											dash: {
												loader: false,
											}
										},
										element: <AccountServicesTab />,
									},
								],
							},
						],
					},
					{
						id: 'products',
						path: 'products',
						handle: {
							breadcrumb: {
								content: 'Produkter',
							},
							auth: {
								required: true,
								scopes: ['tripletex_product_read'],
							},
						},
						element: <ProductsPage />,
					},
					{
						id: 'domains',
						path: 'domains',
						handle: {
							breadcrumb: {
								content: 'Domener',
							},
						},
						children: [
							{
								index: true,
								handle: {
									auth: {
										required: true,
										scopes: ['domain_read'],
									},
								},
								element: <DomainsPage />,
							},
							{
								path: 'new',
								loader: newDomainLoader,
								handle: {
									auth: {
										required: true,
										scopes: ['domain_create'],
									},
									breadcrumb: {
										content: 'Opprett eller flytt domene',
									},
									dash: {
										constrained: true,
										fullHeight: true,
									},
								},
								element: <NewDomainPage />,
							},
							{
								id: 'domain',
								path: ':domainId',
								loader: domainLoader,
								handle: {
									breadcrumb: ({ data: { domain } }) => ({
										// if domain is null, return a promise that never resolves, resulting in <Dash /> showing a <BreadcrumbPlaceholder />
										content: Promise.resolve(domain).then(res => isObject(res) && isObject(res.data) ? res.data.domain : new Promise(() => {})),
									}),
								},
								children: [
									{
										id: 'domainOverview',
										index: true,
										handle: {
											auth: {
												required: true,
												scopes: ['domain_read'],
											},
										},
										element: <DomainPage><DomainOverviewTab /></DomainPage>,
									},
									{
										id: 'domainDnsRecords',
										path: 'dns-records',
										handle: {
											breadcrumb: {
												content: 'DNS-pekere',
											},
											auth: {
												required: true,
												scopes: ['domain_record_read'],
											},
										},
										element: <DomainPage><DomainDnsRecordsTab /></DomainPage>,
									},
									{
										path: 'edit',
										handle: {
											breadcrumb: {
												content: 'Endre domene',
											},
											auth: {
												required: true,
												scopes: ['domain_update'],
											},
											dash: {
												constrained: true,
												fullHeight: true,
											},
										},
										element: <EditDomainPage />,
									},
									{
										path: 'delete',
										handle: {
											breadcrumb: {
												content: 'Bekreft sletting av domene',
											},
											auth: {
												required: true,
												scopes: ['domain_read', 'domain_delete'],
											},
											dash: {
												constrained: true,
											},
										},
										element: <DeleteDomainPage />,
									},
								],
							},
						],
					},
					{
						id: 'kitmail',
						path: 'kitmail',
						handle: {
							breadcrumb: {
								content: 'KitMail',
								link: false,
							},
						},
						children: [
							{
								index: true,
								element: <Navigate to="users" replace />,
							},
							{
								id: 'kitmailUsers',
								path: 'users',
								handle: {
									breadcrumb: {
										content: 'Brukere',
									},
								},
								children: [
									{
										index: true,
										handle: {
											auth: {
												required: true,
												scopes: ['mail1_user_read'],
											},
										},
										element: <KitmailUsersPage />,
									},
									{
										path: 'new',
										handle: {
											breadcrumb: {
												content: 'Opprett bruker',
											},
											auth: {
												required: true,
												scopes: ['mail1_user_create'],
											},
											dash: {
												constrained: true,
											},
										},
										element: <NewKitmailUserPage />,
									},
									{
										id: 'kitmailUser',
										path: ':email',
										loader: kitmailUserLoader,
										handle: {
											breadcrumb: ({ data: { kitmailUser } }) => ({
												// if kitmailUser is null, return a promise that never resolves, resulting in <Dash /> showing a <BreadcrumbPlaceholder />
												content: Promise.resolve(kitmailUser).then(res => isObject(res) && isObject(res.data) && isObject(res.data.email) ? res.data.email.address : new Promise(() => {})),
											}),
										},
										children: [
											{
												index: true,
												handle: {
													auth: {
														required: true,
														scopes: ['mail1_user_read'],
													},
												},
												element: <KitmailUserPage />,
											},
											{
												path: 'edit',
												handle: {
													breadcrumb: {
														content: 'Endre bruker',
													},
													auth: {
														required: true,
														scopes: ['mail1_user_update'],
													},
													dash: {
														constrained: true,
													},
												},
												element: <EditKitmailUserPage />,
											},
											{
												path: 'delete',
												handle: {
													breadcrumb: {
														content: 'Bekreft sletting av bruker',
													},
													auth: {
														required: true,
														scopes: ['mail1_user_delete'],
													},
													dash: {
														constrained: true,
													},
												},
												element: <DeleteKitmailUserPage />,
											},
										]
									},
								],
							},
							{
								id: 'kitmailDomains',
								path: 'domains',
								handle: {
									breadcrumb: {
										content: 'Domener',
									},
								},
								children: [
									{
										index: true,
										handle: {
											auth: {
												required: true,
												scopes: ['mail1_domain_read'],
											},
										},
										element: <KitmailDomainsPage />,
									},
									{
										path: 'new',
										handle: {
											breadcrumb: {
												content: 'Opprett domene',
											},
											auth: {
												required: true,
												scopes: ['mail1_domain_create'],
											},
											dash: {
												constrained: true,
											},
										},
										element: <NewKitmailDomainPage />,
									},
									{
										id: 'kitmailDomain',
										path: ':domain',
										loader: kitmailDomainLoader,
										handle: {
											breadcrumb: ({ params: { domain } }, i, matches) => {
												const { pathname } = matches[matches.length - 1]
												return {
													content: domain,
													link: pathname.endsWith('/edit') || pathname.endsWith('/' + domain + '/delete'),
												}
											},
										},
										children: [
											{
												index: true,
												element: <Navigate to="users" replace />,
											},
											{
												path: 'users',
												handle: {
													breadcrumb: {
														content: 'Brukere',
													},
												},
												children: [
													{
														index: true,
														id: 'kitmailDomainUsers',
														handle: {
															auth: {
																required: true,
																scopes: ['mail1_domain_read', 'mail1_user_read'],
															},
														},
														element: <KitmailDomainPage><KitmailDomainUsersPage /></KitmailDomainPage>,
													},
													{
														path: 'new',
														handle: {
															breadcrumb: {
																content: 'Opprett bruker',
															},
															auth: {
																required: true,
																scopes: ['mail1_domain_read', 'mail1_user_create'],
															},
															dash: {
																constrained: true,
															},
														},
														element: <NewKitmailUserPage />,
													},
													{
														id: 'kitmailDomainUser',
														path: ':email',
														loader: kitmailUserLoader,
														handle: {
															breadcrumb: ({ data: { kitmailUser } }) => ({
																// if kitmailUser is null, return a promise that never resolves, resulting in <Dash /> showing a <BreadcrumbPlaceholder />
																content: Promise.resolve(kitmailUser).then(res => isObject(res) && isObject(res.data) && isObject(res.data.email) ? res.data.email.address : new Promise(() => {})),
															}),
														},
														children: [
															{
																index: true,
																handle: {
																	auth: {
																		required: true,
																		scopes: ['mail1_domain_read', 'mail1_user_read'],
																	},
																},
																element: <KitmailUserPage />,
															},
															{
																path: 'edit',
																handle: {
																	breadcrumb: {
																		content: 'Endre bruker',
																	},
																	auth: {
																		required: true,
																		scopes: ['mail1_domain_read', 'mail1_user_update'],
																	},
																	dash: {
																		constrained: true,
																	},
																},
																element: <EditKitmailUserPage />,
															},
															{
																path: 'delete',
																handle: {
																	breadcrumb: {
																		content: 'Bekreft sletting av bruker',
																	},
																	auth: {
																		required: true,
																		scopes: ['mail1_domain_read', 'mail1_user_delete'],
																	},
																	dash: {
																		constrained: true,
																	},
																},
																element: <DeleteKitmailUserPage />,
															},
														]
													},
												],
											},
											{
												path: 'forwardings',
												handle: {
													breadcrumb: {
														content: 'Videresendinger',
													},
												},
												children: [
													{
														index: true,
														id: 'kitmailDomainForwardings',
														handle: {
															auth: {
																required: true,
																scopes: ['mail1_domain_read', 'mail1_forwarding_read'],
															},
														},
														element: <KitmailDomainPage><KitmailDomainForwardingsPage /></KitmailDomainPage>,
													},
													{
														path: 'new',
														handle: {
															breadcrumb: {
																content: 'Opprett KitMail-videresending',
															},
															auth: {
																required: true,
																scopes: ['mail1_domain_read', 'mail1_forwarding_create'],
															},
															dash: {
																constrained: true,
															},
														},
														element: <NewKitmailForwardingPage />,
													},
													{
														id: 'kitmailDomainForwarding',
														path: ':source/delete',
														loader: kitmailForwardingLoader,
														handle: {
															breadcrumb: {
																content: 'Bekreft sletting av videresending',
															},
															auth: {
																required: true,
																scopes: ['mail1_domain_read', 'mail1_domain_delete'],
															},
															dash: {
																constrained: true,
															},
														},
														element: <DeleteKitmailForwardingPage />,
													},
												],
											},
											{
												path: 'edit',
												handle: {
													breadcrumb: {
														content: 'Endre domene',
													},
													auth: {
														required: true,
														scopes: ['mail1_domain_read', 'mail1_domain_update'],
														dash: {
															constrained: true,
														},
													},
												},
												element: <EditKitmailDomainPage />,
											},
											{
												path: 'delete',
												handle: {
													breadcrumb: {
														content: 'Bekreft sletting av domene',
													},
													auth: {
														required: true,
														scopes: ['mail1_domain_read', 'mail1_domain_delete'],
													},
													dash: {
														constrained: true,
													},
												},
												element: <DeleteKitmailDomainPage />,
											},
										],
									},
								],
							},
							{
								id: 'kitmailForwardings',
								path: 'forwardings',
								handle: {
									breadcrumb: {
										content: 'Videresendinger',
									},
								},
								children: [
									{
										index: true,
										handle: {
											auth: {
												required: true,
												scopes: ['mail1_forwarding_read'],
											},
										},
										element: <KitmailForwardingsPage />,
									},
									{
										path: 'new',
										handle: {
											breadcrumb: {
												content: 'Opprett videresending',
											},
											auth: {
												required: true,
												scopes: ['mail1_forwarding_create'],
											},
										},
										element: <NewKitmailForwardingPage />,
									},
									{
										id: 'kitmailForwarding',
										path: ':source/delete',
										loader: kitmailForwardingLoader,
										handle: {
											breadcrumb: {
												content: 'Bekreft sletting av videresending',
											},
											auth: {
												required: true,
												scopes: ['mail1_forwarding_delete'],
											},
											dash: {
												constrained: true,
											},
										},
										element: <DeleteKitmailForwardingPage />,
									},
								],
							},
						],
					},
					{
						id: 'sms',
						path: 'sms',
						children: [
							{
								index: true,
								handle: {
									auth: {
										required: true,
										scopes: ['sms_read'],
									},
									dash: {
										fluid: true,
									},
								},
								element: <SmsGatewayNavigatePage to="messages" />,
							},
							{
								path: 'messages',
								children: [
									{
										index: true,
										handle: {
											auth: {
												required: true,
												scopes: ['sms_read'],
											},
											dash: {
												fluid: true,
											},
										},
										element: <SmsGatewayNavigatePage to="messages" />,
									},
									{
										path: 'new',
										handle: {
											auth: {
												required: true,
												scopes: ['sms_create'],
											},
											dash: {
												constrained: true,
											},
										},
										element: <SmsGatewayNavigatePage to="messages/new" />,
									},
								],
							},
							{
								path: 'gateways',
								handle: {
									breadcrumb: {
										content: 'SMS-gatewayer',
									},
								},
								children: [
									{
										id: 'smsGateways',
										index: true,
										handle: {
											auth: {
												required: true,
												scopes: ['sms_gateway_read'],
											},
										},
										element: <SmsGatewaysPage />,
									},
									{
										id: 'smsGateway',
										path: ':gatewayId',
										loader: smsGatewayLoader,
										handle: {
											breadcrumb: ({ data: { gateway } }) => ({
												// if gateway is null, return a promise that never resolves, resulting in <Dash /> showing a <BreadcrumbPlaceholder />
												content: Promise.resolve(gateway).then(res => isObject(res) && isObject(res.data) ? (res.data.title + (res.data.default_sender !== res.data.title ? ' (' + res.data.default_sender + ')' : '')) : new Promise(() => {})),
												link: false,
											}),
										},
										children: [
											{
												index: true,
												handle: {
													dash: {
														fluid: true,
													},
												},
												element: <Navigate to="messages" replace />,
											},
											{
												path: 'messages',
												handle: {
													breadcrumb: {
														content: 'Tekstmeldinger',
													},
												},
												children: [
													{
														id: 'smsMessages',
														index: true,
														handle: {
															auth: {
																required: true,
																scopes: ['sms_read'],
															},
															dash: {
																fluid: true,
															},
														},
														element: <SmsMessagesPage />,
													},
													{
														id: 'smsNew',
														path: 'new',
														loader: newSmsLoader,
														handle: {
															breadcrumb: {
																content: 'Ny tekstmelding',
															},
															auth: {
																required: true,
																scopes: ['sms_create'],
															},
															dash: {
																constrained: true,
															},
														},
														element: <NewSmsPage />,
													},
												],
											},
										],
									},
								],
							},
						],
					},
					{
						id: 'tasks',
						path: 'tasks',
						handle: {
							breadcrumb: {
								content: 'Bakgrunnsjobber',
							},
						},
						children: [
							{
								index: true,
								element: <Navigate to="executions" replace />
							},
							{
								path: 'executions',
								children: [
									{
										index: true,
										handle: {
											auth: {
												required: true,
												scopes: ['task_executions_read'],
											},
										},
										element: <TaskExecutionsPage />,
									},
									{
										path: ':executionId',
										loader: taskExecutionLoader,
										handle: {
											breadcrumb: ({ params: { executionId } }) => ({
												content: 'Kjøring #' + executionId,
											}),
											auth: {
												required: true,
												scopes: ['task_executions_read'],
											},
										},
										element: <TaskExecutionPage />,
									},
								],
							},
						],
					},
					{
						id: 'credentials',
						path: 'credentials',
						handle: {
							breadcrumb: {
								content: 'Tredjeparts API-tilgangsnøkler'
							},
						},
						children: [
							{
								index: true,
								element: <CredentialsPage />,
							},
						],
					},
					{
						id: 'tickets',
						path: 'tickets',
						handle: {
							breadcrumb: {
								content: 'Saker',
							},
						},
						children: [
							{
								index: true,
								handle: {
									auth: {
										required: true,
										scopes: ['ticket_read'],
									},
								},
								element: <TicketsPage />,
							},
							{
								path: 'shipping-label',
								handle: {
									breadcrumb: {
										content: 'Opprett fraktlapp',
									},
									auth: {
										required: true,
										scopes: ['ticket_read', 'mybring_create_consignment'],
									},
									dash: {
										constrained: true,
									},
								},
								element: <TicketShippingLabelPage />,
							},
							{
								path: ':ticketNumber',
								handle: {
									breadcrumb: ({ params: { ticketNumber } }) => ({
										content: ticketNumber,
									}),
								},
								children: [
									{
										index: true,
										loader: ticketLoader,
										handle: {
											dash: {
												disabled: true,
											},
										},
										element: <TicketPage />,
									},
									{
										path: 'shipping-label',
										loader: ticketShippingLabelLoader,
										handle: {
											breadcrumb: {
												content: 'Opprett fraktlapp',
											},
											auth: {
												required: true,
												scopes: ['ticket_read', 'mybring_create_consignment'],
											},
											dash: {
												constrained: true,
											},
										},
										element: <TicketShippingLabelPage />,
									},
								],
							},
						],
					},
					{
						id: 'tools',
						path: 'tools',
						handle: {
							breadcrumb: {
								content: 'Verktøy',
								link: false,
							},
						},
						children: [
							{
								id: 'toolCtiAutotask',
								path: 'cti-autotask',
								loader: autotaskCallToInteractionLoader,
								handle: {
									breadcrumb: {
										content: 'Autotask Call-To-Interaction (CTI)',
									},
									auth: {
										required: true,
										scopes: ['company_read', 'contact_read', 'ticket_read'],
									},
									dash: {
										constrained: true,
									},
								},
								element: <AutotaskCallToInteractionToolPage />,
							},
							{
								id: 'toolGenerateLabel',
								path: 'generate-label',
								loader: generateLabelLoader,
								handle: {
									breadcrumb: {
										content: 'Lag merkelapp',
									},
									auth: {
										required: true,
										scopes: ['tool_label'],
									},
									dash: {
										constrained: true,
										disabled: true,
									},
								},
								element: <GenerateLabelToolPage />,
							},
							{
								id: 'toolImportInternetAutotask',
								path: 'import-internet-autotask',
								loader: autotaskImportInternetLoader,
								handle: {
									breadcrumb: {
										content: 'Importer internett til Autotask',
									},
									auth: {
										required: true,
										scopes: ['tool_import_internet_autotask'],
									},
									dash: {
										constrained: true,
									},
								},
								element: <AutotaskImportInternetToolPage />,
							},
							{
								id: 'toolImportWebsiteAutotask',
								path: 'import-website-autotask',
								handle: {
									breadcrumb: {
										content: 'Importer nettside til Autotask',
									},
									auth: {
										required: true,
										scopes: ['tool_import_website_autotask'],
									},
									dash: {
										constrained: true,
									},
								},
								element: <AutotaskImportWebsiteToolPage />,
							},
							{
								id: 'toolInvoicing',
								path: 'invoicing',
								handle: {
									breadcrumb: {
										content: 'Fakturering',
									},
									auth: {
										required: true,
										scopes: ['tool_invoicing'],
									},
									dash: {
										constrained: true,
									},
								},
								element: <InvoicingToolPage />,
							},
							{
								id: 'toolLookupDomain',
								path: 'lookup-domain',
								children: [
									{
										index: true,
										loader: lookupDomainLoader,
										handle: {
											breadcrumb: {
												content: 'Domeneoppslag',
											},
											auth: {
												required: true,
												scopes: ['tool_lookup_domain'],
											},
											dash: {
												constrained: true,
											},
										},
										element: <LookupDomainToolPage />,
									},
									{
										path: ':domain',
										loader: lookupDomainLoader,
										handle: {
											breadcrumb: {
												content: 'Domeneoppslag',
											},
											auth: {
												required: true,
												scopes: ['tool_lookup_domain'],
											},
											dash: {
												constrained: true,
											},
										},
										element: <LookupDomainToolPage />,
									},
								],
							},
						],
					},
				]
			},
		])

		ReactDOM.createRoot(rootElement).render(
			<RouterProvider router={router} />
		)
	}
}
