import React, { useState } from 'react'
import { Segment, Container, Header, Button, Form, Placeholder } from 'semantic-ui-react'
import { handleRedirect, isObject, navigate, preventDefault } from '../../../helpers'
import { deleteForwarding, getForwarding } from '../../../api/mail1/forwardings'
import { Await, defer, useNavigate, useRouteLoaderData } from 'react-router-dom'
import { preloadedEntity, useMessages } from '../../root'
import { Response } from '../../../api'

export async function loader({ params: { source } }) {
	if (isObject(preloadedEntity.current) && isObject(preloadedEntity.current.source) && preloadedEntity.current.source.address === source) {
		const kitmailForwarding = new Response({data: preloadedEntity.current})
		preloadedEntity.current = null
		return { source, kitmailForwarding }
	}
	const kitmailForwarding = getForwarding(source).catch(e => {
		console.error('Unable to load forwarding:', e)
		return null
	})
	return defer({ source, kitmailForwarding })
}

export default function DeleteKitmailForwardingPage() {
	const forwardingData = useRouteLoaderData('kitmailForwarding')
	const domainForwardingData = useRouteLoaderData('kitmailDomainForwarding')
	const { source, kitmailForwarding } = (forwardingData ?? domainForwardingData)
	const [, setMessages] = useMessages()
	const [forwardingConfirmInput, setForwardingConfirmInput] = useState('')
	const routerNavigateFn = useNavigate()

	const handleDeleteForwarding = () => {
		if (forwardingConfirmInput !== source) return
		deleteForwarding(source).then(res => {
			setMessages(messages => messages.concat({
				key: 'success_' + Math.round(new Date() / 1000).toString(),
				dismissable: true,
				type: 'success',
				icon: 'check',
				content: 'Videresendingen er slettet.',
			}))
			Promise.resolve(kitmailForwarding).then(({data: kitmailForwarding}) => navigate(kitmailForwarding.source.domain ? '/kitmail/domains/' + kitmailForwarding.source.domain.domain + '/forwardings' : '/kitmail/domains', '_self', {}, routerNavigateFn))
		}).catch(e => {
			handleRedirect(e.res, routerNavigateFn)
			setMessages(messages => messages.concat({
				key: 'error_' + Math.round(new Date() / 1000).toString(),
				dismissable: true,
				type: 'error',
				icon: 'exclamation circle',
				content: 'Videresendingen kunne ikke slettes: ' + (e && e.message ? e.message : 'Ukjent feil (sjekk JavaScript-konsollen)'),
			}))
		})
	}

	return <>
		<Segment vertical padded="very">
			<Container>
				<Header size="huge">
					Slette videresending fra <code>{source}</code>?
				</Header>

				<React.Suspense fallback={
					<Placeholder>
						<Placeholder.Line />
					</Placeholder>
				}>
					<Await resolve={kitmailForwarding}>
						{res => <p>
							Videresendingen går til <code>{isObject(res) && isObject(res.data) && isObject(res.data.destination) ? res.data.destination.address : null}</code>
						</p>}
					</Await>
				</React.Suspense>

				<Form onSubmit={preventDefault(() => handleDeleteForwarding())}>
					<Form.Input
						type="email"
						autoComplete="off"
						label="Skriv inn fra-epostadressen for å bekrefte sletting"
						placeholder="user@example.com"
						value={forwardingConfirmInput}
						onChange={(e, data) => setForwardingConfirmInput(data.value)}
					/>
					<Form.Field>
						<Button
							as="a"
							href={'/kitmail/users/' + source}
							onClick={preventDefault(e => navigate('/kitmail/users/' + source, (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
							content="Avbryt"
						/>
						<Button
							type="submit"
							negative
							content="Bekreft"
							disabled={forwardingConfirmInput !== source}
						/>
					</Form.Field>
				</Form>
			</Container>
		</Segment>
	</>
}