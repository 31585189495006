import React, { useCallback, useState } from 'react'
import { Segment, Header, Container, Button, Popup, Icon, Grid } from 'semantic-ui-react'
import DataTable from '../../components/data_table'
import { getDomains } from '../../api/domains'
import { getAccounts } from '../../api/accounts'
import { dateToString, formatNumber, filterTypes, isDomainExpired, getDomainRenewalDate, navigate, preventDefault, handleRedirect } from '../../helpers'
import Link from '../../components/link'
import { useNavigate } from 'react-router-dom'
import { useAuth, usePreloadedEntityDispatcher } from '../root'

const defaultQuery = {op: 'or', grp: [
	{op: 'eq', key: 'services.domain', val: true},
	{op: 'eq', key: 'services.dns', val: true},
]}

export default function DomainsPage() {
	const { user } = useAuth()
	const [dataInfo, setDataInfo] = useState(null)
	const routerNavigateFn = useNavigate()
	const preloadEntity = usePreloadedEntityDispatcher()

	const apiSearch = useCallback(async (query, options, requestOptions) => {
		const res = await getDomains(query, options, requestOptions)
		handleRedirect(res, routerNavigateFn)
		if (JSON.stringify(query) === JSON.stringify(defaultQuery)) {
			setDataInfo(res.data_info)
		}
		return res
	}, [routerNavigateFn])

	return <>
		<Segment vertical padded="very">
			<Container>
				<Grid stackable>
					<Grid.Row>
						<Grid.Column width={10}>
							<Header size="huge">
								Domener
								<Header.Subheader>Liste over domener du kan administrere</Header.Subheader>
							</Header>
						</Grid.Column>
						<Grid.Column width={6} textAlign="right">
							<Button
								as="a"
								href="/domains/new"
								onClick={preventDefault(e => navigate('/domains/new', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
								color="orange"
								label={dataInfo ? formatNumber(dataInfo.count) : '0'}
								labelPosition="right"
								content={<><Icon name="add" /> Opprett eller flytt domene</>}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<p></p>

				<DataTable
					columns={[
						{ key: 'domain', text: 'Domene', visible: true, sortable: true, searchable: true, filterType: filterTypes.search, filterSearchColumns: ['domain', 'ace'] },
						{ key: 'ace', text: 'Domene (ren tekst)', searchable: true },
						{ key: 'account.name', text: 'Kunde', visible: user && user.auth.accountIds.length > 1, sortable: true, searchable: true, filterType: user && user.auth.accountIds.length > 1 ? filterTypes.model : null, filterApiSearch: getAccounts, filterKey: 'name', filterSearchColumns: ['name', 'email', 'phone.number', 'customer_no', 'supplier_no'], filterPlaceholder: 'Søk etter en kunde...' },
						{ key: 'contacts.owner.name', text: 'Juridisk eier', visible: !user || user.auth.accountIds.length <= 1, searchable: true, filterType: filterTypes.search, filterSearchColumns: ['contacts.owner.name', 'contacts.owner.organization_name'] },
						{ key: 'contacts.owner.organization_name', text: 'Juridisk eier (Firma)', searchable: true },
						{ key: 'expire_at', text: 'Utløp', visible: true, sortable: true, searchable: true, filterType: filterTypes.date },
						{ key: 5, text: '', visible: true },
					]}
					query={defaultQuery}
					defaultOrder={{'account.name': 'ASC', 'domain': 'ASC'}}
					apiSearch={apiSearch}
					onClickRow={(data, index, { ctrlKey, shiftKey }) => {
						// preload domain data
						preloadEntity(data)
						// navigate to domain
						const url = '/domains/' + encodeURIComponent(data.id)
						const target = (ctrlKey || shiftKey) ? '_blank' : '_self'
						navigate(url, target, {}, routerNavigateFn)
					}}
					renderRow={data => {
						const expired = isDomainExpired(data)
						const renewalDate = getDomainRenewalDate(data)
						const hasOwner = data.contacts.owner !== null
						const hasOwnerOrgName = hasOwner && typeof data.contacts.owner.organization_name === 'string' && data.contacts.owner.organization_name.trim().length > 0
						const hasOwnerName = hasOwner && typeof data.contacts.owner.name === 'string' && data.contacts.owner.name.trim().length > 0
						return [
							<>
								{data.domain}
								{data.ace ? <><br/><span className="text-secondary">{data.ace}</span></> : null}
							</>,
							<>
								<Link href={'/accounts/' + encodeURIComponent(data.account.id)}>{data.account.name}</Link>
								{hasOwnerOrgName && data.contacts.owner.organization_name.toLowerCase() !== data.account.name.toLowerCase() ? <>
									<br/>
									{data.contacts.owner.organization_name}
								</> : null}
								{hasOwnerName && data.contacts.owner.name.toLowerCase() !== data.account.name.toLowerCase() ? <>
									<br/>
									{hasOwnerOrgName ? <span className="text-secondary">Kontaktperson: {data.contacts.owner.name}</span> : data.contacts.owner.name}
								</> : null}
							</>,
							!data.expire_at ? 'Ukjent utløp' : ((data.auto_renew ? 'Fornyes ' : (!expired ? 'Løper ut ' : 'Løp ut ')) + dateToString(renewalDate, false)),
							<div className="text-ha-right">
								<Button.Group size="mini" compact>
									{!data.services.dns || (data.services.domain && data.expire_at && expired && !data.auto_renew) ? null : <Popup
										inverted
										content="Endre DNS-pekere"
										trigger={<Button
											as="a"
											color="blue"
											href={'/domains/' + encodeURIComponent(data.id) + '/dns-records'}
											onClick={preventDefault(e => {
												// preload domain data
												preloadEntity(data)
												// navigate to domain DNS records page
												navigate('/domains/' + encodeURIComponent(data.id) + '/dns-records', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn)
											})}
										>DNS</Button>}
									/>}
									{!data.services.domain || (data.expire_at && expired && !data.auto_renew) ? null : <Popup
										inverted
										content="Endre domene"
										trigger={<Button
											as="a"
											icon="edit"
											href={'/domains/' + encodeURIComponent(data.id) + '/edit'}
											onClick={preventDefault(e => {
												// preload domain data
												preloadEntity(data)
												// navigate to domain edit page
												navigate('/domains/' + encodeURIComponent(data.id) + '/edit', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn)
											})}
										/>}
									/>}
									{!data.services.domain || !data.auto_renew ? null : <Popup
										inverted
										content="Si opp domene"
										trigger={<Button
											as="a"
											icon="x"
											negative
											href={'/domains/' + encodeURIComponent(data.id) + '/delete'}
											onClick={preventDefault(e => {
												// preload domain data
												preloadEntity(data)
												// navigate to domain expire page
												navigate('/domains/' + encodeURIComponent(data.id) + '/delete', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn)
											})}
										/>}
									/>}
								</Button.Group>
							</div>
						]
					}}
				/>
			</Container>
		</Segment>
	</>
}