import React from 'react'
import { Segment, Header, Container, Placeholder, Label, Icon, Popup, Button, Menu, Grid } from 'semantic-ui-react'
import { age, dateToRelativeString, isDomainExpired, preventDefault, navigate, isObject } from '../../../helpers'
import { getDomain } from '../../../api/domains'
import { ID_KIT } from '../../../api/accounts'
import { Await, Outlet, defer, useMatches, useNavigate, useRouteLoaderData } from 'react-router-dom'
import { preloadedEntity, useAuth, usePreloadedEntityDispatcher } from '../../root'
import { Response } from '../../../api'
import { PERIOD_TYPE_ONE_TIME as PRODUCT_PERIOD_TYPE_ONE_TIME, CATEGORY_DOMAIN_OWNER_CHANGE as PRODUCT_CATEGORY_DOMAIN_OWNER_CHANGE, getProducts } from '../../../api/autotask/products'

export async function loader({ params: { domainId } }) {
	let domain
	if (isObject(preloadedEntity.current) && preloadedEntity.current.id === domainId) {
		domain = new Response({data: preloadedEntity.current})
		preloadedEntity.current = null
	} else {
		domain = getDomain(domainId).catch(e => {
			console.error('Unable to load domain:', e)
			return null
		})
	}

	const ownerChangePriceNOK = Promise.resolve(domain)
		.then(res => {
			if (!isObject(res) || !isObject(res.data)) return {data: []}
			return getProducts([
				{op: 'eq', field: 'productCategory', value: PRODUCT_CATEGORY_DOMAIN_OWNER_CHANGE},
				{op: 'eq', field: 'periodType', value: PRODUCT_PERIOD_TYPE_ONE_TIME},
				{op: 'eq', field: 'isActive', value: true},
				{op: 'in', field: 'name', value: [
					'Eierskifte .' + domain.domain_name_parts.tld,
					'Eierskifte .' + domain.domain_name_parts.public_suffix,
				]},
			], { limit: 2 })
		})
		.then(res => {
			let price = 0.0
			if (res.data.length === 1) {
				price = res.data[0].unitPrice
			} else if (res.data.length > 1) {
				let productNameMaxLength = 0
				for (const product of res.data) {
					if (typeof product.name === 'string' && product.name.length > productNameMaxLength) {
						price = product.unitPrice
						productNameMaxLength = product.name.length
					}
				}
			}
			return price
		})
		.catch(e => {
			console.error('Unable to load owner change price:', e)
			return null
		})

	return defer({ domainId, domain, ownerChangePriceNOK })
}

export default function DomainPage({ children }) {
	const { user } = useAuth()
	const { domainId, domain } = useRouteLoaderData('domain')
	const routerNavigateFn = useNavigate()
	const preloadEntity = usePreloadedEntityDispatcher()
	const matches = useMatches()
	const { id: activeTab } = matches[matches.length - 1]

	return <>
		<Segment vertical padded="very">
			<Container>
				<Grid stackable>
					<Grid.Row>
						<Grid.Column width={10}>
							<React.Suspense fallback={
								<Placeholder>
									<Placeholder.Header>
										<Placeholder.Line />
										<Placeholder.Line />
									</Placeholder.Header>
								</Placeholder>
							}>
								<Await resolve={domain}>
									{res => <Header size="huge">
										{isObject(res) && isObject(res.data) ? res.data.domain : null}
										{isObject(res) && isObject(res.data) && res.data.ace ? <Header.Subheader>{res.data.ace}</Header.Subheader> : null}
									</Header>}
								</Await>
							</React.Suspense>

							<React.Suspense fallback={
								<Placeholder>
									<Placeholder.Line />
								</Placeholder>
							}>
								<Await resolve={domain}>
									{res => {
										const now = new Date()
										const expired = isObject(res) && isObject(res.data) ? isDomainExpired(res.data) : null
										const domainAge = isObject(res) && isObject(res.data) ? age(res.data.created_at, expired ? new Date(res.data.expire_at) : now) : null

										return <Label.Group>
											{user.account.id === ID_KIT && isObject(res) && isObject(res.data) && res.data.account.id === ID_KIT ? <Popup
												inverted
												position="bottom left"
												content="Tilhører Konsept-IT AS"
												trigger={<Label color="orange">
													<Icon name="building" /><span>&ensp;Konsept-IT</span>
												</Label>}
											/> : null}
											{user.account.id === ID_KIT && isObject(res) && isObject(res.data) && res.data.account.id !== ID_KIT ? <Popup
												inverted
												position="bottom left"
												content={'Tilhører kunde ' + res.data.account.name}
												trigger={<Label color="purple">
													<Icon name="building" /><span>&ensp;Kunde</span>
												</Label>}
											/> : null}
											<Popup
												inverted
												position="bottom left"
												content={'Opprettet ' + (isObject(res) && isObject(res.data) ? dateToRelativeString(res.data.created_at) : '')}
												trigger={<Label image>
													<div className="content">
														<Icon name="time" /><span>&ensp;Alder&ensp;</span>
													</div>
													<Label.Detail>{domainAge === 0 ? '< 1' : domainAge} år</Label.Detail>
												</Label>}
											/>
											{expired ? <Popup
												inverted
												position="bottom left"
												content={'Løp ut ' + (isObject(res) && isObject(res.data) ? dateToRelativeString(res.data.expire_at) : '')}
												trigger={<Label color="red"><Icon name="times" /><span>&ensp;Utløpt</span></Label>}
											/> : (isObject(res) && isObject(res.data) && !res.data.auto_renew ? <Label color="yellow"><Icon name="x" /><span>&ensp;Løper ut</span></Label> : <Label color="green"><Icon name="check" /><span>&ensp;Aktivt</span></Label>)}
										</Label.Group>
									}}
								</Await>
							</React.Suspense>
						</Grid.Column>
						<Grid.Column width={6} textAlign="right">
							<React.Suspense fallback={
								<>
									<Button
										disabled
										content={<><Icon name="edit" /> Endre domene</>}
									/>
									<Button
										disabled
										negative
										content={<><Icon name="x" /> Si opp domene</>}
									/>
								</>
							}>
								<Await resolve={domain}>
									{res => <>
										<Button
											as="a"
											href={'/domains/' + encodeURIComponent(domainId) + '/edit'}
											onClick={preventDefault(e => {
												// preload domain data
												if (isObject(res) && isObject(res.data)) preloadEntity(res.data)
												// navigate to edit page
												navigate('/domains/' + encodeURIComponent(domainId) + '/edit', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn)
											})}
											disabled={!isObject(res) || !isObject(res.data) || !isObject(res.data.services) || !res.data.services.domain || (res.data.expire_at !== null && isDomainExpired(res.data) && !res.data.auto_renew)}
											content={<><Icon name="edit" /> Endre domene</>}
										/>
										<Button
											as="a"
											href={'/domains/' + encodeURIComponent(domainId) + '/delete'}
											onClick={preventDefault(e => {
												// preload domain data
												if (isObject(res) && isObject(res.data)) preloadEntity(res.data)
												// navigate to delete page
												navigate('/domains/' + encodeURIComponent(domainId) + '/delete', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn)
											})}
											disabled={!isObject(res) || !isObject(res.data) || !isObject(res.data.services) || !res.data.services.domain || !res.data.auto_renew}
											negative
											content={<><Icon name="x" /> Si opp domene</>}
										/>
									</>}
								</Await>
							</React.Suspense>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
		</Segment>

		<Menu attached tabular>
			<Container>
				<Menu.Item
					link
					as="a"
					href={'/domains/' + encodeURIComponent(domainId)}
					onClick={preventDefault(e => navigate('/domains/' + encodeURIComponent(domainId), (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
					active={activeTab === 'domainOverview'}
					content="Oversikt"
				/>
				<Menu.Item
					link
					as="a"
					href={'/domains/' + encodeURIComponent(domainId) + '/dns-records'}
					onClick={preventDefault(e => navigate('/domains/' + encodeURIComponent(domainId) + '/dns-records', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
					active={activeTab === 'domainDnsRecords'}
					content="DNS-pekere"
				/>
			</Container>
		</Menu>

		{children}
		<Outlet />
	</>
}