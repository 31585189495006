import React, { useState } from 'react'
import { Segment, Container, Header, Form } from 'semantic-ui-react'
import { emailDomainRegex, handleRedirect, navigate, preventDefault } from '../../../helpers'
import { createDomain } from '../../../api/mail1/domains'
import { useNavigate } from 'react-router-dom'
import { useMessages } from '../../root'

export default function NewKitmailDomainPage() {
	const [, setMessages] = useMessages()
	const [domain, setDomain] = useState('')
	const [domainDirty, setDomainDirty] = useState(false)
	const [transport, setTransport] = useState('')
	const [external, setExternal] = useState(false)
	const routerNavigateFn = useNavigate()

	const handleCreateDomain = () => {
		if (domain.length < 1 || !(emailDomainRegex.test(domain))) return
		createDomain({ domain, transport, external }).then(_res => {
			setMessages(messages => messages.concat({
				key: 'success_' + Math.round(new Date() / 1000).toString(),
				dismissable: true,
				type: 'success',
				icon: 'check',
				content: 'Domenet er opprettet.',
			}))
			navigate('/kitmail/domains/' + domain, '_self', {}, routerNavigateFn)
		}).catch(e => {
			setMessages(messages => messages.concat({
				key: 'error_' + Math.round(new Date() / 1000).toString(),
				dismissable: true,
				type: 'error',
				icon: 'exclamation circle',
				content: 'Domenet kunne ikke opprettes: ' + (e && e.message ? e.message : 'Ukjent feil (sjekk JavaScript-konsollen)'),
			}))
			handleRedirect(e.res, routerNavigateFn)
		})
	}

	return <>
		<Segment vertical padded="very">
			<Container>
				<Header size="huge">Opprett KitMail-domene</Header>
				<Form onSubmit={preventDefault(() => handleCreateDomain())}>
					<Form.Input
						label="Domene"
						required
						error={domainDirty && !(emailDomainRegex.test(domain))}
						value={domain}
						pattern={emailDomainRegex.source}
						onChange={(e, data) => {
							setDomain(data.value)
							if (data.value.length === 0) {
								setDomainDirty(false)
							} else {
								setDomainDirty(true)
							}
						}}
					/>
					<Form.Input
						label="Transportmetode"
						placeholder="maildrop"
						value={transport}
						onChange={(e, data) => setTransport(data.value)}
					/>
					<Form.Checkbox
						label="Eksternt domene (kun SMTP)"
						checked={external}
						onChange={(e, data) => setExternal(data.checked)}
					/>
					<Form.Button
						type="submit"
						color="orange"
						disabled={domain.length < 1 || !(emailDomainRegex.test(domain))}
						content="Opprett domene"
					/>
				</Form>
			</Container>
		</Segment>
	</>
}