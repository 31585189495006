import React from 'react'
import { Grid, Header, Icon, Label, Placeholder } from 'semantic-ui-react'
import ResourceCard from './resource_card'
import { isObject } from '../helpers'
import Link from './link'

export default function DomainRegistrarServiceCard({ loading, value }) {
	loading = loading ?? false
	value = value ?? null

	let content = <ResourceCard.Content>Skriv inn et domenenavn...</ResourceCard.Content>
	if (loading) {
		content = <ResourceCard.Content>
			<Placeholder>
				<Placeholder.Line />
			</Placeholder>
		</ResourceCard.Content>
	} else if (isObject(value) && isObject(value.whois)) {
		content = []
		if (isObject(value.whois.registrar)) {
			content.push(<ResourceCard.Content key="registrar_info">
				<Header size="small">
					{value.name}
					{value.type !== 'kit' && value.whois.registrar.id ? <Header.Subheader>ID: {value.whois.registrar.id}</Header.Subheader> : null}
				</Header>
				{(typeof value.whois.registrar.url || value.whois.registrar.email || value.whois.registrar.phone) ? <p>
					{[
						value.whois.registrar.url ? <Link href={value.whois.registrar.url} rel="noopener nofollow noreferrer" target="_blank"><Icon name="external" />&ensp;{value.whois.registrar.url}</Link> : null,
						value.whois.registrar.email ? <Link href={'mailto:' + value.whois.registrar.email} rel="noopener nofollow noreferrer" target="_blank">{value.whois.registrar.email}</Link> : null,
						value.whois.registrar.phone ? <Link href={'tel:' + value.whois.registrar.phone.replace('.', '')} rel="noopener nofollow noreferrer" target="_blank">{value.whois.registrar.phone}</Link> : null,
					].filter(line => line !== null).map((line, i) => <React.Fragment key={'registrar_contact_item_' + i}>{i > 0 ? <br/> : null}{line}</React.Fragment>)}
				</p> : null}
				{value.whois.registrar.address ? <p>
					{value.whois.registrar.address.map((line, i) => <React.Fragment key={'registrar_contact_address_' + i}>{i > 0 ? <br/> : null}{line}</React.Fragment>)}
				</p> : null}
			</ResourceCard.Content>)
		}
		content.push(<ResourceCard.Content key="registrar_domain_info">
			<Grid>
				<Grid.Row columns={2}>
					<Grid.Column>{value.whois.idnName}</Grid.Column>
					<Grid.Column textAlign="right">
						<Label className="very basic" color={!value.whois.registered ? 'green' : 'red'}>
							{!value.whois.registered ? 'Ledig' : 'Opptatt'}
							<Icon className="right" name={!value.whois.registered ? 'check' : 'x'} />
						</Label>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<p>
				{value.whois.created ? <><br/>Opprettet: {value.whois.created}</> : null}
				{value.whois.changed ? <><br/>Sist endret: {value.whois.changed}</> : null}
			</p>
		</ResourceCard.Content>)
	}

	return <ResourceCard
		icon="building"
		title="Registrar"
		titleLink={isObject(value) && typeof value.domain_id === 'string' && value.domain_id.length > 0 ? {href: '/domains/' + encodeURIComponent(value.domain_id), name: 'Vis', title: 'Vis domene', external: true} : null}
	>
		{content}
	</ResourceCard>
}