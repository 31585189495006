import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Segment, Header, Container, Button, Icon, Form, Message, Checkbox, List, Placeholder } from 'semantic-ui-react'
import { hostRegex, formatNumber, isObject, preventDefault, navigate, handleRedirect, hasAccessScope, isDomainExpired } from '../../helpers'
import DomainContact, { domainContactIsValid, getDomainContactHandle } from '../../components/domain_contact'
import NameserversInput from '../../components/nameservers_input'
import { updateDomain } from '../../api/domains'
import { Await, useNavigate, useRouteLoaderData } from 'react-router-dom'
import { useAuth, useMessages } from '../root'
import { getAccounts } from '../../api/accounts'
import DataSelect from '../../components/data_select'
import DomainContactSelect from '../../components/domain_contact_select'
import Link from '../../components/link'

export default function EditDomainPage() {
	const routerNavigateFn = useNavigate()
	const [, setMessages] = useMessages()
	const { domainId, domain, ownerChangePriceNOK } = useRouteLoaderData('domain')
	const domainAndOwnerChangePriceNOK = useMemo(() => Promise.all([domain, ownerChangePriceNOK]), [domain, ownerChangePriceNOK])

	const { user } = useAuth()
	const canChangeCustomer = hasAccessScope(user, 'domain_change_customer')

	const [newCustomer, setNewCustomer] = useState(null)

	const [newOwnerContactInfo, setNewOwnerContactInfo] = useState(null)
	const [newOwnerContactInfoDirty, setNewOwnerContactInfoDirty] = useState([])
	const [newManagedDNS, setNewManagedDNS] = useState(null)
	const [newNameservers, setNewNameservers] = useState(null)

	const [applyOwnerChangesToSharedContact, setApplyOwnerChangesToSharedContact] = useState(false)
	const [updatingDomain, setUpdatingDomain] = useState(false)

	useEffect(() => {
		Promise.resolve(domain).then(({data: domain}) => {
			if (!domain.services.domain || (domain.expire_at !== null && isDomainExpired(domain) && !domain.auto_renew)) {
				// redirect to previous page with message if user does not have access
				setMessages(messages => messages.concat({
					key: 'domain_inactive_error_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'error',
					icon: 'exclamation circle',
					content: 'Du kan ikke endre dette domenet, fordi det ikke er aktivt.'
				}))
				routerNavigateFn('/domains/' + domain.id)
			}
		})
	}, [domain, routerNavigateFn, setMessages])

	const checkIfDirty = () => {
		return newOwnerContactInfo !== null
			|| newManagedDNS !== null
			|| newNameservers !== null
			|| newCustomer !== null
	}
	const dirty = checkIfDirty()

	const checkForOwnerChange = useCallback((domain) => {
		if (
			!isObject(newOwnerContactInfo) ||
			!isObject(domain) ||
			!isObject(domain.domain_name_parts) ||
			!isObject(domain.domain_name_parts.owner_change_data) ||
			!domain.domain_name_parts.owner_change_data.hasOwnProperty(newOwnerContactInfo.contact_type) ||
			!isObject(domain.contacts) ||
			!isObject(domain.contacts.owner)
		) return null
		// non-billable owner update only supported on Norid registry
		if (domain.registry !== 'NORID') return true
		for (const field of domain.domain_name_parts.owner_change_data[newOwnerContactInfo.contact_type]) {
			if (newOwnerContactInfo[field] !== domain.contacts.owner[field]) {
				return true
			}
		}
		return false
	}, [newOwnerContactInfo])

	const reset = () => {
		setNewOwnerContactInfo(null)
		setNewOwnerContactInfoDirty([])
		setNewManagedDNS(null)
		setNewNameservers(null)
		setNewCustomer(null)
	}

	const renderCustomerTransferChanges = (originalManagedDNS, managedDNS) => {
		const tcnsLink = <Link
			href="https://developers.cloudflare.com/dns/nameservers/custom-nameservers/tenant-custom-nameservers/"
			rel="noopener nofollow noreferrer"
			target="_blank"
		>
			Tenant custom nameservers (TCNS)
		</Link>

		return <List bulleted>
			{managedDNS ? <>
				<List.Item>En Cloudflare-konto opprettes for den nye kunden, hvis de ikke allerede har en via oss</List.Item>
				<List.Item>Domenet blir opprettet i ny kunde sin Cloudflare-konto, hvis det ikke allerede eksisterer et med samme navn i kontoen</List.Item>
			</> : null}
			{originalManagedDNS && managedDNS ? <>
				<List.Item>DNS-pekere blir kopiert fra eksisterende Cloudflare DNS-sone til ny</List.Item>
				<List.Item>{tcnsLink} blir deaktivert i eksisterende Cloudflare DNS-sone og aktivert i ny</List.Item>
			</> : null}
			{!originalManagedDNS && managedDNS ? <>
				<List.Item>DNS-pekere for domeneparkering blir opprettet i ny Cloudflare DNS-sone</List.Item>
				<List.Item>{tcnsLink} blir aktivert i ny Cloudflare DNS-sone</List.Item>
			</> : null}
			<List.Item>Eksisterende Autotask Configuration Item blir inaktivert og opprettet på nytt hos ny kunde</List.Item>
			<List.Item>Eksisterende Autotask Subscription blir kansellert og opprettet på nytt hos ny kunde</List.Item>
		</List>
	}

	const customerChangeSegment = !canChangeCustomer ? null : <Segment vertical padded="very">
		<Container fluid>
			<Header size="large">
				Kunde
				<Header.Subheader>Hvilken kunde skal domenet faktureres?</Header.Subheader>
			</Header>

			<React.Suspense fallback={<>
				{renderCustomerTransferChanges(true, true)}
				<DataSelect disabled loading />
			</>}>
				<Await resolve={domain}>
					{res => {
						const originalManagedDNS = isObject(res.data) ? res.data.services.dns : true
						const managedDNS = newManagedDNS !== null ? newManagedDNS : originalManagedDNS
						return <>
							{renderCustomerTransferChanges(originalManagedDNS, managedDNS)}
							<DataSelect
								searchColumns={['customer_no', 'name']}
								apiSearch={async (query, options, requestOptions) => {
									const res = await getAccounts(query, options, requestOptions)
									handleRedirect(res, routerNavigateFn)
									return res
								}}
								query={{op: 'eq', key: 'is_customer', val: true}}
								valueKey="id"
								textKey="name"
								renderItem={item => item.customer_no + ' ' + item.name}
								value={newCustomer ?? res.data.account}
								onChange={value => setNewCustomer(value)}
							/>
						</>
					}}
				</Await>
			</React.Suspense>
		</Container>
	</Segment>

	const formContent = <>
		<div className="kit-dash-segment-container kit-dash-scroll-content">
			<Segment vertical padded="very" secondary>
				<Container fluid>
					<Header size="huge">
						Endre domene <React.Suspense fallback={<Placeholder><Placeholder.Line /></Placeholder>}>
							<Await resolve={domain}>
								{({data: domain}) => domain.domain}
							</Await>
						</React.Suspense>
						<Header.Subheader>Her kan du endre juridisk eier og DNS-servere for domenet.</Header.Subheader>
					</Header>
				</Container>
			</Segment>
			{customerChangeSegment}
			<Segment vertical padded="very">
				<Container fluid>
					<Header size="large">Juridisk eier</Header>
					<React.Suspense>
						<Await resolve={domainAndOwnerChangePriceNOK}>
							{([{data: domain}, ownerChangePriceNOK]) => {
								const isOwnerChange = checkForOwnerChange(domain)
								if (!isOwnerChange || ownerChangePriceNOK === null || ownerChangePriceNOK <= 0) {
									return null
								}
								return <Message className="small-icon" info icon="info" content={'Endringer på juridisk eier av domenet (eierskifte) koster NOK ' + formatNumber(ownerChangePriceNOK) + ' (ekskl. mva.)'} />
							}}
						</Await>
					</React.Suspense>
					<React.Suspense fallback={
						<>
							<Form.Field>
								<label>Kontaktobjekt</label>
								<DataSelect
									loading
									disabled
								/>
							</Form.Field>
							<DomainContact
								fluid
								editMode
								type="owner"
								loading
							/>
						</>
					}>
						<Await resolve={domain}>
							{res => {
								const originalOwnerContactInfo = isObject(res) && isObject(res.data) ? res.data.contacts.owner : null
								const originalOwnerContactHandle = isObject(res) && isObject(res.data) ? getDomainContactHandle(originalOwnerContactInfo, res.data.registry) : null
								const ownerContactInfo = newOwnerContactInfo !== null ? newOwnerContactInfo : originalOwnerContactInfo

								return <>
									<Form.Field>
										<label>Kontaktobjekt</label>
										<DomainContactSelect
											registry={res.data.registry}
											publicSuffix={res.data.domain_name_parts.public_suffix}
											accountId={newCustomer ? newCustomer.id : res.data.account.id}
											value={ownerContactInfo}
											onChange={contact => {
												const handle = getDomainContactHandle(contact, res.data.registry)
												setNewOwnerContactInfo(contact.id === originalOwnerContactInfo.id || (isObject(originalOwnerContactHandle) && isObject(handle) && handle.handle === originalOwnerContactHandle.handle) ? null : contact)
											}}
										/>
									</Form.Field>
									{!isObject(res) || !isObject(res.data) || !isObject(res.data.contacts.owner) ? null : <DomainContact
										fluid
										editMode
										type="owner"
										loading={!isObject(res) || !isObject(res.data)}
										disabled={updatingDomain}
										domainNameParts={isObject(res) && isObject(res.data) ? res.data.domain_name_parts : null}
										originalData={isObject(res) && isObject(res.data) && isObject(res.data.contacts) ? res.data.contacts.owner : null}
										data={ownerContactInfo}
										dirty={newOwnerContactInfoDirty}
										onChange={newOwnerContactInfo => {
											const newOwnerContactInfoDirty = Object.keys(originalOwnerContactInfo).map(key => {
												return JSON.stringify(originalOwnerContactInfo[key]) === JSON.stringify(newOwnerContactInfo[key]) ? null : key
											}).filter(key => key !== null)

											setNewOwnerContactInfo(newOwnerContactInfoDirty.length === 0 ? null : newOwnerContactInfo)
											setNewOwnerContactInfoDirty(newOwnerContactInfoDirty)
										}}
									/>}
								</>
							}}
						</Await>
					</React.Suspense>
					<React.Suspense>
						<Await resolve={domain}>
							{res => {
								const originalOwnerContactInfo = isObject(res) && isObject(res.data) ? res.data.contacts.owner : null
								const ownerContactInfo = newOwnerContactInfo !== null ? newOwnerContactInfo : originalOwnerContactInfo
								const ownerContactHandle = isObject(res) && isObject(res.data) ? getDomainContactHandle(ownerContactInfo, res.data.registry) : null
								const relatedDomains = isObject(res) && isObject(res.data) && isObject(ownerContactHandle) && Array.isArray(ownerContactHandle.owned_domains) ? ownerContactHandle.owned_domains.filter(ownedDomain => ownedDomain.id !== domainId) : []
								const isOwnerChange = checkForOwnerChange(res.data)

								if (isOwnerChange || res.data.registry !== 'NORID' || relatedDomains.length < 1) return null

								return <>
									<Checkbox
										toggle
										label="Oppdater eierinfo på domener med samme eier"
										checked={applyOwnerChangesToSharedContact}
										onChange={(_, data) => setApplyOwnerChangesToSharedContact(data.checked)}
									/>
									<p></p>
									<p className="text-secondary">Følgende domener har samme juridisk eier. <b>De blir {applyOwnerChangesToSharedContact ? 'også' : 'ikke'} oppdatert.</b></p>
									<List bulleted>
										{relatedDomains.map(relatedDomain => <List.Item key={'related_domain_' + relatedDomain.id}>
											{relatedDomain.domain}
											{relatedDomain.ace ? <span className='text-secondary'>&nbsp;({relatedDomain.ace})</span> : null}
										</List.Item>)}
									</List>
								</>
							}}
						</Await>
					</React.Suspense>
				</Container>
			</Segment>
			<Segment vertical padded="very">
				<Container fluid>
					<Header size="large">DNS-servere</Header>
					<React.Suspense fallback={
						<>
							<Form.Checkbox
								toggle
								checked
								disabled
								label="Bruk Konsept-IT sin DNS-tjeneste"
							/>
							{['ns1.example.com', 'ns2.example.com'].map((hostname, i) => <Form.Input
								key={i}
								value={hostname}
								readOnly
								disabled
							/>)}
						</>
					}>
						<Await resolve={domain}>
							{res => {
								const originalNameservers = isObject(res) && isObject(res.data) && Array.isArray(res.data.nameservers) ? res.data.nameservers.map(ns => ns.hostname) : []
								const nameservers = newNameservers !== null ? newNameservers : originalNameservers

								const originalManagedDNS = isObject(res.data) ? res.data.services.dns : true
								const managedDNS = newManagedDNS !== null ? newManagedDNS : originalManagedDNS

								return <>
									<Form.Checkbox
										toggle
										disabled={updatingDomain}
										checked={managedDNS}
										onChange={(_, data) => {
											const newManagedDNS = data.checked === originalManagedDNS ? null : data.checked;
											setNewManagedDNS(newManagedDNS)
											setNewNameservers(newManagedDNS !== false ? null : [])
										}}
										label="Bruk Konsept-IT sin DNS-tjeneste"
									/>

									<Form.Field disabled={managedDNS}>
										<label>DNS-servere</label>
									</Form.Field>
									{managedDNS ? nameservers.map((hostname, i) => <Form.Input
										key={i}
										value={hostname}
										readOnly
										disabled
									/>) : <NameserversInput
										value={nameservers}
										min={isObject(res) && isObject(res.data) ? res.data.domain_name_parts.min_ns : 1}
										max={isObject(res) && isObject(res.data) ? res.data.domain_name_parts.max_ns : 13}
										disabled={updatingDomain}
										onChange={newNameservers => setNewNameservers(originalNameservers => JSON.stringify(newNameservers) === JSON.stringify(originalNameservers) ? null : newNameservers)}
									/>}
								</>
							}}
						</Await>
					</React.Suspense>
				</Container>
			</Segment>
		</div>
		<Segment vertical padded secondary>
			<Container fluid>
				<Button type="submit" color="orange" loading={updatingDomain} disabled={updatingDomain || !dirty}>
					<Icon name="save" /> Lagre endringer
				</Button>
				<Button type="reset" loading={updatingDomain} disabled={updatingDomain || !dirty} onClick={preventDefault(() => reset())}>
					<Icon name="undo" /> Tilbakestill endringer
				</Button>
			</Container>
		</Segment>
	</>

	return <React.Suspense
		fallback={
			<Form className="kit-dash-segment-container kit-dash-scroll-container" onSubmit={preventDefault(() => {})}>
				{formContent}
			</Form>
		}
	>
		<Await resolve={domain}>
			{res => {
				const handleUpdateDomain = () => {
					const managedDNS = newManagedDNS !== null ? newManagedDNS : (isObject(res) && isObject(res.data) && isObject(res.data.services) ? res.data.services.dns : true)

					const isValidNameservers = () => {
						const data = newNameservers !== null ? newNameservers : (isObject(res) && isObject(res.data) && Array.isArray(res.data.nameservers) ? res.data.nameservers.map(ns => ns.hostname) : [])
						if (!res.data || !data) return false

						for (let i = 0; i < data.length; i++) {
							const hostname = data[i]
							if (!hostRegex.test(hostname)) return false
							if (data.indexOf(hostname) !== i) return false
						}
						return true
					}

					if (newOwnerContactInfo !== null && !domainContactIsValid(newOwnerContactInfo, res.data.domain_name_parts)) {
						setMessages(messages => messages.concat({
							key: 'owner_contact_info_validation_error_' + Math.round(new Date() / 1000).toString(),
							dismissable: true,
							type: 'error',
							icon: 'exclamation circle',
							content: 'Ugyldig kontaktinfo for juridisk eier'
						}))
						return
					}
					if (newNameservers !== null && !isValidNameservers()) {
						setMessages(messages => messages.concat({
							key: 'nameservers_validation_error_' + Math.round(new Date() / 1000).toString(),
							dismissable: true,
							type: 'error',
							icon: 'exclamation circle',
							content: 'Ugyldig(e) DNS-server(e)'
						}))
						return
					}
					setUpdatingDomain(true)
					updateDomain(domainId, {
						account: { id: isObject(newCustomer) ? newCustomer.id : null },
						contacts: { owner: newOwnerContactInfo },
						nameservers: newNameservers,
						services: { dns: managedDNS },
						apply_owner_changes_to_shared_contact: applyOwnerChangesToSharedContact,
					}).then(res => {
						setMessages(messages => messages.concat({
							key: 'update_success_' + Math.round(new Date() / 1000).toString(),
							dismissable: true,
							type: 'success',
							icon: 'check',
							content: 'Oppdatering av domenet er satt i gang.',
						}))

						// redirect back to overview on success
						navigate('/domains/' + encodeURIComponent(domainId), '_self', {}, routerNavigateFn)
					}).catch(e => {
						const errorMessage = e && e.res && e.res.error && e.res.error.message ? e.res.error.message : 'Oppdatering av domenet feilet. Prøv igjen senere eller kontakt oss dersom problemet vedvarer.'

						// Add failure message (unknown error)
						console.error('Error updating domain:', e)
						setUpdatingDomain(false)
						setMessages(messages => messages.concat({
							key: 'update_error_' + Math.round(new Date() / 1000).toString(),
							dismissable: true,
							type: 'error',
							icon: 'exclamation circle',
							content: errorMessage,
						}))
						handleRedirect(e.res, routerNavigateFn)
					})
				}

				return <Form className="kit-dash-segment-container kit-dash-scroll-container" onSubmit={preventDefault(() => handleUpdateDomain())}>
					{formContent}
				</Form>
			}}
		</Await>
	</React.Suspense>
}