import React, { useCallback, useState } from 'react'
import { Segment, Header, Container, Form, Button, Popup, Icon, Modal, Table } from 'semantic-ui-react'
import DataTable from '../components/data_table'
import { append, filterTypes, handleRedirect, isObject, omit, preventDefault } from '../helpers'
import { getCredentials } from '../api/credentials'
import { useNavigate } from 'react-router-dom'
import { useMessages } from './root'
import { createCredential, updateCredential, deleteCredential } from '../api/credentials'

const newCredentialData = {
	third_party: '',
	identifier: '',
	user: '',
	secret: '',
}

const initialCredentialEdits = {
	'new': newCredentialData
}

export default function CredentialsPage() {
	const routerNavigateFn = useNavigate()
	const [, setMessages] = useMessages()

	const apiSearch = useCallback(async (query, options, requestOptions) => {
		const res = await getCredentials(query, options, requestOptions)
		handleRedirect(res, routerNavigateFn)
		return res
	}, [routerNavigateFn])

	const [credentialEdits, setCredentialEdits] = useState(initialCredentialEdits)
	const [editingCredentials, setEditingCredentials] = useState([])
	const [savingCredentials, setSavingCredentials] = useState([])
	const [deletingCredentials, setDeletingCredentials] = useState([])
	const [promptDeleteCredentialData, setPromptDeleteCredentialData] = useState(null)
	const [activePane, setActivePane] = useState(null)
	const [dataTableRefresh, setDataTableRefresh] = useState(false)
	const [dataTableRefreshCallbacks, setDataTableRefreshCallbacks] = useState([])

	const toggleCredentialEdit = recordId => {
		console.log('toggle edit: %s', recordId)
		setEditingCredentials(editingCredentials => {
			const editing = editingCredentials.includes(recordId)
			return editing ? editingCredentials.filter(id => id !== recordId) : append(editingCredentials, recordId)
		})
		setSavingCredentials(savingCredentials => {
			const saving = savingCredentials.includes(recordId)
			return saving ? savingCredentials.filter(id => id !== recordId) : savingCredentials
		})
		setDeletingCredentials(deletingCredentials => {
			const deleting = deletingCredentials.includes(recordId)
			return deleting ? deletingCredentials.filter(id => id !== recordId) : deletingCredentials
		})
		setCredentialEdits(credentialEdits => {
			let newCredentialEdits = omit(recordId, credentialEdits)
			if (recordId === 'new') {
				newCredentialEdits = append(newCredentialEdits, {
					[recordId]: newCredentialData,
				})
			}
			return newCredentialEdits
		})
	}

	const renderCredentialForm = (data) => {
		const stateRecordId = data === null ? 'new' : data.id

		const storeEdit = (field) => preventDefault((e, elemData) => {
			const newValue = typeof elemData === 'object' && elemData.hasOwnProperty('value') ? elemData.value : e.target.value

			setCredentialEdits(credentialEdits => {
				const existingData = credentialEdits.hasOwnProperty(stateRecordId) && isObject(credentialEdits[stateRecordId]) ? credentialEdits[stateRecordId] : {}

				const getNewValue = (newField, fallback) => {
					if (field === newField) {
						return newValue
					}
					if (existingData.hasOwnProperty(newField)) {
						return existingData[newField]
					}
					if (data !== null && data.hasOwnProperty(newField)) {
						return data[newField]
					}
					return fallback
				}

				// start with fresh data if creating a new record or type edited
				let newData = {}
				if (data === null && (!credentialEdits.hasOwnProperty(stateRecordId) || !isObject(credentialEdits[stateRecordId]) || field === 'type')) {
					newData = append(newCredentialData, {
						name: getNewValue('name', '')
					})
				}

				// update data with current edit
				newData[field] = newValue

				let credentialEdit = append(existingData, newData)

				if (data !== null && credentialEdit[field] === data[field]) {
					delete credentialEdit[field]
				}
				if (credentialEdit.hasOwnProperty('data') && Object.keys(credentialEdit.data).length === 0) {
					delete credentialEdit.data
				}

				let newCredentialEdits
				if (Object.keys(credentialEdit).length === 0) {
					newCredentialEdits = omit(stateRecordId, credentialEdits)

					if (stateRecordId === 'new') {
						newCredentialEdits = append(newCredentialEdits, {
							[stateRecordId]: newCredentialData,
						})
					}
				} else {
					newCredentialEdits = append(credentialEdits, {
						[stateRecordId]: credentialEdit
					})
				}

				return newCredentialEdits
			})
		})

		const getValue = field => {
			if (credentialEdits.hasOwnProperty(stateRecordId) && isObject(credentialEdits[stateRecordId]) && credentialEdits[stateRecordId].hasOwnProperty(field)) {
				return credentialEdits[stateRecordId][field].toString()
			}
			if (data !== null && data.hasOwnProperty(field)) {
				return data[field].toString()
			} else {
				return ''
			}
		}

		const deleteRecord = preventDefault(() => {
			// abort if already deleting
			if (deletingCredentials.includes(stateRecordId)) return
			// trigger delete record modal
			setPromptDeleteCredentialData(data)
		})

		const saveRecord = preventDefault(() => {
			// abort if already saving
			if (savingCredentials.includes(stateRecordId)) return
			// set loading state, disabling buttons
			setSavingCredentials(savingCredentials => append(savingCredentials, stateRecordId))

			const changes = credentialEdits.hasOwnProperty(stateRecordId) && isObject(credentialEdits[stateRecordId]) ? credentialEdits[stateRecordId] : null
			if (changes === null) {
				toggleCredentialEdit(stateRecordId)
				return
			}
			if (data === null) {
				createCredential(changes)
					.then(res => {
						handleRedirect(res, routerNavigateFn)
						setDataTableRefresh(true)
						setDataTableRefreshCallbacks(callbacks => append(callbacks, () => toggleCredentialEdit(stateRecordId)))
					})
					.catch(e => {
						handleRedirect(e.res, routerNavigateFn)
						console.error('Unable to create credential:', e)
						setMessages(messages => messages.concat({
							key: 'credential_create_error_' + Math.round(new Date() / 1000).toString(),
							dismissable: true,
							type: 'error',
							icon: 'exclamation circle',
							title: 'En feil oppstod ved oppretting av nøkkel',
							content: e.message
						}))
						setSavingCredentials(savingCredentials => savingCredentials.filter(id => id !== stateRecordId))
					})
			} else {
				updateCredential(stateRecordId, changes)
					.then(res => {
						handleRedirect(res, routerNavigateFn)
						setDataTableRefresh(true)
						setDataTableRefreshCallbacks(callbacks => append(callbacks, () => toggleCredentialEdit(stateRecordId)))
					})
					.catch(e => {
						handleRedirect(e.res, routerNavigateFn)
						console.error('Unable to update credential ID %s:', stateRecordId, e)
						setMessages(messages => messages.concat({
							key: 'credential_update_error_' + Math.round(new Date() / 1000).toString(),
							dismissable: true,
							type: 'error',
							icon: 'exclamation circle',
							title: 'En feil oppstod ved oppdatering av nøkkel',
							content: e.message
						}))
						setSavingCredentials(savingCredentials => savingCredentials.filter(id => id !== stateRecordId))
					})
			}
		})

		const saving = savingCredentials.includes(stateRecordId)
		const deleting = deletingCredentials.includes(stateRecordId)
		const loading = saving || deleting

		let formContent = <Form.Group>
			<Form.Input
				width={4}
				disabled={loading}
				label="Tredjepart"
				value={getValue('third_party')}
				onChange={storeEdit('third_party')}
			/>
			<Form.Input
				width={4}
				disabled={loading}
				label="Identifikator"
				value={getValue('identifier')}
				onChange={storeEdit('identifier')}
			/>
			<Form.Input
				width={4}
				disabled={loading}
				label="Bruker"
				placeholder="********"
				value={getValue('user')}
				onChange={storeEdit('user')}
			/>
			<Form.Input
				width={4}
				disabled={loading}
				type="password"
				label="Hemmelighet"
				placeholder="********"
				value={getValue('secret')}
				onChange={storeEdit('secret')}
			/>
		</Form.Group>

		return <Form className="kit-dns-edit-form" onSubmit={saveRecord}>
			{formContent}
			<Form.Group className="kit-va-bottom">
				{data === null ? null : <Form.Button
					size="small"
					disabled={loading}
					loading={deleting}
					content="Slett"
					className="kit-va-expand"
					onClick={deleteRecord}
				/>}
				<Form.Button
					size="small"
					disabled={loading}
					content="Avbryt"
					onClick={preventDefault(() => {
						if (stateRecordId === 'new') {
							setActivePane(null)
						}
						toggleCredentialEdit(stateRecordId)
					})}
				/>
				<Form.Button
					size="small"
					disabled={loading}
					loading={saving}
					content="Lagre"
					color="orange"
					type="submit"
				/>
			</Form.Group>
		</Form>
	}

	const renderCredentialRow = (data, showActions = true) => {
		const editing = editingCredentials.includes(data.id)
		const saving = savingCredentials.includes(data.id)
		const deleting = deletingCredentials.includes(data.id)
		const loading = saving || deleting

		let cells = [
			data.third_party,
			data.identifier,
			'********',
			'********',
		]
		if (showActions) {
			cells.push(
				<div className="text-ha-right">
					<Button.Group size="small" compact>
						<Popup
							inverted
							content="Endre nøkkel"
							trigger={<Button
								disabled={loading}
								onClick={preventDefault(() => toggleCredentialEdit(data.id))}
							>
								Endre <Icon name={'triangle ' + (editing ? 'down' : 'right')} />
							</Button>}
						/>
					</Button.Group>
				</div>
			)
		}
		return cells
	}

	const renderCredentialDeleteModal = () => {
		if (!promptDeleteCredentialData) return null

		const deleteRecord = (recordId) => {
			// set loading state, disabling buttons
			setDeletingCredentials(deletingCredentials => append(deletingCredentials, recordId))
			setPromptDeleteCredentialData(null)

			deleteCredential(recordId)
				.then(res => {
					handleRedirect(res, routerNavigateFn)
					setDataTableRefresh(true)
					setDataTableRefreshCallbacks(callbacks => append(callbacks, () => toggleCredentialEdit(recordId)))
				})
				.catch(e => {
					handleRedirect(e.res, routerNavigateFn)
					console.error('Unable to delete credential ID %s:', recordId, e)
					setMessages(messages => messages.concat({
						key: 'credential_delete_error_' + Math.round(new Date() / 1000).toString(),
						dismissable: true,
						type: 'error',
						icon: 'exclamation circle',
						title: 'En feil oppstod ved sletting av nøkkel',
						content: e.message
					}))
					setDeletingCredentials(deletingCredentials => deletingCredentials.filter(id => id !== recordId))
				})
		}

		return <Modal
			open
			onClose={() => setPromptDeleteCredentialData(null)}
		>
			<Modal.Header>Bekreft sletting av nøkkel</Modal.Header>
			<Modal.Content>
				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Tredjepart</Table.HeaderCell>
							<Table.HeaderCell>Identifikator</Table.HeaderCell>
							<Table.HeaderCell>Bruker</Table.HeaderCell>
							<Table.HeaderCell>Hemmelighet</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						<Table.Row>{renderCredentialRow(promptDeleteCredentialData, false).map((cellContent, i) => <Table.Cell key={i}>{cellContent}</Table.Cell>)}</Table.Row>
					</Table.Body>
				</Table>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={preventDefault(() => setPromptDeleteCredentialData(null))}>
					Avbryt
				</Button>
				<Button negative onClick={preventDefault(() => deleteRecord(promptDeleteCredentialData.id))}>
					Slett
				</Button>
			</Modal.Actions>
		</Modal>
	}

	return <>
		<Segment vertical padded="very">
			<Container>
				<Header size="huge">
					Tredjeparts API-tilgangsnøkler
					<Header.Subheader>Liste over alle tredjeparts API-tilgangsnøkler</Header.Subheader>
				</Header>

				<DataTable
					columns={[
						{ key: 'third_party', text: 'Tredjepart', visible: true, sortable: true, filterType: filterTypes.search },
						{ key: 'identifier', text: 'Identifikator', visible: true, sortable: true, searchable: true, filterType: filterTypes.search },
						{ key: 'user', text: 'Bruker', visible: true },
						{ key: 'secret', text: 'Hemmelighet', visible: true },
						{ key: 4, text: '', visible: true, width: 2 },
					]}
					defaultOrder={{'third_party': 'ASC', 'identifier': 'ASC'}}
					apiSearch={apiSearch}
					dirty={Object.keys(credentialEdits).filter(key => key !== 'new').length > 0}
					onCancelEdit={() => setCredentialEdits({
						'new': newCredentialData,
					})}
					onCancelAdd={() => setCredentialEdits(credentialEdits => append(credentialEdits, {
						'new': newCredentialData,
					}))}
					renderRow={(data, columns, index) => renderCredentialRow(data)}
					renderEditRow={(data, columns, index) => renderCredentialForm(data)}
					isRowBeingEdited={(data, columns) => editingCredentials.includes(data.id)}
					extraPanes={[
						{
							key: 'add',
							icon: 'add',
							title: 'Opprett nøkkel',
							render: () => renderCredentialForm(null),
						},
					]}
					activePane={activePane}
					onChangeActivePane={pane => {
						setActivePane(pane)
						if (pane === 'add') {
							toggleCredentialEdit('new')
						}
					}}
					refresh={dataTableRefresh}
					onRefresh={() => {
						dataTableRefreshCallbacks.forEach(callback => callback())
						setDataTableRefreshCallbacks([])
						setDataTableRefresh(false)
					}}
				/>
			</Container>
		</Segment>

		{renderCredentialDeleteModal()}
	</>
}