import React, { useState } from 'react'
import { Button, Form, Header, Icon, Segment } from 'semantic-ui-react'
import { hostRegex, preventDefault } from '../helpers'

export default function NameserversInput({
	value,
	min,
	max,
	onChange,
}) {
	value = value ?? []
	value = value.concat(new Array(Math.max(0, min - value.length)).fill(''))
	min = min ?? 0
	max = max ?? 10
	onChange = onChange ?? (value => {})

	const [dirty, setDirty] = useState([])

	const handleChange = (i, hostname) => {
		setDirty(dirty.filter(v => v !== i).concat(i))
		onChange(value.slice(0, i).concat(hostname, value.slice(i + 1)))
	}

	const handleAdd = () => {
		onChange(value.concat(''))
	}

	const handleRemove = i => {
		setDirty(dirty.filter(v => v !== i))
		onChange(value.slice(0, i).concat(value.slice(i + 1)))
	}

	if (value.length === 0) {
		return <Segment placeholder>
			<Header textAlign="center">Ingen DNS-servere er lagt til.</Header>
			<Button color="orange" onClick={preventDefault(() => handleAdd())}>
				<Icon name="plus" /> Legg til
			</Button>
		</Segment>
	}

	return <>
		{value.map((hostname, i) => <Form.Group key={i}>
			<Form.Input
				required={(i + 1) <= min}
				error={dirty.includes(i) && (((i + 1) <= min && hostname.trim().length === 0) || !hostRegex.test(hostname) || value.indexOf(hostname) !== i)}
				width={16 - ((i + 1) > min ? 1 : 0)}
				placeholder={'ns' + (i + 1) + '.example.com'}
				value={hostname}
				onChange={(e, data) => handleChange(i, data.value)}
			/>
			<Form.Button
				width={1}
				icon="x"
				color="red"
				disabled={(i + 1) <= min}
				onClick={preventDefault(() => handleRemove(i))}
			/>
		</Form.Group>)}
		<Form.Button
			color="orange"
			disabled={value.length === max}
			onClick={preventDefault(() => handleAdd())}
		>
			<Icon name="plus" /> Legg til
		</Form.Button>
	</>
}