import { Response, PaginatedResponse } from '.'
import { buildQuery } from '../helpers'

// GET /api/credentials
// POST /api/credentials/search
export async function getCredentials(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/credentials' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/credentials/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/credentials
export async function createCredential(credential, requestOptions = {}) {
	return Response.request('/api/credentials', credential, requestOptions)
}

// GET /api/credentials/:credential_id
export async function getCredential(credentialId, requestOptions = {}) {
	return Response.request('/api/credentials/' + encodeURIComponent(credentialId), null, requestOptions)
}

// PUT /api/credentials/:credential_id
export async function updateCredential(credentialId, credential, requestOptions = {}) {
	return Response.request('/api/credentials/' + encodeURIComponent(credentialId), credential, Object.assign({ method: 'PUT' }, requestOptions))
}

// DELETE /api/credentials/:credential_id
export async function deleteCredential(credentialId, requestOptions = {}) {
	return Response.request('/api/credentials/' + encodeURIComponent(credentialId), null, Object.assign({ method: 'DELETE' }, requestOptions))
}
