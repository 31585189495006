import React, { useState } from 'react'
import { Segment, Container, Header, Form, Input } from 'semantic-ui-react'
import { emailAliasRegex, emailRegex, handleRedirect, navigate, preventDefault } from '../../../helpers'
import { createForwarding } from '../../../api/mail1/forwardings'
import { useNavigate, useRouteLoaderData } from 'react-router-dom'
import { useMessages } from '../../root'

const initialForwardingType = 'alias'
const defaultKitmailDomainLoaderData = {
	domain: '',
	kitmailDomain: null,
}

export default function NewKitmailForwardingPage() {
	const { domain } = useRouteLoaderData('kitmailDomain') ?? defaultKitmailDomainLoaderData
	const [, setMessages] = useMessages()
	const [forwardingType, setForwardingType] = useState(initialForwardingType)
	const [forwardingSource, setForwardingSource] = useState(domain.length > 0 && forwardingType === 'forwarding' ? '@' + domain : '')
	const [forwardingDestination, setForwardingDestination] = useState(domain.length > 0 && forwardingType === 'alias' ? '@' + domain : '')
	const routerNavigateFn = useNavigate()

	const handleCreateForwarding = () => {
		if (!(emailRegex.test(forwardingSource)) || !(emailRegex.test(forwardingDestination))) return
		createForwarding({ source: forwardingSource, destination: forwardingDestination }).then(res => {
			setMessages(messages => messages.concat({
				key: 'success_' + Math.round(new Date() / 1000).toString(),
				dismissable: true,
				type: 'success',
				icon: 'check',
				content: 'Videresendingen er opprettet.',
			}))
			navigate('/kitmail' + (domain.length > 0 ? '/domains/' + domain : '') + '/forwardings', '_self', {}, routerNavigateFn)
		}).catch(e => {
			setMessages(messages => messages.concat({
				key: 'error_' + Math.round(new Date() / 1000).toString(),
				dismissable: true,
				type: 'error',
				icon: 'exclamation circle',
				content: 'Videresendingen kunne ikke opprettes: ' + (e && e.message ? e.message : 'Ukjent feil (sjekk JavaScript-konsollen)'),
			}))
			handleRedirect(e.res, routerNavigateFn)
		})
	}

	return <>
		<Segment vertical padded="very">
			<Container>
				<Header size="huge">Opprett KitMail-videresending</Header>
				<Form onSubmit={preventDefault(() => handleCreateForwarding())}>
					{domain.length < 1 ? null : <Form.Dropdown
						label="Type videresending"
						selection
						options={[
							{ value: 'alias', text: 'Alias' },
							{ value: 'forwarding', text: 'Videresending' },
						]}
						value={forwardingType}
						onChange={(e, data) => {
							setForwardingType(data.value)
							setForwardingSource(data.value === 'forwarding' ? '@' + domain : '')
							setForwardingDestination(data.value === 'alias' ? '@' + domain : '')
						}}
					/>}
					<Form.Field>
						<label>Fra e-postadresse</label>
						<Input
							label={domain.length > 0 && forwardingType === 'forwarding' ? '@' + domain : null}
							labelPosition={forwardingType === 'forwarding' ? 'right' : null}
							placeholder={domain.length > 0 && forwardingType === 'forwarding' ? 'ola.nordmann' : 'ola.nordmann@example.com'}
							type="text"
							autoComplete="off"
							required
							value={domain.length > 0 && forwardingType === 'forwarding' ? forwardingSource.replace('@' + domain, '') : forwardingSource}
							onChange={(e, data) => {
								setForwardingSource(domain.length > 0 && forwardingType === 'forwarding' ? data.value.replace(/@.*$/g, '') + '@' + domain : data.value)
							}}
						/>
					</Form.Field>
					<Form.Field>
						<label>Til e-postadresse</label>
						<Input
							label={domain.length > 0 && forwardingType === 'alias' ? '@' + domain : null}
							labelPosition={forwardingType === 'alias' ? 'right' : null}
							placeholder={domain.length > 0 && forwardingType === 'alias' ? 'ola.nordmann' : 'ola.nordmann@example.com'}
							type="text"
							autoComplete="off"
							required
							value={domain.length > 0 && forwardingType === 'alias' ? forwardingDestination.replace('@' + domain, '') : forwardingDestination}
							onChange={(e, data) => {
								setForwardingDestination(domain.length > 0 && forwardingType === 'alias' ? data.value.replace(/@.*$/g, '') + '@' + domain : data.value)
							}}
						/>
					</Form.Field>
					<Form.Button
						type="submit"
						color="orange"
						disabled={!(emailAliasRegex.test(forwardingSource)) || !(emailRegex.test(forwardingDestination))}
						content="Opprett videresending"
					/>
				</Form>
			</Container>
		</Segment>
	</>
}