import React from 'react'
import { Button, Form, Header, Icon, Placeholder } from 'semantic-ui-react'
import { append, countryOptions, emailRegex, isObject, omit, preventDefault, renderOrgId } from '../helpers'
import ResourceCard from './resource_card'
import PhoneInput from './phone_input'
import Link from './link'

export const roleOwner   = 'owner'
export const roleAdmin   = 'admin'
export const roleTech    = 'tech'
export const roleBilling = 'billing'

export const contactTypeIndividual   = 'individual'
export const contactTypeOrganization = 'organization'

export const noridIdentityTypeLocal = 'localIdentity'
export const noridIdentityTypePerson = 'anonymousPersonIdentifier'
export const noridIdentityTypeNational = 'nationalIdentityNumber'
export const noridIdentityTypeOrganization = 'organizationNumber'

export const defaultCountry = {
	iso2: null,
	name: null,
}

export const defaultPhone = {
	number: null,
	uri: null,
}

export const defaultData = {
	id: null,
	account_id: null,
	handles: [],
	country: {...defaultCountry},
	name: null,
	phone: {...defaultPhone},
	fax: {...defaultPhone},
	email_address: null,
	city: null,
	state: null,
	street_address: null,
	postal_code: null,
	norid_identity_type: null,
	norid_identity: null,
	contact_type: contactTypeOrganization,
	organization_name: null,
	organization_number: null,
	id_card_number: null,
	created_at: null,
	updated_at: null,
}

export function getDomainContactHandle(contact, registry) {
	if (!isObject(contact)) return null
	if (!Array.isArray(contact.handles)) return null
	const handle = contact.handles.find(handle => handle.registry === registry)
	if (!isObject(handle)) return null
	return handle
}

export function domainContactIsValid(data, domainNameParts) {
	if (
		!isObject(domainNameParts) ||
		!isObject(domainNameParts.required_owner_data) ||
		!isObject(data) ||
		!data.hasOwnProperty('contact_type') ||
		!domainNameParts.required_owner_data.hasOwnProperty(data.contact_type)
	) {
		console.error('DomainContact validation check 0 failed: Invalid data')
		return false
	}

	const countryCode = ((data.country ?? { iso2: '' }).iso2 ?? '').trim().toUpperCase()
	const isNorid = domainNameParts.tld.toLowerCase() === 'no'
	const requiredFields = domainNameParts.required_owner_data[data.contact_type]
	let postalCode = data.postal_code ?? ''
	if (isNorid) postalCode = postalCode.replace(countryCode + '-', '')

	let organizationIdField = 'organization_number'
	let personalIdField = 'id_card_number'
	if (isNorid && data.norid_identity_type === noridIdentityTypeOrganization) {
		organizationIdField = 'norid_identity'
	} else if (isNorid && data.norid_identity_type === noridIdentityTypePerson) {
		personalIdField = 'norid_identity'
	}

	if (requiredFields.includes('email_address') && !emailRegex.test(data.email_address)) {
		console.error('DomainContact validation check 1 failed: Invalid email address')
		return false
	}
	if (requiredFields.includes(organizationIdField) && (data[organizationIdField] ?? '').trim().length === 0) {
		console.error('DomainContact validation check 2 failed: Invalid organization number')
		return false
	}
	if (requiredFields.includes('organization_name') && (data.organization_name ?? '').trim().length === 0) {
		console.error('DomainContact validation check 3 failed: Invalid organization name')
		return false
	}
	if (requiredFields.includes(personalIdField) && (isNorid ? !/^N\.PRI\.\d+$/.test(data[personalIdField]) : (data[personalIdField] ?? '').trim().length === 0)) {
		console.error('DomainContact validation check 4 failed: Invalid person ID/ID card number')
		return false
	}
	if (requiredFields.includes('name') && (data.name ?? '').trim().length === 0) {
		console.error('DomainContact validation check 5 failed: Invalid owner name/contact person name')
		return false
	}
	if (requiredFields.includes('street_address') && (data.street_address ?? '').trim().length === 0) {
		console.error('DomainContact validation check 6 failed: Invalid address')
		return false
	}
	if (requiredFields.includes('city') && (data.city ?? '').trim().length === 0) {
		console.error('DomainContact validation check 7 failed: Invalid city')
		return false
	}
	if (requiredFields.includes('state') && !isNorid && (data.state ?? '').trim().length === 0) {
		console.error('DomainContact validation check 8 failed: Invalid state')
		return false
	}
	if (requiredFields.includes('postal_code') && isNorid && countryCode === 'NO' ? postalCode.trim().length !== 4 : postalCode.trim().length === 0) {
		console.error('DomainContact validation check 9 failed: Invalid postal code')
		return false
	}
	if (requiredFields.includes('country.iso2') && countryCode.length === 0) {
		console.error('DomainContact validation check 10 failed: Invalid country code')
		return false
	}
	return true
}

export default function DomainContact(props) {
	const domainNameParts = props.domainNameParts ?? null
	const type = props.type ?? roleOwner
	const loading = props.loading ?? false
	const disabled = props.disabled ?? false
	const editMode = props.editMode ?? false
	const originalData = props.originalData ?? null
	const currentData = props.data ?? {...defaultData}
	const onChange = props.onChange ?? (() => {})
	const dirty = props.dirty ?? []

	let title = 'Ukjent kontakt'
	if (type === roleOwner) {
		title = 'Juridisk eier'
	} else if (type === 'admin') {
		title = 'Administrativ kontakt'
	} else if (type === 'tech') {
		title = 'Teknisk kontakt'
	} else if (type === 'billing') {
		title = 'Faktureringskontakt'
	}

	const isNorid = isObject(domainNameParts) && domainNameParts.tld.toLowerCase() === 'no'

	const countryCode = isObject(currentData.country) && typeof currentData.country.iso2 === 'string' ? currentData.country.iso2.toUpperCase() : null
	let postalCode = !loading ? currentData.postal_code ?? '' : ''
	if (isNorid) postalCode = countryCode !== null ? postalCode.replace(countryCode + '-', '') : postalCode

	let content = null
	if (editMode) {
		let organizationIdField = 'organization_number'
		let personalIdField = 'id_card_number'
		if (isNorid && currentData.norid_identity_type === noridIdentityTypeOrganization) {
			organizationIdField = 'norid_identity'
		} else if (isNorid && currentData.norid_identity_type === noridIdentityTypePerson) {
			personalIdField = 'norid_identity'
		}

		const requiredFields = type === roleOwner && isObject(domainNameParts) ? domainNameParts.required_owner_data[currentData.contact_type] : []

		const emailInput = <Form.Input
			disabled={disabled || loading}
			loading={loading}
			required={requiredFields.includes('email_address')}
			type="email"
			label="E-postadresse"
			error={requiredFields.includes('email_address') && dirty.includes('email_address') && !emailRegex.test(currentData.email_address)}
			value={(currentData.email_address ?? '')}
			onChange={(e, data) => onChange(append(currentData, { email_address: data.value }))}
		/>

		const form = <>
			<Form.Field disabled={disabled || loading}>
				<label>Type</label>
				<Button.Group fluid basic>
					<Button
						disabled={disabled || loading}
						content="Privatperson"
						active={currentData.contact_type === contactTypeIndividual}
						onClick={preventDefault(() => {
							if (currentData.contact_type === contactTypeIndividual) return
							onChange(append(originalData ?? currentData, {
								contact_type: contactTypeIndividual,
								norid_identity_type: isNorid ? noridIdentityTypePerson : null,
								norid_identity: isNorid ? ((originalData ?? currentData).norid_identity_type === noridIdentityTypePerson ? (originalData ?? currentData).norid_identity : '') : null,
								organization_name: null,
								organization_number: null
							}))
						})}
					/>
					<Button
						disabled={disabled || loading}
						content="Foretak"
						active={currentData.contact_type === contactTypeOrganization}
						onClick={preventDefault(() => {
							if (currentData.contact_type === contactTypeOrganization) return
							onChange(append(originalData ?? currentData, {
								contact_type: contactTypeOrganization,
								norid_identity_type: isNorid ? noridIdentityTypeOrganization : null,
								norid_identity: isNorid ? ((originalData ?? currentData).norid_identity_type === noridIdentityTypeOrganization ? (originalData ?? currentData).norid_identity : '') : null,
								organization_name: (originalData ?? currentData).organization_name,
								organization_number: (originalData ?? currentData).organization_number
							}))
						})}
					/>
				</Button.Group>
			</Form.Field>
			{currentData.contact_type !== contactTypeOrganization ? null : <Form.Group>
				<Form.Input
					disabled={disabled || loading}
					loading={loading}
					required={requiredFields.includes(organizationIdField)}
					width={6}
					label="Organisasjonsnummer"
					error={requiredFields.includes(organizationIdField) && dirty.includes(organizationIdField) && (currentData[organizationIdField] ?? '').trim().length === 0}
					value={currentData[organizationIdField] ?? ''}
					onChange={(e, data) => onChange(append(currentData, { [organizationIdField]: data.value}))}
				/>
				<Form.Input
					disabled={disabled || loading}
					loading={loading}
					required={requiredFields.includes('organization_name')}
					width={10}
					label="Foretaksnavn"
					error={requiredFields.includes('organization_name') && dirty.includes('organization_name') && (currentData.organization_name ?? '').trim().length === 0}
					value={(currentData.organization_name ?? '')}
					onChange={(e, data) => onChange(append(currentData, { organization_name: data.value }))}
				/>
			</Form.Group>}
			<Form.Group>
				{!requiredFields.includes(personalIdField) ? null : <Form.Input
					disabled={disabled || loading}
					loading={loading}
					required={requiredFields.includes(personalIdField)}
					width={6}
					label={isNorid ? <label>Person-ID:&ensp;<Link href="https://pid.norid.no" target="_blank" rel="noopener nofollow noreferrer"><Icon name="external" /> Lag person-ID</Link></label> : 'ID-kortnummer'}
					error={requiredFields.includes(personalIdField) && dirty.includes(personalIdField) && (isNorid ? !/^N\.PRI\.\d+$/.test(currentData[personalIdField]) : (currentData[personalIdField] ?? '').trim().length === 0)}
					pattern="N\.PRI\.\d+"
					value={currentData[personalIdField] ?? ''}
					onChange={(e, data) => onChange(append(currentData, { [personalIdField]: data.value}))}
				/>}
				<Form.Input
					disabled={disabled || loading}
					loading={loading}
					required={requiredFields.includes('name')}
					width={requiredFields.includes(personalIdField) ? 10 : 16}
					label={(currentData.contact_type === contactTypeIndividual && type === roleOwner ? '' : 'Kontaktperson: ') + 'Fullt navn'}
					error={requiredFields.includes('name') && dirty.includes('name') && (currentData.name ?? '').trim().length === 0}
					value={(currentData.name ?? '')}
					onChange={(e, data) => onChange(append(currentData, { name: data.value }))}
				/>
			</Form.Group>
			<Form.Group widths="equal">
				<PhoneInput
					disabled={disabled || loading}
					required={requiredFields.includes('phone.number')}
					label="Telefonnummer"
					value={currentData.phone.number ?? ''}
					isDirty={dirty.includes('phone')}
					onChange={value => onChange(append(currentData, { phone: { number: value } }))}
				/>
				{isNorid ? emailInput : <PhoneInput
					disabled={disabled || loading}
					required={requiredFields.includes('fax.number')}
					label="Faks-nummer"
					value={currentData.fax.number ?? ''}
					isDirty={dirty.includes('fax')}
					onChange={value => onChange(append(currentData, { fax: { number: value } }))}
				/>}
			</Form.Group>
			{isNorid ? null : emailInput}
			<Form.Input
				disabled={disabled || loading}
				loading={loading}
				required={requiredFields.includes('street_address')}
				label="Adresse"
				error={requiredFields.includes('street_address') && dirty.includes('street_address') && (currentData.street_address ?? '').trim().length === 0}
				value={(currentData.street_address ?? '')}
				onChange={(e, data) => onChange(append(currentData, { street_address: data.value }))}
			/>
			<Form.Group>
				<Form.Input
					disabled={disabled || loading}
					loading={loading}
					required={requiredFields.includes('postal_code')}
					width={4}
					label="Postnummer"
					pattern={isNorid && countryCode === 'NO' ? '\\d{4}' : null}
					error={requiredFields.includes('postal_code') && dirty.includes('postal_code') && (isNorid && countryCode === 'NO' ? postalCode.trim().length !== 4 : postalCode.trim().length === 0)}
					value={postalCode}
					onChange={(e, data) => {
						let newPostalCode = data.value
						if (isNorid) newPostalCode = (countryCode !== null ? countryCode + '-' : '') + newPostalCode
						onChange(append(currentData, { postal_code: newPostalCode }))
					}}
				/>
				<Form.Input
					disabled={disabled || loading}
					loading={loading}
					required={requiredFields.includes('city')}
					width={isNorid ? 12 : 6}
					label="By"
					error={requiredFields.includes('city') && dirty.includes('city') && (currentData.city ?? '').trim().length === 0}
					value={(currentData.city ?? '')}
					onChange={(e, data) => onChange(append(currentData, { city: data.value }))}
				/>
				{isNorid ? null : <Form.Input
					disabled={disabled || loading}
					loading={loading}
					required={requiredFields.includes('state')}
					width={6}
					label="Delstat/provins"
					error={requiredFields.includes('state') && dirty.includes('state') && !isNorid && (currentData.state ?? '').trim().length === 0}
					value={(currentData.state ?? '')}
					onChange={(e, data) => onChange(append(currentData, { state: data.value }))}
				/>}
			</Form.Group>
			<Form.Dropdown
				disabled={disabled || loading}
				loading={loading}
				required={requiredFields.includes('country.iso2')}
				selection
				search
				label="Land"
				options={countryOptions.no}
				value={currentData.country.iso2}
				error={requiredFields.includes('country.iso2') && dirty.includes('country') && ((currentData.country ?? { iso2: '' }).iso2 ?? '').trim().length === 0}
				onChange={(e, countryData) => onChange(append(currentData, { country: { iso2: countryData.value, name: countryData.text } }))}
			/>
		</>
		if (editMode && editMode !== 'boxed') return form
		content = <ResourceCard.Content>{form}</ResourceCard.Content>
	} else {
		let header = null
		if (loading) {
			header = <Placeholder>
				<Placeholder.Header>
					<Placeholder.Line />
					<Placeholder.Line />
					<Placeholder.Line />
				</Placeholder.Header>
			</Placeholder>
		} else {
			let identity = null
			if (isNorid && currentData.norid_identity_type === noridIdentityTypeOrganization) {
				identity = <> (org.nr: {renderOrgId(currentData.norid_identity)})</>
			} else if (!isNorid && currentData.contact_type === contactTypeOrganization && currentData.organization_number) {
				identity = <> (org.nr: {renderOrgId(currentData.organization_number)})</>
			} else if (isNorid && currentData.norid_identity_type === noridIdentityTypePerson) {
				identity = <> (PID: {currentData.norid_identity})</>
			} else if (!isNorid && currentData.contact_type === contactTypeIndividual && currentData.id_card_number) {
				identity = <> (ID-kortnummer: {currentData.id_card_number})</>
			}
			header = <Header size="small">
				{type !== roleOwner ? null : <Header.Subheader>{currentData.contact_type === contactTypeIndividual ? 'Privatperson' : 'Foretak'}{identity}</Header.Subheader>}
				{currentData.contact_type === contactTypeIndividual ? currentData.name : currentData.organization_name}
			</Header>
		}
		let contactInfo = null
		if (loading) {
			contactInfo = <Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		} else {
			let contactInfoParts = []
			if (currentData.phone.number) {
				contactInfoParts.push(<Link key="phoneNumber" href={currentData.phone.uri} target="_blank">{currentData.phone.number}</Link>)
			}
			const email_addresses = typeof currentData.email_address === 'string' ? [currentData.email_address] : currentData.email_address
			if (typeof email_addresses === 'object' && Array.isArray(email_addresses)) {
				for (const email_address of email_addresses) {
					if (contactInfoParts.length > 0) {
						contactInfoParts.push(<br key="spacer" />)
					}
					contactInfoParts.push(<Link key="emailAddress" href={'mailto:' + email_address} target="_blank">{email_address}</Link>)
				}
			}
			if (contactInfoParts.length > 0) {
				contactInfo = <p>{contactInfoParts}</p>
			}
		}
		let address = null
		if (loading) {
			address = <Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		} else if (currentData.street_address !== null) {
			address = <p>
				{typeof currentData.street_address === 'object' && Array.isArray(currentData.street_address) ? currentData.street_address.map((line, i) => <React.Fragment key={'street_address_' + i}>{i > 0 ? <br/> : null}{line}</React.Fragment>) : currentData.street_address}<br/>
				{postalCode} {currentData.city}<br/>
				{currentData.country.name}
			</p>
		}
		let contactPerson = null
		if (loading) {
			contactPerson = <Placeholder.Header>
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder.Header>
		} else if (currentData.contact_type === contactTypeOrganization && currentData.name) {
			contactPerson = <Header size="small">
				<Header.Subheader>Kontaktperson</Header.Subheader>
				{currentData.name}
			</Header>
		}
		content = <>
			<ResourceCard.Content>
				{header}
				{contactPerson}
				{contactInfo}
			</ResourceCard.Content>
			{!loading && currentData.street_address === null ? null : <ResourceCard.Content children={address} />}
		</>
	}

	return <ResourceCard
		{...omit(['domainNameParts', 'type', 'loading', 'disabled', 'editMode', 'originalData', 'data', 'onChange', 'icon', 'title', 'titleLink', 'children', 'className', 'dirty'], props)}
		className="kit-domain-contact-card"
		icon="user"
		title={title}
		children={content}
	/>
}