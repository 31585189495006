import React, { useState } from 'react'
import { Segment, Container, Header, Form, Icon } from 'semantic-ui-react'
import { handleRedirect, navigate, preventDefault } from '../../helpers'
import { sendSMSMessage, STATUS_CODES } from '../../api/sms'
import PhoneInput from '../../components/phone_input'
import { Await, useLoaderData, useNavigate, useRouteLoaderData } from 'react-router-dom'
import Link from '../../components/link'
import { useMessages } from '../root'

export async function loader({ request }) {
	const url = new URL(request.url)
	const recipient = url.searchParams.get('recipient') ?? ''
	const contents = url.searchParams.get('contents') ?? ''
	return { recipient, contents }
}

export default function NewSmsPage() {
	const { gatewayId, gateway } = useRouteLoaderData('smsGateway')
	const { recipient: initialRecipient, contents: initialContents } = useLoaderData()
	const [, setMessages] = useMessages()
	const [recipient, setRecipient] = useState(initialRecipient)
	const [recipientDirty, setRecipientDirty] = useState(false)
	const [contents, setContents] = useState(initialContents)
	const [sending, setSending] = useState(false)
	const routerNavigateFn = useNavigate()

	const sendMessage = () => {
		if (sending) return;
		setSending(true)
		sendSMSMessage(gatewayId, recipient, contents)
			.then((res) => {
				// error if no messages were received
				if (res.data.length === 0) {
					console.error('Error sending SMS')
					setMessages(messages => messages.concat({
						key: 'send_sms_error_' + Math.round(new Date() / 1000).toString(),
						dismissable: true,
						type: 'error',
						icon: 'exclamation circle',
						title: 'Feil ved sending av tekstmelding',
						content: 'Kunne ikke sende tekstmelding: Ukjent feil'
					}))
					setSending(false)
					return
				}

				// check each returned message for errors, stop on first error
				for (const message of res.data) {
					if (message.delivery_status !== 1000 && message.delivery_status !== 2000) {
						console.error('Error sending SMS')
						setMessages(messages => messages.concat({
							key: 'send_sms_error_' + Math.round(new Date() / 1000).toString(),
							dismissable: true,
							type: 'error',
							icon: 'exclamation circle',
							title: 'Feil ved sending av tekstmelding',
							content: 'Kunne ikke sende tekstmelding: ' + (STATUS_CODES.no.hasOwnProperty(message.delivery_status) ? STATUS_CODES.no[message.delivery_status] : 'Ukjent feil')
						}))
						setSending(false)
						return
					}
				}

				// redirect to message list
				navigate('/sms/gateways/' + encodeURIComponent(gatewayId) + '/messages', '_self', {}, routerNavigateFn)
			})
			.catch(e => {
				console.error('Error sending SMS:', e)
				setMessages(messages => messages.concat({
					key: 'send_sms_error_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'error',
					icon: 'exclamation circle',
					title: 'Feil ved sending av tekstmelding',
					content: 'Kunne ikke sende tekstmelding: ' + e.message
				}))
				setSending(false)
				handleRedirect(e.res, routerNavigateFn)
			})
	}

	return <>
		<Segment vertical padded="very">
			<Container>
				<Header size="huge">Ny tekstmelding</Header>
				<Form onSubmit={preventDefault(() => sendMessage())}>
					<React.Suspense fallback={
						<Form.Input
							readOnly
							loading
							disabled
							label="Avsender"
						/>
					}>
						<Await resolve={gateway}>
							{({data: gateway}) => <Form.Input
								readOnly
								label="Avsender"
								value={gateway.default_sender}
							/>}
						</Await>
					</React.Suspense>
					<p className="text-secondary">For å velge en annen avsender, velg en annen <Link href="/sms/gateways">SMS-gateway</Link>.</p>
					<PhoneInput
						required
						label="Mottaker"
						value={recipient}
						isDirty={recipientDirty}
						onChange={value => {
							setRecipient(value)
							setRecipientDirty(value !== initialRecipient)
						}}
					/>
					<Form.TextArea
						required
						label="Melding"
						value={contents}
						onChange={(e, data) => setContents(data.value)}
					/>
					<Form.Button
						type="submit"
						color="orange"
						disabled={sending}
						loading={sending}
					>
						<Icon name="send" /> Send tekstmelding
					</Form.Button>
				</Form>
			</Container>
		</Segment>
	</>
}