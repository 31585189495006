import { Response, PaginatedResponse } from '..'
import { buildQuery } from '../../helpers'

// GET /api/autotask/ticket-additional-configuration-items
// POST /api/autotask/ticket-additional-configuration-items/search
export async function getTicketAdditionalConfigurationItems(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/autotask/ticket-additional-configuration-items' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/autotask/ticket-additional-configuration-items/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/autotask/ticket-additional-configuration-items
export async function createTicketAdditionalConfigurationItem(data, requestOptions = {}) {
	return Response.request('/api/autotask/ticket-additional-configuration-items', data, requestOptions)
}

// GET /api/autotask/ticket-additional-configuration-items/:id
export async function getTicketAdditionalConfigurationItem(id, requestOptions = {}) {
	return Response.request('/api/autotask/ticket-additional-configuration-items/' + encodeURIComponent(id), null, requestOptions)
}

// PUT /api/autotask/ticket-additional-configuration-items/:id
export async function updateTicketAdditionalConfigurationItem(id, data, requestOptions = {}) {
	return Response.request('/api/autotask/ticket-additional-configuration-items/' + encodeURIComponent(id), data, Object.assign({ method: 'PUT' }, requestOptions))
}

// DELETE /api/autotask/tickets/:ticket_id/additional-configuration-items/:id
export async function deleteTicketAdditionalConfigurationItem(ticket_id, id, requestOptions = {}) {
	return Response.request('/api/autotask/tickets/' + encodeURIComponent(ticket_id) + '/additional-configuration-items/' + encodeURIComponent(id), null, Object.assign({ method: 'DELETE' }, requestOptions))
}