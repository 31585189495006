import React from 'react'

export default function Align({ children, vertical, verticalExpand, horizontal, horizontalExpand, expand, className }) {
	let dom = children ? children : null
	if (horizontal) {
		dom = <div className={'kit-ha' + (typeof horizontal === 'string' ? ' kit-ha-' + horizontal : '') + (expand || horizontalExpand ? ' kit-ha-expand' : '') + (className ? ' ' + className : '')}>{dom}</div>
	}
	if (vertical) {
		dom = <div className={'kit-va' + (typeof vertical === 'string' ? ' kit-va-' + vertical : '') + (expand || verticalExpand ? ' kit-va-expand' : '') + (className ? ' ' + className : '')}>{dom}</div>
	}
	if (!vertical && !horizontal) {
		dom = <div className={'kit-va kit-va-expand' + (className ? ' ' + className : '')}>{dom}</div>
	}
	return dom
}
