import { Response, PaginatedResponse } from '..'
import { buildQuery } from '../../helpers'

// GET /api/autotask/contract-services
// POST /api/autotask/contract-services/search
export async function getContractServices(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/autotask/contract-services' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/autotask/contract-services/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/autotask/contract-services
export async function createContractService(data, requestOptions = {}) {
	return Response.request('/api/autotask/contract-services', data, requestOptions)
}

// GET /api/autotask/contract-services/:id
export async function getContractService(id, requestOptions = {}) {
	return Response.request('/api/autotask/contract-services/' + encodeURIComponent(id), null, requestOptions)
}

// PUT /api/autotask/contract-services/:id
export async function updateContractService(id, data, requestOptions = {}) {
	return Response.request('/api/autotask/contract-services/' + encodeURIComponent(id), data, Object.assign({ method: 'PUT' }, requestOptions))
}
