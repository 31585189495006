import React from 'react'
import { Segment, Container, Header, Menu, Button, Grid, Icon, Placeholder } from 'semantic-ui-react'
import { navigate, preventDefault } from '../../../helpers'
import { Await, Outlet, useMatches, useNavigate, useRouteLoaderData } from 'react-router-dom'

export default function KitmailDomainPage({ children }) {
	const { domain, kitmailDomain } = useRouteLoaderData('kitmailDomain')
	const routerNavigateFn = useNavigate()
	const matches = useMatches()
	const { id: activeTab } = matches[matches.length - 1]

	return <>
		<Segment vertical padded="very">
			<Container>
				<Grid stackable>
					<Grid.Row>
						<React.Suspense fallback={
							<Grid.Column width={10}>
								<Header size="huge">
									<Placeholder>
										<Placeholder.Header>
											<Placeholder.Line />
										</Placeholder.Header>
									</Placeholder>
								</Header>
								<Placeholder>
									<Placeholder.Line />
								</Placeholder>
							</Grid.Column>
						}>
							<Await resolve={kitmailDomain}>
								{({data: kitmailDomain}) => <Grid.Column width={10}>
									<Header size="huge">
										KitMail-domene <code>{domain}</code>
										{kitmailDomain.external ? <Header.Subheader>Eksternt domene (kun SMTP)</Header.Subheader> : null}
									</Header>
									<p>E-post fra KitMail-server leveres til domenet med transportmetode <code>{kitmailDomain && kitmailDomain.transport ? kitmailDomain.transport : 'maildrop'}</code></p>
								</Grid.Column>}
							</Await>
						</React.Suspense>

						<Grid.Column width={6} textAlign="right">
							<Button
								as="a"
								href={'/kitmail/domains/' + domain + '/edit'}
								onClick={preventDefault(e => navigate('/kitmail/domains/' + domain + '/edit', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
								content={<><Icon name="edit" /> Endre domene</>}
							/>
							<Button
								as="a"
								negative
								href={'/kitmail/domains/' + domain + '/delete'}
								onClick={preventDefault(e => navigate('/kitmail/domains/' + domain + '/delete', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
								content={<><Icon name="delete" /> Slett domene</>}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
		</Segment>

		<Menu attached tabular>
			<Container>
				<Menu.Item
					link
					as="a"
					href={'/kitmail/domains/' + domain + '/users'}
					active={activeTab === 'kitmailDomainUsers'}
					onClick={preventDefault(e => navigate('/kitmail/domains/' + domain + '/users', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
					content="Brukere"
				/>
				<Menu.Item
					link
					as="a"
					href={'/kitmail/domains/' + domain + '/forwardings'}
					active={activeTab === 'kitmailDomainForwardings'}
					onClick={preventDefault(e => navigate('/kitmail/domains/' + domain + '/forwardings', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
					content="Videresendinger"
				/>
			</Container>
		</Menu>

		<Segment vertical padded="very">
			<Container>
				{children}
				<Outlet />
			</Container>
		</Segment>
	</>
}